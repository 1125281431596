import { logger } from "../utils/logger/logger";
import { getOptions } from "../../options/options";
import { DocumentData, DocUploadData, NetworkErrorId } from "../types/types";
import ResponseTimeLogger from "../utils/responseTimeLogger/ResponseTimeLogger";
import { getSafe } from "../utils/objects";
import { isSuccessiveCall } from "./NetworkDebugUtils";

const defaultJsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const defaultUploadHeaders = {
  "Content-Type": "application/json",
};

export const fetchWithTimeout = async (
  url: string,
  requestInit: RequestInit = {},
  /** optional b/c sometimes it has already been called */
  checkSuccessive = true,
) => {
  const timeout = getOptions().httpRequestTimeout;

  let urlSearch;
  try {
    const urlObj = new URL(url);
    urlSearch = urlObj?.search || "";
  } catch (e) {
    logger.error(e, undefined, { url });
  }

  const errorAttributes = {
    url: url.replace(urlSearch, ""),
    queryStr: urlSearch?.search,
    method: requestInit.method,
  };

  if (checkSuccessive) {
    // report errors to offsite logs, if applicable
    isSuccessiveCall(url, requestInit, errorAttributes);
  }

  return new Promise<Response>((resolve, reject) => {
    const timeoutTimer = setTimeout(async () => {
      const networkErrorId: NetworkErrorId = "requestTimeout";
      logger.error("Request timeout", undefined, {
        ...errorAttributes,
        timeoutMs: `${timeout}`,
      });

      reject(new Error(networkErrorId));
    }, timeout);

    return fetch(url, requestInit)
      .then(resolve, async (error) => {
        logger.error("Failed to fetch", error, errorAttributes);
        const networkErrorId: NetworkErrorId = "failedToFetch";

        reject(new Error(networkErrorId));
      })
      .finally(() => {
        clearTimeout(timeoutTimer);
      });
  });
};

const processResponse = async (
  response: Response,
  timeLog: ResponseTimeLogger,
): Promise<Object> => {
  let responseData: Promise<Object>;
  try {
    responseData = await response.json();
  } catch (error) {
    // Handle 204 response
    responseData = Promise.resolve({});
  } finally {
    timeLog.logNow();
    logger.info("Response received", responseData);
  }
  return responseData;
};

export const PostJson = async (url: string, body: Object, headers = {}): Promise<Object> => {
  const timeLog = new ResponseTimeLogger(url);
  const response = await fetchWithTimeout(url, {
    headers: { ...defaultJsonHeaders, ...headers },
    method: "POST",
    body: JSON.stringify(body),
  });
  return processResponse(response, timeLog);
};

export const DeleteJson = async (url: string, headers = defaultJsonHeaders): Promise<Object> => {
  const timeLog = new ResponseTimeLogger(url);
  const response = await fetchWithTimeout(url, {
    headers,
    method: "DELETE",
  });
  return processResponse(response, timeLog);
};

let previousPromise;
export const GetJson = async (url: string, headers = defaultJsonHeaders): Promise<Object> => {
  const timeLog = new ResponseTimeLogger(url);
  let response;
  if (isSuccessiveCall(url, headers, {}) && previousPromise) {
    response = previousPromise;
  } else {
    const thePromise = fetchWithTimeout(
      url,
      {
        headers,
        method: "GET",
      },
      false, // don't check successive again, it overwrites the previous hash
    );
    previousPromise = thePromise;
    response = thePromise;
  }
  return processResponse(await response, timeLog);
};

export const GetResponse = async (url: string, headers = defaultJsonHeaders): Promise<Object> =>
  fetchWithTimeout(url, {
    headers,
    method: "GET",
  });

export const createFormData = (files: File[]) => {
  const formData = new FormData();
  files.forEach((file, index) => {
    if (file) {
      formData.append(`file${index}`, file);
    }
  });
  return formData;
};

export const createDocUploadData = (files: File[]): DocUploadData[] =>
  files
    .filter((file) => !!file)
    .map((file) => ({
      fileName: file.name,
      mimeType: file.type,
      fileSize: file.size,
    }));

export const PostFiles = async (
  url: string,
  files: File[],
  headers = defaultUploadHeaders,
): Promise<Object> => {
  const timeLog = new ResponseTimeLogger(url);
  logger.info(`PostFiles posting to ${url}`, files);
  const docUploadData: string = JSON.stringify({
    files: createDocUploadData(files),
  });

  const docUploadRsp: Response = await fetch(url, {
    headers,
    method: "POST",
    body: docUploadData,
  });
  const docUploadRspData = await docUploadRsp.json();

  const documents = getSafe(() => docUploadRspData.documents);
  const submissionUrl = getSafe(() => docUploadRspData.submissionUrl);

  if (documents && submissionUrl) {
    await Promise.all(
      documents.map((document: DocumentData, index: number) =>
        fetch(document.uploadUrl, {
          method: "PUT",
          body: files[index],
        }),
      ),
    );

    const completeDocUploadRsp = await fetch(submissionUrl, {
      headers,
      method: "POST",
    });

    // Normal return flow
    return processResponse(completeDocUploadRsp, timeLog);
  }

  // return initial docUpload response if an error occured
  return docUploadRspData;
};
