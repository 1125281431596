import React from "react";

const LoadingSpinnerComponent = () => (
  <div className="sid-spinner">
    <div className="sid-spinner__frame" />
    <div className="sid-spinner__frame" />
    <div className="sid-spinner__frame" />
    <div className="sid-spinner__frame" />
  </div>
);

export { LoadingSpinnerComponent };
