/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { resetRefs } from "../../lib/refs/refs";
import {
  VerificationService,
  DriverLicensePersonalInfoViewModel,
  DriverLicensePersonalInfoResponse,
  State,
  FormSelectChoice,
  FormFieldConfig,
} from "../../lib/types/types";
import {
  getAvailableStateChoices,
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { DriverLicenseNumberComponent as DriverLicenseNumber } from "../FormFields/DriverLicenseNumber/DriverLicenseNumberComponent";
import { StateSelectComponent } from "../FormFields/State/StateSelectComponent";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { getOptions } from "../../options/options";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepDriverLicensePersonalInfoComponentProps {
  intl: any;
  verificationService: VerificationService;
}

export const StepDriverLicensePersonalInfo = ({
  intl,
  verificationService,
}: StepDriverLicensePersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as DriverLicensePersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as DriverLicensePersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const updateIdentityViewModel = (key: keyof DriverLicensePersonalInfoViewModel, value: any) => {
    const nextState: DriverLicensePersonalInfoViewModel = produce(
      viewModel,
      (draft: DriverLicensePersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  // Refs should be reset to ensure the field order isn't affected by the previous step
  resetRefs();

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="drivers-license"
      />

      <ChangeLocaleV2Component verificationService={verificationService} />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateIdentityViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateIdentityViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Personal email address is recommended"
          />
        }
        onChange={(newValue) => {
          updateIdentityViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateIdentityViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}
      <StateSelectComponent
        isRequired
        options={getAvailableStateChoices(verificationService.programTheme, intl)}
        value={viewModel.state || undefined}
        isErrored={Boolean(fieldValidationErrors.state)}
        onChange={async (stateChoice: FormSelectChoice<State, string>) => {
          const stateValue = stateChoice ? stateChoice.value : undefined;
          updateIdentityViewModel("state", stateValue);
          updateFieldValidationErrorsByFieldId("state", stateValue, verificationService);
        }}
        label={
          <FormattedHTMLMessage id="driverLicenseState" defaultMessage="Driver license state" />
        }
      />
      <DriverLicenseNumber
        value={viewModel.driverLicenseNumber}
        isErrored={!!fieldValidationErrors.driverLicenseNumber}
        isRequired
        onChange={(newValue) => {
          updateIdentityViewModel("driverLicenseNumber", newValue);
          updateFieldValidationErrorsByFieldId(
            "driverLicenseNumber",
            newValue,
            verificationService,
          );
        }}
      />

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateIdentityViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <PersonalInfoStepSubmitButton
        step={VerificationStepsEnum.collectDriverLicensePersonalInfo}
        verificationService={verificationService}
        viewModel={viewModel}
        failedInstantMatch={failedInstantMatch}
        buttonText={
          <FormattedHTMLMessage id="verifyMyIdentityStatus" defaultMessage="Verify My Identity" />
        }
      />
    </PersonalInfoWrapper>
  );
};

export const StepDriverLicensePersonalInfoComponent = injectIntl(StepDriverLicensePersonalInfo);
