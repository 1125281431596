import { logger } from "../logger/logger";
import { addSchool } from "../../ServerApi/OrganizationApiClient";

export const submitAddSchoolRequest = async (
  canSubmitForm,
  requestOrganizationService,
  dispatch,
) => {
  const { viewModel } = requestOrganizationService;

  if (!canSubmitForm()) {
    return;
  }

  const body = {
    programId: requestOrganizationService.programId,
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    schoolName: viewModel.orgName,
    schoolDomain: viewModel.orgDomain,
    schoolCountry: viewModel.countryChoice.value,
  };
  try {
    updateIsLoading(dispatch, true);
    await addSchool(body).then(() => {
      updateIsLoading(dispatch, false);
      dispatch({ type: "SET_SUBMIT", payload: true });
    });
  } catch (e) {
    logger.error(e, "submitAddSchoolRequest");
    dispatch({ type: "SET_ERROR", payload: true });
  }
};

const updateIsLoading = (dispatch, isLoading: boolean) => {
  dispatch({ type: "SET_LOADING", payload: isLoading });
};

export const getOrgNameWithoutLocation = (organizationName: string) => {
  // Matches parenthetical text that displays location after the org name
  const regex = /(\s\([A-Za-z]+(?: [A-Za-z]+)*),+ ([A-Za-z]+\))/;
  return organizationName.replace(regex, "");
};
