import React from "react";
// eslint-disable-next-line import/extensions
import ReactDOMServer from "react-dom/server";
import { ReactNode } from "react-redux";

interface TagMap {
  [key: string]: string | ((content: string) => string | ReactNode);
}

function replaceCustomTags(text: string | JSX.Element, tagmap: TagMap): string {
  if (typeof text !== "string") return;
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(text, "text/html");

  Object.entries(tagmap).forEach(([tagName, result]) => {
    const customElement = htmlDoc.querySelector(tagName);
    if (!customElement) {
      return;
    }
    let replacement: string | ReactNode;
    const content = customElement.innerHTML;

    if (typeof result === "function") {
      replacement = result(content);
    } else {
      replacement = result;
    }

    if (replacement) {
      if (typeof replacement === "string") {
        customElement.insertAdjacentHTML("afterend", replacement);
      } else {
        const rendered = ReactDOMServer.renderToStaticMarkup(<>{replacement}</>);

        customElement.insertAdjacentHTML("afterend", rendered);
      }

      customElement.parentElement.removeChild(customElement);
    }
  });

  return htmlDoc.body.innerHTML;
}

/**
 * Given a tagmap will return a function that can be passed directly as the
 * children of an FormattedHTMLMessage. See the Readme
 *
 */
export const makeFormattedHTMLTagReplacer =
  (tagmap: TagMap): ((text: string | JSX.Element) => ReactNode) =>
  (text) => {
    const newText = replaceCustomTags(text, tagmap);
    return <span dangerouslySetInnerHTML={{ __html: newText }} />;
  };
