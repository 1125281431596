import React, { useEffect } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { VerificationService, ViewModel } from "../../lib/types/types";
import { submitForm } from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { CollectInfoSteps, VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { setRef } from "../../lib/refs/refs";
import { getOptions } from "../../options/options";
import { logger } from "../../lib/utils/logger/logger";
import {
  CollectStepStartEvent,
  CollectStepSubmitEvent,
  CustomMetricNames,
  EventName,
  recordGaEvent,
} from "../../lib/GoogleAnalytics/ga";

interface PersonalInfoStepSubmitButtonProps {
  step: CollectInfoSteps;
  verificationService: VerificationService;
  viewModel: ViewModel;
  buttonText: React.ReactNode;
  failedInstantMatch?: boolean;
}

const wrapperClassNames: Record<CollectInfoSteps, string> = {
  collectStudentPersonalInfo: "sid-student--submit",
  collectSeniorPersonalInfo: "sid-senior-submit",
  collectTeacherPersonalInfo: "sid-teacher-submit",
  collectMoverPersonalInfo: "sid-mover-submit",
  collectInactiveMilitaryPersonalInfo: "sid-inactive-military-submit sid-military-submit",
  collectActiveMilitaryPersonalInfo: "sid-active-military-personal-submit sid-military-submit",
  collectMilitaryStatus: "",
  collectMemberPersonalInfo: "sid-member-submit",
  collectMedicalProfessionalPersonalInfo: "sid-medical-submit",
  collectLowIncomePersonalInfo: "sid-low-income-submit",
  collectLicensedProfessionalPersonalInfo: "sid-licensed-professional-submit",
  collectHybridIdentityPersonalInfo: "sid-hybrid-identity-submit",
  collectGeneralIdentityPersonalInfo: "sid-general-identity-submit",
  collectDriverLicensePersonalInfo: "sid-drivers-license-submit",
  collectFirstResponderPersonalInfo: "sid-first-responder-personal-info-submit",
  collectEmployeePersonalInfo: "sid-employment-submit",
  collectAgePersonalInfo: "sid-age-personal-info-submit",
  collectIdentifier: "sid-identifier-submit",
  collectPersonalInfo: "sid-personal-info-submit",
};

const buttonClassNames: Record<CollectInfoSteps, string> = {
  collectStudentPersonalInfo: "sid-student-submit-btn",
  collectSeniorPersonalInfo: "sid-senior-submit-btn",
  collectTeacherPersonalInfo: "sid-teacher-submit-btn",
  collectMoverPersonalInfo: "sid-mover-submit-btn",
  collectInactiveMilitaryPersonalInfo: "sid-inactive-military-submit-btn sid-military-submit-btn",
  collectActiveMilitaryPersonalInfo:
    "sid-active-military-personal-submit-btn sid-military-submit-btn",
  collectMilitaryStatus: "",
  collectMemberPersonalInfo: "sid-member-submit-btn",
  collectMedicalProfessionalPersonalInfo: "sid-medical-submit-btn",
  collectLowIncomePersonalInfo: "sid-low-income-submit-btn",
  collectLicensedProfessionalPersonalInfo: "sid-licensed-professional-submit-btn",
  collectHybridIdentityPersonalInfo: "sid-hybrid-identity-submit-btn",
  collectGeneralIdentityPersonalInfo: "sid-general-identity-submit-btn",
  collectDriverLicensePersonalInfo: "sid-drivers-license-submit-btn",
  collectFirstResponderPersonalInfo: "sid-first-responder-submit-btn",
  collectEmployeePersonalInfo: "sid-employment-submit-btn",
  collectAgePersonalInfo: "sid-age-personal-info-btn",
  collectIdentifier: "sid-identifier-submit-btn",
  collectPersonalInfo: "sid-personal-info-submit-btn",
};

const usesWrapperID: CollectInfoSteps[] = [
  VerificationStepsEnum.collectMoverPersonalInfo,
  VerificationStepsEnum.collectStudentPersonalInfo,
  VerificationStepsEnum.collectEmployeePersonalInfo,
];

const COLLECT_INFO_START_MARK = "collect-info-start-mark";
const COLLECT_INFO_SUBMISSION_TIME = "collect-info-submission-time";
const COLLECT_INFO_SUBMIT = "collect-info-submit";

/**
 * A shared wrapper for all personal info collect steps.
 */
export const PersonalInfoStepSubmitButton: React.FC<PersonalInfoStepSubmitButtonProps> = ({
  step,
  verificationService,
  viewModel,
  buttonText,
  failedInstantMatch = false,
}) => {
  const ldClient = useLDClient();

  useEffect(() => {
    // set mark when this component first renders
    try {
      performance.mark(COLLECT_INFO_START_MARK);
    } catch {
      // do nothing if performance.mark errors (this happens in tests because jsdom)
    }

    const event: CollectStepStartEvent = {
      eventName: EventName.COLLECT_STARTED,
      params: {},
    };

    recordGaEvent(event);
  }, []);

  const successCallback = () => {
    let timeOnForm;
    try {
      timeOnForm = performance.measure("timeOnForm", COLLECT_INFO_START_MARK);
      const event: CollectStepSubmitEvent = {
        eventName: EventName.COLLECT_SUBMITTED,
        params: {
          [CustomMetricNames.EVENT_DURATION]: timeOnForm.duration,
        },
      };
      recordGaEvent(event);
    } catch (e) {
      logger.warn("Unable to measure time on form");
    }

    if (getOptions()._launchDarklyUserTargeting) {
      ldClient.track(COLLECT_INFO_SUBMIT, {
        segment: verificationService.verificationResponse.segment,
      });
      logger.info("LD Metric:", COLLECT_INFO_SUBMIT);

      try {
        ldClient.track(
          COLLECT_INFO_SUBMISSION_TIME,
          {
            segment: verificationService.verificationResponse.segment,
          },
          timeOnForm.duration,
        );
        logger.info("LD Metric:", COLLECT_INFO_SUBMISSION_TIME, timeOnForm.duration);
      } catch (e) {
        logger.error("Unable to submit `timeOnForm` metric", e);
      }
    }
    const event: CollectStepSubmitEvent = {
      eventName: EventName.COLLECT_SUBMITTED,
      params: {
        [CustomMetricNames.EVENT_DURATION]: timeOnForm.duration,
      },
    };
    recordGaEvent(event);
  };

  // The majority of the class/id dancing in this component are to support the
  // existing inconsistencies in our different collect info steps when they were
  // brought together into this shared component. When we get away from css based
  // customization this can get much saner
  const wrapperCls = wrapperClassNames[step];
  const buttonCls = buttonClassNames[step];

  const btnId = usesWrapperID.includes(step)
    ? "sid-submit-wrapper__collect-info"
    : "sid-submit-btn-collect-info";

  const textSpanProps =
    step === VerificationStepsEnum.collectEmployeePersonalInfo
      ? { className: "sid-employment-submit-btn__text" }
      : {};

  return (
    <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
      <div className={`${wrapperCls} sid-submit`}>
        <button
          id={btnId}
          onClick={() => submitForm(viewModel, verificationService, step, successCallback)}
          type="submit"
          className={`sid-btn ${buttonCls} sid-btn--dark sid-l-full-width ${
            !isFormFilled(viewModel, verificationService.formValidationOptions)
              ? "sid-btn--disabled-like"
              : ""
          }`}
          aria-labelledby="verify-status-text"
          ref={(button) => setRef("submitButton", button)}
        >
          <span id="verify-status-text" {...textSpanProps}>
            {failedInstantMatch ? (
              <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
            ) : (
              <>{buttonText}</>
            )}
          </span>
        </button>
      </div>
    </div>
  );
};
