import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

interface PassedProps {
  explanation?: string | JSX.Element;
}

export const DriverLicenseNumber = ({
  value,
  isErrored,
  onChange,
  explanation,
  intl,
  placeholder = "",
  isRequired = false,
}: FormFieldComponentProps & PassedProps) => {
  const getDefaultExplanation = () =>
    !isRequired ? <FormattedHTMLMessage id="optional" defaultMessage="Optional" /> : "";
  return (
    <FormFieldText
      fieldId="driver-license-number"
      refId="driverLicenseNumber"
      value={value}
      onChange={onChange}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({
          id: "driverLicenseNumberPlaceholder",
          defaultMessage: "Driver license number",
        })
      }
      label={
        <FormattedHTMLMessage id="driverLicenseNumber" defaultMessage="Driver license number" />
      }
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id="errorId.invalidDriverLicenseNumber"
            defaultMessage="Invalid driver license number"
          />
        )
      }
      showPlaceholderAndHideLabel={!!placeholder}
      required={isRequired}
      explanation={explanation || getDefaultExplanation()}
    />
  );
};

export const DriverLicenseNumberComponent = injectIntl(DriverLicenseNumber);
