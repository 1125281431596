import React, { useState } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Segment, VerificationService, VerificationStep } from "../../lib/types/types";
import { FaqLinkComponent } from "../FaqLink/FaqLinkComponent";
import { assertValidSegmentName } from "../../lib/types/assertions";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { howDoesVerifyingWorkMessages } from "./HowDoesVerifyingWorkMessages";
import { getSafe } from "../../lib/utils/objects";

interface HowDoesVerifyingWorkProps {
  verificationService: VerificationService;
  initialVisibility?: boolean; // eslint-disable-line
}

const getDefaultDetailsMessage = (step: VerificationStep, segment: Segment): string => {
  const messageType = step === VerificationStepsEnum.docUpload ? "docUpload" : "default";
  const defaultMessages = howDoesVerifyingWorkMessages();
  assertValidSegmentName(segment);
  const message = getSafe(() => defaultMessages[segment][messageType], "-");
  return message;
};

const HowDoesVerifyingWorkComponent: React.FC<HowDoesVerifyingWorkProps> = ({
  verificationService,
  initialVisibility = false,
}: HowDoesVerifyingWorkProps) => {
  const [tooltipVisibility, setTooltipVisibility] = useState(initialVisibility);
  const { segment } = verificationService.verificationResponse;
  const step = verificationService.verificationResponse.currentStep;
  const defaultMessage = `${getDefaultDetailsMessage(step, segment)}`;
  const howDoesVerifyWorkId = "how-does-verifying-work";
  let timeoutId: number;

  const showTooltip = () => {
    setTooltipVisibility(true);
    clearTimeout(timeoutId);
  };

  const hideTooltip = () => {
    timeoutId = window.setTimeout(() => setTooltipVisibility(false));
  };

  const handleOnClick = (tooltipIsOpen: boolean) => (tooltipIsOpen ? hideTooltip() : showTooltip());

  return (
    <div className="sid-how-verify-works" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        className="sid-link sid-how-does-verifying-work-hoverable"
        onClick={() => handleOnClick(tooltipVisibility)}
        aria-expanded={tooltipVisibility}
        aria-describedby={tooltipVisibility ? "how-does-verifying-work-tooltip" : null}
        aria-controls={howDoesVerifyWorkId}
        role="button"
      >
        <FormattedHTMLMessage id="howDoesVerifyingWork" defaultMessage="How does verifying work?" />
      </a>
      {tooltipVisibility ? (
        <div className="sid-how-verify-works__container">
          <div className="sid-how-verify-works__caret" />
          <div
            className="sid-how-verify-works__tooltip"
            aria-live="polite"
            role="tooltip"
            id={howDoesVerifyWorkId}
          >
            <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
              {(companyName) => (
                <FormattedHTMLMessage
                  id={`step.${
                    step === VerificationStepsEnum.docUpload ? "docUpload" : "personalInfo"
                  }.howDoesVerifyingWorkDetails`}
                  defaultMessage={defaultMessage}
                  values={{ companyName }}
                />
              )}
            </FormattedHTMLMessage>
            &nbsp;
            <FaqLinkComponent verificationService={verificationService} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export { HowDoesVerifyingWorkComponent };
