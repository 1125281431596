import React from "react";
import { VerificationService, VerificationStep } from "../../lib/types/types";
import { useFlag } from "../FeatureFlags/hooks";
import { FormFooterComponent } from "../FormFooter/FormFooterComponent";
import { dashed } from "../../lib/utils/strings";

interface PersonalInfoWrapperProps {
  step: VerificationStep;
  verificationService: VerificationService;
  className?: string;
}

/**
 * A shared wrapper for all personal info collect steps.
 */
export const PersonalInfoWrapper: React.FC<PersonalInfoWrapperProps> = ({
  step,
  verificationService,
  className,
  children,
}) => {
  const fontVariations = useFlag("font-size");

  const dashedStep = dashed(step).replace(/^collect-/, "");
  const classes = [`sid-${dashedStep}-container`, "sid-l-container", "sid-left-align"];

  if (className) {
    classes.push(className);
  }

  switch (fontVariations) {
    case "14px":
      classes.push("_experiment_sid-font-md");
      break;
    case "16px":
      classes.push("_experiment_sid-font-lg");
      break;
    default:
      break;
  }

  return (
    <div id={`sid-step-${dashedStep}`} className={classes.join(" ")}>
      {children}
      <FormFooterComponent verificationService={verificationService} />
    </div>
  );
};
