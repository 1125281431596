import { assertValidHtmlElement } from "../lib/types/assertions";
import { DiscreteField, Locale, StringMap, ErrorId } from "../lib/types/types";
import { getIntlMessages, getLocaleSafely } from "../lib/intl/intl";

export abstract class DiscreteFieldDefault implements DiscreteField {
  value: any;

  callback: Function;

  mountPoint: HTMLElement;

  locale: Locale;

  messages: StringMap;

  isErrored: boolean;

  errorId: ErrorId;

  constructor(element: HTMLElement) {
    assertValidHtmlElement(element);
    this.mountPoint = element;

    // TODO use a formal init process, pass the VerificationRespnose to getIntlMessages so can have messages from the server.
    this.locale = getLocaleSafely();
    this.messages = getIntlMessages(this.locale);

    if (typeof this.render === "function") {
      this.render();
    } else {
      throw new Error(
        'Method "render" not found. Did you use the "new" keyword when instantiating this class?',
      );
    }
  }

  abstract render(): void;

  getValue() {
    return this.value;
  }

  // Register an external callback to be called when the value changes
  onChange(callback: Function) {
    this.callback = callback;
  }

  setValue(value: any) {
    this.value = value;
    this.render();
  }

  doChange(value: any) {
    this.value = value;
    this.render(); // Call child's render method
    if (typeof this.callback === "function") this.callback(this.value);
  }

  setIsErrored(value: boolean) {
    this.isErrored = value;
  }

  setErrorId(value: ErrorId) {
    this.errorId = value;
  }
}
