import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormFieldComponentProps,
  VerificationServiceProps,
  Organization,
} from "../../../lib/types/types";
import { getOptions } from "../../../options/options";
import { FormFieldTypeahead } from "../FormFieldTypeahead/FormFieldTypeahead";
import { getOrgSearchUrl } from "../../../lib/ProgramTheme/programThemeGetters";

export const CollegeName = ({
  value,
  isErrored,
  onChange,
  verificationService,
  intl,
  placeholder = "",
  isRequired = true,
}: FormFieldComponentProps<Organization> & VerificationServiceProps) => {
  const urlAddSchoolForm = (getOptions().urlAddSchoolForm || "").trim();

  return (
    <FormFieldTypeahead
      fieldId="college-name"
      value={value}
      verificationService={verificationService}
      onChange={(choice: Organization) => onChange(choice)}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({
          id: "schoolNamePlaceholder",
          defaultMessage: "University / College name",
        })
      }
      label={<FormattedHTMLMessage id="schoolName" defaultMessage="University / College name" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        )
      }
      isRequired={isRequired}
      showPlaceholderAndHideLabel={!!placeholder}
      orgSearchUrl={getOrgSearchUrl(
        verificationService.viewModel,
        verificationService.programTheme,
      )}
      urlAddSchoolForm={urlAddSchoolForm}
    />
  );
};

export const CollegeNameComponent = injectIntl(CollegeName);
