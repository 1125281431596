/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from "../../types/types";

export const employmentMessages: SegmentSpecificMessages = {
  addressExplanation: "Use your home address",
  emailExplanation: "Use your work email if you have one",
  step: {
    personalInfo: {
      title: "Unlock this Employment-Only Offer",
      subtitle: "Verify you're a current employee at an eligible company.",
      howDoesVerifyingWorkDetails:
        "{companyName} has set up this special offer for all current employees of select companies." +
        "<br><br>" +
        "{companyName} uses SheerID, a trusted partner, to verify that you are currently employed. SheerID only collects information that assists in verifying your eligibility. Additional information or documentation may be requested.",
      tryAgain: {
        title: "Check the information that you have provided",
        subtitle: "The information you have provided should match your official documents.",
      },
      verifyingTitle: "Verifying your employment status",
    },
    docUpload: {
      title: "We need more information",
      subtitle:
        "Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.",
      howDoesVerifyingWorkDetails:
        "Document review is handled by SheerID, a trusted partner who specializes in verifying employees." +
        "<br><br>" +
        "Documents are used only for verify your employment status is current and will be permanently deleted after review is finalized.",
      uploadInstructions: "Upload an official document that shows",
    },
    success: {
      title: "You've been verified",
      subtitle: "Here is your personal coupon code. It is unique to you and can only be used once.",
      redirectButtonText: "Use code now",
    },
    sso: {
      title: "Verify using your company-provided email credentials",
      subtitle: "Please sign in to your company's email portal. It will open in a new tab.",
      subtitle2: "Can't sign in to your email?",
      login: "Sign in to my email",
      cancel: "Upload proof of employment",
      alternative: "You can also verify by uploading proof of employment",
    },
  },
};
