import React from "react";

interface StepCardComponentProps {
  icon: React.ReactNode;
  step: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  isHighlighted?: boolean;
}

const StepCard = ({
  step,
  icon,
  title,
  subtitle,
  isHighlighted = false,
}: StepCardComponentProps) => (
  <div
    className={`sid-id-check-step-card ${isHighlighted && "sid-id-check-step-card--highlighted"}`}
  >
    <div className="sid-id-check-step-card__header">
      <div className="sid-id-check-step-card__icon">{icon}</div>
      {step && (
        <div
          className={`sid-id-check-step-card__step ${
            isHighlighted && "sid-id-check-step-card__step--highlighted"
          }`}
        >
          {step}
        </div>
      )}
    </div>

    <div className="sid-id-check-step-card__title sid-l-space-btm-sm">{title}</div>
    <div className="sid-id-check-step-card__subtitle">{subtitle}</div>
  </div>
);

export const StepCardComponent = StepCard;
