import React from "react";

interface InputSelectButtonProps {
  clearSelection: any;
  getToggleButtonProps: any;
  isOpen: boolean;
  disabled?: boolean;
}

const InputSelectButton = ({
  clearSelection,
  disabled,
  getToggleButtonProps,
  isOpen,
}: InputSelectButtonProps) => {
  const {
    onClick: onClickToggleButton,
    "aria-label": ariaLabel,
    "aria-haspopup": ariaHaspopup,
    "data-toggle": dataToggle,
    onKeyUp,
    onKeyDown,
    onBlur,
  } = getToggleButtonProps();

  return (
    <button
      aria-label={clearSelection ? "clear selection and open menu" : ariaLabel}
      aria-haspopup={ariaHaspopup}
      data-toggle={dataToggle}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      className="sid-input-select-button"
      disabled={disabled}
      type="button"
      onClick={(event) => {
        if (typeof clearSelection === "function") clearSelection();
        if (typeof onClickToggleButton === "function") onClickToggleButton(event);
      }}
    >
      <svg
        viewBox="0 0 20 20"
        preserveAspectRatio="none"
        width={16}
        fill="transparent"
        stroke="#5A5A5A"
        strokeWidth="1.1px"
        transform={isOpen ? "rotate(180)" : undefined}
      >
        <path d="M1,6 L10,15 L19,6" />
      </svg>
    </button>
  );
};

export const InputSelectButtonComponent = InputSelectButton;
