import { logger } from "../utils/logger/logger";
import { RegisteredHooks, Hook, HookName } from "../types/types";
import { assertValidHook, assertValidHookName } from "../types/assertions";
import { getSafe } from "../utils/objects";

/**
 * @description The instance that contains callback functions ("hooks") that have been added.
 * @private
 */
let registeredHooks: RegisteredHooks = {};

/**
 * @description Register a callback function (a "hook") that will be called for a given hook name.
 *      Hook names are called at specific points during execution and allow some form of extensibility.
 * @param hookName A specific string, one of the HookName type.
 * @param callback The function to call when the system performs certain actions, described by the hook's name.
 */
export const addHook = (hook: Hook): RegisteredHooks => {
  assertValidHook(hook);

  if (!Array.isArray(registeredHooks[hook.name])) {
    registeredHooks[hook.name] = [];
  }

  registeredHooks[hook.name].push(hook);
  return registeredHooks;
};

/**
 * @description Remove a hook
 * @param hookName A specific string, one of the HookName type.
 * @private
 */
export const removeHook = (hook: HookName): RegisteredHooks => {
  try {
    delete registeredHooks[hook];
  } catch (err) {
    logger.warn("Unable to remove hook", hook);
  }

  return registeredHooks;
};

/**
 * @description Retrieve a hook object that has been stored.
 * @param hookName
 */
export const getHook = (hookName: HookName): Function => {
  assertValidHookName(hookName);
  return (value: any) => {
    if (
      registeredHooks[hookName] &&
      Array.isArray(registeredHooks[hookName]) &&
      registeredHooks[hookName].length > 0
    ) {
      registeredHooks[hookName].forEach((hook: Hook) => {
        if (hook.callback && typeof hook.callback === "function") {
          hook.callback(typeof value === "string" ? value : { ...value });
        } else {
          logger.warn("Expected typeof function for hook callback");
        }
      });
    } else {
      // return a no-op function to avoid error "function doesn't exist"
      return () => {};
    }
  };
};

/**
 * Used for unit testing
 * @private
 */
export const resetHooks = (): void => {
  registeredHooks = {};
};

/**
 * Used for unit testing
 * @private
 */
export const getHooks = (): RegisteredHooks => registeredHooks;

/**
 * @description Look for an array of Hook objects that a developer may have provided in the window.sheerId object.
 *     If present, add each to the internal registry of hooks.
 *
 * @private
 * @returns true if any hooks were successfully registered.
 */
export const addPredefinedHooks = (): boolean => {
  const successCount = 0;
  getSafe(() => window.sheerId.hooks, []).forEach((hook: Hook) => {
    try {
      addHook(hook);
    } catch (e) {
      logger.warn("Error adding hook:", hook, e);
    }
  });
  return successCount > 0;
};
