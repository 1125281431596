import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { VerificationService } from "../../lib/types/types";
import { getFaqLink } from "../../lib/ProgramTheme/programThemeGetters";
import { LinkExternal } from "../LinkExternal";
import { getLocaleSafely } from "../../lib/intl/intl";

interface Props {
  verificationService: VerificationService;
  linkText?: string;
}

const FaqLinkComponent = ({ verificationService, linkText }: Props) => {
  const { segment } = verificationService.verificationResponse;
  const { programId, programTheme } = verificationService;

  if (segment) {
    const faqHref = getFaqLink(
      programTheme,
      segment,
      programId,
      getLocaleSafely(verificationService.viewModel),
      verificationService.verificationResponse,
    );

    return (
      <LinkExternal className="sid-faq sid-link" href={faqHref}>
        {linkText || <FormattedHTMLMessage id="getHelp" defaultMessage="Get help" />}
      </LinkExternal>
    );
  }
  return null;
};

export { FaqLinkComponent };
