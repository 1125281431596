/**
 * Return a unique array of primitive members
 * Sorts the array
 * @private
 */
export function arrayUnique<T>(array: T[]): T[] {
  return array.sort().filter((item, pos, ary) => !pos || item !== ary[pos - 1]);
}

// alphabetic sort callback
export const alphabetically = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
