/**
 * Insert `newPart` into a string
 * Use:
 *      "Hi !".insertAt(3, "world"); // "Hi world!"
 */
String.prototype.insertAt = function (index: number, newPart: string) {
  if (index > this.length) {
    return this;
  }
  return `${this.slice(0, index)}${newPart}${this.slice(index)}`;
};
