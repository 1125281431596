import {
  iframeConstants as constants,
  QUERY_STRING_INSTALL_PAGE_URL,
  iframeTitle,
  QUERY_STRING_INSTALL_TYPE,
} from "../../constants";
import { InstallPageEvent } from "../frames/frameMessages";
import { getCurrentUrl } from "../ServerApi/ApiUrls";
import { assertValidFunction } from "../types/assertions";
import { Options, PostMessageAction, ViewModel } from "../types/types";
import { logger } from "../utils/logger/logger";
import { getVerificationUrl } from "./utils";

type IframeOptions = {
  className: string;
  title: string;
};

class Iframe {
  containerElement: HTMLElement;

  verificationUrl: URL;

  isMobileDevice: boolean;

  iframe: HTMLIFrameElement;

  verificationIframeUid: string;

  hasLoaded: boolean = false;

  onLoadEvents: Function[] = [];

  onCleanupEvents: (() => void)[] = [];

  installType = "cdn_inline_iframe";

  constructor(containerElement: HTMLElement, url: string) {
    if (Iframe.isValidHttpUrl(url)) {
      const options = {
        className: constants.CLASS_NAMES.INLINE_IFRAME_CONTENT,
        title: iframeTitle,
      };
      this.containerElement = containerElement;
      this.verificationUrl = new URL(getVerificationUrl(url));
      this.verificationIframeUid = Iframe.createUniqueId();
      this.createIframe(options);
      this.addVerificationSizeUpdatesListener();
    } else {
      logger.error("Invalid URL. Provide a proper URL: https://example.com/", "iframe url");
    }
  }

  cleanup(): void {
    this.onCleanupEvents.forEach((callback) => callback());
  }

  static createUniqueId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  static isValidHttpUrl(urlString: string): boolean {
    try {
      const url = new URL(urlString);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch {
      return false;
    }
  }

  createIframe(options: IframeOptions): HTMLIFrameElement {
    this.iframe = document.createElement("iframe");
    this.iframe.classList.add(options.className);
    this.iframe.title = options.title;
    let mark = "?";
    if (this.verificationUrl.search) {
      mark = "&";
    }
    this.iframe.src = `${this.verificationUrl.href}${mark}verificationIframeUid=${
      this.verificationIframeUid
    }&${QUERY_STRING_INSTALL_PAGE_URL}=${encodeURIComponent(
      getCurrentUrl(),
    )}&${QUERY_STRING_INSTALL_TYPE}=${this.installType}`;
    this.iframe.allow = "camera;microphone";
    this.iframe.onload = () => this.onLoad();
    return this.iframe;
  }

  // If iFrame hasn't loaded yet, queue up the callback
  // otherwise call it immediately
  addOnLoadEvent(callback: Function) {
    try {
      assertValidFunction(callback);
    } catch (e) {
      logger.error("Invalid callback provided to iFrame.onLoad()", "iframe addOnLoadEvent");
      return;
    }
    if (this.hasLoaded) {
      callback();
    } else {
      this.onLoadEvents.push(callback);
    }
  }

  onLoad() {
    this.hasLoaded = true;
    this.onLoadEvents.forEach((callback) => callback());
  }

  /**
   * Using this to add parent window message listeners gives us
   * - Event Cleanup for modals
   * - verification of origin, verificationIframeUid, and message data structure
   */
  addWindowMessageListener(actionCb: (action: PostMessageAction) => void): void {
    const listener = (ev: MessageEvent) => {
      if (this.verificationUrl.origin !== ev.origin) {
        return;
      }

      if (ev.data.verificationIframeUid !== this.verificationIframeUid) {
        return;
      }

      if (ev.data.action && ev.data.action.type) {
        actionCb(ev.data.action);
      } else if (ev.data.action && ev.data.height) {
        // Older versions of the jslib send the full action object as the message
        actionCb(ev.data);
      }
    };

    window.addEventListener("message", listener);
    this.onCleanupEvents.push(() => window.removeEventListener("message", listener));
  }

  addVerificationSizeUpdatesListener() {
    this.addWindowMessageListener((messageAction) => {
      if (
        (messageAction.action && messageAction.action === "updateHeight") ||
        messageAction.type === "updateHeight"
      ) {
        // UX-1129: If we are getting height updates, turn off scrolling for
        // the iframe. This prevents browsers (Safari in particular) from
        // re-wrapping because of different widths and constantly changing
        // it's own height. `scrolling = "no"` is deprecated, but supported in
        // all browsers and fixes the problem well.
        this.iframe.scrolling = "no";
        this.iframe.style.height = `${messageAction.height}px`;
      }
    });
  }

  setViewModel(viewModel: Partial<ViewModel> | ViewModel) {
    const message: InstallPageEvent = {
      action: "setViewModel",
      viewModel,
    };

    this.addOnLoadEvent(() => {
      this.iframe.contentWindow.postMessage(message, this.verificationUrl.origin);
    });
  }

  setOptions(options: Options) {
    const message: InstallPageEvent = {
      action: "setOptions",
      options,
    };

    this.addOnLoadEvent(() => {
      this.iframe.contentWindow.postMessage(message, this.verificationUrl.origin);
    });
  }

  setInstallType(installType: string) {
    this.installType = installType;
  }

  init() {
    this.containerElement.appendChild(this.iframe);
  }
}

export default Iframe;

/**
 * All post messages from inside our iframes and modals should use this function
 * to post messages to the parent window. It strongly types the data and makes
 * sure we're only listening to events that have the iframeUid
 */
export function postMessageFromIframe(action: PostMessageAction): void {
  const origin = "*";
  const verificationUrl = new URL(window.location.href);
  const verificationIframeUid = verificationUrl.searchParams.get("verificationIframeUid");

  window.parent.postMessage(
    {
      verificationIframeUid,
      action,
    },
    origin,
  );
}
