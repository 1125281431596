import React from "react";
import { injectIntl, FormattedHTMLMessage, InjectedIntl } from "react-intl";
import { produce } from "immer";

import { logger } from "../../lib/utils/logger/logger";
import {
  VerificationService,
  StudentPersonalInfoViewModel,
  SMSLoopResponse,
  SMSLoopViewModel,
} from "../../lib/types/types";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { getSafe } from "../../lib/utils/objects";

import { SMSCodeComponent } from "../FormFields/SMSCode/SMSCodeComponent";

import { VerificationApiClient } from "../../lib/ServerApi/VerificationApiClient";

interface StepSMSLoopProps {
  intl: InjectedIntl;
  verificationService: VerificationService;
}

const StepSMSLoop = ({ intl, verificationService }: StepSMSLoopProps) => {
  const viewModel = verificationService.viewModel as SMSLoopViewModel;
  const verificationResponse = verificationService.verificationResponse as SMSLoopResponse;
  const isErrored = Boolean(verificationService.fieldValidationErrors.smsCode);
  const previousViewModel =
    (verificationService.previousViewModel as StudentPersonalInfoViewModel) || undefined;

  const [stepResultMessage, setStepResultMessage] = React.useState("");
  const [isAttemptLimitExceeded, setAttemptLimitExceeded] = React.useState(
    verificationResponse.errorIds &&
      verificationResponse.errorIds.includes("incorrectSMSCodeAttemptLimitExceeded"),
  );

  const stepSuccessMessage = intl.formatHTMLMessage({
    id: "step.smsLoop.successResend",
    defaultMessage: "SMS message re-sent successfully",
  });
  const maxTotalRetriesMessage = intl.formatHTMLMessage({
    id: "step.smsLoop.errors.codeResendLimit",
    defaultMessage: "Maximum number of re-tries has been reached.",
  });
  const maxRetriesPerCodeMessage = intl.formatHTMLMessage({
    id: "step.smsLoop.errors.codeResendLimit",
    defaultMessage: "Maximum number of re-tries has been reached.",
  });
  const errorResendingSMSMessage = intl.formatHTMLMessage({
    id: "step.smsLoop.errors.resend",
    defaultMessage: "Error sending the SMS message",
  });

  React.useEffect(() => {
    if (isAttemptLimitExceeded) {
      setStepResultMessage(maxRetriesPerCodeMessage);
    }
  }, []);

  if (
    getSafe(() => verificationResponse.errorIds.length) &&
    !verificationService.fieldValidationErrors.smsCode
  ) {
    verificationService.updateFieldValidationErrors({
      ...verificationService.fieldValidationErrors,
      smsCode: verificationResponse.errorIds[0],
    });
  }

  const updateSmsCode = (value) => {
    const nextState: SMSLoopViewModel = produce(viewModel, (draft: SMSLoopViewModel) => {
      draft.smsCode = value;
      draft.phoneNumber = previousViewModel && previousViewModel.phoneNumber;
    });
    verificationService.updateViewModel(nextState);
  };

  const submitForm = () => {
    logger.info("StepSMSLoop submitting form");
    verificationService.submitStep(
      VerificationStepsEnum.smsLoop,
      viewModel,
      verificationService.verificationResponse,
    );
  };

  const resendSmsCode = async (verificationId) => {
    const response = await VerificationApiClient.getResendNewSmsCode(verificationId);
    if (response) {
      setAttemptLimitExceeded(false);
      setStepResultMessage(response.status === 204 ? stepSuccessMessage : maxTotalRetriesMessage);
    } else {
      setStepResultMessage(errorResendingSMSMessage);
    }
  };

  return (
    <div id="sid-step-sms-loop" className="sid-sms-loop-container sid-l-container">
      <div className="sid-header__title sid-l-horz-center">
        <FormattedHTMLMessage
          id="step.smsLoop.verificationCode"
          defaultMessage="Verification Code"
          tagName="h1"
        />
      </div>

      <div className="sid-header__subtitle">
        {getSafe(() => previousViewModel.phoneNumber) ? (
          <FormattedHTMLMessage
            id="step.smsLoop.titleWithNumber"
            defaultMessage="We've sent an SMS message to the mobile number ending in {number}. Enter your code here."
            values={{
              number: getSafe(() => previousViewModel.phoneNumber.slice(-4), ""),
            }}
          />
        ) : (
          <FormattedHTMLMessage
            id="step.smsLoop.titleWithoutNumber"
            defaultMessage="We've sent an SMS to your mobile number. Please enter the code here."
          />
        )}
      </div>

      <div className="sid-sms-code-id-wrapper sid-l-horz-center">
        <SMSCodeComponent
          onChange={updateSmsCode}
          value={viewModel.smsCode}
          isErrored={isErrored}
          errorId={verificationService.fieldValidationErrors.smsCode}
        />
      </div>

      {stepResultMessage && (
        <div>
          <p>{stepResultMessage}</p>
        </div>
      )}

      <div className="sid-submit sid-sms-loop-submit sid-l-space-top-md sid-l-horz-center">
        <button
          id="sid-submit-sms-code"
          onClick={submitForm}
          type="button"
          className="sid-btn sid-sms-loop-submit-btn sid-btn--dark sid-submit__continue"
          aria-label="submit"
          disabled={
            !!(!viewModel.smsCode || viewModel.smsCode.length <= 0 || isAttemptLimitExceeded)
          }
        >
          <FormattedHTMLMessage id="step.smsLoop.submitButton" defaultMessage="Submit" />
        </button>

        <div className="sid-l-space-left-sm">&nbsp;</div>

        <button
          id="sid-resend-sms-code"
          onClick={() => resendSmsCode(verificationResponse.verificationId)}
          type="submit"
          className="sid-btn sid-resend-sms-code-btn sid-btn--light sid-submit__cancel"
          aria-label="re-send"
        >
          <FormattedHTMLMessage id="step.smsLoop.resendButton" defaultMessage="Re-send" />
        </button>
      </div>

      <div className="sid-incorrect-number sid-l-horz-center">
        <FormattedHTMLMessage
          id="step.smsLoop.incorrectNumber.incorrectNumber1"
          defaultMessage="Incorrect number? Click"
        />
        &nbsp;
        <button
          type="button"
          onClick={() => window.location.reload()}
          className="sid-incorrect-number__refresh-link sid-h-btn-link-like"
        >
          <FormattedHTMLMessage
            id="step.smsLoop.incorrectNumber.incorrectNumber2"
            defaultMessage="here"
          />
          &nbsp;
        </button>
        <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
          {(companyName) => (
            <FormattedHTMLMessage
              id="step.smsLoop.incorrectNumber.incorrectNumber3"
              defaultMessage="to return to {companyName}"
              values={{ companyName }}
            />
          )}
        </FormattedHTMLMessage>
      </div>
    </div>
  );
};

export const StepSMSLoopComponent = injectIntl(StepSMSLoop);
