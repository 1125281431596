import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";

type Props = {
  useSsn: () => void;
  useDocUpload: () => void;
  verificationService: any;
};

const Step = ({ useSsn, useDocUpload, verificationService }: Props) => (
  <div id="sid-ssn-choice" className="sid-ssn-choice-container sid-l-container sid-l-horz-center">
    <div className="sid-header">
      <div className="sid-ssn-choice-logo-container sid-l-horz-center">
        <LogoComponent verificationService={verificationService} />
      </div>
      <div className="sid-header__title sid-l-horz-center">
        <FormattedHTMLMessage
          id="step.collectSocialSecurityNumber.selectOption"
          defaultMessage="Select an option"
          tagName="h1"
        />
      </div>
    </div>

    <div className="sid-ssn-choice__choice1 sid-l-space-top-lg">
      <button
        type="button"
        onClick={useSsn}
        className="sid-btn sid-ssn-choice-one-btn sid-btn--light sid-l-full-width sid-use-ssn"
      >
        <FormattedHTMLMessage
          id="step.collectSocialSecurityNumber.useSsn"
          defaultMessage="Verify using your social security number"
        />
      </button>
    </div>

    <div className="sid-ssn-choice__choice2 sid-l-space-top-md">
      <button
        type="button"
        onClick={useDocUpload}
        className="sid-btn sid-ssn-choice-two-btn sid-btn--light sid-l-full-width sid-use-doc-upload"
      >
        <FormattedHTMLMessage
          id="step.collectSocialSecurityNumber.uploadDoc"
          defaultMessage="Upload a document"
        />
      </button>
    </div>

    <div className="sid-ssn-choice__footer sid-l-space-top-lg">
      <FormFooter verificationService={verificationService} />
    </div>
  </div>
);

export const SsnChoice = Step;
