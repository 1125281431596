import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { VerificationService } from "../es6";

interface ExtraMessageProps {
  verificationService: VerificationService;
  suffix: "CollectAboveSubmit"; // In the future, expand this type for other official extra messages. e.g. add "| 'CollectBelowSubmit'"
}
/**
 * Extra message component
 *
 * Easily register extra messages to specific spots in the form using this component
 *
 * These new regions do NOT have a message by default, but messages can be added simply by specifying the messageId + value
 * either in my.sheerid.com or via setOptions(). No need to override a component just to add a message as long as the ExtraMessage
 * appears in a desirable spot.
 */
const ExtraMessage = ({ verificationService, suffix }: ExtraMessageProps) =>
  (verificationService.messages as any)[`extraMessage${suffix}`] ? (
    <div className={`sid-extra-message sid-extra-message-${suffix}`}>
      <div className="sid-l-space-top-md" />
      <FormattedHTMLMessage id={`extraMessage${suffix}`} defaultMessage=" " />
    </div>
  ) : null;

export { ExtraMessage };
