import React from "react";
import { VerificationService } from "../../lib/types/types";
import { LogoComponent } from "../LogoComponent/LogoComponent";

export interface FormHeaderProps {
  verificationService: VerificationService;
  title: React.ReactNode;
  children?: React.ReactNode;
  fallbackLogo?: JSX.Element;
  classNameIdentifier?: string;
}

export const FormHeader = ({
  verificationService,
  title,
  children,
  fallbackLogo,
  classNameIdentifier = undefined,
}: FormHeaderProps) => (
  <header className="sid-header">
    <div
      className={`sid-logo-container sid-l-horz-center ${
        classNameIdentifier ? `sid-${classNameIdentifier}-logo-container` : ""
      }`}
    >
      <LogoComponent verificationService={verificationService} fallbackLogo={fallbackLogo} />
    </div>
    <div
      className={`sid-header__title sid-l-horz-center ${
        classNameIdentifier ? `sid-${classNameIdentifier}-header` : ""
      }`}
    >
      {title}
    </div>
    {children}
  </header>
);
