import { ProgramTheme, ReduxState } from "../../types/types";
import { deepClone } from "../../utils/objects";
import { createUpdateProgramTheme } from "../VerificationServiceActions";
import { getStore } from "../VerificationServiceStore";
import { logger } from "../../utils/logger/logger";
import { addHook } from "../../hooks/hooks";

export const setProgramTheme = (
  newProgramTheme: ProgramTheme | {},
  options: { partial: boolean } = { partial: true },
) => {
  const store = getStore();
  const state: ReduxState = store.getState();
  const doSet = () => {
    const updatedProgramTheme = deepClone(newProgramTheme);
    logger.info("Setting programTheme", updatedProgramTheme);
    createUpdateProgramTheme(store.dispatch)(updatedProgramTheme, options.partial);
  };

  if (state.isLoading) {
    addHook({
      name: "ON_VERIFICATION_READY",
      callback: () => {
        doSet();
      },
    });
  } else {
    doSet();
  }
};
