import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { FormFieldInputSelect } from "../FormFieldInputSelect/FormFieldInputSelect";

interface StatusComponentProps extends InputSelectComponentProps {
  segmentId: string;
  errorId?: string;
}

export const Status = ({
  value,
  isErrored,
  errorId = "invalidStatus",
  options,
  onChange,
  intl,
  placeholder = "",
  onKeyDown = undefined,
  segmentId,
}: StatusComponentProps) => {
  const inputId = `${segmentId}-status`;
  const hasOnlyOneOption = options.length === 1;

  React.useEffect(() => {
    if (hasOnlyOneOption) {
      onChange(options[0]);
    }
  }, []);

  if (hasOnlyOneOption) {
    return null;
  }

  return (
    <FormFieldInputSelect
      fieldId={inputId}
      options={options}
      onChange={(status: FormSelectChoice) => onChange(status)}
      onKeyDown={onKeyDown}
      value={options.find((option) => option.value === (value && value.value))}
      placeholder={
        placeholder || intl.formatHTMLMessage({ id: "statusPlaceholder", defaultMessage: "Status" })
      }
      showPlaceholderAndHideLabel={!!placeholder}
      isRequired
      containerId={`sid-${inputId}`}
      label={<FormattedHTMLMessage id="status" defaultMessage="Status" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id={`errorId.${errorId}`}
            defaultMessage={`Invalid ${segmentId} status`}
          />
        )
      }
    />
  );
};

export const StatusComponent = injectIntl(Status);
