import React from "react";

interface SelectButtonProps {
  getToggleButtonProps: any;
  isOpen: boolean;
}

const SelectButton = ({ getToggleButtonProps, isOpen }: SelectButtonProps) => (
  <button {...getToggleButtonProps()} className="sid-select-button" type="button">
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={16}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
      transform={isOpen ? "rotate(180)" : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  </button>
);

export const SelectButtonComponent = SelectButton;
