import React from "react";
import { VerificationService } from "../../lib/types/types";
import { LinkExternal } from "../LinkExternal";

interface Props {
  verificationService: VerificationService;
}

const TestModeFlagComponent = ({ verificationService }: Props) =>
  verificationService.programTheme && verificationService.programTheme.isTestMode ? (
    <div className="sid-test-mode-flag sid-l-container sid-l-horz-center">
      Test Mode
      <LinkExternal
        className="sid-test-mode-flag__link"
        href="http://developer.sheerid.com/getting-started#test-program"
        aria-label="learn more about a sheerid test mode program"
      >
        (learn more)
      </LinkExternal>
    </div>
  ) : null;

export { TestModeFlagComponent };
