/**
 * TODO - preamble
 *
 * Empty objects, useful for initializing
 */

import { deepClone } from "../utils/objects";

import {
  AgePersonalInfoViewModel,
  SeniorPersonalInfoViewModel,
  StudentPersonalInfoViewModel,
  TeacherPersonalInfoViewModel,
  ActiveMilitaryPersonalInfoViewModel,
  DocUploadViewModel,
  FieldValidationErrors,
  InactiveMilitaryPersonalInfoViewModel,
  FirstResponderPersonalInfoViewModel,
  MedicalProfessionalPersonalInfoViewModel,
  EmploymentPersonalInfoViewModel,
  SocialSecurityViewModel,
  ProgramTheme,
  SSOViewModel,
  WithCoreFields,
  MemberPersonalInfoViewModel,
  SMSLoopViewModel,
  RequestOrganizationViewModel,
  VerificationStepMap,
  ViewModel,
  EmailLoopViewModel,
  DriverLicensePersonalInfoViewModel,
  GeneralIdentityPersonalInfoViewModel,
  HybridIdentityPersonalInfoViewModel,
  LicensedProfessionalPersonalInfoViewModel,
  MoverPersonalInfoViewModel,
  LowIncomePersonalInfoViewModel,
  OverrideViewModel,
  WithOrganization,
  EmailLoopCollectOrganizationEmailViewModel,
  CollectIdentifierViewModel,
  CollectPersonalInfoViewModel,
  CompleteAuthenticationViewModel,
} from "./types";
import { getCustomValidatorFields } from "../validators/customValidators";
import { getLocaleSafely } from "../intl/intl";

const coreFieldsEmpty: WithCoreFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const withLocaleAndCountryEmpty = {
  countryChoice: undefined,
  localeChoice: {
    value: undefined,
    label: undefined,
  },
};
export const blankOrganization: WithOrganization = {
  organization: {
    id: 0,
    name: "",
  },
};

const withFieldsToSkipValidationEmpty = {
  fieldsToSkipValidation: undefined,
};

// TODO use Object.freeze here
export const stuPInfoReqEmpty: StudentPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...blankOrganization,
  birthDate: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

// TODO use Object.freeze here
export const seniorPInfoReqEmpty: SeniorPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  birthDate: "",
  postalCode: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const agePInfoReqEmpty: AgePersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  birthDate: "",
  postalCode: "",
  phoneNumber: "",
  country: "US",
  metadata: {
    marketConsentValue: false,
    carrierConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

// TODO use Object.freeze here
export const teaPInfoReqEmpty: TeacherPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...blankOrganization,
  birthDate: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const memberInfoReqEmpty: MemberPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...blankOrganization,
  birthDate: "",
  phoneNumber: "",
  memberId: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const activeMilitaryPInfoReqEmpty: ActiveMilitaryPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...blankOrganization,
  activeDutyStartDate: "",
  birthDate: "",
  status: undefined,
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const inactiveMilitaryPInfoReqEmpty: InactiveMilitaryPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...blankOrganization,
  activeDutyStartDate: "",
  birthDate: "",
  dischargeDate: "",
  status: undefined,
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const firstResponderPInfoReqEmpty: FirstResponderPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...blankOrganization,
  birthDate: "",
  status: undefined,
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const medicalProfessionalPInfoReqEmpty: MedicalProfessionalPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...blankOrganization,
  birthDate: "",
  postalCode: "",
  status: undefined,
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
  memberId: undefined,
};

export const employmentPInfoReqEmpty: EmploymentPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...blankOrganization,
  postalCode: "",
  address1: "",
  city: "",
  state: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const driverLicensePInfoReqEmpty: DriverLicensePersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...withLocaleAndCountryEmpty,
  state: "",
  driverLicenseNumber: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const generalIdentityPInfoReqEmpty: GeneralIdentityPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...withLocaleAndCountryEmpty,
  birthDate: "",
  address1: "",
  city: "",
  state: "",
  postalCode: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const hybridIdentityPInfoReqEmpty: HybridIdentityPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...withLocaleAndCountryEmpty,
  birthDate: "",
  address1: "",
  city: "",
  state: "",
  postalCode: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
  driverLicenseNumber: "",
};

export const licensedProfessionalPInfoReqEmpty: LicensedProfessionalPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...withLocaleAndCountryEmpty,
  ...blankOrganization,
  birthDate: "",
  postalCode: "",
  statuses: [],
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const moverPInfoReqEmpty: MoverPersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...withLocaleAndCountryEmpty,
  address1: "",
  postalCode: "",
  statuses: [],
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const lowIncomePInfoReqEmpty: LowIncomePersonalInfoViewModel = {
  ...coreFieldsEmpty,
  ...withFieldsToSkipValidationEmpty,
  ...withLocaleAndCountryEmpty,
  ...blankOrganization,
  birthDate: "",
  address1: "",
  city: "",
  state: "",
  postalCode: "",
  statuses: [],
  ebtCardNumber: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const collectIdentifierReqEmpty: CollectIdentifierViewModel = {
  ...withLocaleAndCountryEmpty,
  email: "",
  transactionId: "",
  expirationTimestamp: "",
  remembered: false,
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const completeAuthenticationReqEmpty: CompleteAuthenticationViewModel = {
  ...withLocaleAndCountryEmpty,
  authenticationCode: "",
  email: "",
  didManuallyVerify: false,
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

export const collectPersonalInfoReqEmpty: CollectPersonalInfoViewModel = {
  ...withLocaleAndCountryEmpty,
  ...blankOrganization,
  ...coreFieldsEmpty,
  birthDate: "",
  email: "",
  statuses: [],
  socialSecurityNumber: "",
  memberId: "",
  status: undefined,
  postalCode: "",
  dischargeDate: "",
  driverLicenseNumber: "",
  address1: "",
  city: "",
  state: "",
  metadata: {
    marketConsentValue: false,
    refererUrl: window.location.href, // CX-249, ensure we define our own refererUrl to avoid issues with the browser's referer header
  },
};

// TODO use Object.freeze here
export const docUploadEmpty: DocUploadViewModel = {
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  file1: undefined,
  file2: undefined,
  file3: undefined,
};

export const ssoEmpty: SSOViewModel = {
  ...withLocaleAndCountryEmpty,
  ...withFieldsToSkipValidationEmpty,
  isSSOActive: false,
};

export const overrideEmpty: OverrideViewModel = {
  ...withLocaleAndCountryEmpty,
  overrideCode: undefined,
};
export const socialSecurityEmpty: SocialSecurityViewModel = {
  ...withLocaleAndCountryEmpty,
  socialSecurityNumber: undefined,
} as const;

export const smsLoopEmpty: SMSLoopViewModel = {
  ...withLocaleAndCountryEmpty,
  smsCode: "",
  phoneNumber: undefined,
};

export const emailLoopEmpty: EmailLoopViewModel = {
  ...withLocaleAndCountryEmpty,
  organizationEmail: "",
  emailToken: "",
};

export const emailLoopCollectOrganizationEmailEmpty: EmailLoopCollectOrganizationEmailViewModel = {
  ...withLocaleAndCountryEmpty,
  organizationEmail: "",
};

export const emptyViewModel = {
  ...withLocaleAndCountryEmpty,
} as ViewModel;

const initialErrorStructure = {
  firstName: undefined,
  lastName: undefined,
  memberId: undefined,
  email: undefined,
  phoneNumber: undefined,
  birthDate: undefined,
  organization: undefined,
  postalCode: undefined,
  address1: undefined,
  city: undefined,
  country: undefined,
  state: undefined,
  activeDutyStartDate: undefined,
  dischargeDate: undefined,
  docUpload: undefined,
  status: undefined,
  statuses: undefined,
  socialSecurityNumber: undefined,
  marketConsentValue: undefined,
  carrierConsentValue: undefined,
  smsCode: undefined,
  driverLicenseNumber: undefined,
  ebtCardNumber: undefined,
  overrideCode: undefined,
  organizationEmail: undefined,
  authenticationCode: undefined,
} as const;

export const getAllEmptyViewModels = (): VerificationStepMap<ViewModel> => ({
  collectStudentPersonalInfo: deepClone(stuPInfoReqEmpty),
  collectTeacherPersonalInfo: deepClone(teaPInfoReqEmpty),
  collectMemberPersonalInfo: deepClone(memberInfoReqEmpty),
  collectMilitaryStatus: deepClone(activeMilitaryPInfoReqEmpty),
  collectActiveMilitaryPersonalInfo: deepClone(activeMilitaryPInfoReqEmpty),
  collectInactiveMilitaryPersonalInfo: deepClone(inactiveMilitaryPInfoReqEmpty),
  collectSeniorPersonalInfo: deepClone(seniorPInfoReqEmpty),
  collectAgePersonalInfo: deepClone(agePInfoReqEmpty),
  collectFirstResponderPersonalInfo: deepClone(firstResponderPInfoReqEmpty),
  collectMedicalProfessionalPersonalInfo: deepClone(medicalProfessionalPInfoReqEmpty),
  collectEmployeePersonalInfo: deepClone(employmentPInfoReqEmpty),
  collectSocialSecurityNumber: deepClone(socialSecurityEmpty),
  collectDriverLicensePersonalInfo: deepClone(driverLicensePInfoReqEmpty),
  collectGeneralIdentityPersonalInfo: deepClone(generalIdentityPInfoReqEmpty),
  collectHybridIdentityPersonalInfo: deepClone(hybridIdentityPInfoReqEmpty),
  collectLicensedProfessionalPersonalInfo: deepClone(licensedProfessionalPInfoReqEmpty),
  collectLowIncomePersonalInfo: deepClone(lowIncomePInfoReqEmpty),
  collectIdentifier: deepClone(collectIdentifierReqEmpty),
  collectPersonalInfo: deepClone(collectPersonalInfoReqEmpty),
  completeAuthentication: deepClone(completeAuthenticationReqEmpty),
  collectMoverPersonalInfo: deepClone(moverPInfoReqEmpty),
  docUpload: deepClone(docUploadEmpty),
  cancelEmailLoop: deepClone(emailLoopEmpty),
  sso: deepClone(ssoEmpty),
  smsLoop: deepClone(smsLoopEmpty),
  emailLoop: deepClone(emailLoopEmpty),
  emailLoopCollectOrganizationEmail: deepClone(emailLoopCollectOrganizationEmailEmpty),
  idCheckLoop: deepClone(emptyViewModel),
  pending: deepClone(emptyViewModel),
  success: deepClone(emptyViewModel),
  consolation: deepClone(emptyViewModel),
  override: deepClone(emptyViewModel),
  error: deepClone(emptyViewModel),
  cancelSocialSecurityNumber: deepClone(emptyViewModel),
  docReviewLimitExceeded: deepClone(emptyViewModel),
  cancelDocUpload: deepClone(emptyViewModel),
  missingRequiredMetadata: deepClone(emptyViewModel),
});

export const getFieldValidationErrorsEmpty = (): FieldValidationErrors =>
  deepClone(initialErrorStructure);

export const getExtendedFieldValidationErrorsEmpty = () => {
  const customValidatorFieldsEmpty = {};
  const customValidatorFields = getCustomValidatorFields();
  if (customValidatorFields) {
    for (const field of customValidatorFields) {
      customValidatorFieldsEmpty[field] = undefined;
    }
  }
  return customValidatorFieldsEmpty;
};

export const GetEmptyTheme = (): ProgramTheme =>
  ({
    intl: {
      locale: getLocaleSafely(),
      messages: {},
    },
    customCss: "",
    // TODO: create a proper empty theme, or return this as
    // Partial<ProgramTheme> and update the places this is used.
  } as ProgramTheme);

export const requestOrganizationEmpty: RequestOrganizationViewModel = {
  ...withLocaleAndCountryEmpty,
  orgDomain: "",
  orgName: "",
  firstName: "",
  lastName: "",
  email: "",
  displayResults: false,
  searchByOrgName: false,
  completeRequest: false,
  eligibleOrgs: [],
  ineligibleOrgs: [],
  orgCountryError: null,
  orgDomainError: null,
  orgNameError: null,
  firstNameError: null,
  lastNameError: null,
  emailError: null,
  isSearching: false,
};
