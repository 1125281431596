import React from "react";

interface DocTypeCardComponentProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  setDocType: () => void;
}

const DocTypeCard = ({ icon, title, subtitle, setDocType }: DocTypeCardComponentProps) => (
  <button className="sid-id-check-doc-type-card" type="button" onClick={setDocType}>
    <div className="sid-id-check-doc-type-card__content">
      <div className="sid-id-check-doc-type-card__header">
        <div className="sid-id-check-doc-type-card__icon">{icon}</div>
      </div>

      <div className="sid-id-check-doc-type-card__label">
        <div className="sid-id-check-doc-type-card__title sid-l-space-btm-sm">{title}</div>
        <div className="sid-id-check-doc-type-card__subtitle">{subtitle}</div>
      </div>
    </div>

    <img
      alt=""
      src="https://assets-resources.sheerid.com/common/images/2018/icons/chevron-right.svg"
    />
  </button>
);

export const DocTypeCardComponent = DocTypeCard;
