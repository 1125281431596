import { InjectedIntl } from "react-intl";
import { FormSelectChoice } from "../../types/types";
import { logger } from "../logger/logger";

type compareLabelReturn = 1 | -1;

export const compareLabel = (
  a: FormSelectChoice<any>,
  b: FormSelectChoice<any>,
): compareLabelReturn => {
  let sortValue: compareLabelReturn;

  try {
    sortValue = a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;
  } catch (e) {
    sortValue = 1;
  }

  return sortValue;
};

export const SortByLabel = (
  choices: FormSelectChoice<any, string>[],
): FormSelectChoice<any, string>[] => choices.sort(compareLabel);

export const getFormFieldSelectOptions = (
  namespace: string,
  optionValues: string[] = [],
  intl: InjectedIntl,
): FormSelectChoice<string, string>[] => {
  const options: FormSelectChoice<string, string>[] = [];
  const omittedOptions: string[] = [];

  optionValues.forEach((value) => {
    if (typeof value === "undefined") return omittedOptions.push("undefined");
    if (!value) return omittedOptions.push(JSON.stringify(value));
    if (typeof value === "number") return omittedOptions.push(JSON.stringify(value));
    if (typeof value === "string" && !value.trim())
      return omittedOptions.push(JSON.stringify(value));

    options.push({
      value,
      label: intl.formatHTMLMessage({
        id: `${namespace}.${value}`,
        defaultMessage: `{${namespace}.${value}}`,
      }),
    });
  });

  if (omittedOptions.length > 0) {
    logger.warn(
      `FormSelectChoice.getFormFieldSelectOptions contains unexpected values (${
        omittedOptions.length
      }): ${omittedOptions.join(", ")}`,
    );
  }

  return options;
};
