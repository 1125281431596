import { getVerificationStatusUrl } from "../ApiUrls";
import { getRouteOverride, getOverriddenMock } from "../TestingRouteOverrides";
import { GetJson } from "../Network";

import { MockStep, VerificationResponse } from "../../types/types";
import { logger } from "../../utils/logger/logger";

export async function fetchExistingVerificationRequest(
  verificationId: string,
): Promise<VerificationResponse> {
  try {
    logger.info(
      "fetchExistingVerificationRequest: Calling back-end for existing verification request",
    );
    const url: string = getVerificationStatusUrl(verificationId);
    const response = (await GetJson(url)) as VerificationResponse;
    const overriddenStep: MockStep = getRouteOverride(response);
    const mockResponse: VerificationResponse = await getOverriddenMock(overriddenStep, response);

    if (mockResponse) {
      return mockResponse;
    }
    return response;
  } catch (e) {
    logger.error(e, "fetchExistingVerificationRequest");
  }
}
