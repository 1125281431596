import { produce } from "immer";
import { InjectedIntl } from "react-intl";
import {
  FieldId,
  FieldValidationErrors,
  Organization,
  VerificationService,
  FieldContent,
  FieldRef,
  ExtendedFieldId,
  WithOrganization,
  InputSelectOnKeyDown,
  FormSelectChoice,
  Country,
  ProgramTheme,
  Locale,
  ViewModel,
  MilitaryStatusResponse,
  State,
  PendingResponse,
  DocUploadResponse,
  FieldDescription,
  FirstResponderStatus,
  MilitaryStatus,
  MedicalProfessionalStatus,
  LowIncomeStatus,
  AllStatuses,
  RecentMoverStatus,
} from "../../types/types";
import {
  VerificationStepsEnum,
  MilitaryStatusDefaultMessagesEnum,
  FirstResponderStatusDefaultMessagesEnum,
  MedicalProfessionalStatusDefaultMessagesEnum,
  LowIncomeStatusDefaultMessagesEnum,
  RecentMoverStatusDefaultMessagesEnum,
} from "../../types/runtimeTypes";
import { blankOrganization } from "../../types/empties";
import {
  validateFieldById,
  getFieldValidationErrors,
  isFormErrored,
} from "../../validators/validators";
import { logger } from "../logger/logger";
import { setFocus } from "../browser/inputHelpers";
import { getRefByFieldId, getRefs } from "../../refs/refs";
import { getSafe } from "../objects";
import {
  getConfiguredCountries,
  getAvailableLocales,
  getConfiguredStates,
  getEstimatedReviewTime,
  getMaxReviewTime,
} from "../../ProgramTheme/programThemeGetters";
import { getQueryParamsFromUrl } from "../routing/Url";
import { FIELD_IDS } from "../../../constants";

export const updateViewModelOrganization = (
  organization: Organization,
  verificationService: VerificationService,
) => {
  const viewModel = verificationService.viewModel as WithOrganization;
  const nextState = produce(viewModel, (draft) => {
    draft.organization = organization
      ? {
          id: organization.id,
          idExtended: organization.idExtended,
          name: organization.name,
        }
      : blankOrganization.organization;
    if (viewModel.organization && viewModel.organization.source !== undefined) {
      draft.organization.source = viewModel.organization.source;
    }
  });
  verificationService.updateViewModel(nextState as ViewModel);
};

export const populateViewModelFromQueryParams = (verificationService: VerificationService) => {
  const viewModel = verificationService.viewModel as WithOrganization;
  const queryParams = getQueryParamsFromUrl();

  const nextState = produce(viewModel, (draft) => {
    Object.keys(viewModel).forEach((key) => {
      if (queryParams.has(key)) {
        draft[key] = queryParams.get(key);
      }
    });
  });

  verificationService.updateViewModel(nextState as ViewModel);
};

export const updateFieldValidationErrors = (
  fieldValidationErrors: FieldValidationErrors,
  verificationService: VerificationService,
) => {
  verificationService.updateFieldValidationErrors(fieldValidationErrors);
};

export const updateFieldValidationErrorsByFieldId = (
  fieldId: FieldId | ExtendedFieldId,
  value: FieldContent,
  verificationService: VerificationService,
) => {
  const { fieldValidationErrors } = verificationService;
  const nextState = produce(fieldValidationErrors, (draft) => {
    if (draft[fieldId]) {
      draft[fieldId] = validateFieldById(fieldId, value, verificationService.formValidationOptions);
    }
  });
  updateFieldValidationErrors(nextState, verificationService);
};

export const shouldCollectAddressFields = (
  countryChoice: FormSelectChoice<Country, string>,
  countries: Country[],
): boolean =>
  (getSafe(() => countries.length === 1) && getSafe(() => countries[0]) === "US") ||
  getSafe(() => countryChoice.value === "US") ||
  getSafe(() => countryChoice.value === undefined);

export const getStatusLabel = (
  intl: InjectedIntl,
  status:
    | FirstResponderStatus
    | MilitaryStatus
    | MedicalProfessionalStatus
    | LowIncomeStatus
    | RecentMoverStatus
    | AllStatuses
    | undefined,
  defaultMessages:
    | typeof MilitaryStatusDefaultMessagesEnum
    | typeof FirstResponderStatusDefaultMessagesEnum
    | typeof MedicalProfessionalStatusDefaultMessagesEnum
    | typeof LowIncomeStatusDefaultMessagesEnum
    | typeof RecentMoverStatusDefaultMessagesEnum
    | undefined,
): string => {
  if (!status) {
    return "";
  }
  return intl.formatHTMLMessage({
    id: status,
    defaultMessage: defaultMessages ? defaultMessages[status] : "status",
  });
};

export const getAvailableLowIncomeStatuses = (
  intl: InjectedIntl,
  availableStatusesResponse: LowIncomeStatus[],
) => {
  const availableStatuses: FormSelectChoice[] = [];

  if (availableStatusesResponse) {
    availableStatusesResponse.forEach((status) => {
      availableStatuses.push({
        value: status,
        label: getStatusLabel(intl, status, LowIncomeStatusDefaultMessagesEnum),
      });
    });
  }
  return availableStatuses;
};

export const getAvailableMedicalStatuses = (
  intl: InjectedIntl,
  availableStatusesResponse: MedicalProfessionalStatus[],
) => {
  const availableStatuses: FormSelectChoice[] = [];

  availableStatusesResponse.forEach((status) => {
    availableStatuses.push({
      value: status,
      label: getStatusLabel(intl, status, MedicalProfessionalStatusDefaultMessagesEnum),
    });
  });
  return availableStatuses;
};

export const getAvailableFirstResponderStatuses = (
  intl: InjectedIntl,
  availableStatusesResponse: FirstResponderStatus[],
): FormSelectChoice[] => {
  const availableStatuses: FormSelectChoice[] = [];

  availableStatusesResponse.forEach((status) => {
    availableStatuses.push({
      value: status,
      label: getStatusLabel(intl, status, FirstResponderStatusDefaultMessagesEnum),
    });
  });

  return availableStatuses;
};

export const getAvailableStatuses = (
  intl: InjectedIntl,
  availableStatusesResponse: AllStatuses[],
): FormSelectChoice[] => {
  const availableStatuses: FormSelectChoice[] = [];

  availableStatusesResponse.forEach((status) => {
    availableStatuses.push({
      value: status,
      label: getStatusLabel(intl, status, null),
    });
  });

  return availableStatuses;
};

export const orgToOption = (org: Organization) => ({
  value: org.id,
  label: org.name,
  country: org.country,
});

// The jslib should not be the owner of this mapping,
// DATA-3164 exists to find another way.
const countryStatusAllowList = new Map<Country, MilitaryStatus[]>([
  ["GB", ["ACTIVE_DUTY", "VETERAN", "RESERVIST"]],
]);

export const getAvailableMilitaryStatuses = (
  verificationService: VerificationService,
  intl: InjectedIntl,
): FormSelectChoice[] => {
  const availableStatusesFromResponse =
    (verificationService.verificationResponse as MilitaryStatusResponse).availableStatuses ||
    (verificationService.previousVerificationResponse &&
      (verificationService.previousVerificationResponse as MilitaryStatusResponse)
        .availableStatuses);
  const availableStatuses: FormSelectChoice[] = [];

  // The country overrides in this function should be updated with DATA-3164
  const country =
    verificationService.viewModel &&
    verificationService.viewModel.countryChoice &&
    verificationService.viewModel.countryChoice.value;
  const countryAllowedStatuses = country && countryStatusAllowList.get(country);
  const availableStatusesForCountry = countryAllowedStatuses
    ? availableStatusesFromResponse.filter((status) => countryAllowedStatuses.includes(status))
    : availableStatusesFromResponse;

  if (!availableStatusesForCountry) {
    return null;
  }

  availableStatusesForCountry.forEach((status) => {
    availableStatuses.push({
      value: status,
      label: getStatusLabel(intl, status, MilitaryStatusDefaultMessagesEnum),
    });
  });
  return availableStatuses;
};

/**
 * @private
 */
export const getFieldDisplayOrderFromRefs = (): FieldId[] | ExtendedFieldId[] => {
  const refs: FieldRef[] = getRefs();
  const fieldDisplayOrder: FieldId | ExtendedFieldId[] = [];

  refs.map((refObject: FieldRef) => fieldDisplayOrder.push(refObject.fieldId));

  return fieldDisplayOrder;
};

/**
 * HD-638 - focus on year, rather than month (which is an open <select> that covers error msg)
 */
export const adjustFirstErroredFieldId = (
  firstErroredFieldId: FieldId | ExtendedFieldId,
): FieldId | ExtendedFieldId => {
  if (firstErroredFieldId === "birthDate" || firstErroredFieldId === "birthDateDay") {
    return "birthDateYear";
  }
  return firstErroredFieldId;
};

/**
 * @private
 */
export const getFirstErroredFieldId = (
  fieldDisplayOrder: FieldId[] | ExtendedFieldId[],
  fieldValidationErrors: FieldValidationErrors,
): FieldId | ExtendedFieldId => {
  let firstErroredFieldId: FieldId | ExtendedFieldId;

  fieldDisplayOrder.reverse().forEach((field) => {
    if (fieldValidationErrors[field]) {
      firstErroredFieldId = field;
    }
  });

  return adjustFirstErroredFieldId(firstErroredFieldId);
};

/**
 * @private
 */
export const handleEmailOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const submitButtonRef: HTMLElement = getRefByFieldId("submitButton");
  if (event.key === "Enter" && submitButtonRef) {
    submitButtonRef.click();
  }
};

/**
 * @deprecated Accessibility standards prevent setting focus without user keyboard interaction
 */
export const handleCountryOnKeyDown: InputSelectOnKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (event.key === "Tab" && !event.shiftKey) {
    event.preventDefault();
    setFocus("organization");
  }
};

/**
 * @public
 */
export const submitForm = (
  viewModel: ViewModel,
  verificationService: VerificationService,
  step: VerificationStepsEnum,
  successCallback?: () => void,
) => {
  const currentFieldValidationErrors = getFieldValidationErrors(
    viewModel,
    verificationService.formValidationOptions,
  );
  const isFormValid = !isFormErrored(currentFieldValidationErrors);

  if (isFormValid) {
    logger.info(`${step} submitting form`);
    verificationService.submitStep(step, viewModel, verificationService.verificationResponse);
    if (successCallback) {
      successCallback();
    }
  } else {
    const fieldDisplayOrder: FieldId[] | ExtendedFieldId[] = getFieldDisplayOrderFromRefs();
    const firstErroredFieldId: FieldId | ExtendedFieldId = getFirstErroredFieldId(
      fieldDisplayOrder,
      currentFieldValidationErrors,
    );
    updateFieldValidationErrors(currentFieldValidationErrors, verificationService);
    setFocus(firstErroredFieldId);
  }
};

/**
 * @public
 * @description Help with commonly-accessed values. Returns the fully-resolved verbiage in the appropriate language.
 * @return {Object}
 */
export const getEstAndMaxReviewTimes = (
  verificationResponse: DocUploadResponse | PendingResponse,
  programTheme: ProgramTheme,
  intl: any,
): { estReviewTime: string; maxReviewTime: string } => {
  const estReviewTime = intl.formatHTMLMessage({
    id: `dateTime.${
      getSafe(() => verificationResponse.estimatedReviewTime) ||
      getEstimatedReviewTime(programTheme)
    }`,
    default: "a few minutes",
  });
  const maxReviewTime = intl.formatHTMLMessage({
    id: `dateTime.${
      getSafe(() => verificationResponse.maxReviewTime) || getMaxReviewTime(programTheme)
    }`,
    default: "2 hours",
  });
  return {
    estReviewTime,
    maxReviewTime,
  };
};

/**
 * @private
 */
export const getAvailableCountryChoices = (
  programTheme: ProgramTheme,
  intl: InjectedIntl,
): FormSelectChoice<Country, string>[] => {
  const availableCountries: Country[] = getConfiguredCountries(programTheme);
  return availableCountries.map((countryCode) => ({
    value: countryCode,
    label: intl.formatHTMLMessage({
      id: `countries.${countryCode}`,
      defaultMessage: countryCode,
    }),
  }));
};

/**
 * @private
 */
export const getSortedCountryChoices = (
  programTheme: ProgramTheme,
  intl: InjectedIntl,
  locale?: Locale,
): FormSelectChoice<Country, string>[] => {
  const localeAwareSorter = (a, b) => Intl.Collator(locale).compare(a.label, b.label);
  return getAvailableCountryChoices(programTheme, intl).sort(localeAwareSorter);
};

/**
 * @private
 */
export const getSortedIdCheckCountryChoices = (
  countries: Country[],
  intl: InjectedIntl,
  locale?: Locale,
): FormSelectChoice<Country, string>[] => {
  const localeAwareSorter = (a, b) => Intl.Collator(locale).compare(a.label, b.label);
  return countries
    .map((countryCode) => ({
      value: countryCode,
      label: intl.formatHTMLMessage({
        id: `countries.${countryCode}`,
        defaultMessage: countryCode,
      }),
    }))
    .sort(localeAwareSorter);
};

export const getAvailableStateChoices = (
  programTheme: ProgramTheme,
  intl: InjectedIntl,
): FormSelectChoice<State, string>[] => {
  const availableStates: State[] = getConfiguredStates(programTheme);
  return availableStates.map((stateCode) => ({
    value: stateCode,
    label: intl.formatHTMLMessage({
      id: `states.${stateCode}`,
      defaultMessage: stateCode,
    }),
  }));
};

export const produceDraftViewModelWithRequiredFields = <T extends ViewModel>(
  previousModel: T,
  newRequiredFields: FieldDescription[],
  conditionalRequiredFieldKeys: (keyof T)[],
) =>
  produce(previousModel, (draft: T) => {
    conditionalRequiredFieldKeys.forEach((requiredFieldKey) => {
      if (newRequiredFields.some((field) => field.key === requiredFieldKey)) {
        (draft[requiredFieldKey] as any) =
          previousModel[requiredFieldKey] !== undefined ? previousModel[requiredFieldKey] : "";
      } else {
        delete draft[requiredFieldKey];
      }
    });
  });

export const produceDraftViewModelWithAllRequiredFields = <T extends ViewModel>(
  viewModel: T,
  newRequiredFields: FieldDescription[],
) =>
  produce(viewModel, (draft: T) => {
    FIELD_IDS.forEach((requiredFieldKey) => {
      if (newRequiredFields.some((field) => field.key === requiredFieldKey)) {
        (draft[requiredFieldKey] as any) =
          viewModel[requiredFieldKey] !== undefined ? viewModel[requiredFieldKey] : "";
      } else {
        delete draft[requiredFieldKey];
      }
    });
  });

/**
 * @private
 */
export const getAvailableLocaleChoices = (
  programTheme: ProgramTheme,
  intl: InjectedIntl,
): FormSelectChoice<Locale, string>[] => {
  const availableLocales: Locale[] = getAvailableLocales(programTheme);
  return availableLocales.map((locale) => ({
    value: locale,
    label: intl.formatHTMLMessage({
      id: `locales.${locale}`,
      defaultMessage: locale,
    }),
  }));
};

/**
 * @private
 */
export const getDefaultCountryChoice = (
  countryChoices: FormSelectChoice<Country, string>[],
): FormSelectChoice<Country, string> => {
  if (countryChoices.length > 0) {
    return countryChoices[0];
  }
  return { value: "US", label: "United States" };
};

/**
 * @private
 */
export const produceDraftViewModel = <T extends ViewModel>(
  previousModel: T,
  key: keyof T,
  value: any,
) =>
  produce(previousModel, (draft: T) => {
    (draft[key] as any) = value;
  });

/**
 * @private
 */
export const resetViewModelOrganization = (verificationService: VerificationService) => {
  const viewModelWithOrg = verificationService.viewModel as WithOrganization;
  if (viewModelWithOrg.organization !== undefined) {
    return updateViewModelOrganization(blankOrganization.organization, verificationService);
  }
};

/**
 * @private
 */
export const getRememberMeRedirectDestination = (
  locale: Locale,
  programId: string,
  verificationId: string,
): string => {
  const supportBasePreviewUrl = "https://support-preview.sheerid.net";
  const supportBaseProdUrl = "https://support.sheerid.com";
  let baseUrl = supportBaseProdUrl;
  const { host } = new URL(window.location.href);

  if (host.includes("preview") || host.includes("localhost")) {
    baseUrl = supportBasePreviewUrl;
  }

  return `${baseUrl}/${locale}/${programId}/${verificationId}/remember-me/`;
};
