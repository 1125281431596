//
// NOTE this file is high risk for circular dependencies! Avoid imports, or double check that they don't create circular deps
//

import type { NavigatorBeta } from "../types/types";

/**
 * A singleton to allow access to user properties that we've set on GA without
 * querying GA directly. This is primarily used to also send them to New Relic.
 */
const _attrCache: Record<string, unknown> = {};

export const setGaAttributes = (attrs: Record<string, unknown>): void =>
  Object.entries(attrs).forEach(([k, v]) => {
    _attrCache[k] = v;
  });

export const getGaAttributes = async (): Promise<object> => _attrCache;

export const doNotTrack = () => (window.navigator as NavigatorBeta).globalPrivacyControl;

export const isNewRelicAvailable = () => window.NREUM && !doNotTrack();
