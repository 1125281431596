import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import { VerificationStepsEnum, FieldIdEnum } from "../../lib/types/runtimeTypes";
import { setRef } from "../../lib/refs/refs";
import { VerificationService, SocialSecurityViewModel } from "../../lib/types/types";
import {
  submitForm,
  updateFieldValidationErrorsByFieldId,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { SocialSecurityNumber } from "../FormFields/SSN/SSN";
import { SsnChoice } from "./SsnChoice";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";

interface StepSocialSecurityNumberProps {
  verificationService: VerificationService;
}

const Step = ({ verificationService }: StepSocialSecurityNumberProps) => {
  const [showChoice, setShowChoice] = React.useState(true);
  const viewModel = verificationService.viewModel as SocialSecurityViewModel;
  const { fieldValidationErrors } = verificationService;
  const doCancelStep = () =>
    verificationService.submitStep(
      VerificationStepsEnum.cancelSocialSecurityNumber,
      viewModel,
      verificationService.verificationResponse,
    );

  const updateSSNViewModel = (key: keyof SocialSecurityViewModel, value: any) => {
    const nextState: SocialSecurityViewModel = produce(
      viewModel,
      (draft: SocialSecurityViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  const isSubmittable =
    viewModel.socialSecurityNumber && viewModel.socialSecurityNumber.toString().length === 9;

  if (showChoice) {
    return (
      <SsnChoice
        useSsn={() => setShowChoice(false)}
        useDocUpload={doCancelStep}
        verificationService={verificationService}
      />
    );
  }

  return (
    <div
      id="sid-step-social-security"
      className="sid-social-sercurity-number-container sid-l-container sid-l-horz-center"
    >
      <div className="sid-header">
        <div className="sid-social-security-number-logo-container sid-l-horz-center">
          <LogoComponent verificationService={verificationService} />
        </div>
        <div className="sid-header__title sid-l-horz-center">
          <FormattedHTMLMessage
            id="step.collectSocialSecurityNumber.title"
            defaultMessage="Enter your social security number"
            tagName="h1"
          />
        </div>

        <ChangeLocaleV2Component verificationService={verificationService} />

        <div className="sid-ssn-wrapper">
          <SocialSecurityNumber
            value={viewModel.socialSecurityNumber}
            onChange={(value: string) => {
              updateSSNViewModel("socialSecurityNumber", value);
              updateFieldValidationErrorsByFieldId(
                "socialSecurityNumber",
                value,
                verificationService,
              );
            }}
            isErrored={!!fieldValidationErrors[FieldIdEnum.socialSecurityNumber]}
            placeholder="000-00-0000"
          />
        </div>
      </div>

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-social-security-number-submit sid-submit">
          <button
            id="sid-submit-wrapper__collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectSocialSecurityNumber,
              )
            }
            type="submit"
            className={`sid-btn sid-social-security-number-submit-btn sid-btn--dark sid-l-full-width ${
              isSubmittable ? "" : "sid-btn--disabled-like"
            }`}
            aria-label="submit"
            ref={(button) => setRef("submitButton", button)}
          >
            <FormattedHTMLMessage id="proceed" defaultMessage="Proceed" />
          </button>
        </div>
      </div>

      <div
        className="sid-form-region sid-social-security-number-upload-instead-btn sid-l-space-top-md"
        role="button"
        tabIndex={0}
        onKeyUp={doCancelStep}
        onClick={doCancelStep}
      >
        <div className="sid-social-security-number-upload-instead-text sid-h-link-like">
          <FormattedHTMLMessage
            id="step.collectSocialSecurityNumber.uploadInstead"
            defaultMessage="Upload a document instead"
          />
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

// TODO remove injectIntl, it's not used
export const StepSocialSecurityNumber = injectIntl(Step);
