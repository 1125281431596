import { produce } from "immer";
import React, { useEffect } from "react";
import { FormattedHTMLMessage } from "react-intl";

import { shouldCollectPostalCode } from "../../lib/organization/organization";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import {
  FormFieldConfig,
  Organization,
  TeacherPersonalInfoResponse,
  TeacherPersonalInfoViewModel,
  VerificationService,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";

import { getOptions } from "../../options/options";
import { ExtraMessage } from "../ExtraMessage";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { TeacherSchoolComponent } from "../FormFields/TeacherSchool/TeacherSchoolComponent";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepTeacherPersonalInfoComponentProps {
  verificationService: VerificationService;
}

const StepTeacherPersonalInfo = ({
  verificationService,
}: StepTeacherPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as TeacherPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as TeacherPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const locale = viewModel.localeChoice.value;

  const updateTeacherViewModel = (key: keyof TeacherPersonalInfoViewModel, value: any) => {
    const nextState: TeacherPersonalInfoViewModel = produce(
      viewModel,
      (draft: TeacherPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  // Don't validate DOB if ID Check is not enabled
  useEffect(() => {
    if (!verificationService.programTheme.idCheckAddOnEnabled) {
      updateTeacherViewModel("birthDate", "");
      updateTeacherViewModel("fieldsToSkipValidation", ["birthDate"]);
    } else {
      updateTeacherViewModel("fieldsToSkipValidation", []);
    }
  }, [verificationService.programTheme.idCheckAddOnEnabled]);

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="teacher-personal-info"
      />

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      <TeacherSchoolComponent
        value={(verificationService.viewModel as TeacherPersonalInfoViewModel).organization}
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateTeacherViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateTeacherViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      {verificationService.programTheme.idCheckAddOnEnabled ? (
        <BirthDate
          isErrored={!!fieldValidationErrors.birthDate}
          errorId={fieldValidationErrors.birthDate}
          locale={locale}
          onChange={(newValue) => {
            updateTeacherViewModel("birthDate", newValue);
            updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
          }}
          value={viewModel.birthDate}
        />
      ) : null}

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Must be your school-issued email address"
          />
        }
        onChange={(newValue) => {
          updateTeacherViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />

      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateTeacherViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}
      {shouldCollectPostalCode(verificationService.programTheme, viewModel) ? (
        <PostalCodeComponent
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updateTeacherViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      ) : null}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateTeacherViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />
      <PersonalInfoStepSubmitButton
        step={VerificationStepsEnum.collectTeacherPersonalInfo}
        verificationService={verificationService}
        viewModel={viewModel}
        failedInstantMatch={failedInstantMatch}
        buttonText={
          <FormattedHTMLMessage
            id="verifyMyTeacherStatus"
            defaultMessage="Verify My Teacher Status"
          />
        }
      />
    </PersonalInfoWrapper>
  );
};

export const StepTeacherPersonalInfoComponent = StepTeacherPersonalInfo;
