export const fadeInElements = (elements: HTMLElement[]) => {
  elements.forEach((element: HTMLElement) => {
    element.classList.add("fade-in");
  });
};

export const removeElement = (element: HTMLElement, transitionTimeout: number) => {
  element.classList.remove("fade-in");
  window.setTimeout(() => {
    document.body.style.overflow = "auto";
    element.parentNode.removeChild(element);
  }, transitionTimeout);
};

export const getVerificationUrl = (url: string): string => {
  const newUrl = new URL(url);
  const parentUrl = new URL(window.location.href);

  // Combine query parameters from parent window and provided url.
  // If duplicates, provided url parameters supercede.
  parentUrl.searchParams.forEach((value, key) => {
    if (!newUrl.searchParams.has(key)) {
      newUrl.searchParams.set(key, value);
    }
  });

  return newUrl.href;
};
