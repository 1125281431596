import React from "react";

import { FormattedHTMLMessage } from "react-intl";
import { VerificationService } from "../../lib/types/types";
import { getLogoUrl } from "../../lib/ProgramTheme/programThemeGetters";

interface LogoProps {
  verificationService: VerificationService;
  fallbackLogo?: JSX.Element;
}

const LogoComponent = ({ verificationService, fallbackLogo }: LogoProps) => {
  const logoUrl = getLogoUrl(verificationService.programTheme);

  if (logoUrl) {
    return (
      <div className="sid-logo sid-l-space-top-md">
        <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
          {(companyName) => (
            <img className="sid-logo__img" alt={`${companyName} logo`} src={logoUrl} />
          )}
        </FormattedHTMLMessage>
      </div>
    );
  }

  if (fallbackLogo) {
    return fallbackLogo;
  }

  return null;
};

export { LogoComponent };
