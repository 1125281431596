import React from "react";

import produce from "immer";
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from "react-intl";
import {
  VerificationService,
  CollectPersonalInfoViewModel,
  FormFieldConfig,
  Organization,
  FormSelectChoice,
  State,
  CollectFieldsResponse,
  CollectPersonalInfoResponse,
  FieldDescription,
  ViewModel,
} from "../../lib/types/types";
import {
  getAvailableStateChoices,
  orgToOption,
  getStatusLabel,
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
  produceDraftViewModelWithAllRequiredFields,
  getAvailableStatuses,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { CollegeNameComponent as CollegeName } from "../FormFields/CollegeName/CollegeNameComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { FieldIdEnum, SegmentEnum, VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { setRef } from "../../lib/refs/refs";
import { CityComponent } from "../FormFields/City/City";
import { StateSelectComponent } from "../FormFields/State/StateSelectComponent";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { AddressComponent } from "../FormFields/Address/AddressComponent";
import { ActiveDutyStartDateComponent } from "../FormFields/ActiveDutyStartDate/ActiveDutyStartDateComponent";
import { BranchOfServiceComponent as BranchOfService } from "../FormFields/BranchOfService/BranchOfServiceComponent";
import { DriverLicenseNumberComponent as DriverLicenseNumber } from "../FormFields/DriverLicenseNumber/DriverLicenseNumberComponent";
import { EBTCardNumberComponent } from "../FormFields/EBTCardNumber/EBTCardNumberComponent";
import { StatusComponent as Status } from "../FormFields/Status/StatusComponent";
import { LowIncomeOrganizationComponent as LowIncomeOrganization } from "../FormFields/LowIncomeOrganization/LowIncomeOrganizationComponent";
import { FirstResponderOrganizationComponent as FirstResponderOrganization } from "../FormFields/FirstResponderOrganization/FirstResponderOrganizationComponent";
import { LicensedProfessionalOrganizationComponent as LicensedProfessionalOrganization } from "../FormFields/LicensedProfessionalOrganization/LicensedProfessionalOrganizationComponent";
import { MedicalProfessionalOrganizationComponent as MedicalProfessionalOrganization } from "../FormFields/MedicalProfessionalOrganization/MedicalProfessionalOrganizationComponent";
import { MemberIdComponent as MemberId } from "../FormFields/MemberId/MemberIdComponent";
import { MembershipOrganizationComponent as MembershipOrganization } from "../FormFields/MembershipOrganization/MembershipOrganizationComponent";
import { TeacherSchoolComponent as TeacherSchool } from "../FormFields/TeacherSchool/TeacherSchoolComponent";
import { SocialSecurityNumber } from "../FormFields/SSN/SSN";
import { DischargeDateComponent } from "../FormFields/DischargeDate/DischargeDateComponent";
import { getAllFieldsToCollect } from "../../lib/ServerApi/VerificationApiClient";
import { getSafe } from "../../lib/utils/objects";
import { logger } from "../../lib/utils/logger/logger";
import { getMarketConsent } from "../../lib/ProgramTheme/programThemeGetters";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepCollectPersonalInfoProps {
  intl: InjectedIntl;
  verificationService: VerificationService;
}

export const StepCollectPersonalInfo = ({
  intl,
  verificationService,
}: StepCollectPersonalInfoProps) => {
  const viewModel = verificationService.viewModel as CollectPersonalInfoViewModel;
  const { updateViewModel, programTheme } = verificationService;

  const [requiredFields, setRequiredFields] = React.useState<FieldDescription[]>(undefined);

  const isFieldRequired = (key: keyof CollectPersonalInfoViewModel) =>
    requiredFields && requiredFields.some((field) => field.key === key);

  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as CollectPersonalInfoResponse;

  const updatePersonalInfoViewModel = (key: keyof CollectPersonalInfoViewModel, value: any) => {
    const nextState: CollectPersonalInfoViewModel = produce(
      viewModel,
      (draft: CollectPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    updateViewModel(nextState);
  };

  const updateViewModelWithRequiredFields = (newRequiredFields: FieldDescription[]) => {
    const nextState: CollectPersonalInfoViewModel = produceDraftViewModelWithAllRequiredFields(
      viewModel,
      newRequiredFields,
    );
    updateViewModel(nextState);
  };

  const locale = viewModel.localeChoice.value;

  const { segment, availableStatuses } = verificationResponse;
  const pascalCaseSegment = segment.charAt(0).toUpperCase() + segment.slice(1);

  const isStatusRequired = (): boolean =>
    availableStatuses &&
    (segment === SegmentEnum.FIRST_RESPONDER ||
      segment === SegmentEnum.MEDICAL ||
      segment === SegmentEnum.LOW_INCOME ||
      segment === SegmentEnum.LICENSED_PROFESSIONAL ||
      segment === SegmentEnum.EMPLOYMENT ||
      segment === SegmentEnum.RECENT_MOVER ||
      segment === SegmentEnum.MILITARY);

  const getAndSetRequiredFields = (requestBody: Partial<ViewModel>) =>
    (async () => {
      try {
        const collectFieldsResponse: CollectFieldsResponse = await getAllFieldsToCollect(
          verificationResponse.verificationId,
          verificationResponse.currentStep,
          requestBody,
        );
        const newRequiredFields = getSafe(() => collectFieldsResponse.fieldsToCollect.required, []);

        if (isStatusRequired()) {
          // Adding `status` as a required input field if it's been removed by getting
          // all fields to collect with a statuses req body
          newRequiredFields.push({ key: "status" });
        }

        updateViewModelWithRequiredFields(newRequiredFields);
        setRequiredFields(newRequiredFields);
      } catch (error) {
        logger.error(`Failed to determine fields to collect: ${error}`);
      }
    })();

  const getOrganizationField = () => {
    switch (segment) {
      case SegmentEnum.STUDENT:
        return (
          <CollegeName
            value={(verificationService.viewModel as CollectPersonalInfoViewModel).organization}
            verificationService={verificationService}
            isErrored={!!fieldValidationErrors.organization}
            isRequired
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
          />
        );
      case SegmentEnum.TEACHER:
        return (
          <TeacherSchool
            value={(verificationService.viewModel as CollectPersonalInfoViewModel).organization}
            verificationService={verificationService}
            isErrored={!!fieldValidationErrors.organization}
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
          />
        );
      case SegmentEnum.MEMBER:
        return (
          <MembershipOrganization
            value={(verificationService.viewModel as CollectPersonalInfoViewModel).organization}
            verificationService={verificationService}
            isErrored={!!fieldValidationErrors.organization}
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
          />
        );
      case SegmentEnum.MILITARY:
        return (
          <BranchOfService
            isErrored={!!fieldValidationErrors.organization}
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
            value={{
              value: getSafe(
                () =>
                  (verificationService.viewModel as CollectPersonalInfoViewModel).organization.id,
              ),
              label: getSafe(
                () =>
                  (verificationService.viewModel as CollectPersonalInfoViewModel).organization.name,
              ),
            }}
            verificationService={verificationService}
            organizations={verificationService.orgList}
          />
        );
      case SegmentEnum.FIRST_RESPONDER:
        return (
          <FirstResponderOrganization
            isErrored={!!fieldValidationErrors.organization}
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
            value={(verificationService.viewModel as CollectPersonalInfoViewModel).organization}
            verificationService={verificationService}
          />
        );
      case SegmentEnum.MEDICAL:
        return (
          <MedicalProfessionalOrganization
            isErrored={!!fieldValidationErrors.organization}
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
            value={(verificationService.viewModel as CollectPersonalInfoViewModel).organization}
            verificationService={verificationService}
          />
        );
      case SegmentEnum.LICENSED_PROFESSIONAL:
        return (
          <LicensedProfessionalOrganization
            isErrored={!!fieldValidationErrors.organization}
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
            value={(verificationService.viewModel as CollectPersonalInfoViewModel).organization}
            verificationService={verificationService}
          />
        );
      case SegmentEnum.LOW_INCOME:
        return (
          <LowIncomeOrganization
            isErrored={!!fieldValidationErrors.organization}
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
            value={orgToOption(
              (verificationService.viewModel as CollectPersonalInfoViewModel).organization,
            )}
            verificationService={verificationService}
            isRequired
          />
        );
      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (availableStatuses && segment === SegmentEnum.MILITARY) {
      // display all required military fields by setting an initial default status
      getAndSetRequiredFields({ statuses: [] });
    } else {
      // fetch initial list of required input fields
      getAndSetRequiredFields({});
    }
  }, []);

  React.useEffect(() => {
    if (!getMarketConsent(programTheme).enabled && requiredFields && requiredFields.length === 0) {
      // skip this step if there are no required fields, and marketConsent is not enabled
      submitForm(viewModel, verificationService, VerificationStepsEnum.collectPersonalInfo);
    }
  }, [requiredFields]);

  React.useEffect(() => {
    if (viewModel.status) {
      // if status changes, re-fetch required fields because they may have changed based on selected status
      getAndSetRequiredFields({ statuses: [viewModel.status] });
    }
  }, [viewModel.status]);

  return (
    <div
      id="sid-step-personal-info-personal-info"
      className="sid-personal-info-container sid-l-container"
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="personal-info"
      />

      <CountryComponentWrapper verificationService={verificationService} />

      {isStatusRequired() && (
        <Status
          segmentId={segment}
          value={{
            value: viewModel.status,
            label: getStatusLabel(intl, viewModel.status, null),
          }}
          isErrored={!!fieldValidationErrors.status}
          errorId={`invalid${pascalCaseSegment}Status`}
          options={getAvailableStatuses(intl, availableStatuses)}
          onChange={(status: FormSelectChoice) => {
            updatePersonalInfoViewModel("status", status ? (status.value as string) : "");
            updateFieldValidationErrorsByFieldId(
              "status",
              status ? (status.value as string) : "",
              verificationService,
            );
          }}
        />
      )}

      {isFieldRequired("organization") && getOrganizationField()}

      <div className="sid-names">
        {isFieldRequired("firstName") && (
          <FirstName
            value={viewModel.firstName}
            isErrored={!!fieldValidationErrors.firstName}
            onChange={(newValue) => {
              updatePersonalInfoViewModel("firstName", newValue);
              updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
            }}
          />
        )}

        {isFieldRequired("lastName") && (
          <LastName
            value={viewModel.lastName}
            isErrored={!!fieldValidationErrors.lastName}
            onChange={(newValue) => {
              updatePersonalInfoViewModel("lastName", newValue);
              updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
            }}
          />
        )}
      </div>

      {isFieldRequired("birthDate") && (
        <BirthDate
          value={viewModel.birthDate}
          isErrored={!!fieldValidationErrors.birthDate}
          errorId={fieldValidationErrors.birthDate}
          locale={locale}
          isRequired
          onChange={(newValue) => {
            updatePersonalInfoViewModel("birthDate", newValue);
            updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
          }}
        />
      )}

      {isFieldRequired("email") && (
        <Email
          value={viewModel.email}
          isErrored={!!fieldValidationErrors.email}
          explanation=" "
          onChange={(newValue) => {
            updatePersonalInfoViewModel("email", newValue);
            updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
          }}
          onKeyDown={(event) => handleEmailOnKeyDown(event)}
        />
      )}

      {isFieldRequired("phoneNumber") && (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updatePersonalInfoViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      )}

      {isFieldRequired("address1") && (
        <AddressComponent
          value={viewModel.address1}
          isRequired
          isErrored={!!fieldValidationErrors.address1}
          errorId={fieldValidationErrors.address1}
          onChange={(value) => {
            updatePersonalInfoViewModel("address1", value);
            updateFieldValidationErrorsByFieldId("address1", value, verificationService);
          }}
        />
      )}

      {isFieldRequired("city") && (
        <CityComponent
          value={viewModel.city}
          isRequired
          isErrored={!!fieldValidationErrors.city}
          errorId={fieldValidationErrors.city}
          onChange={(newValue) => {
            updatePersonalInfoViewModel("city", newValue);
            updateFieldValidationErrorsByFieldId("city", newValue, verificationService);
          }}
        />
      )}

      {isFieldRequired("dischargeDate") && (
        <DischargeDateComponent
          isErrored={!!fieldValidationErrors.dischargeDate}
          onChange={(newValue) => {
            updatePersonalInfoViewModel("dischargeDate", newValue);
            updateFieldValidationErrorsByFieldId("dischargeDate", newValue, verificationService);
          }}
          value={viewModel.dischargeDate}
        />
      )}

      {isFieldRequired("driverLicenseNumber") && (
        <DriverLicenseNumber
          value={viewModel.driverLicenseNumber}
          isErrored={!!fieldValidationErrors.driverLicenseNumber}
          isRequired
          onChange={(newValue) => {
            updatePersonalInfoViewModel("driverLicenseNumber", newValue);
            updateFieldValidationErrorsByFieldId(
              "driverLicenseNumber",
              newValue,
              verificationService,
            );
          }}
        />
      )}

      {isFieldRequired("ebtCardNumber") && (
        <EBTCardNumberComponent
          value={viewModel.ebtCardNumber}
          isErrored={!!fieldValidationErrors.ebtCardNumber}
          onChange={(newValue) => {
            updatePersonalInfoViewModel("ebtCardNumber", newValue);
            updateFieldValidationErrorsByFieldId("ebtCardNumber", newValue, verificationService);
          }}
        />
      )}

      {isFieldRequired("memberId") && (
        <MemberId
          value={viewModel.memberId}
          isErrored={!!fieldValidationErrors.memberId}
          isRequired={false}
          onChange={(newValue) => {
            updatePersonalInfoViewModel("memberId", newValue);
            updateFieldValidationErrorsByFieldId("memberId", newValue, verificationService);
          }}
          explanation={<></>}
          label={
            <FormattedHTMLMessage
              id="step.collectMedicalPersonalInfo.registrationNumberLabel"
              defaultMessage="Registration Number"
            />
          }
        />
      )}

      {isFieldRequired("postalCode") && (
        <PostalCodeComponent
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updatePersonalInfoViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      )}

      {isFieldRequired("socialSecurityNumber") && (
        <div className="sid-ssn-wrapper">
          <SocialSecurityNumber
            value={viewModel.socialSecurityNumber}
            onChange={(value: string) => {
              updatePersonalInfoViewModel("socialSecurityNumber", value);
              updateFieldValidationErrorsByFieldId(
                "socialSecurityNumber",
                value,
                verificationService,
              );
            }}
            isErrored={!!fieldValidationErrors[FieldIdEnum.socialSecurityNumber]}
            placeholder="000-00-0000"
          />
        </div>
      )}

      {isFieldRequired("state") && (
        <StateSelectComponent
          isRequired
          options={getAvailableStateChoices(verificationService.programTheme, intl)}
          value={viewModel.state || undefined}
          isErrored={Boolean(fieldValidationErrors.state)}
          onChange={async (stateChoice: FormSelectChoice<State, string>) => {
            const stateValue = stateChoice ? stateChoice.value : undefined;
            updatePersonalInfoViewModel("state", stateValue);
            updateFieldValidationErrorsByFieldId("state", stateValue, verificationService);
          }}
          label={<FormattedHTMLMessage id="state" defaultMessage="State" />}
        />
      )}

      {isFieldRequired("activeDutyStartDate") &&
        verificationService.programTheme.strictMilitaryValidationEnabled && (
          <ActiveDutyStartDateComponent
            isErrored={!!fieldValidationErrors.activeDutyStartDate}
            errorId={fieldValidationErrors.activeDutyStartDate}
            locale={locale}
            onChange={(newValue) => {
              updatePersonalInfoViewModel("activeDutyStartDate", newValue);
              updateFieldValidationErrorsByFieldId(
                "activeDutyStartDate",
                newValue,
                verificationService,
              );
            }}
            value={viewModel.activeDutyStartDate}
          />
        )}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updatePersonalInfoViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-personal-info--submit sid-submit">
          <button
            id="sid-submit-wrapper__collect-info"
            onClick={() =>
              submitForm(viewModel, verificationService, VerificationStepsEnum.collectPersonalInfo)
            }
            type="submit"
            className={`sid-btn sid-personal-info-submit-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-label="submit"
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              <FormattedHTMLMessage
                id="step.collectPersonalInfo.submitButton"
                defaultMessage="Verify my {segment} status"
                values={{ segment }}
              />
            </span>
          </button>
        </div>
      </div>
      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepCollectPersonalInfoComponent = injectIntl(StepCollectPersonalInfo);
