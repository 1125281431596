/**
 * Flags is our TS modeling of the flags provided by Launch Darkly.
 * our hooks use this to know what is available.
 */
export type Flags = {
  // When a flag is added here it should reference its removal ticket.
  // ex. "docupload-preview": false; // removal HC-123
  "doc-upload-redesign": boolean; // removal HC-154
  "font-size": "default" | "control" | "14px" | "16px"; // removal HC-208
  "email-first": "default" | "control" | "isolated" | "all-fields-visible"; // removal HC-410
};

/**
 * These are flag defaults they are passed into the LD flag provider and
 * populate the provider before LD's responses come back.
 */
export const flagDefaults: Flags = {
  "doc-upload-redesign": false,
  "email-first": "default",
  "font-size": "default",
};

export type FlagNames = keyof Flags;
