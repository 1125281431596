import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { VerificationService, Locale } from "../../lib/types/types";
import { getSafe } from "../../lib/utils/objects";
import { getLocaleSafely } from "../../lib/intl/intl";
import { makeFormattedHTMLTagReplacer } from "../../lib/utils/customTagReplacer";
import { getPrivacyPolicyUrl } from "../../lib/ProgramTheme/programThemeGetters";
import { LinkExternal } from "../LinkExternal";
import { FaqLinkComponent } from "../FaqLink/FaqLinkComponent";
import { setViewModel } from "../../lib/VerificationService/ViewModel";

export interface FormFooterProps {
  verificationService: VerificationService;
  children?: React.ReactNode;
  showShield?: boolean;
}

const FormFooterComponent = ({
  verificationService,
  children,
  showShield = false,
}: FormFooterProps) => {
  const { viewModel } = verificationService;
  const countryChoice = getSafe<string>(() => viewModel.countryChoice.value, undefined);
  const locale: Locale = getLocaleSafely(viewModel);
  const privacyPolicyRef = React.useRef<any>({});
  const cleanupCommonTags =
    /(<div[^>]+>|<div>|<\/div>)|(<span[^>]+>|<span>|<\/span>)|(<p[^>]+>|<p>|<\/p>)/gm;

  // Store the footer message that the user was shown when the user submitted
  React.useEffect(() => {
    if (!privacyPolicyRef.current) {
      return;
    }
    const privacyPolicyText = privacyPolicyRef.current.innerHTML.replace(cleanupCommonTags, "");
    const updatedViewModel = { metadata: { submissionOptIn: privacyPolicyText } };
    setViewModel(updatedViewModel);
  }, [privacyPolicyRef, locale]);

  return (
    <div className="sid-footer sid-h-small-text sid-l-space-top-md">
      {(showShield || !children) && (
        <div className="sid-footer__shield">
          <img
            alt=""
            src="https://assets-resources.sheerid.com/common/images/2018/icons/shield-check.svg"
          />
        </div>
      )}
      <div>
        <div className="sid-footer__one" ref={privacyPolicyRef}>
          {children || (
            // The footer text block is collapsed until focused and as such is interactive.
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div className="sid-footer__text" tabIndex={0}>
              <FormattedHTMLMessage
                id="footerText"
                defaultMessage="By submitting the personal information above, I acknowledge that my personal information is being collected under the <privacyPolicy>privacy policy</privacyPolicy> of the business from which I am seeking a discount, and I understand that my personal information will be shared with SheerID as a processor/third-party service provider in order for SheerID to confirm my eligibility for a special offer. <aboutSheerID>More about SheerID</aboutSheerID>."
              >
                {makeFormattedHTMLTagReplacer({
                  privacyPolicy: (content) => {
                    const url = getPrivacyPolicyUrl(verificationService!.programTheme);
                    return url ? (
                      <LinkExternal className="sid-privacy-policy sid-link" href={url}>
                        {content}
                      </LinkExternal>
                    ) : (
                      content
                    );
                  },
                  aboutSheerID: (content) => (
                    <FaqLinkComponent
                      verificationService={verificationService}
                      linkText={content}
                    />
                  ),
                })}
              </FormattedHTMLMessage>
              {typeof countryChoice === "string" &&
              countryChoice.length > 0 &&
              countryChoice !== "US" ? (
                <>
                  {" "}
                  <FormattedHTMLMessage
                    id="informationTransferredToUS"
                    defaultMessage="Your information will be transferred to the United States and will be treated according to SheerID’s privacy policy."
                  />
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { FormFooterComponent };
