import { produce } from "immer";
import { DocUploadViewModel } from "../types/types";

import { MAX_DOC_UPLOAD_DOCS_ALLOWED } from "../../constants";

export const addFiles = (
  viewModel: DocUploadViewModel,
  files: File[],
  erroredFileNames?: string[],
): DocUploadViewModel =>
  produce(viewModel, (draft) => {
    Object.keys(viewModel)
      .filter((key: string) => !!viewModel[key] && key.includes("file"))
      .map((key: string) => viewModel[key])
      .concat(files, Array(MAX_DOC_UPLOAD_DOCS_ALLOWED).fill(undefined))
      .splice(0, 3)
      .forEach((file: File, index: number) => (draft[`file${index + 1}`] = file)); // eslint-disable-line
    if (erroredFileNames) {
      draft.erroredFileNames = erroredFileNames;
    } else {
      draft.erroredFileNames = undefined;
    }
  });

export const removeFile = (
  viewModel: DocUploadViewModel,
  fileNumber: number,
): DocUploadViewModel => {
  if (![1, 2, 3].includes(fileNumber)) {
    throw new Error(
      `Expected either 1, 2 or 3 for argument "fileNumber", but received ${fileNumber}`,
    );
  }

  return produce(viewModel, (draft) => {
    Object.keys(viewModel)
      .filter((key: string) => key.includes("file") && !key.includes(fileNumber.toString()))
      .map((key: string) => viewModel[key])
      .concat(Array(MAX_DOC_UPLOAD_DOCS_ALLOWED).fill(undefined))
      .splice(0, 3)
      .forEach((file: File, index: number) => (draft[`file${index + 1}`] = file)); // eslint-disable-line
  });
};

export const removeAllFiles = (viewModel: DocUploadViewModel): DocUploadViewModel =>
  produce(viewModel, (draft) => {
    for (let i = 0; i < MAX_DOC_UPLOAD_DOCS_ALLOWED; i++) {
      draft[`file${i + 1}`] = undefined;
    }
  });
