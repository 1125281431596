import clone from "clone";

export function deepClone<T>(obj: T): T {
  return clone(obj);
}

/**
 * Avoid "can't access property of undefined" errors in a more readable way.
 * @param fn Function that wraps a nested property access
 * @param defaultVal Optional - the value to return if property access failed
 */
export function getSafe<T>(fn: () => T, defaultVal?: T): T | undefined {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired from https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6#gistcomment-3120712
 */
export function deepMerge(...objects: object[]) {
  const isObject = (obj: any) => obj && typeof obj === "object";

  function deepMergeInner(target: object, source: object) {
    Object.keys(source).forEach((key: string) => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue); // eslint-disable-line
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = deepMergeInner(Object.assign({}, targetValue), sourceValue); // eslint-disable-line
      } else {
        target[key] = sourceValue; // eslint-disable-line
      }
    });

    return target;
  }

  if (objects.length < 2) {
    throw new Error("deepMerge: this function expects at least 2 objects to be provided");
  }

  if (objects.some((object) => !isObject(object))) {
    throw new Error('deepMerge: all values should be of type "object"');
  }

  const target = objects.shift();
  let source: object;

  // eslint-disable-next-line
  while ((source = objects.shift())) {
    deepMergeInner(target, source);
  }

  return target;
}
