/* eslint no-console:0 */
// ~~~ This file and its tests are the only files that should disable "no-console" ~~~

import { getGaAttributes, isNewRelicAvailable } from "../../GoogleAnalytics/gaHelpers";
import { LogLevel, ReduxState, VerificationStore } from "../../types/types";
import { getSafe } from "../objects";

let logLevel = 4; // default
let prefix = "";
const logLevels = {
  info: 1,
  log: 2,
  warn: 3,
  error: 4,
};

const colors = {
  info: "#26c1db",
  log: "#09f979",
  warn: "#f6b13f",
  error: "#e12046",
};

const standardStyles = "color: white; font-weight: bold; padding: 2px 10px;";

let reduxStore;

const isError = (e) => e && e.stack && e.message;

const warn = (...args) => {
  if (logLevel <= 3) {
    console.warn(`%c${prefix} warn`, `background: ${colors.warn};${standardStyles}`, ...args);
  }
};

const log = (...args) => {
  if (logLevel <= 2) {
    console.log(`%c${prefix} log`, `background: ${colors.log};${standardStyles}`, ...args);
  }
};

const info = (...args) => {
  if (logLevel <= 1) {
    console.log(`%c${prefix} info`, `background: ${colors.info};${standardStyles}`, ...args);
  }
};

/**
 * @param error Primary, details error message or full error object
 * @param errorMessageGroup Use this to group error messages together with a string that does not vary as frequently.
 *      Do not pass programId, verificationId, etc
 */
const error = async (
  err: Error | string,
  errorMessageGroup: string = "unknown group",
  additionalUsefulAttributes: { [a: string]: string } = {},
) => {
  if (logLevel <= 4) {
    if (!err) {
      console.error(new Error("An error must be supplied"));
      return;
    }

    let errorObj;
    if (typeof err === "string") errorObj = new Error(err);
    if (isError(err)) errorObj = err;
    if (!errorObj) errorObj = new Error("Unknown error");

    let usefulAttributes: object = { errorMessageGroup };

    // #region Google Analytics
    // Include everything we can from Google Analytics' data b/c that's useful:
    try {
      const gaAttributes = await getGaAttributes();

      usefulAttributes = {
        ...gaAttributes,
        ...usefulAttributes,
      };
      delete (usefulAttributes as any).jslibVersionActual; // used by GA not New Relic (duplicated)
    } catch (e) {
      if (isNewRelicAvailable()) {
        window.NREUM.noticeError(errorObj, usefulAttributes);
      } else {
        // Some customers can't install New Relic so this is not an error:
        warn("Unable to assemble GA error attributes", e);
      }
    }
    // #endregion Google Analytics

    // #region Redux
    try {
      // Some invokers of logger.error() don't use redux
      if (reduxStore && reduxStore.getState) {
        const state: ReduxState = reduxStore.getState();
        const errorIds = getSafe(() => state.verificationResponse.errorIds);
        usefulAttributes = {
          ...usefulAttributes,
          programId: getSafe(() => state.programId),
          isLoading: getSafe(() => state.isLoading),
          isErrored: getSafe(() => state.isErrored),
          errorIdsFromVerRsp: Array.isArray(errorIds) ? errorIds.join(", ") : undefined,
          verificationId: getSafe(() => state.verificationResponse.verificationId),
          currentStep: getSafe(() => state.verificationResponse.currentStep),
          locale: getSafe(() => state.programTheme.intl.locale),
          isTestMode: getSafe(() => state.programTheme.isTestMode),
          openOrgSearchEnabled: getSafe(() => state.programTheme.openOrgSearchEnabled),
          jslibVerActual: getSafe(() => window.sheerIdPubV, "?"),
          ...additionalUsefulAttributes,
        };
      }
    } catch (e) {
      if (isNewRelicAvailable()) {
        window.NREUM.noticeError(errorObj, usefulAttributes);
      } else {
        // Some customers can't install New Relic so this is not an error:
        warn("Unable to assemble useful error attributes", e);
      }
    }
    // #endregion Redux

    if (isNewRelicAvailable()) {
      window.NREUM.noticeError(errorObj, usefulAttributes);
    }

    console.error(
      `%c${prefix} error`,
      `background: ${colors.error};${standardStyles}`,
      errorObj,
      usefulAttributes,
    );
  }
};

export const newRelicPageAction = (eventName: string, attributes = {}) => {
  if (isNewRelicAvailable()) {
    window.NREUM.addPageAction(eventName, attributes);
  }
};

/**
 * @deprecated Use newRelicPageAction() instead
 */
const logAPIResponseTime = (url: string, time: number) => {
  newRelicPageAction("API-calls-timing", {
    api_call: url,
    api_response_time: time,
  });
};

export const logger = {
  error,
  warn,
  log,
  info,
  /** @deprecated use newRelicPageAction() instead */
  logAPIResponseTime,
  setLogLevel: (desiredLogLevel: LogLevel) => {
    if (!logLevels.hasOwnProperty(desiredLogLevel)) {
      throw new Error(`Unknown logLevel '${desiredLogLevel}'`);
    }

    logLevel = logLevels[desiredLogLevel];
    console.log(
      `%c${prefix} log level set to ${desiredLogLevel}`,
      `background: ${colors[desiredLogLevel]};${standardStyles}`,
    );

    // Some customers can't install New Relic so this is not an error:
    if (!isNewRelicAvailable()) {
      warn("Offsite logging not enabled");
    }
  },
  setPrefix: (thisPrefix: string) => {
    prefix = thisPrefix;
  },
  init: (store: VerificationStore) => {
    reduxStore = store;
  },
};
