import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

interface PassedProps {
  explanation?: string | JSX.Element;
  onKeyDown?: Function;
}

export const Email = ({
  value,
  isErrored,
  onChange,
  explanation,
  intl,
  onKeyDown = undefined,
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <FormFieldText
    fieldId="email"
    value={value}
    onChange={onChange}
    type="email"
    placeholder={
      placeholder ||
      intl.formatHTMLMessage({ id: "emailAddressPlaceholder", defaultMessage: "Email" })
    }
    label={<FormattedHTMLMessage id="emailAddress" defaultMessage="Email address" />}
    errorMessage={
      isErrored && <FormattedHTMLMessage id="errorId.invalidEmail" defaultMessage="Invalid email" />
    }
    explanation={
      explanation || (
        <FormattedHTMLMessage
          id="emailExplanation"
          defaultMessage="Needed to send you your unique code"
        />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
    onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
  />
);

export const EmailComponent = injectIntl(Email);
