import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

export const OverrideCode = ({
  value,
  isErrored,
  onChange,
  placeholder = "",
}: FormFieldComponentProps) => (
  <FormFieldText
    fieldId="overrideCode"
    value={value}
    onChange={onChange}
    placeholder=""
    type="password"
    label={
      <FormattedHTMLMessage
        id="step.override.verificationOverrideCodeLabel"
        defaultMessage="Verification Override Code"
      />
    }
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage
          id="errorId.invalidOverrideCode"
          defaultMessage="Invalid Override Code"
        />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
  />
);

export const OverrideCodeComponent = injectIntl(OverrideCode);
