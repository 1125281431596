import React from "react";
import produce from "immer";
import { injectIntl, FormattedHTMLMessage } from "react-intl";

import { logger } from "../../lib/utils/logger/logger";
import {
  StudentPersonalInfoViewModel,
  EmailLoopViewModel,
  EmailLoopResponse,
} from "../../lib/types/types";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { getSafe } from "../../lib/utils/objects";

import { LogoComponent } from "../LogoComponent/LogoComponent";
import { populateViewModelFromQueryParams } from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { usePollingInterval } from "../../lib/utils/usePollingInterval/usePollingInterval";

import { VerificationApiClient } from "../../lib/ServerApi/VerificationApiClient";
import { StepEmailLoopComponentProps } from "./StepEmailLoopComponent";

const EMAIL_LOOP_POLL_DURATION = 10000;

export const EmailLoopPending = ({ intl, verificationService }: StepEmailLoopComponentProps) => {
  const verificationResponse = verificationService.verificationResponse as EmailLoopResponse;
  const { programId } = verificationService;
  const { verificationId } = verificationResponse;
  const { canResendEmailLoop } = verificationResponse;
  const canCancelEmailLoop = verificationResponse.cancelUrl;
  const viewModel = verificationService.viewModel as EmailLoopViewModel;
  const previousViewModel =
    (verificationService.previousViewModel as StudentPersonalInfoViewModel) || undefined;
  const previousVerificationResponse =
    verificationService.previousVerificationResponse || undefined;

  const [resendMessage, setResendMessage] = React.useState("");
  const email = getSafe(() => previousViewModel.email);

  const submitForm = () => {
    logger.info("StepEmailLoopComponent submitting form");
    verificationService.submitStep(
      VerificationStepsEnum.emailLoop,
      viewModel,
      verificationService.verificationResponse,
    );
  };

  React.useEffect(() => {
    populateViewModelFromQueryParams(verificationService);
  }, []);

  React.useEffect(() => {
    if (viewModel.emailToken) {
      submitForm();
    }
  }, [viewModel.emailToken]);

  usePollingInterval(() => {
    verificationService.fetchExistingVerificationRequest(
      programId,
      verificationId,
      previousVerificationResponse,
      previousViewModel,
      false,
    );
  }, EMAIL_LOOP_POLL_DURATION);

  const resendEmail = async (verificationId) => {
    const response = await VerificationApiClient.getResendNewEmailCode(verificationId);
    if (response) {
      const successMsgObject = {
        id: "step.emailLoop.successResend",
        defaultMessage: "Email re-sent successfully.",
      };
      const maxRetriesMsgObject = {
        id: "step.emailLoop.errors.resend",
        defaultMessage: "Error sending email",
      };
      const message =
        response.status === 204
          ? intl.formatHTMLMessage(successMsgObject)
          : intl.formatHTMLMessage(maxRetriesMsgObject);
      setResendMessage(message);
    } else {
      setResendMessage(
        intl.formatHTMLMessage({
          id: "step.emailLoop.errors.resend",
          defaultMessage: "Error sending email",
        }),
      );
    }
  };

  const cancelEmailLoop = async () => {
    logger.info("StepEmailLoopComponent submitting form after skipping email loop");
    const nextState = produce(viewModel, () => {});
    verificationService.submitStep(
      VerificationStepsEnum.cancelEmailLoop,
      nextState,
      verificationService.verificationResponse,
    );
  };

  return (
    <div className="sid-header sid-l-horz-center">
      <div className="sid-email-loop-logo-container sid-l-horz-center">
        <LogoComponent
          verificationService={verificationService}
          fallbackLogo={
            <img
              className="sid-email-loop-logo sid-l-lead-image"
              alt=""
              src="https://assets-resources.sheerid.com/common/images/2018/icons/doc-pending.svg"
            />
          }
        />
      </div>
      <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
        <FormattedHTMLMessage
          id="step.emailLoop.title"
          defaultMessage="Check your email"
          tagName="h1"
        />
      </div>

      <div className="sid-header__subtitle">
        {email ? (
          <FormattedHTMLMessage
            id="step.emailLoop.subtitleWithEmail"
            defaultMessage="An email has been sent to {email} with a personalized link to complete the verification process."
            values={{ email }}
          />
        ) : (
          <FormattedHTMLMessage
            id="step.emailLoop.subtitleWithoutEmail"
            defaultMessage="An email has been sent to your email account with a personalized link to complete the verification process."
          />
        )}
      </div>

      <div className="sid-l-space-top-md" />

      <div className="sid-header__subtitle-two sid-l-space-top-md">
        <FormattedHTMLMessage
          id="step.emailLoop.subtitle2"
          defaultMessage="Please check for an email from us (verify@sheerid.com) for all the details."
        />
      </div>

      {canCancelEmailLoop && (
        <div className="sid-skip-email sid-l-space-top-lg">
          <FormattedHTMLMessage
            id="step.emailLoop.skipEmail"
            defaultMessage="Unable to retrieve this email? Click "
          />
          &nbsp;
          <button
            type="button"
            onClick={() => cancelEmailLoop()}
            className="sid-incorrect-number__refresh-link sid-h-btn-link-like"
          >
            <FormattedHTMLMessage id="step.emailLoop.skipEmailCTA" defaultMessage="here" />
          </button>
          &nbsp;
          <FormattedHTMLMessage id="step.emailLoop.skipEmail2" defaultMessage=" to proceed." />
        </div>
      )}

      {canResendEmailLoop && (
        <div className="sid-header__resend-btn sid-l-space-top-md sid-submit sid-l-horz-center">
          <button
            id="sid-resend-email"
            onClick={() => resendEmail(verificationId)}
            type="submit"
            className="sid-btn sid-btn--light sid-resend-email-btn sid-submit__cancel"
            aria-label="re-send"
          >
            <FormattedHTMLMessage id="step.emailLoop.resendButton" defaultMessage="Re-send" />
          </button>
        </div>
      )}

      {resendMessage && (
        <div className="sid-resent-email-loop-email-message">
          <p>{resendMessage}</p>
        </div>
      )}

      <div className="sid-l-space-top-xl" />
    </div>
  );
};

export const EmailLoopPendingComponent = injectIntl(EmailLoopPending);
