import { includeIPQSDeviceFingerprintScript } from "../IPQS/IPQSutils";
import { getSafe } from "../utils/objects";
import { collectThreatMetrixProfile } from "../VerificationService/VerificationServiceHelpers";
import { VerificationApiClient } from "../ServerApi/VerificationApiClient";
import { DatabaseId } from "../types/types";

export const collectDeviceProfile = async (
  verificationId: DatabaseId,
  programId: DatabaseId,
): Promise<void> => {
  const programTheme = await VerificationApiClient.fetchProgramTheme(programId);

  if (getSafe(() => programTheme.threatMetrixEnabled, false)) {
    collectThreatMetrixProfile(verificationId);
  }

  includeIPQSDeviceFingerprintScript(programTheme, verificationId);
};
