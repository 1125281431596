import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

interface PassedProps {
  explanation?: string | JSX.Element; // eslint-disable-line
  onKeyDown?: Function;
}

export const City = ({
  value,
  isErrored,
  onChange,
  intl,
  isRequired = false,
  onKeyDown = undefined,
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <FormFieldText
    fieldId="city"
    value={value}
    onChange={onChange}
    placeholder={
      placeholder || intl.formatHTMLMessage({ id: "cityPlaceholder", defaultMessage: "City" })
    }
    label={<FormattedHTMLMessage id="city" defaultMessage="City" />}
    errorMessage={
      isErrored && <FormattedHTMLMessage id="errorId.invalidCity" defaultMessage="Invalid city" />
    }
    showPlaceholderAndHideLabel={!!placeholder}
    required={isRequired}
    onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
  />
);

export const CityComponent = injectIntl(City);
