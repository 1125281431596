import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";

import { FormFieldComponentProps } from "../../../lib/types/types";

import { FormFieldText } from "../FormFieldText/FormFieldText";

interface FormFieldTextProps extends FormFieldComponentProps {
  explanation?: string | JSX.Element;
}

export const Address = ({
  value,
  isErrored,
  onChange,
  intl,
  isRequired = false,
  onKeyDown = undefined,
  placeholder = "",
  label,
  explanation = "",
}: FormFieldTextProps) => (
  <FormFieldText
    fieldId="address"
    value={value}
    onChange={onChange}
    placeholder={
      placeholder || intl.formatHTMLMessage({ id: "addressPlaceholder", defaultMessage: "Address" })
    }
    label={label || <FormattedHTMLMessage id="address" defaultMessage="Address" />}
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage id="errorId.invalidAddress" defaultMessage="Invalid address" />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
    required={isRequired}
    onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
    explanation={explanation}
  />
);
export const AddressComponent = injectIntl(Address);
