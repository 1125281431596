import React from "react";
import { injectIntl, FormattedHTMLMessage, InjectedIntl } from "react-intl";

import { VerificationService, OverrideViewModel, OverrideResponse } from "../../lib/types/types";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { OverrideCodeComponent } from "../FormFields/OverrideCode/OverrideCodeComponent";
import { setViewModel } from "../../lib/VerificationService/ViewModel";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { logger } from "../../lib/utils/logger/logger";
import { getSafe } from "../../lib/utils/objects";

interface StepOverrideProps {
  intl: InjectedIntl;
  verificationService: VerificationService;
}

const StepOverride = ({ intl, verificationService }: StepOverrideProps) => {
  const viewModel = verificationService.viewModel as OverrideViewModel;
  const verificationResponse = verificationService.verificationResponse as OverrideResponse;
  const { fieldValidationErrors } = verificationService;
  const [stepResultMessage, setStepResultMessage] = React.useState("");
  const isAttemptLimitExceeded =
    verificationResponse.errorIds &&
    verificationResponse.errorIds.includes("incorrectVerificationOverrideCodeAttemptLimitExceeded");
  const maxRetriesPerOverrideCodeMessage = intl.formatHTMLMessage({
    id: "step.override.errors.codeOverrideLimit",
    defaultMessage: "Override code limit exceeded.",
  });

  React.useEffect(() => {
    if (isAttemptLimitExceeded) {
      setStepResultMessage(maxRetriesPerOverrideCodeMessage);
    }
  }, []);

  if (
    getSafe(() => verificationResponse.errorIds.length) &&
    !verificationService.fieldValidationErrors.overrideCode
  ) {
    verificationService.updateFieldValidationErrors({
      ...verificationService.fieldValidationErrors,
      overrideCode: verificationResponse.errorIds[0],
    });
  }

  const submitForm = () => {
    logger.info("StepOverrideCode submitting form");
    verificationService.submitStep(
      VerificationStepsEnum.override,
      viewModel,
      verificationService.verificationResponse,
    );
  };

  return (
    <div
      id="sid-step-overrice-code"
      className="sid-override-personal-info-container sid-l-container"
    >
      <div className="sid-override-personal-info-logo-container sid-l-horz-center">
        <LogoComponent
          verificationService={verificationService}
          fallbackLogo={
            <img
              className="sid-override-personal-info-logo-default sid-l-lead-image"
              alt=""
              src="https://assets-resources.sheerid.com/common/images/2018/icons/sso-step.svg"
            />
          }
        />
      </div>
      <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
        <FormattedHTMLMessage
          id="step.override.title"
          defaultMessage="Unable to Verify Status"
          tagName="h1"
        />
      </div>
      <div className="sid-header__subtitle-one sid-l-horz-center">
        <FormattedHTMLMessage
          id="step.override.subtitle"
          defaultMessage="Please sign in to your school's web portal. It will open in a new tab."
        />
      </div>
      <div className="sid-header__subtitle-one sid-l-horz-center">
        <FormattedHTMLMessage
          id="step.override.subtitle2"
          defaultMessage="This will enable the customer to redeem the offer"
        />
      </div>
      <OverrideCodeComponent
        value={viewModel.overrideCode}
        isErrored={!!fieldValidationErrors.overrideCode}
        onChange={(newValue) => {
          setViewModel({ ...viewModel, overrideCode: newValue });
        }}
      />
      {stepResultMessage && (
        <div>
          <p>{stepResultMessage}</p>
        </div>
      )}

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-override-submit sid-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={submitForm}
            type="button"
            className={`sid-btn sid-override-submit-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-label="submit"
            disabled={
              !!(
                !viewModel.overrideCode ||
                viewModel.overrideCode.length <= 0 ||
                isAttemptLimitExceeded
              )
            }
          >
            <span id="verify-status-text">
              <FormattedHTMLMessage
                id="step.override.submitOverrideCodeButtonLabel"
                defaultMessage="Submit Override Codes"
              />
            </span>
          </button>
        </div>
      </div>
      <FormFooter verificationService={verificationService} />
    </div>
  );
};
export const StepOverrideComponent = injectIntl(StepOverride);
