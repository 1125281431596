import produce from "immer";
import {
  RequestOrganizationService,
  GenericAction,
  RequestOrganizationViewModel,
} from "../types/types";

export const requestOrganizationReducer = (
  state: RequestOrganizationService,
  action: GenericAction,
) => {
  switch (action.type) {
    case "PROGRAM_ID":
      return {
        ...state,
        programId: action.payload,
      };
    case "PROGRAM_THEME":
      return {
        ...state,
        programTheme: action.payload,
      };
    case "UPDATE_VIEW_MODEL": {
      const viewModel: RequestOrganizationViewModel = produce(
        state.viewModel,
        (draft: RequestOrganizationViewModel) => ({
          ...draft,
          ...action.payload,
        }),
      );
      return {
        ...state,
        viewModel,
      };
    }
    case "SET_MESSAGES":
      return {
        ...state,
        messages: action.payload,
      };
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_SUBMIT":
      return {
        ...state,
        submitted: action.payload,
      };
    case "SET_INITIALIZED":
      return {
        ...state,
        isInitialized: action.payload,
      };
    case "SET_LOADING": {
      const loading = state.isLoading + (action.payload ? 1 : -1);
      return {
        ...state,
        isLoading: loading > 0 ? loading : 0,
      };
    }
    default:
      return state;
  }
};
