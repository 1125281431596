import React from "react";
import { dashed } from "../../../lib/utils/strings";
import { InputTextComponent, InputTextProps } from "../../FormInputs/InputText/InputText";
import { FormFieldLayout } from "../FormFieldLayout/FormFieldLayout";

// Extend InputTextProps to pass along things like required, onKeyUp, etc.
// Omit errorId, isErrored, etc because those are handled within this component
export interface FormFieldTextComponentProps
  extends Omit<InputTextProps, "onChange" | "id" | "errorId" | "isErrored"> {
  fieldId: string; // should be camelCase
  value: string;
  onChange: (value: string) => void;
  placeholder: string; // Placeholder is required but hidden via CSS, so it can be easily shown with CSS only
  label: React.ReactNode; // Label is always required for screen readers
  showPlaceholderAndHideLabel?: boolean;
  errorMessage?: React.ReactNode;
  explanation?: string | JSX.Element;
}

/**
 * Generic text field component.
 * In the JSLIB, a field has a label, inline error message, and an input of some kind.
 */
export const FormFieldText = ({
  fieldId,
  value,
  onChange,
  placeholder,
  label,
  errorMessage,
  showPlaceholderAndHideLabel = false,
  required = true,
  explanation = "",
  ...inputTextProps
}: FormFieldTextComponentProps) => {
  const labelIntlId = `${fieldId}Label`;
  const labelHtmlId = `sid-${dashed(labelIntlId)}`;
  const errorHtmlId = `sid-${dashed(fieldId)}-error`;
  return (
    <FormFieldLayout
      fieldId={fieldId}
      label={label}
      errorMessage={errorMessage}
      showPlaceholderAndHideLabel={showPlaceholderAndHideLabel}
      isRequired={required}
      explanation={explanation}
    >
      <InputTextComponent
        id={dashed(fieldId)} // InputTextComponent prefixes with 'sid-'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        hidePlaceholder={!showPlaceholderAndHideLabel}
        aria-labelledby={labelHtmlId}
        ariaDescribedBy={errorMessage ? errorHtmlId : ""}
        refId={fieldId}
        isErrored={!!errorMessage}
        required={required}
        {...inputTextProps}
      />
    </FormFieldLayout>
  );
};

// DO NOT INJECT INTL. Localize your strings in the calling component and pass them as props to this component.
export const FormFieldTextComponent = FormFieldText;
