import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { produce } from "immer";

import { VerificationService, CollectIdentifierViewModel } from "../../lib/types/types";

import { isFormFilled } from "../../lib/validators/validators";
import { setRef } from "../../lib/refs/refs";
import {
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";

import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepCollectIdentifierProps {
  verificationService: VerificationService;
}

const StepCollectIdentifier = ({ verificationService }: StepCollectIdentifierProps) => {
  const viewModel = verificationService.viewModel as CollectIdentifierViewModel;
  const { fieldValidationErrors } = verificationService;

  const updateCollectIdentifierViewmodel = (key: keyof CollectIdentifierViewModel, value: any) => {
    const nextState: CollectIdentifierViewModel = produce(
      viewModel,
      (draft: CollectIdentifierViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  return (
    <div
      id="sid-step-collect-transaction-id"
      className="sid-step-collect-transaction-id-container sid-l-container"
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="step-collect-transaction-id"
      />

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="step.collectIdentifier.inputExplanation"
            defaultMessage="Needed to send authentication code"
          />
        }
        onChange={(newValue) => {
          updateCollectIdentifierViewmodel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleEmailOnKeyDown(event)}
      />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-step-collect-transaction-id--submit sid-submit">
          <button
            id="sid-submit-wrapper__collect-info"
            onClick={() => {
              submitForm(viewModel, verificationService, VerificationStepsEnum.collectIdentifier);
            }}
            type="submit"
            className={`sid-btn sid-collect-transaction-id-submit-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions) &&
              "sid-btn--disabled-like"
            }`}
            aria-label="submit"
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              <FormattedHTMLMessage
                id="step.collectIdentifier.submitButton"
                defaultMessage="Continue"
              />
            </span>
          </button>
        </div>
      </div>
      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepCollectIdentifierComponent = StepCollectIdentifier;
