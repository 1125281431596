import { logger } from "../utils/logger/logger";
import { FieldId, Validator } from "../types/types";

let overridenValidators = {};

export const overrideValidator = (validatorField: FieldId, newValidator: Validator) => {
  logger.log(`overrideValidator registering ${newValidator} for '${validatorField}'`);
  overridenValidators[validatorField] = newValidator;
};

export const getOverridenValidator = (validatorField: FieldId): Validator => {
  if (overridenValidators[validatorField]) {
    logger.log(`getOverridenValidator returning '${validatorField}'`);
    return overridenValidators[validatorField];
  }
  return null;
};

export const resetOverriddenValidators = () => {
  overridenValidators = {};
  logger.info("Overridden validators have been reset");
};
