import React, { useRef, useEffect, useState } from "react";
import { useCombobox } from "downshift";

import { FetchOrganizationsComponent } from "../../FetchOrganizationsComponent";
import { Organization, DatabaseId, Country, Locale } from "../../../lib/types/types";
import { setRef } from "../../../lib/refs/refs";
import { assertValidFunction } from "../../../lib/types/assertions";
import { handleStateChange } from "./TypeaheadComponentHelper";
import { logger } from "../../../lib/utils/logger/logger";
import {
  AsrEvent,
  CustomMetricNames,
  EventName,
  OrgSelectedEvent,
  recordGaEvent,
} from "../../../lib/GoogleAnalytics/ga";

export interface TypeaheadProps {
  onChange: (org: Organization) => void;
  programId: DatabaseId;
  inputHtmlId: string;
  autoFocus?: boolean;
  className?: string;
  countryCode?: Country;
  disabled?: boolean;
  minimumSearchValueLength?: number;
  placeholder?: string;
  value?: Organization;
  openOrgSearchEnabled?: boolean;
  orgSearchUrl: string;
  isRequired?: boolean;
  locale?: Locale;
  urlAddSchoolForm?: string;
  isErrored?: boolean;
  ariaDescribedBy?: string;
}

const Typeahead = ({
  onChange,
  programId,
  className,
  countryCode,
  disabled,
  minimumSearchValueLength,
  placeholder,
  value,
  inputHtmlId,
  openOrgSearchEnabled,
  orgSearchUrl,
  isRequired,
  locale,
  urlAddSchoolForm,
  isErrored,
  ariaDescribedBy,
}: TypeaheadProps) => {
  const [inputValue, setInputValue] = useState("");
  const [loadedOrgs, setLoadedOrgs] = useState([]);

  const [numReworks, setNumReworks] = useState(0);
  const [startTime, setStartTime] = useState(0);

  assertValidFunction(onChange);

  const itemToString = (item: Organization) => (item ? item.name : "");

  const { isOpen, highlightedIndex, getMenuProps, getItemProps, getInputProps, getComboboxProps } =
    useCombobox({
      itemToString,
      id: inputHtmlId,
      items: loadedOrgs,
      onStateChange: (changes) => handleStateChange(changes, onChange, openOrgSearchEnabled),
      onInputValueChange: (changes) => {
        const newInputValue = changes.inputValue;
        try {
          if (startTime === 0) {
            setStartTime(Date.now());
          }
          if (newInputValue.length < inputValue.length) {
            setNumReworks(numReworks + 1);
          }
        } catch (e) {
          logger.error(e);
        }

        setInputValue(newInputValue);
      },
      selectedItem: value,
      onSelectedItemChange: ({ selectedItem }) => {
        try {
          const event: OrgSelectedEvent = {
            eventName: EventName.ORG_SELECTED,
            params: {
              [CustomMetricNames.QUERY_LENGTH]: inputValue.length,
              [CustomMetricNames.NUMBER_REWORKS]: numReworks,
              [CustomMetricNames.SELECTED_RESULT_RANK]:
                loadedOrgs.findIndex((o) => o.id === selectedItem.id) + 1,
              [CustomMetricNames.EVENT_DURATION]: Date.now() - startTime,
              [CustomMetricNames.NUMBER_OF_RESULTS]: loadedOrgs.length,
            },
          };
          recordGaEvent(event);
        } catch (e) {
          logger.error(e);
        }

        setStartTime(0);
        setNumReworks(0);
        onChange(selectedItem);
      },
    });

  const onClickAddSchool = () => {
    try {
      const event: AsrEvent = {
        eventName: EventName.ASR_SELECTED,
        params: {
          [CustomMetricNames.QUERY_LENGTH]: inputValue.length,
          [CustomMetricNames.NUMBER_REWORKS]: numReworks,
          [CustomMetricNames.EVENT_DURATION]: Date.now() - startTime,
          [CustomMetricNames.NUMBER_OF_RESULTS]: loadedOrgs.length,
        },
      };
      recordGaEvent(event);
    } catch (e) {
      logger.error(e);
    }
  };

  // Maintain same labelledby value as previous versions even though label has "for" attribute
  const comboboxArgs = { "aria-labelledby": `${inputHtmlId}-label` };

  const inputRef = useRef<HTMLInputElement>(null);

  // NOTE: Dependency array omitted to account for use of resetRef(), as useEffect with dep arr
  // will only trigger on initial render leaving the refs empty, this replicates prior version
  // where ref callback would trigger with each re-render
  useEffect(() => {
    setRef("organization", inputRef.current);
  });

  return (
    <>
      <div className="sid-organization-list" {...getComboboxProps(comboboxArgs)}>
        <input
          {...getInputProps({ ref: inputRef })}
          className={`sid-l-full-width sid-hidden-placeholder sid-text-input ${className}`}
          disabled={disabled}
          id={inputHtmlId}
          placeholder={placeholder}
          aria-required={isRequired}
          role="combobox"
          aria-controls="sid-org-list-menu"
          aria-expanded={isOpen}
          aria-describedby={ariaDescribedBy}
          aria-invalid={isErrored}
        />

        <div {...getMenuProps()}>
          {isOpen && inputValue ? (
            <FetchOrganizationsComponent
              countryCode={countryCode}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              itemToString={itemToString}
              minimumSearchValueLength={minimumSearchValueLength}
              onLoaded={(loadedOrganizations) => setLoadedOrgs(loadedOrganizations)}
              programId={programId}
              orgSearchUrl={orgSearchUrl}
              locale={locale}
              urlAddSchoolForm={urlAddSchoolForm}
              onClickAddSchool={onClickAddSchool}
              openOrgSearchEnabled={openOrgSearchEnabled}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export const TypeaheadComponent = Typeahead;
