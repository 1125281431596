import React, { useEffect, useRef } from "react";
import { setRef } from "../../../lib/refs/refs";

interface OptInInputProps {
  id?: string;
  isErrored: boolean;
  onChange: (value: any) => void;
  value: boolean;
  ariaLabelledby?: string;
  ariaDescribedBy?: string;
}

const OptInInput = ({
  id = "new",
  isErrored,
  onChange,
  value,
  ariaLabelledby,
  ariaDescribedBy,
}: OptInInputProps) => {
  const a = ariaLabelledby ? { "aria-labelledby": ariaLabelledby } : {};

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnClickAndKeyUp = () => {
    if (inputRef.current) {
      onChange(inputRef.current.checked);
    }
  };

  // NOTE: Dependency array omitted to account for use of resetRef(), as useEffect with dep arr
  // will only trigger on initial render leaving the refs empty, this replicates prior version
  // where ref callback would trigger with each re-render
  useEffect(() => {
    setRef("optIn", inputRef.current);
  });

  return (
    <div className="sid-checkbox">
      <input
        className="sid-checkbox__input"
        id={`sid-opt-in__${id}`}
        name="sid-opt-in"
        type="checkbox"
        checked={value}
        ref={inputRef}
        onChange={(e) => onChange(e.target.checked)}
        aria-invalid={isErrored}
        aria-describedby={ariaDescribedBy}
        aria-hidden // Opacity is set to 0 with css. Prefer the span below as the visible checkbox, for better styling control
      />
      <span
        {...a}
        className={`sid-checkbox__input-like ${isErrored ? "sid-checkbox__input-like--error" : ""}`}
        onClick={handleOnClickAndKeyUp}
        onKeyUp={handleOnClickAndKeyUp}
        role="checkbox"
        aria-checked={value}
        tabIndex={0}
        data-testid="stylable-checkbox-input"
      />
    </div>
  );
};

export const OptInInputComponent = OptInInput;
