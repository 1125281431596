import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import {
  FormFieldConfig,
  FormSelectChoice,
  LicensedProfessionalPersonalInfoResponse,
  LicensedProfessionalPersonalInfoViewModel,
  LicensedProfessionalStatus,
  Organization,
  VerificationService,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { StatusComponent as LicensedProfessionalStatusComponent } from "../FormFields/Status/StatusComponent";
import { LicensedProfessionalOrganizationComponent as LicensedProfessionalOrganization } from "../FormFields/LicensedProfessionalOrganization/LicensedProfessionalOrganizationComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepLicensedProfessionalPersonalInfoComponentProps {
  verificationService: VerificationService;
  intl: any;
}

type DefaultStatusMessages = {
  [key in LicensedProfessionalStatus]: string;
};

const defaultStatusMessages: DefaultStatusMessages = {
  LICENSED_COSMETOLOGIST: "Licensed Cosmetologist",
  LICENSED_REAL_ESTATE_AGENT: "Licensed Real Estate Agent",
  VETERINARIAN: "Veterinarian",
  CHILD_CARE_WORKER: "Child Care Worker",
  LIBRARIAN: "Librarian",
  INTERIOR_DESIGNER: "Interior Designer",
  ARCHITECT: "Architect",
  GENERAL_CONTRACTOR: "General Contractor",
};

const StepLicensedProfessionalPersonalInfo = ({
  intl,
  verificationService,
}: StepLicensedProfessionalPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as LicensedProfessionalPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as LicensedProfessionalPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const locale = viewModel.localeChoice.value;

  const updateLicensedProfessionalViewModel = (
    key: keyof LicensedProfessionalPersonalInfoViewModel,
    value: any,
  ) => {
    const nextState: LicensedProfessionalPersonalInfoViewModel = produce(
      viewModel,
      (draft: LicensedProfessionalPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  const getStatusLabel = (status: LicensedProfessionalStatus | undefined): string => {
    if (!status) {
      return "";
    }
    return intl.formatHTMLMessage({ id: status, defaultMessage: defaultStatusMessages[status] });
  };

  const getAvailableLicensedProfessionalStatuses = () => {
    const availableStatusesResponse = verificationResponse.availableStatuses;
    const availableStatuses: FormSelectChoice[] = [];

    if (availableStatusesResponse) {
      for (const status of availableStatusesResponse) {
        availableStatuses.push({
          value: status,
          label: getStatusLabel(status),
        });
      }
    }
    return availableStatuses;
  };

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="licensed-professional"
      />

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      <LicensedProfessionalStatusComponent
        segmentId="licensed-professional"
        value={{
          value: viewModel.statuses ? viewModel.statuses[0] : "",
          label: viewModel.statuses ? getStatusLabel(viewModel.statuses[0]) : "",
        }}
        isErrored={!!fieldValidationErrors.statuses}
        options={getAvailableLicensedProfessionalStatuses()}
        onChange={(status: FormSelectChoice) => {
          const updatedStatuses = status ? [status.value as string] : [];
          updateLicensedProfessionalViewModel("statuses", updatedStatuses);
          updateFieldValidationErrorsByFieldId(
            "statuses",
            status ? (status.value as string) : "",
            verificationService,
          );
        }}
      />
      <LicensedProfessionalOrganization
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
        value={
          (verificationService.viewModel as LicensedProfessionalPersonalInfoViewModel).organization
        }
        verificationService={verificationService}
      />
      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateLicensedProfessionalViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateLicensedProfessionalViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>
      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />
      <PostalCodeComponent
        isErrored={!!fieldValidationErrors.postalCode}
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("postalCode", newValue);
          updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
        }}
        value={viewModel.postalCode}
        explanation={
          <FormattedHTMLMessage
            id="postalCodeExplanation"
            defaultMessage="Use the location on file with your licensing body"
          />
        }
      />
      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Needed to send you your unique code"
          />
        }
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateLicensedProfessionalViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateLicensedProfessionalViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <PersonalInfoStepSubmitButton
        step={VerificationStepsEnum.collectLicensedProfessionalPersonalInfo}
        verificationService={verificationService}
        viewModel={viewModel}
        failedInstantMatch={failedInstantMatch}
        buttonText={
          <FormattedHTMLMessage
            id="verifyMyLicensedProfessionalStatus"
            defaultMessage="Verify My Licensed Professional Status"
          />
        }
      />
    </PersonalInfoWrapper>
  );
};

export const StepLicensedProfessionalPersonalInfoComponent = injectIntl(
  StepLicensedProfessionalPersonalInfo,
);
