import React, { Children } from "react";
import { Organization } from "../../lib/types/types";

interface OrganizationListProps {
  getItemProps: any;
  highlightedIndex: number;
  itemToString: any;
  organizations: Organization[];
  children: React.ReactNode;
  maxItemsToDisplay?: number; // eslint-disable-line
  // Next line is deprecated. Menu is always mounted now, up in TypeaheadComponent.
  getMenuProps?: any; // eslint-disable-line
}

const ROW_HEIGHT = 42;
const MAX_ITEMS = 26;

const OrganizationList = ({
  getItemProps,
  highlightedIndex,
  organizations,
  itemToString,
  children,
  maxItemsToDisplay = MAX_ITEMS,
}: OrganizationListProps) => {
  const childrenCount = Children.count(children);
  const fullHeight = (organizations.length + childrenCount) * ROW_HEIGHT;
  const item = (organization: Organization, index: number) => (
    <div
      className={`sid-organization-list__item ${
        highlightedIndex === index ? "sid-organization-list__item--highlighted" : ""
      }`}
      key={index}
      {...getItemProps({
        index,
        item: organization,
      })}
    >
      {itemToString(organization)}
    </div>
  );
  return (
    <div
      id="sid-org-list-menu"
      className="sid-organization-list__menu"
      tabIndex={-1}
      role="listbox"
    >
      <div style={{ height: Math.min(fullHeight, 280) }}>
        {organizations
          .filter((_, index) => index <= maxItemsToDisplay - 1)
          .map((organization, index) => item(organization, index))}
        {children}
      </div>
    </div>
  );
};

export const OrganizationListComponent = OrganizationList;
