import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { VerificationService, ConsolationResponse } from "../../lib/types/types";

import { LogoComponent } from "../LogoComponent/LogoComponent";
import { CopyToClipboard } from "../CopyToClipboard/CopyToClipboardComponent";
import { RewardDisplayEnum } from "../../lib/types/runtimeTypes";
import { getBarcodeUrl } from "../../lib/ServerApi/ApiUrls";

interface StepConsolationProps {
  verificationService: VerificationService;
}

const StepConsolation = ({ verificationService }: StepConsolationProps) => {
  const consolationRewardCode =
    (verificationService.verificationResponse as ConsolationResponse).consolationRewardCode ||
    "${consolationRewardCode}"; // eslint-disable-line no-template-curly-in-string

  const noCodeType = verificationService.programTheme.config.offerType === "noCode";
  const { verificationId } = verificationService.verificationResponse;

  const { rewardDisplay } = verificationService.programTheme.config;
  const showReward = !noCodeType && !!rewardDisplay.length;

  return (
    <div id="sid-step-consolation" className="sid-l-container">
      <div className="sid-header sid-l-horz-center sid-l-space-top-md">
        <div className="sid-l-horz-center">
          <LogoComponent verificationService={verificationService} />
        </div>

        <div
          className="sid-header__title sid-l-horz-center sid-l-space-top-md"
          role="alert"
          aria-live="polite"
        >
          <FormattedHTMLMessage
            id="step.consolation.title"
            defaultMessage="Claim your offer"
            tagName="h1"
          />
        </div>

        <div className="sid-l-horz-center">
          <FormattedHTMLMessage
            id="step.consolation.subtitle"
            defaultMessage="Unfortunately we could not verify your status, but we value your interest and are offering this promo code as a thank you. It is unique to you and can only be used once."
          />
        </div>
      </div>

      {showReward && (
        <div className="sid-l-horz-center sid-reward-container sid-l-space-top-md">
          {rewardDisplay.map((displayType) => {
            if (displayType !== RewardDisplayEnum.TEXT)
              return (
                <img
                  key={displayType}
                  className="sid-l-space-top-md sid-barcode"
                  alt={consolationRewardCode}
                  src={getBarcodeUrl(verificationId, "consolationRewardCode", displayType)}
                />
              );
            return (
              <>
                <div className="sid-reward-code sid-l-space-top-lg sid-h-jumbo-text sid-l-horz-center">
                  {consolationRewardCode}
                </div>
                <div className="sid-l-space-top-md sid-h-bold-text sid-l-horz-center">
                  <CopyToClipboard
                    textToCopy={consolationRewardCode}
                    notificationText={<FormattedHTMLMessage id="copied" defaultMessage="Copied" />}
                  >
                    <FormattedHTMLMessage
                      id="step.success.copyCouponCode"
                      defaultMessage="Copy Coupon code"
                    />
                  </CopyToClipboard>
                </div>
              </>
            );
          })}
        </div>
      )}

      <div className="sid-footer sid-l-horz-center sid-l-space-top-lg">
        <FormattedHTMLMessage
          id="rewardCodeEmailNotification"
          defaultMessage="We have emailed you a copy so you can use your new code now or later. Look for the email from verify@sheerid.com."
        />
      </div>

      <div className="sid-footer sid-l-horz-center sid-l-space-top-lg sid-h-small-text">
        <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
          {(companyName) => (
            <FormattedHTMLMessage
              id="step.consolation.verificationOnly"
              defaultMessage="Please direct any and all questions about this offer to {companyName}."
              values={{ companyName }}
            />
          )}
        </FormattedHTMLMessage>
      </div>
    </div>
  );
};

export const StepConsolationComponent = StepConsolation;
