import { SegmentEnum, SubSegmentEnum } from "../../lib/types/runtimeTypes";
import { NestedMessagePaths, Segment, SubSegment } from "../../lib/types/types";
import { Flags } from "../FeatureFlags/flags";

const SegmentAcceptableUploads: Record<SegmentEnum, NestedMessagePaths[]> = {
  [SegmentEnum.STUDENT]: [
    "step.docUpload.acceptableUploads.student.idCard", // 'School ID card with expiration date'
    "step.docUpload.acceptableUploads.student.classSchedule", // Class Schedule
    "step.docUpload.acceptableUploads.student.tuitionReceipt", // Tuition receipt
  ],
  [SegmentEnum.TEACHER]: [
    "step.docUpload.acceptableUploads.teacher.idCard", // "Teacher ID card with valid date"
    "step.docUpload.acceptableUploads.teacher.payStub", // "Pay stub from within last 60 days"
  ],
  [SegmentEnum.MEMBER]: [
    "step.docUpload.acceptableUploads.member.idCard", // "Membership Document"
    "step.docUpload.acceptableUploads.member.payStub", // "Pay stub from within last 90 days"
  ],
  [SegmentEnum.SENIOR]: [
    "step.docUpload.acceptableUploads.senior.birthCertificate", // "Your birth certificate"
    "step.docUpload.acceptableUploads.senior.driversLicense", // "Your drivers license"
    "step.docUpload.acceptableUploads.senior.passport", // "Your passport"
    "step.docUpload.acceptableUploads.senior.stateId", // "Your state-issued ID"
  ],
  [SegmentEnum.AGE]: [
    "step.docUpload.acceptableUploads.age.birthCertificate", // "Your birth certificate"
    "step.docUpload.acceptableUploads.age.driversLicense", // "Your drivers license"
    "step.docUpload.acceptableUploads.age.passport", // "Your passport"
    "step.docUpload.acceptableUploads.age.stateId", // "Your state-issued ID"
  ],
  [SegmentEnum.FIRST_RESPONDER]: [
    "step.docUpload.acceptableUploads.firstResponder.idCard", // "ID Card"
    "step.docUpload.acceptableUploads.firstResponder.payStub", // "Pay stub from within last 60 days"
    "step.docUpload.acceptableUploads.firstResponder.letter", // "An official letter"
  ],
  [SegmentEnum.MEDICAL]: [
    "step.docUpload.acceptableUploads.medical.licenseCertificate", // "Certificate of license"
    "step.docUpload.acceptableUploads.medical.idCard", // "Nursing ID card with expiration date"
    "step.docUpload.acceptableUploads.medical.photoPayStub", // "Photo ID and recent Pay Stub"
  ],
  [SegmentEnum.EMPLOYMENT]: [
    "step.docUpload.acceptableUploads.employment.employeeIdCard", // "Employee ID Card"
    "step.docUpload.acceptableUploads.employment.payStub", // "Pay stub from within last 60 days"
    "step.docUpload.acceptableUploads.employment.officialLetter", // "An official letter"
  ],
  [SegmentEnum.LICENSED_PROFESSIONAL]: [
    "step.docUpload.acceptableUploads.licensedProfessional.license", // "Certificate of license"
  ],
  [SegmentEnum.RECENT_MOVER]: [
    "step.docUpload.acceptableUploads.recentMover.changeOfAddress", // "U.S. Postal Service change of address confirmation letter"
    "step.docUpload.acceptableUploads.recentMover.mortgageDeed", // "Mortgage deed"
    "step.docUpload.acceptableUploads.recentMover.rentalAgreement", // "Lease or rental agreement"
  ],
  [SegmentEnum.LOW_INCOME]: [
    "step.docUpload.acceptableUploads.lowIncome.programCard", // "Back and front of a card showing you qualify for the selected government assistance program (for example, an EBT card for a SNAP Program)."
    "step.docUpload.acceptableUploads.lowIncome.programApprovalLetter", // "Approval letter of the selected government assistance program issued by a government agency (for example, a Supplemental Security Income (SSI) eligibility letter)."
    "step.docUpload.acceptableUploads.lowIncome.programApprovalScreenshot", // "A screenshot of the online portal of the selected government assistance program showing you qualify for it (Screenshot of your Medicaid application status on your online account)."
    "step.docUpload.acceptableUploads.lowIncome.programBankStatement", // "A bank statement showing recent payment of the selected benefit."
  ],
  [SegmentEnum.MILITARY]: [],
  [SegmentEnum.IDENTITY]: [],
};

const SubSegmentAcceptableUploads: Record<SubSegmentEnum, NestedMessagePaths[]> = {
  [SubSegmentEnum.ACTIVE_DUTY]: [
    "step.docUpload.acceptableUploads.military.activeDuty", // "Any document that proves you are currently serving as active duty in the armed forces for 30 days or more"
    "step.docUpload.allowedDocuments", // "Please only upload documents you are legally allowed to provide."
    "step.docUpload.nameChanged", // "If your name has changed since the document was issued, upload a 2nd document showing your official name change."
  ],
  [SubSegmentEnum.VETERAN]: [
    "step.docUpload.acceptableUploads.military.veteran", // "Any document that proves you met the qualifications of military service and were honorably discharged"
    "step.docUpload.nameChanged", // "If your name has changed since the document was issued, upload a 2nd document showing your official name change."
  ],
  [SubSegmentEnum.RESERVIST]: [
    "step.docUpload.acceptableUploads.military.reservistA", // "Any document that shows you are currently serving in a reserve component of the armed forces"
    "step.docUpload.nameChanged", // "If your name has changed since the document was issued, upload a 2nd document showing your official name change."
  ],
  [SubSegmentEnum.MILITARY_RETIREE]: [
    "step.docUpload.acceptableUploads.military.retiree", // "Any document that proves you are a retiree from the armed forces"
    "step.docUpload.nameChanged", // "If your name has changed since the document was issued, upload a 2nd document showing your official name change."
  ],
  [SubSegmentEnum.MILITARY_FAMILY]: [
    "step.docUpload.acceptableUploads.military.dependent", // "Any document that proves you are a Registered Military Dependent"
    "step.docUpload.nameChanged", // "If your name has changed since the document was issued, upload a 2nd document showing your official name change."
  ],
  [SubSegmentEnum.GOLD_STAR_FAMILY]: [],
  [SubSegmentEnum.FIREFIGHTER]: [],
  [SubSegmentEnum.POLICE]: [],
  [SubSegmentEnum.EMT]: [],
  [SubSegmentEnum.NURSE]: [],
  [SubSegmentEnum.DRIVER_LICENSE]: [],
  [SubSegmentEnum.GENERAL_IDENTITY]: [],
  [SubSegmentEnum.HYBRID_IDENTITY]: [],
  [SubSegmentEnum.HOME_BUYER]: [],
  [SubSegmentEnum.OTHER_MOVER]: [],
  [SubSegmentEnum.LICENSED_COSMETOLOGIST]: [],
  [SubSegmentEnum.LICENSED_REAL_ESTATE_AGENT]: [],
  [SubSegmentEnum.VETERINARIAN]: [],
  [SubSegmentEnum.SNAP_BENEFITS]: [],
  [SubSegmentEnum.OTHER_GOVERNMENT_ASSISTANCE]: [],
  [SubSegmentEnum.CHILD_CARE_WORKER]: [],
  [SubSegmentEnum.LIBRARIAN]: [],
  [SubSegmentEnum.INTERIOR_DESIGNER]: [],
  [SubSegmentEnum.ARCHITECT]: [],
  [SubSegmentEnum.GENERAL_CONTRACTOR]: [],
};

export function getAcceptableUploads(
  segment: Segment,
  subSegment: SubSegment,
  options: { flags: Flags; hideMilitaryFamilyAcceptableUploads: boolean },
): NestedMessagePaths[] {
  const subSegmentUploads: NestedMessagePaths[] =
    subSegment === SubSegmentEnum.MILITARY_FAMILY && options.hideMilitaryFamilyAcceptableUploads
      ? []
      : SubSegmentAcceptableUploads[subSegment] || [];

  return [...subSegmentUploads, ...(SegmentAcceptableUploads[segment] || [])];
}
