import { NavigatorBeta } from "../../types/types";
import { logger } from "../logger/logger";

class ResponseTimeLogger {
  url: string;

  start: number;

  constructor(url: string) {
    this.url = url;
    this.start = Date.now();
  }

  logNow() {
    if ((window.navigator as NavigatorBeta).globalPrivacyControl) {
      return;
    }

    // eslint-disable-next-line
    let result: { api_call: string; api_response_time: number };

    if (window.NREUM) {
      result = {
        api_call: this.url,
        api_response_time: Date.now() - this.start,
      };
      window.NREUM.addPageAction("API-calls-timing", result);
    } else {
      logger.warn("Off-site logging not available.");
    }

    return result;
  }
}

export default ResponseTimeLogger;
