import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { FormSelectChoice } from "../../../../lib/types/types";

interface InputSelectListProps {
  getItemProps: any;
  getMenuProps: any;
  highlightedIndex: number;
  itemToString: any;
  options: FormSelectChoice[];
  ariaLabel: string;
  isOpen: boolean;
}

const InputSelectList = ({
  getItemProps,
  getMenuProps,
  highlightedIndex,
  options,
  itemToString,
  ariaLabel,
  isOpen,
}: InputSelectListProps) => {
  const listItem = (item: FormSelectChoice, index: number) => (
    <div
      className={`sid-input-select-list__item ${
        highlightedIndex === index ? "sid-input-select-list__item--highlighted" : ""
      }`}
      {...getItemProps({
        item,
        index,
        key: item.value,
      })}
    >
      {itemToString(options[index])}
    </div>
  );

  return (
    <div {...getMenuProps({ "aria-label": ariaLabel })}>
      {isOpen ? (
        <div className="sid-input-select-list__menu">
          {options.length > 0 ? (
            options.map((option, index) => listItem(option, index))
          ) : (
            <div className="sid-input-select-list__message">
              <FormattedHTMLMessage id="noOptions" defaultMessage="No Options" />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export const InputSelectListComponent = InputSelectList;
