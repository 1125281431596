import Iframe, { postMessageFromIframe } from "./iframe";
import Modal from "./modal";
import { IframeUserConfiguration, PostMessagesOptions } from "../types/types";

// Called from the parent page to load the jslib within an iFrame NOT in a modal, but in the flow of a page. e.g. between two divs
export function loadInlineIframe(containerElement: HTMLElement, url: string): Iframe {
  const iframe = new Iframe(containerElement, url);
  iframe.init();
  return iframe;
}

// Called from the parent page to load the jslib within an iFrame in a modal
export function loadInModal(url: string, userConfig: IframeUserConfiguration = {}) {
  const modal = new Modal(url, userConfig);
  modal.init();
  return modal;
}

// Called from the inner iFrame window to let the parent window know about size updates
export function postVerificationSizeUpdates(
  options: PostMessagesOptions = { origin: "*", interval: 100 },
) {
  if (window.self !== window.top) {
    let bodyHeight = document.body.offsetHeight;
    const interval = window.setInterval(() => {
      if (bodyHeight !== document.body.offsetHeight) {
        bodyHeight = document.body.offsetHeight;

        const heightWithComputedMargins =
          bodyHeight +
          parseInt(window.getComputedStyle(document.body).marginTop, 10) +
          parseInt(window.getComputedStyle(document.body).marginBottom, 10);

        postMessageFromIframe({ type: "updateHeight", height: heightWithComputedMargins });
      }
    }, options.interval);

    return () => clearInterval(interval);
  }
}
