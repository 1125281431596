import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

export const FirstName = ({
  value,
  isErrored,
  onChange,
  intl,
  placeholder = "",
}: FormFieldComponentProps) => (
  <FormFieldText
    fieldId="firstName"
    value={value}
    onChange={onChange}
    placeholder={
      placeholder ||
      intl.formatHTMLMessage({ id: "firstNamePlaceholder", defaultMessage: "First Name" })
    }
    label={<FormattedHTMLMessage id="firstName" defaultMessage="First name" />}
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage id="errorId.invalidFirstName" defaultMessage="Invalid first name" />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
  />
);
export const FirstNameComponent = injectIntl(FirstName);
