import React from "react";

export const usePollingInterval = (callback, pollTime) => {
  const timerRef = React.useRef(null);

  function stopInterval() {
    clearInterval(timerRef.current);
  }

  React.useEffect(() => {
    timerRef.current = setInterval(callback, pollTime);

    return () => stopInterval();
  }, [callback, pollTime]);

  return stopInterval;
};
