import React from "react";
import { VerificationFormLayout, VerificationService } from "../../lib/types/types";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

export interface LayoutProps {
  layout: VerificationFormLayout;
  children: React.ReactNode;
  verificationService?: VerificationService;
  renderAsLandingPage?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  layout,
  children,
  verificationService,
  renderAsLandingPage,
}) => {
  if (!renderAsLandingPage) {
    return <>{children}</>;
  }

  return (
    <div className={`sid-layout sid-layout--${layout}`}>
      <div className="sid-layout__container">
        {layout === "fullRight" && (
          <div className="sid-layout__content">
            <PersonalInfoHeader
              verificationService={verificationService}
              classNameIdentifier="landing"
            />
          </div>
        )}
        <div className="sid-layout__form-container">
          <div className="sid-layout__form">{children}</div>
        </div>
      </div>
    </div>
  );
};
