import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FieldIdEnum } from "../../../lib/types/runtimeTypes";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { displaySSN, unDisplaySSN } from "./SSNHelper";
import { FormFieldText } from "../FormFieldText/FormFieldText";

export const SocialSecurity = ({
  value,
  isErrored,
  onChange,
  intl,
  placeholder = "",
}: FormFieldComponentProps<string>) => (
  <FormFieldText
    fieldId="ssn"
    className="sid-ssn__input"
    refId={FieldIdEnum.socialSecurityNumber}
    value={displaySSN(value)}
    onChange={(newValue) => onChange(unDisplaySSN(newValue))}
    placeholder={
      placeholder || intl.formatHTMLMessage({ id: "ssnPlaceholder", defaultMessage: "000-00-0000" })
    }
    label={<FormattedHTMLMessage id="ssn" defaultMessage="Social Security Number" />}
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage
          id="errorId.invalidSocialSecurityNumber"
          defaultMessage="Invalid SSN"
        />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
    required
  />
);

export const SocialSecurityNumber = injectIntl(SocialSecurity);
