import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { FormHeader } from "../FormHeaderComponent";
import { hasFailedInstantMatch } from "../../../lib/VerificationService/VerificationServiceHelpers";
import {
  PersonalInfoResponse,
  VerificationResponse,
  VerificationService,
} from "../../../lib/types/types";
import { HowDoesVerifyingWorkComponent } from "../../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { RewardsRemainingComponent } from "../../RewardsRemaining/RewardsRemainingComponent";

export interface PersonalInfoHeaderProps {
  verificationService: VerificationService;
  classNameIdentifier?: string;
}

const isPersonalInfoResponse = (
  verificationResponse: VerificationResponse,
): verificationResponse is PersonalInfoResponse => verificationResponse !== undefined;

export const PersonalInfoHeader = ({
  verificationService,
  classNameIdentifier = undefined,
}: PersonalInfoHeaderProps) => {
  const { verificationResponse } = verificationService;

  const failedInstantMatch = isPersonalInfoResponse(verificationResponse)
    ? hasFailedInstantMatch(verificationResponse)
    : false;

  const titleMsgId = !failedInstantMatch
    ? "step.personalInfo.title"
    : "step.personalInfo.tryAgain.title";
  const subtitleMsgId = !failedInstantMatch
    ? "step.personalInfo.subtitle"
    : "step.personalInfo.tryAgain.subtitle";

  return (
    <div className="sid-personal-info-header">
      <FormHeader
        verificationService={verificationService}
        title={<FormattedHTMLMessage id={titleMsgId} tagName="h1" />}
        classNameIdentifier={classNameIdentifier}
      >
        <div
          className={`sid-header__subtitle sid-l-horz-center ${
            failedInstantMatch ? "sid-header__subtitle--error" : ""
          }`}
        >
          <FormattedHTMLMessage id={subtitleMsgId} />
        </div>
        <div className="sid-header__how-verifying-works sid-l-horz-center">
          {!failedInstantMatch && (
            <>
              <HowDoesVerifyingWorkComponent verificationService={verificationService} />
              <RewardsRemainingComponent verificationService={verificationService} />
            </>
          )}
        </div>
      </FormHeader>
    </div>
  );
};
