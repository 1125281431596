import { logger } from "../utils/logger/logger";
import { OverrideableComponentName, StepComponent } from "../types/types";

let overriddenComponents = {};

export const overrideComponent = (
  componentName: OverrideableComponentName,
  newComponent: StepComponent,
) => {
  logger.log(`overrideComponent registering ${componentName}`);
  overriddenComponents[componentName] = newComponent;
};

export const getOverridenComponent = (componentName: OverrideableComponentName) => {
  if (overriddenComponents[componentName]) {
    logger.log(`getOverridenComponent returning ${componentName}`);
    return overriddenComponents[componentName];
  }
  return null;
};

export const resetOverriddenComponents = () => {
  overriddenComponents = {};
};
