import React from "react";
import produce from "immer";

import { FormattedHTMLMessage } from "react-intl";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { FormFieldText } from "../FormFields/FormFieldText/FormFieldText";
import { EmailLoopCollectOrganizationEmailViewModel, VerificationService } from "../../es6";
import {
  updateFieldValidationErrorsByFieldId,
  submitForm,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { logger } from "../../lib/utils/logger/logger";

export const EmailLoopCollectOrganizationEmail = ({
  verificationService,
}: {
  verificationService: VerificationService;
}) => {
  const viewModel = verificationService.viewModel as EmailLoopCollectOrganizationEmailViewModel;
  const fieldValidationErrors = verificationService.fieldValidationErrors;
  const isInvalidOrgEmail = (verificationService.verificationResponse?.errorIds || []).includes(
    "invalidEmail",
  );

  const setEmailValue = (organizationEmail: string) => {
    verificationService.updateViewModel({ ...viewModel, organizationEmail });
    updateFieldValidationErrorsByFieldId(
      "organizationEmail",
      organizationEmail,
      verificationService,
    );
  };

  const cancelEmailLoop = async () => {
    logger.info("StepEmailLoopComponent submitting form after skipping email loop");
    const nextState = produce(viewModel, () => {});
    return verificationService.submitStep(
      VerificationStepsEnum.cancelEmailLoop,
      nextState,
      verificationService.verificationResponse,
    );
  };

  return (
    <>
      <div className="sid-header sid-l-horz-center">
        <div className="sid-sso-logo-container sid-l-horz-center">
          <LogoComponent
            verificationService={verificationService}
            fallbackLogo={
              <img
                className="sid-sso-logo-default sid-l-lead-image"
                alt=""
                src="https://assets-resources.sheerid.com/common/images/2018/icons/doc-pending.svg"
              />
            }
          />
        </div>
        <div className="sid-preheader-label sid-l-space-top-md">
          <span className="sid-h-label">
            <FormattedHTMLMessage id="instant" defaultMessage="Instant" />
          </span>
        </div>
        <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
          <FormattedHTMLMessage
            id="step.emailLoop.collectOrganizationEmail.title"
            defaultMessage="Verify using your work email address"
            tagName="h1"
          />
        </div>
        <div className="sid-header__subtitle-one">
          <FormattedHTMLMessage
            id="step.emailLoop.collectOrganizationEmail.subtitle"
            defaultMessage="Enter the email address provided by your employer and we'll send you a verification link"
          />
        </div>
      </div>

      <div className="sid-form-region sid-email-submit sid-submit-wrapper sid-l-space-top-md">
        <FormFieldText
          fieldId="emailOverride"
          value={viewModel.organizationEmail}
          onChange={setEmailValue}
          placeholder=""
          label={
            <FormattedHTMLMessage
              id="step.emailLoop.collectOrganizationEmail.email.label"
              defaultMessage="Work email address"
            />
          }
          errorMessage={
            (isInvalidOrgEmail && (
              <FormattedHTMLMessage
                id="step.emailLoop.errors.invalidEmail"
                defaultMessage="This email address is not valid for instant verification. Please use an email address provided by your employer or upload documentation below."
              />
            )) ||
            (fieldValidationErrors.organizationEmail && (
              <FormattedHTMLMessage id="emailIds.invalidEmail" defaultMessage="Invalid email" />
            ))
          }
        />
        <div className="sid-l-space-top-sm">
          <button
            id="sid-submit-btn-sso"
            type="submit"
            disabled={!!fieldValidationErrors.organizationEmail}
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.emailLoopCollectOrganizationEmail,
              )
            }
            className="sid-btn sid-sso-submit-btn sid-btn--dark sid-l-full-width "
            aria-label="submit"
          >
            <FormattedHTMLMessage
              id="step.emailLoop.collectOrganizationEmail.email.send"
              defaultMessage="Send Email"
            />
          </button>
        </div>

        <div className="sid-form-region sid-l-space-top-lg sid-l-horz-center">
          <div className="sid-header__subtitle-two sid-h-bold-text">
            <FormattedHTMLMessage
              id="step.emailLoop.collectOrganizationEmail.alternative"
              defaultMessage="Unable to access a work email address?"
            />
          </div>
          <div className="sid-form-region sid-l-space-btm-sm sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="step.sso.alternative"
              defaultMessage="You can also verify by uploading proof of eligibility"
            />
          </div>
          <div className="sid-l-space-top-sm">
            <button
              onClick={cancelEmailLoop}
              className="sid-h-btn-link-like sid-link sid-h-bold-text"
              type="button"
            >
              <FormattedHTMLMessage
                id="step.sso.cancel"
                defaultMessage="Upload Proof of Enrollment"
              />
            </button>
          </div>
        </div>

        <div className="sid-l-space-top-lg" />
      </div>

      <FormFooter verificationService={verificationService} />
    </>
  );
};
