/* tslint:disable:max-line-length */
import { defaultMessages } from "./defaultMessages";
import { RequestOrganizationMessages } from "../../types/types";

export const requestOrganizationMessages: RequestOrganizationMessages = {
  title: "Request to Add School",
  description:
    "Please fill out the form below. Schools submitted are not guaranteed to be added, but all requests are researched and considered by SheerID.",
  noCountry: "Don't see your school’s country or region? You are not eligible for this offer.",
  searchByOrgName: "Don't see your school? Search by name.",
  completeRequest:
    "Don't see your school in the list of eligible or ineligible schools? Complete your request",
  submit: "Submit",
  eligibleOrgs:
    "Eligible schools. Click the icon to copy name then return to the verification form:",
  ineligibleOrgs:
    "Ineligible Schools. We are sorry, people from these schools are not eligible for this offer:",
  none: "None",
  country: "Where is your educational institution located?",
  changeLanguage: "Change language",
  poweredBy: defaultMessages.poweredBy,
  copied: "Copied",
  fields: {
    countryPlaceholder: "Select Country",
    domainLabel: "School website",
    domainPlaceholder: "www.myschool.edu",
    orgNameLabel: "School name",
    orgNamePlaceholder: "My school",
  },
  step: {
    success: {
      title: "Thank you",
      description:
        "Your request to add your educational institution has been received. As a reminder, each request is evaluated by SheerID against the requirements set by this offer. There is no guarantee that your school will be added. The evaluation process can take up to 7 business days.",
    },
    error: {
      title: "An unexpected error occurred",
      description: "Sorry we are not able to process your request. Please try again later.",
      seeingProblem:
        "If you keep seeing this problem <a class='sid-link' href='{feedbackUrl}&pid={programId}' target='_blank'>contact us</a>.",
      contactUs: "contact us",
    },
  },
  errorId: {
    invalidCountry: "Field is required",
    invalidEmail: "Invalid Email",
    invalidFirstName: "Invalid first name",
    invalidLastName: "Invalid last name",
    requiredField: "Field is required",
    invalidField: "Field is invalid",
    invalidUrl: "Invalid URL",
    tooManyResults:
      "Your search returned too many results, please refine your search and try again",
  },
  orgType: {
    university: "Universities {e}",
    k12: "K12 {e}",
    postSecondary: "Post Secondary Institutions {e}",
    highSchool: "High Schools / Secondary Schools {e}",
    eligible:
      "- Eligible for this offer. Click the icon to copy name then return to the verification form:",
    ineligible: "- (Not eligible for this offer)",
  },
  faq: "SheerID Add School Request FAQ",
};
