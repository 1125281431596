import React, { useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { setRef } from "../../../lib/refs/refs";
import { OptInInputComponent } from "../../FormInputs/OptInInput/OptInInputComponent";
import { FieldLabel } from "../../FieldLabel";

interface PhoneNumberProps {
  explanation?: string | JSX.Element;
  isRequired?: boolean;
  onKeyDown?: Function;
  onCarrierConsentChange?: Function;
  displaySmsConsent?: boolean;
  requireCarrierConsent?: boolean;
  carrierConsent?: boolean;
  internationalSupport?: boolean; // deprecated
  selectedCountryCode?: string;
}

const PhoneNumber = ({
  value,
  isErrored,
  onChange,
  explanation,
  onCarrierConsentChange,
  // eslint-disable-next-line
  intl, // deprecated
  isRequired = false,
  onKeyDown = undefined,
  placeholder = "",
  displaySmsConsent = true,
  requireCarrierConsent = false,
  carrierConsent = false,
  // eslint-disable-next-line
  internationalSupport = false, // deprecated
  selectedCountryCode = "us",
}: FormFieldComponentProps & PhoneNumberProps) => {
  const getDefaultExplanation = () =>
    !isRequired ? (
      <FormattedHTMLMessage
        id="phoneNumberExplanation"
        defaultMessage="Optional - get text notifications of status updates"
      />
    ) : (
      ""
    );

  const inputRef = useRef<HTMLInputElement>(null);
  const errorHtmlId = "sid-phone-number-error";

  // NOTE: Dependency array omitted to account for use of resetRef(), as useEffect with dep arr
  // will only trigger on initial render leaving the refs empty, this replicates prior version
  // where ref callback would trigger with each re-render
  useEffect(() => {
    setRef("phoneNumber", inputRef.current);
  });

  return (
    <div className="sid-field sid-phone-number">
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={<FormattedHTMLMessage id="phoneNumber" defaultMessage="Mobile Number" />}
        htmlForLabel="sid-phone-number"
        id="sid-phone-number-label"
        displayClasses={`sid-field__label-with-explanation${
          placeholder && "sid-h-screen-reader-only"
        }`}
        isRequired={isRequired}
      >
        {explanation || getDefaultExplanation()}
      </FieldLabel>

      {
        <>
          <PhoneInput
            country={selectedCountryCode.toLowerCase()}
            containerClass="react-tel-input sid-phone-number__wrapper"
            inputClass={`sid-text-input sid-phone-number__field sid-phone-number__field--intl ${
              isRequired ? "sid-text-input--required" : ""
            } sid-l-full-width ${isErrored ? "sid-text-input--error" : ""}`}
            autoFormat={false}
            value={value}
            onChange={(value) => onChange(`+${value}`)} // react-phone-input-2 returns a string without the + prefix
            placeholder={placeholder}
            // @ts-ignore
            ref={inputRef}
            inputProps={{
              id: "sid-phone-number",
              name: "sid-phone-number",
              onKeyDown: (e) => (onKeyDown ? onKeyDown(e) : undefined),
            }}
            aria-labelledby="sid-phone-number-label"
            aria-describedby={isErrored ? errorHtmlId : ""}
            aria-invalid={isErrored}
            countryCodeEditable={false}
          />
        </>
      }

      {isErrored && (
        <div className="sid-field-error" id={errorHtmlId}>
          <FormattedHTMLMessage
            id="errorId.invalidPhoneNumber"
            defaultMessage="Invalid mobile number"
          />
        </div>
      )}

      {displaySmsConsent && (
        <div className="sid-h-small-text sid-l-space-top-md sid-sms-consent">
          <FormattedHTMLMessage
            id="phoneNumberWarnMessage1"
            defaultMessage="By submitting this form with a mobile number, you consent to receive SMS/text messages for verification purposes. Standard phone carrier text messaging (SMS) and data rates may apply."
          />
        </div>
      )}
      {requireCarrierConsent && (
        <div className="sid-l-position sid-carrier-disclosure-consent">
          <div className="sid-h-small-text sid-l-space-top-md">
            <label htmlFor="sid-opt-in__carrier-consent">
              <OptInInputComponent
                id="carrier-consent"
                ariaDescribedBy={errorHtmlId}
                isErrored={isErrored}
                value={carrierConsent}
                onChange={(value) => onCarrierConsentChange(value)}
              />
              <div className="sid-field__label sid-checkbox__label">
                <FormattedHTMLMessage
                  id="phoneNumberWarnMessage2"
                  defaultMessage="You authorize your mobile service provider to disclose your mobile number and certain account information to SheerID, solely for identity verification and to avoid fraud."
                />
              </div>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export const PhoneNumberComponent = injectIntl(PhoneNumber);
