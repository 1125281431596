import produce from "immer";
import React from "react";

import { FormattedHTMLMessage, InjectedIntl, injectIntl } from "react-intl";
import { FormFieldConfig, VerificationService } from "../../../lib/types/types";
import { logger } from "../../../lib/utils/logger/logger";
import { getSafe } from "../../../lib/utils/objects";
import { getFormFieldSelectOptions } from "../../../lib/utils/structures/FormSelectChoice";

import { FormFieldCheckbox } from "../FormFieldCheckbox/FormFieldCheckbox";
import { FormFieldText } from "../FormFieldText/FormFieldText";
import { FormFieldSelect } from "../FormFieldSelect/FormFieldSelect";
import { DateComponent } from "../../DateComponent/DateComponent";
import { FormFieldRadio } from "../FormFieldRadio/FormFieldRadio";

export const FormFieldConfigType = {
  TEXT: "text",
  CHECKBOX: "checkbox",
  SELECT: "select",
  RADIO: "radio",
  DATE: "date",
} as const;

export interface FormFieldCustomProps {
  config: FormFieldConfig;
  verificationService: VerificationService;
  intl: InjectedIntl;
}

export interface FormFieldBaseComponentProps<TFieldValue> {
  fieldId: string; // should be camelCase
  value: TFieldValue;
  onChange: (value: TFieldValue) => void;
  label: React.ReactNode; // Label is always required for screen readers
  errorMessage?: React.ReactNode;
}

export const getMessageIdForLabel = (config: FormFieldConfig) => `${config.fieldId}Label`;
export const getMessageIdForExplanation = (config: FormFieldConfig) =>
  `${config.fieldId}Explanation`;
export const getMessageIdForPlaceholder = (config: FormFieldConfig) =>
  `${config.fieldId}Placeholder`;

/**
 * Custom form field component.
 * Implementors can render a form field passing in config using setOptions, rather than
 * having to create an entire React project and override component(s).
 */
export const FormFieldCustom = injectIntl(
  ({ config, verificationService, intl }: FormFieldCustomProps) => {
    const currentValue = getSafe(() => verificationService.viewModel.metadata[config.fieldId]);
    const errorId = getSafe(() => verificationService.fieldValidationErrors[config.fieldId]);

    const onChange = (value) => {
      verificationService.updateViewModel(
        produce(verificationService.viewModel, (draft) => {
          draft.metadata = draft.metadata || {};
          draft.metadata[config.fieldId] = value;
        }),
      );
      verificationService.updateFieldValidationErrors(
        produce(verificationService.fieldValidationErrors, (draft) => {
          const errorId: ReturnType<typeof config.validate> = config.validate(value);
          if (errorId === undefined) {
            delete draft[config.fieldId];
          }
          // Don't set error. Only do that when submit is clicked, same as jslib's built-in fields
        }),
      );
    };

    switch (config.fieldType) {
      case FormFieldConfigType.TEXT:
        return (
          <FormFieldText
            fieldId={config.fieldId}
            value={`${currentValue || ""}`}
            onChange={onChange}
            placeholder={intl.formatHTMLMessage({
              id: getMessageIdForPlaceholder(config),
              defaultMessage: config.fieldId,
            })}
            label={
              <FormattedHTMLMessage
                id={getMessageIdForLabel(config)}
                defaultMessage={config.fieldId}
              />
            }
            explanation={
              getSafe(() => intl.messages[getMessageIdForExplanation(config)]) && (
                <FormattedHTMLMessage id={getMessageIdForExplanation(config)} />
              )
            }
            errorMessage={
              errorId && (
                <FormattedHTMLMessage
                  id={`errorId.${errorId}`}
                  defaultMessage={`Invalid ${config.fieldId}`}
                />
              )
            }
            showPlaceholderAndHideLabel={config.showPlaceholderAndHideLabel}
            required={config.required}
          />
        );
      case FormFieldConfigType.CHECKBOX:
        return (
          <FormFieldCheckbox
            fieldId={config.fieldId}
            value={typeof currentValue === "boolean" ? currentValue : false}
            onChange={onChange}
            label={
              <FormattedHTMLMessage
                id={getMessageIdForLabel(config)}
                defaultMessage={config.fieldId}
              />
            }
            errorMessage={
              errorId && (
                <FormattedHTMLMessage
                  id={`errorId.${errorId}`}
                  defaultMessage={`Invalid ${config.fieldId}`}
                />
              )
            }
          />
        );
      case FormFieldConfigType.SELECT:
        return (
          <FormFieldSelect
            fieldId={config.fieldId}
            value={`${currentValue || ""}`}
            onChange={onChange}
            options={getFormFieldSelectOptions(config.fieldId, config.options, intl)}
            placeholder={intl.formatHTMLMessage({
              id: `${config.fieldId}Placeholder`,
              defaultMessage: config.fieldId,
            })}
            label={
              <FormattedHTMLMessage
                id={getMessageIdForLabel(config)}
                defaultMessage={config.fieldId}
              />
            }
            errorMessage={
              errorId && (
                <FormattedHTMLMessage
                  id={`errorId.${errorId}`}
                  defaultMessage={`Invalid ${config.fieldId}`}
                />
              )
            }
            showPlaceholderAndHideLabel={config.showPlaceholderAndHideLabel}
            required={config.required}
          />
        );
      case FormFieldConfigType.DATE:
        return (
          <DateComponent
            fieldId={config.fieldId}
            fieldName={config.fieldId}
            value={`${currentValue || ""}`}
            onChange={onChange}
            label={
              <FormattedHTMLMessage
                id={getMessageIdForLabel(config)}
                defaultMessage={config.fieldId}
              />
            }
            explanation={
              getSafe(() => intl.messages[getMessageIdForExplanation(config)]) && (
                <FormattedHTMLMessage id={getMessageIdForExplanation(config)} />
              )
            }
            isErrored={errorId}
            errorMsg={
              errorId && (
                <FormattedHTMLMessage
                  id={`errorId.${errorId}`}
                  defaultMessage={`Invalid ${config.fieldId}`}
                />
              )
            }
            isRequired={config.required}
          />
        );
      case FormFieldConfigType.RADIO:
        return (
          <FormFieldRadio
            onChange={onChange}
            value={`${currentValue || ""}`}
            options={getFormFieldSelectOptions(config.fieldId, config.options, intl)}
            required={config.required}
            fieldId={config.fieldId}
            label={
              <FormattedHTMLMessage
                id={getMessageIdForLabel(config)}
                defaultMessage={config.fieldId}
              />
            }
            explanation={
              getSafe(() => intl.messages[getMessageIdForExplanation(config)]) && (
                <FormattedHTMLMessage id={getMessageIdForExplanation(config)} />
              )
            }
            errorMessage={
              errorId && (
                <FormattedHTMLMessage
                  id={`errorId.${errorId}`}
                  defaultMessage={`Invalid ${config.fieldId}`}
                />
              )
            }
          />
        );
      default:
        logger.warn(`Unknown field type: ${config.fieldType}`);
        return null;
    }
  },
);
