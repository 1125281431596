import React from "react";
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from "react-intl";
import { Organization } from "../../lib/types/types";
import { OrganizationResultComponent as OrganizationResult } from "./OrganizationResultComponent";
import { requestOrganizationConstants } from "../../constants";

interface RequestOrganizationSearchResultComponentProps {
  eligibleOrgs: Organization[];
  ineligibleOrgs: Organization[];
  intl: InjectedIntl;
}
const orgTypes = {
  university: "UNIVERSITY",
  k12: "K12",
  postSecondary: "POST_SECONDARY",
  highSchool: "HIGH_SCHOOL",
};
function byType(org) {
  return org.type === orgTypes[this];
}

export const RequestOrganizationSearchResult = ({
  eligibleOrgs,
  ineligibleOrgs,
  intl,
}: RequestOrganizationSearchResultComponentProps) => {
  const hasMaxResults = () =>
    eligibleOrgs.length + ineligibleOrgs.length >= requestOrganizationConstants.MAX_RESULT_SIZE;
  const eligibleUniversities = eligibleOrgs.filter(byType, "university");
  const eligibleK12 = eligibleOrgs.filter(byType, "k12");
  const eligiblePostSecondaries = eligibleOrgs.filter(byType, "postSecondary");
  const eligibleHighSchools = eligibleOrgs.filter(byType, "highSchool");
  const ineligibleUniversities = ineligibleOrgs.filter(byType, "university");
  const ineligibleK12 = ineligibleOrgs.filter(byType, "k12");
  const ineligiblePostSecondaries = ineligibleOrgs.filter(byType, "postSecondary");
  const ineligibleHighSchools = ineligibleOrgs.filter(byType, "highSchool");

  return (
    <div className="sid-l-space-top-md">
      {hasMaxResults() ? (
        <div className="sid-header__subtitle--error">
          <FormattedHTMLMessage
            id="errorId.tooManyResults"
            defaultMessage="Your search returned too many results, please refine your search and try again"
          />
        </div>
      ) : null}
      {(eligibleOrgs.length === 0 || ineligibleOrgs.length === 0) && (
        <div>
          <FormattedHTMLMessage id="none" defaultMessage="None" />
        </div>
      )}

      {eligibleUniversities.length > 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.university"
              defaultMessage="Universities ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.eligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {eligibleUniversities.map((organization) => (
              <OrganizationResult key={organization.id} organization={organization} isEligible />
            ))}
          </div>
        </>
      )}
      {eligibleK12.length > 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.k12"
              defaultMessage="K12 ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.eligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {eligibleK12.map((organization) => (
              <OrganizationResult key={organization.id} organization={organization} isEligible />
            ))}
          </div>
        </>
      )}
      {eligiblePostSecondaries.length > 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.postSecondary"
              defaultMessage="Post Secondary Institutions ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.eligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {eligiblePostSecondaries.map((organization) => (
              <OrganizationResult key={organization.id} organization={organization} isEligible />
            ))}
          </div>
        </>
      )}
      {eligibleHighSchools.length > 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.highSchool"
              defaultMessage="High Schools / Secondary Schools ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.eligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {eligibleHighSchools.map((organization) => (
              <OrganizationResult key={organization.id} organization={organization} isEligible />
            ))}
          </div>
        </>
      )}
      {ineligibleUniversities.length > 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.university"
              defaultMessage="Universities ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.ineligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {ineligibleUniversities.map((organization) => (
              <OrganizationResult
                key={organization.id}
                organization={organization}
                isEligible={false}
              />
            ))}
          </div>
        </>
      )}
      {ineligibleK12.length > 0 && eligibleHighSchools.length <= 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.k12"
              defaultMessage="K12 ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.ineligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {ineligibleK12.map((organization) => (
              <OrganizationResult
                key={organization.id}
                organization={organization}
                isEligible={false}
              />
            ))}
          </div>
        </>
      )}
      {ineligiblePostSecondaries.length > 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.postSecondary"
              defaultMessage="Post Secondary Institutions ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.ineligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {ineligiblePostSecondaries.map((organization) => (
              <OrganizationResult
                key={organization.id}
                organization={organization}
                isEligible={false}
              />
            ))}
          </div>
        </>
      )}
      {ineligibleHighSchools.length > 0 && eligibleK12.length <= 0 && (
        <>
          <div className="sid-l-space-top-sm">
            <FormattedHTMLMessage
              id="orgType.highSchool"
              defaultMessage="High Schools / Secondary Schools ({e})"
              values={{ e: intl.formatHTMLMessage({ id: "orgType.ineligible" }) }}
            />
          </div>
          <div className="sid-l-space-top-sm">
            {ineligibleHighSchools.map((organization) => (
              <OrganizationResult
                key={organization.id}
                organization={organization}
                isEligible={false}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export const RequestOrganizationSearchResultComponent = injectIntl(RequestOrganizationSearchResult);
