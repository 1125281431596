import React from "react";
import { injectIntl, InjectedIntl } from "react-intl";

import { EmailLoopResponse, VerificationService } from "../../lib/types/types";

import { EmailLoopCollectOrganizationEmail } from "./EmailLoopCollectOrganizationEmail";
import { EmailLoopPendingComponent } from "./EmailLoopPending";

export interface StepEmailLoopComponentProps {
  intl: InjectedIntl;
  verificationService: VerificationService;
}

export const StepEmailLoop = ({ verificationService }: StepEmailLoopComponentProps) => {
  const isCollectOrgEmailState =
    (verificationService.verificationResponse as EmailLoopResponse).currentState ===
    "collectOrganizationEmail";
  return (
    <div id="sid-step-email-loop" className="sid-step-email-loop-container sid-l-container">
      {isCollectOrgEmailState ? (
        <EmailLoopCollectOrganizationEmail verificationService={verificationService} />
      ) : (
        <EmailLoopPendingComponent verificationService={verificationService} />
      )}
    </div>
  );
};

export const StepEmailLoopComponent = injectIntl(StepEmailLoop);
