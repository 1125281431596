import {
  ReduxState,
  PersonalInfoViewModel,
  Metadata,
  VerificationResponse,
  VerificationServiceAction,
  ViewModel,
  MetadataConfig,
  ProgramTheme,
} from "../types/types";
import { getFieldValidationErrorsEmpty } from "../types/empties";
import { VerificationStepsEnum, isCollectStep } from "../types/runtimeTypes";
import { setMetadata, getMetadata } from "../metadata/metadata";
import { deepClone, deepMerge } from "../utils/objects";
import {
  recordViewModelChange,
  recordVerificationResponse,
  setDimension,
  isTestValues,
  CustomDimensionNames,
} from "../GoogleAnalytics/ga";
import { getMetadataConfig } from "../ProgramTheme/programThemeGetters";
import { isMockedStep } from "../ServerApi/TestingRouteOverrides";

/**
 * The initial state of the verification service.
 */
const initialState: ReduxState = Object.freeze({
  programId: undefined,
  programTheme: undefined,
  isLoading: true,
  isErrored: false,
  viewModel: undefined,
  previousViewModel: undefined,
  previousVerificationResponse: undefined,
  verificationResponse: undefined,
  fieldValidationErrors: getFieldValidationErrorsEmpty(),
  messages: undefined,
  orgList: undefined,
  formValidationOptions: {},
});

const getInitialState = () => deepClone(initialState);

/**
 * @description Most of the state for the VerificationService
 * @example as a user fills-in the HTML form, we can track the data entered here in the view model.
 * @param state
 * @param action
 */
export const verificationServiceReducer = (
  state: ReduxState = getInitialState(),
  action: VerificationServiceAction,
): ReduxState => {
  switch (action.type) {
    case "PROGRAM_ID":
      setDimension(CustomDimensionNames.programId, action.programId);
      return {
        ...state,
        programId: action.programId,
      };
    case "VERIFICATION_RESPONSE":
      let previousVerificationResponse: VerificationResponse;
      if (
        state.verificationResponse &&
        (state.verificationResponse.currentStep === VerificationStepsEnum.collectMilitaryStatus ||
          state.verificationResponse.currentStep === VerificationStepsEnum.sso ||
          state.verificationResponse.currentStep === VerificationStepsEnum.docUpload)
      ) {
        previousVerificationResponse = state.verificationResponse;
      } else if (state.previousVerificationResponse) {
        previousVerificationResponse = state.previousVerificationResponse;
      } else {
        previousVerificationResponse = state.verificationResponse;
      }

      recordVerificationResponse(action.verificationResponse);
      return {
        ...state,
        previousVerificationResponse,
        // When we get a verificationResponse, hold on to the last viewModel
        // before re-render, in case it needs to be referenced in the new step:
        previousViewModel: state.viewModel,
        verificationResponse: action.verificationResponse,
      };
    case "SET_MESSAGES":
      return {
        ...state,
        messages: action.messages,
      };
    case "FIELD_VALIDATION_ERRORS":
      return {
        ...state,
        fieldValidationErrors: action.fieldValidationErrors,
      };
    case "VIEW_MODEL":
      const newState: ReduxState = { ...state };

      if (action.viewModel) {
        if (action.partial) {
          // New functionality: action.viewModel can be a sparse object. Bring along all previous viewModel
          // This allows devs to set just one field, like firstName, without supplying the rest of the viewModel object
          newState.viewModel = deepMerge({}, state.viewModel, action.viewModel) as ViewModel;
        } else {
          // Existing functionality: replace viewModel object. Leave here for backwards compatibility.
          // WARNING - this is not a deep clone!
          newState.viewModel = { ...action.viewModel } as ViewModel;
        }
      }

      if (action.viewModel && action.viewModel.metadata) {
        setMetadata(action.viewModel.metadata);
        const metadata: Metadata = getMetadata();
        (newState.viewModel as PersonalInfoViewModel).metadata = metadata;
      }

      recordViewModelChange(state, newState);
      return newState;
    case "PRE_LOAD_ORGS":
      return {
        ...state,
        orgList: action.orgList,
      };
    case "PREVIOUS_VIEW_MODEL":
      return {
        ...state,
        previousViewModel: action.previousViewModel,
      };
    case "PROGRAM_THEME":
      setDimension(
        CustomDimensionNames.testMode,
        action.programTheme.isTestMode ? isTestValues.TRUE : isTestValues.FALSE,
      );

      if (action.partial) {
        return {
          ...state,
          programTheme: deepMerge({}, state.programTheme, action.programTheme) as ProgramTheme,
        };
      }

      return {
        ...state,
        programTheme: action.programTheme,
      };
    case "FORM_VALIDATION_OPTIONS":
      return {
        ...state,
        formValidationOptions: {
          maxAge: state.verificationResponse.maxAge || state.programTheme?.config?.maxAge,
          minAge: state.verificationResponse.minAge || state.programTheme?.config?.minAge,
          smsLoopEnabled: state.programTheme.smsLoopEnabled,
          strictMilitaryValidationEnabled: state.programTheme.strictMilitaryValidationEnabled,
          currentStep: state.verificationResponse.currentStep,
          viewModel: state.viewModel,
        },
      };
    case "REQUIRED_METADATA_CHECK":
      // Check if current step is a first step
      if (!isCollectStep(state.verificationResponse.currentStep) || isMockedStep()) {
        return state;
      }
      const metadataConfig: MetadataConfig = getMetadataConfig(state.programTheme);
      const metadata: Metadata = getMetadata();
      return {
        ...state,
        overrideStep:
          metadataConfig.enabled &&
          !(metadataConfig.requiredKeys || []).every((k) => metadata.hasOwnProperty(k))
            ? "missingRequiredMetadata"
            : undefined,
      };
    case "IS_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
        loadingStep: action.isLoading ? action.loadingStep : undefined,
      };
    case "IS_ERRORED":
      return {
        ...state,
        isErrored: action.isErrored,
      };
    case "RESET_STATE":
      return {
        ...deepClone(getInitialState()),
        programId: state.programId,
      };

    case "FORCE_UPDATE":
      return deepClone(state);
    default:
      return state;
  }
};
