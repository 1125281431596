import React from "react";

interface CustomTextWrapperProps {
  dangerouslySetInnerHTML?: any;
}

const CustomTextWrapper = ({ dangerouslySetInnerHTML, ...props }: CustomTextWrapperProps) =>
  dangerouslySetInnerHTML ? (
    <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...props} />
  ) : (
    <React.Fragment {...props} />
  );

export { CustomTextWrapper };
