import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormSelectChoice,
  FormFieldComponentProps,
  VerificationServiceProps,
  Organization,
  ActiveMilitaryPersonalInfoViewModel,
} from "../../../lib/types/types";
import { orgsInStatus } from "../../StepMilitaryPersonalInfo/militaryStepHelpers";
import { orgToOption } from "../../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { FormFieldInputSelect } from "../FormFieldInputSelect/FormFieldInputSelect";

interface BranchOfServiceComponentProps {
  organizations: Organization[];
}

export const BranchOfService = ({
  autoFocus,
  onChange,
  value,
  isErrored,
  organizations,
  verificationService,
  intl,
  placeholder = "",
}: FormFieldComponentProps & VerificationServiceProps & BranchOfServiceComponentProps) => {
  let options: FormSelectChoice[] = [];
  const { status } = verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel;
  const viewModel = verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel;
  const inputId = "branch-of-service";
  const { countryChoice } = viewModel;

  if (organizations) {
    let newOrganizations: Organization[];
    newOrganizations = organizations.filter(orgsInStatus(status));

    if (countryChoice) {
      newOrganizations =
        "US" in countryChoice
          ? newOrganizations.filter((org) => org.country === countryChoice.value)
          : organizations.filter((org) => org.country === countryChoice.value);
    }
    options = newOrganizations.map(orgToOption);
  }

  const handleStateChange = (selectedOrgOption: FormSelectChoice, onChange: Function) => {
    if (selectedOrgOption) {
      onChange({
        id: selectedOrgOption.value,
        name: selectedOrgOption.label,
      });
    } else {
      onChange({
        id: 0,
        name: "",
      });
    }
  };

  return (
    <FormFieldInputSelect
      fieldId={inputId}
      refId="organization"
      autoFocus={autoFocus}
      options={options}
      onChange={(branch: FormSelectChoice) => handleStateChange(branch, onChange)}
      value={options.find((option) => option.value === (value && value.value))}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({
          id: "branchOfServicePlaceholder",
          defaultMessage: "Branch Of Service",
        })
      }
      isRequired
      label={<FormattedHTMLMessage id="branchOfService" defaultMessage="Branch Of Service" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        )
      }
      showPlaceholderAndHideLabel={!!placeholder}
      containerId={`sid-${inputId}-id`}
    />
  );
};

export const BranchOfServiceComponent = injectIntl(BranchOfService);
