import React from "react";
import { injectIntl, InjectedIntl, FormattedHTMLMessage, FormattedMessage } from "react-intl";

import { VerificationService, ErrorId, ErrorResponse } from "../../lib/types/types";
import { PoweredByComponent } from "../PoweredBy/PoweredByComponent";
import { TryAgainButtonComponent } from "../TryAgainButtonComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { requestOrganizationConstants as constants } from "../../constants";
import { getLocaleSafely } from "../../lib/intl/intl";
import { recordEvent } from "../../lib/GoogleAnalytics/ga";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { getSafe } from "../../lib/utils/objects";
import { LinkExternal } from "../LinkExternal";

interface StepErrorProps {
  verificationService: VerificationService;
  errorId?: ErrorId; // eslint-disable-line
  intl?: InjectedIntl; // eslint-disable-line
}

const BtnContainer = ({ children }: { children: JSX.Element | string }) => (
  <div className="sid-l-space-top-lg sid-error-button-container sid-l-half-width sid-l-horz-center">
    {children}
  </div>
);

const StepError = ({ verificationService, errorId }: StepErrorProps) => {
  const verificationResponse = verificationService.verificationResponse as ErrorResponse;
  let errorIds: ErrorId[] = getSafe(() => verificationResponse.errorIds) || [];

  if (errorId) {
    errorIds = [errorId, ...errorIds]; // Prefer passed in err over any others, so it goes first
  }

  // The first is considered the most important, and will drive the logic on this step
  const firstErrorId = getSafe(() => errorIds[0], "error");

  // defaults
  let errorTitle: FormattedMessage.Props = { id: "error", defaultMessage: "Error" };
  let logoContainerClass = "sid-error-logo-container sid-l-horz-center";
  let button = (
    <BtnContainer>
      <TryAgainButtonComponent verificationService={verificationService} />
    </BtnContainer>
  );

  // If there's an overriding title message, use it (UX-160):
  if (getSafe(() => verificationService.messages[`step.error.errorId.${firstErrorId}.title`])) {
    errorTitle = {
      id: `step.error.errorId.${firstErrorId}.title`,
      defaultMessage: verificationService.messages[`step.error.errorId.${firstErrorId}.title`],
    };
  }

  // If there are the proper ingredients to override the button, do that too (UX-160):
  if (
    getSafe(() => verificationService.messages[`step.error.errorId.${firstErrorId}.buttonText`]) &&
    getSafe(() => verificationResponse.redirectUrl)
  ) {
    button = (
      <BtnContainer>
        <LinkExternal
          href={verificationResponse.redirectUrl}
          onClick={() => recordEvent(VerificationStepsEnum.error, verificationResponse.redirectUrl)}
          className="sid-btn sid-error-btn sid-btn-light"
        >
          <FormattedHTMLMessage
            id={`step.error.errorId.${firstErrorId}.buttonText`}
            defaultMessage={
              verificationService.messages[`step.error.errorId.${firstErrorId}.buttonText`]
            }
          />
        </LinkExternal>
      </BtnContainer>
    );
  } else if (errorIds.includes("missingRequiredMetadata")) {
    // If there is not a button override AND the error is
    // `missingRequiredMetadata` don't show a button because clicking try again
    // is useless in that case.
    button = null;
  }

  // special cases
  if (errorIds.includes("verificationLimitExceeded")) {
    button = null;
    logoContainerClass += " sid-l-space-top-xl";
  }

  if (errorIds.includes("unauthorizedAccountStatus")) {
    const faqUrl = getSafe(() => verificationService.programTheme.config.brandInfo.faqUrl);
    button = faqUrl ? (
      <BtnContainer>
        <LinkExternal
          href={faqUrl}
          onClick={() => recordEvent(VerificationStepsEnum.error, faqUrl)}
          className="sid-btn sid-faq-btn sid-btn-light"
        >
          <FormattedHTMLMessage
            id="step.error.errorId.unauthorizedAccountStatus.buttonText"
            defaultMessage={
              verificationService.messages[
                "step.error.errorId.unauthorizedAccountStatus.buttonText"
              ]
            }
          />
        </LinkExternal>
      </BtnContainer>
    ) : null;
  }

  return (
    <div id="sid-step-error" className="sid-error-container sid-l-container">
      <div className="sid-header sid-l-horz-center">
        <div className={logoContainerClass}>
          <LogoComponent
            verificationService={verificationService}
            fallbackLogo={
              <img
                className="sid-error-logo sid-l-lead-image"
                alt=""
                src="https://assets-resources.sheerid.com/common/images/2018/icons/circle-exclamation.svg"
              />
            }
          />
        </div>
        <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
          <FormattedHTMLMessage {...errorTitle} tagName="h1" />
        </div>
        <div className="sid-header__subtitle sid-l-horz-center sid-l-space-top-md">
          <div className="sid-error-msg sid-l-horz-center">
            {errorIds ? (
              <p>
                {errorIds.map((errId) => (
                  <FormattedHTMLMessage
                    key={errId}
                    id={`errorId.${errId}`}
                    defaultMessage={errId}
                    values={{
                      feedbackUrl: constants.FEEDBACK_FORM_URL,
                      programId: verificationService.programId || "",
                      locale: getLocaleSafely(
                        verificationService.viewModel,
                        verificationResponse,
                      ).replace("-", "_"),
                    }}
                  />
                ))}
              </p>
            ) : null}
          </div>
          {button}
        </div>
      </div>

      <div className="sid-footer sid-powered-by-container sid-l-horz-center sid-l-space-top-xl sid-h-small-text">
        <PoweredByComponent verificationService={verificationService} isCentered />
      </div>
    </div>
  );
};

export const StepErrorComponent = injectIntl(StepError);
