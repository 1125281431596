import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import {
  FormFieldComponentProps,
  ExtendedErrorId,
  ExtendedFieldId,
  FieldContent,
  Validator,
  MarketConsentOptions,
} from "../../../lib/types/types";
import { OptInInputComponent } from "../../FormInputs/OptInInput/OptInInputComponent";
import { setCustomValidator } from "../../../lib/validators/customValidators";
import { getMarketConsent } from "../../../lib/ProgramTheme/programThemeGetters";
import { dashed } from "../../../lib/utils/strings";

const OptInComponent = ({
  isErrored,
  isRequired,
  onChange,
  value,
  verificationService,
}: FormFieldComponentProps) => {
  const optInFieldId: ExtendedFieldId = "marketConsentValue";
  const errorHtmlId = `sid-${dashed(optInFieldId)}-error`;
  const getOptInValidationError: Validator = React.useCallback(
    (value: FieldContent): ExtendedErrorId => {
      const invalidError: ExtendedErrorId = "marketConsentRequired";
      if (isRequired && !value) {
        return invalidError;
      }
    },
    [isRequired],
  );

  const marketConsentConfig =
    (verificationService &&
      verificationService.programTheme &&
      getMarketConsent(verificationService.programTheme)) ||
    ({} as MarketConsentOptions);
  const defaultMessage =
    marketConsentConfig.message ||
    "Yes, send me {company} marketing communications about exclusive sales, special offers, latest products and more";

  React.useEffect(() => {
    setCustomValidator(optInFieldId, getOptInValidationError);
  }, [optInFieldId, getOptInValidationError]);

  React.useEffect(() => {
    setCustomValidator(optInFieldId, getOptInValidationError);
  }, [optInFieldId, getOptInValidationError]);

  return (
    <div className="sid-field sid-opt-in">
      <div className="sid-l-space-top-md" />
      <div className="sid-l-position">
        <label htmlFor="sid-opt-in__optIn">
          <OptInInputComponent
            id="optIn"
            ariaDescribedBy={errorHtmlId}
            isErrored={isErrored}
            onChange={onChange}
            value={value}
          />
          <div className="sid-field__label sid-checkbox__label">
            {verificationService ? (
              <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
                {(companyName) => (
                  <FormattedHTMLMessage
                    id="optIn"
                    values={{ company: companyName }}
                    defaultMessage={defaultMessage}
                  />
                )}
              </FormattedHTMLMessage>
            ) : (
              <FormattedHTMLMessage id="optIn" defaultMessage={defaultMessage} />
            )}
          </div>
          {isErrored && (
            <div className="sid-field-error" id={errorHtmlId}>
              <FormattedHTMLMessage
                id="errorId.invalidOptIn"
                defaultMessage="Must be selected to continue"
              />
            </div>
          )}
        </label>
      </div>
    </div>
  );
};

export { OptInComponent };
