/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import {
  VerificationStepsEnum,
  MilitaryStatusDefaultMessagesEnum,
} from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { resetRefs } from "../../lib/refs/refs";
import { logger } from "../../lib/utils/logger/logger";
import {
  VerificationService,
  FormSelectChoice,
  Organization,
  ActiveMilitaryPersonalInfoViewModel,
  ActiveMilitaryPersonalInfoResponse,
  FormFieldConfig,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
  getAvailableMilitaryStatuses,
  getStatusLabel,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { StatusComponent as MilitaryStatus } from "../FormFields/Status/StatusComponent";
import { BranchOfServiceComponent as BranchOfService } from "../FormFields/BranchOfService/BranchOfServiceComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { updateMilitaryViewModel } from "./militaryStepHelpers";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { ActiveDutyStartDateComponent } from "../FormFields/ActiveDutyStartDate/ActiveDutyStartDateComponent";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepActiveMilitaryPersonalInfoComponentProps {
  intl: any;
  verificationService: VerificationService;
}

export const StepActiveMilitaryPersonalInfo = ({
  intl,
  verificationService,
}: StepActiveMilitaryPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel;
  const previousViewModel =
    verificationService.previousViewModel as ActiveMilitaryPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as ActiveMilitaryPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);
  const availableStatuses = getAvailableMilitaryStatuses(verificationService, intl);
  const locale = viewModel.localeChoice.value;

  const updateActiveMilitaryViewModel = (
    key: keyof ActiveMilitaryPersonalInfoViewModel,
    value: any,
  ) => {
    verificationService.updateViewModel(
      updateMilitaryViewModel(key, value, verificationService, availableStatuses),
    );
  };

  const submitStep = () => {
    const nextState = produce(viewModel, () => {});
    logger.info("StepMilitaryStatusComponent submitting form");
    verificationService.submitStep(
      VerificationStepsEnum.collectMilitaryStatus,
      nextState,
      verificationService.previousVerificationResponse || verificationService.verificationResponse,
    );
  };

  if (
    (viewModel.status && previousViewModel && previousViewModel.status !== viewModel.status) ||
    (viewModel.status &&
      verificationService.verificationResponse.currentStep ===
        VerificationStepsEnum.collectMilitaryStatus)
  ) {
    submitStep();
  }

  // Refs should be reset to ensure the field order isn't affected by the previous step
  resetRefs();

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
      className="sid-military-personal-info-container"
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="active-military-personal-info"
      />

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      {availableStatuses ? (
        <MilitaryStatus
          segmentId="military"
          value={{
            value: viewModel.status,
            label: getStatusLabel(intl, viewModel.status, MilitaryStatusDefaultMessagesEnum),
          }}
          isErrored={!!fieldValidationErrors.status}
          errorId="invalidMilitaryStatus"
          options={availableStatuses}
          onChange={(status: FormSelectChoice) => {
            updateActiveMilitaryViewModel("status", status ? (status.value as string) : "");
            updateFieldValidationErrorsByFieldId(
              "status",
              status ? (status.value as string) : "",
              verificationService,
            );
          }}
        />
      ) : null}

      <BranchOfService
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
        value={{
          value: (verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel).organization
            .id,
          label: (verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel).organization
            .name,
        }}
        verificationService={verificationService}
        organizations={verificationService.orgList}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateActiveMilitaryViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateActiveMilitaryViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateActiveMilitaryViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />

      {verificationService.programTheme.strictMilitaryValidationEnabled && (
        <ActiveDutyStartDateComponent
          isErrored={!!fieldValidationErrors.activeDutyStartDate}
          errorId={fieldValidationErrors.activeDutyStartDate}
          locale={locale}
          onChange={(newValue) => {
            updateActiveMilitaryViewModel("activeDutyStartDate", newValue);
            updateFieldValidationErrorsByFieldId(
              "activeDutyStartDate",
              newValue,
              verificationService,
            );
          }}
          value={viewModel.activeDutyStartDate}
        />
      )}

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Personal email address is recommended"
          />
        }
        onChange={(newValue) => {
          updateActiveMilitaryViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateActiveMilitaryViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateActiveMilitaryViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <PersonalInfoStepSubmitButton
        step={VerificationStepsEnum.collectActiveMilitaryPersonalInfo}
        verificationService={verificationService}
        viewModel={viewModel}
        failedInstantMatch={failedInstantMatch}
        buttonText={
          <FormattedHTMLMessage
            id="verifyMyMilitaryStatus"
            defaultMessage="Verify My Military Status"
          />
        }
      />
    </PersonalInfoWrapper>
  );
};

export const StepActiveMilitaryPersonalInfoComponent = injectIntl(StepActiveMilitaryPersonalInfo);
