import React from "react";
import { getCustomCss } from "../lib/ProgramTheme/programThemeGetters";
import { ProgramTheme } from "../lib/types/types";

// This is a workaround to allow the use of css variables in styled-components
declare module "react" {
  interface CSSProperties {
    [key: `--${string}`]: string | number;
  }
}

interface Props {
  programTheme: ProgramTheme;
  children?: JSX.Element | any;
}

const removePollutingStyles = (styles: string): string => {
  if (window.self === window.top) {
    return styles
      .replace(/body,/g, "")
      .replace(/label,/g, "")
      .replace(/button\[type=('|")submit('|")\]\s*?\{[\s\S]*?\}\n?/g, "") // button type submit overwrites
      .replace(/a:link,\s+a:visited,\s+a:hover,\s+a:active\s*?\{[\s\S]*?\}\n?/g, "") // links overwrites
      .replace(/h1\s*?\{[\s\S]*?\}\n?/g, ""); // h1 overwrites
  }
  return styles;
};

const setCssVariablsFromProgramTheme = (programTheme: ProgramTheme) => {
  const {
    backgroundColor: landingBackgroundColor,
    primaryFontColor: landingPrimaryFontColor,
    backgroundImageUrl: landingBackgroundImage,
  } = programTheme?.themeChoices?.landingPage || {};
  const {
    backgroundColor: formBackgroundColor,
    primaryFontColor: formPrimaryFontColor,
    buttonColor: formButtonColor,
    buttonFontColor: formButtonFontColor,
    linkColor: formLinkColor,
    h1FontColor: formH1FontColor,
    helperFontColor: formHelperFontColor,
    font,
  } = programTheme?.themeChoices || {};

  return {
    "--sheerid-landing-background-color": landingBackgroundColor || "#fff",
    "--sheerid-landing-background-image-url": landingBackgroundImage
      ? `url(${landingBackgroundImage})`
      : "none",
    "--sheerid-landing-primary-font-color": landingPrimaryFontColor || "#000",
    "--sheerid-form-background-color": formBackgroundColor || "#fff",
    "--sheerid-form-primary-font-color": formPrimaryFontColor || "#000",
    "--sheerid-form-button-color": formButtonColor || "#000",
    "--sheerid-form-button-font-color": formButtonFontColor || "#fff",
    "--sheerid-form-link-color": formLinkColor || "#006ded",
    "--sheerid-form-h1-font-color": formH1FontColor || "#050608",
    "--sheerid-form-helper-font-color": formHelperFontColor || "#0009",
    "--sheerid-font-family-body": font || "var(--sheerid-font-family-sans)",
  };
};

const CustomCssWrapperComponent = ({ programTheme, children }: Props) => {
  const styles = getCustomCss(programTheme);
  const container = React.useRef<HTMLDivElement>(null);

  return (
    <div style={setCssVariablsFromProgramTheme(programTheme)} ref={container}>
      {styles ? <style>{removePollutingStyles(styles)}</style> : null}

      {children}
    </div>
  );
};

CustomCssWrapperComponent.defaultProps = {
  children: "",
};

export { CustomCssWrapperComponent };
