import React from "react";
import { IntlProvider } from "react-intl";
import flatten from "flat";
import rtlDetect from "rtl-detect";

import { useLDClient } from "launchdarkly-react-client-sdk";
import deterministicStringify from "json-stable-stringify";
import { LoadStepComponent as LoadStep } from "./LoadStepComponent";
import { CustomCssWrapperComponent as CustomCssWrapper } from "./CustomCssWrapperComponent";
import { CustomTextWrapper } from "./CustomTextWrapper";
import { TestModeFlagComponent } from "./TestModeFlag/TestModeFlagComponent";
import { getOptionsMessages, modernizeLocale } from "../lib/intl/intl";
import { ProgramTheme, VerificationService } from "../lib/types/types";
import { includeIPQSDeviceFingerprintScript } from "../lib/IPQS/IPQSutils";
import { LoadingScreenComponent as LoadingScreen } from "./LoadingScreen/LoadingScreenComponent";
import { getOptions } from "../options/options";
import { Layout } from "./Layout/Layout";
import { FormWrapperComponent } from "./FormWrapper/FormWrapperComponent";
import { useFlags } from "./FeatureFlags/hooks";
import { logger } from "../lib/utils/logger/logger";
import { CustomDimensionNames, setUserDimension } from "../lib/GoogleAnalytics/ga";

export const NewVerificationInnerComponent = ({
  locale,
  messages,
  programTheme,
  verificationService,
  programId,
}: {
  // TODO better types:
  locale: any;
  messages: any;
  programTheme: ProgramTheme;
  verificationService: VerificationService;
  programId: string;
}) => {
  const ldClient = useLDClient();
  const options = getOptions();
  const layout = verificationService.programTheme.themeChoices?.landingPage.layout || "center";
  const { renderAs } = options;
  const flags = useFlags();

  React.useEffect(() => {
    try {
      if (flags) {
        setUserDimension(CustomDimensionNames.flags, deterministicStringify(flags));
      }
    } catch (e) {
      logger.error(e);
    }
  }, [flags]);

  React.useEffect(() => {
    includeIPQSDeviceFingerprintScript(
      programTheme,
      verificationService.verificationResponse.verificationId,
    );

    // HC-202: Launch darkly experimentation trial.
    // Keeping this behind a private option so we can turn it on just for hosted programs.
    if (
      verificationService.verificationResponse.verificationId &&
      options._launchDarklyUserTargeting
    ) {
      const identity: {
        kind: string;
        user: { key: string };
        program: { key: string };
        account?: { key: string };
      } = {
        kind: "multi",
        user: { key: verificationService.verificationResponse.verificationId },
        program: { key: programId },
      };
      if (programTheme.accountId) {
        identity.account = { key: programTheme.accountId };
      }
      ldClient.identify(identity);
    }
  }, [verificationService.verificationResponse.verificationId]);

  const messagesWithOptions = { ...messages, ...(flatten(getOptionsMessages(locale)) as Object) };

  return (
    // Don't change locale, so we don't have to load pluralization data, which has complications. HD-560
    <IntlProvider
      locale="en"
      key={locale}
      messages={messagesWithOptions}
      textComponent={CustomTextWrapper}
    >
      <CustomCssWrapper programTheme={programTheme}>
        <div
          className={`${
            rtlDetect.isRtlLang(modernizeLocale(locale)) ? "sid-rtl" : "sid-ltr"
          } sid-locale-${locale}`}
        >
          <FormWrapperComponent>
            <Layout
              layout={layout}
              verificationService={verificationService}
              renderAsLandingPage={renderAs === "landingPage"}
            >
              {verificationService.isLoading ? (
                <LoadingScreen step={verificationService.loadingStep} />
              ) : (
                <div>
                  {!getOptions().hideTestModeFlag && (
                    <TestModeFlagComponent verificationService={verificationService} />
                  )}

                  <LoadStep
                    stepToLoad={
                      verificationService.overrideStep ||
                      verificationService.verificationResponse.currentStep
                    }
                    verificationService={verificationService}
                  />
                </div>
              )}
            </Layout>
          </FormWrapperComponent>
        </div>
      </CustomCssWrapper>
    </IntlProvider>
  );
};
