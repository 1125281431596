import React from "react";

interface FieldLabelProps {
  text: React.ReactNode;
  htmlForLabel: string;
  id?: string;
  displayClasses?: string;
  isRequired?: boolean;
  children?: React.ReactNode;
}
const isEmptyText = (allegedNode: React.ReactNode) =>
  !React.isValidElement(allegedNode) && allegedNode === "";

export const FieldLabel: React.FC<FieldLabelProps> = ({
  text,
  htmlForLabel,
  id,
  displayClasses,
  isRequired,
  children,
}: FieldLabelProps) => (
  <label htmlFor={htmlForLabel}>
    <div id={id} className={`sid-field__label sid-l-space-btm-sm ${displayClasses}`}>
      <span className="sid-field__label-name" aria-hidden="true">
        {text}
        {isRequired && !isEmptyText(text) && (
          <span aria-hidden="true" className="sid-field__label--required">
            *
          </span>
        )}
      </span>

      {children && <div className="sid-field__label-explanation">{children}</div>}
    </div>
  </label>
);

FieldLabel.defaultProps = {
  id: "",
  displayClasses: "",
  isRequired: true,
  children: null,
};
