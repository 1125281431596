import React from "react";
import { LDProvider } from "launchdarkly-react-client-sdk";

import { flagDefaults } from "./flags";

export const getLDProgramContext = (programId: string) => ({
  kind: "program",
  key: programId,
});

interface ProviderProps {
  programId: string;
  children: React.ReactNode;
}

export const FlagProvider = ({ programId, children }: ProviderProps) => (
  <LDProvider
    clientSideID={process.env.LD_CLIENT_ID}
    // It is important that we create our contexts carefully in the jslib
    // to avoid creating contexts for anyone who looks at a jslib form.
    context={getLDProgramContext(programId)}
    flags={flagDefaults}
    reactOptions={{ useCamelCaseFlagKeys: false }}
    options={{ streaming: false }}
  >
    {children}
  </LDProvider>
);
