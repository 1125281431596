import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormSelectChoice,
  FormFieldComponentProps,
  VerificationServiceProps,
  Organization,
} from "../../../lib/types/types";
import { orgToOption } from "../../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { FormFieldInputSelect } from "../FormFieldInputSelect/FormFieldInputSelect";

export const LowIncomeOrganization = ({
  value,
  isErrored,
  onChange,
  verificationService,
  intl,
  placeholder = "",
  isRequired = false,
}: FormFieldComponentProps & VerificationServiceProps) => {
  const { viewModel, orgList } = verificationService;
  const { countryChoice } = viewModel;
  let options: FormSelectChoice[] = [];
  const inputId = "low-income";

  if (orgList) {
    let newOrganizations: Organization[] = orgList;

    if (countryChoice) {
      newOrganizations = orgList.filter((org) => org.country === countryChoice.value);
    }
    options = newOrganizations.map(orgToOption);
  }

  const handleStateChange = (selectedOrgOption: FormSelectChoice, onChange: Function) => {
    if (selectedOrgOption) {
      onChange({
        id: selectedOrgOption.value,
        name: selectedOrgOption.label,
      });
    } else {
      onChange({
        id: 0,
        name: "",
      });
    }
  };

  return (
    <FormFieldInputSelect
      fieldId={inputId}
      value={options.find((option) => option.value === (value && value.value))}
      refId="organization"
      onChange={(organization: FormSelectChoice) => handleStateChange(organization, onChange)}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({
          id: "lowIncomeOrganizationPlaceholder",
          defaultMessage: "Government Assistance Program",
        })
      }
      label={
        <FormattedHTMLMessage
          id="lowIncomeOrganization"
          defaultMessage="Government Assistance Program"
        />
      }
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        )
      }
      isRequired={isRequired}
      showPlaceholderAndHideLabel={!!placeholder}
      options={options}
      containerId={`sid-${inputId}-id`}
    />
  );
};

export const LowIncomeOrganizationComponent = injectIntl(LowIncomeOrganization);
