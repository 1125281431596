import { createUpdateViewModel, doViewModelReset } from "./VerificationServiceActions";
import { getStore } from "./VerificationServiceStore";
import { ViewModel, ReduxState, WithLocaleAndCountry } from "../types/types";
import { addHook } from "../hooks/hooks";

import { logger } from "../utils/logger/logger";
import { deepClone, getSafe } from "../utils/objects";
import { setOptions } from "../../options/options";

const store = getStore();

/**
 * @description Set the viewModel for the form or reset it by passing an empty object
 */
export const setViewModel = (
  newViewModel: ViewModel | {},
  options: { partial: boolean } = { partial: true },
) => {
  const state: ReduxState = store.getState();
  const doSet = () => {
    const updatedViewModel = deepClone(newViewModel);
    logger.info("Setting viewModel", updatedViewModel);
    createUpdateViewModel(store.dispatch)(updatedViewModel, options.partial);
  };

  // The viewModel may not be set until later, and default messages may fail to load
  // so re-use setOptions to make sure we know about the desire to use this locale
  const locale = getSafe(() => (newViewModel as WithLocaleAndCountry).localeChoice.value);
  if (locale) {
    setOptions({ locale });
  }

  if (state.isLoading) {
    addHook({
      name: "ON_VERIFICATION_READY",
      callback: () => {
        doSet();
      },
    });
  } else {
    doSet();
  }
};

/**
 * Schedule, or immediately perform a viewModel reset.
 * Resets fieldValidationErrors and previousViewModel as well.
 * @todo Return promise
 */
export const resetViewModel = () => {
  const state: ReduxState = store.getState();

  if (state.isLoading) {
    addHook({
      name: "ON_VERIFICATION_READY",
      callback: (verificationResponse) => {
        doViewModelReset(store.dispatch)(verificationResponse);
      },
    });
  } else {
    doViewModelReset(store.dispatch)(state.verificationResponse);
  }
};
