import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

export const LastName = ({
  value,
  isErrored,
  onChange,
  intl,
  placeholder = "",
}: FormFieldComponentProps) => (
  <FormFieldText
    fieldId="lastName"
    value={value}
    onChange={onChange}
    placeholder={
      placeholder ||
      intl.formatHTMLMessage({ id: "lastNamePlaceholder", defaultMessage: "Last Name" })
    }
    label={<FormattedHTMLMessage id="lastName" defaultMessage="Last name" />}
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage id="errorId.invalidLastName" defaultMessage="Invalid last name" />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
  />
);
export const LastNameComponent = injectIntl(LastName);
