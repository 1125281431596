import { logger } from "../logger/logger";
import { ExtendedFieldId, FieldId } from "../../types/types";
import { getRefByFieldId } from "../../refs/refs";

/**
 * @private
 */
export const setFocus = (fieldId: FieldId | ExtendedFieldId) => {
  const fieldRef = getRefByFieldId(fieldId);

  if (fieldRef) {
    try {
      fieldRef.focus();
    } catch (e) {
      logger.error(
        `The ref '${fieldRef}' could not be focused. Is the ref an HTMLInputElement or HTMLButtonElement?`,
        "ref error",
      );
    }
  } else {
    logger.error(
      `A ref for fieldId '${fieldId}' could not be found. Has the ref been registered using setRef()?`,
      "ref error",
    );
  }
};
