import React from "react";
import { FormFieldBaseComponentProps } from "../FormFieldCustom/FormFieldCustom";
import { FormSelectChoice, InputSelectOnKeyDown } from "../../../lib/types/types";
import { InputRadio } from "../../FormInputs/InputRadio/InputRadio";
import { FormFieldLayout, getFormFieldLabelHtmlId } from "../FormFieldLayout/FormFieldLayout";
import { dashed } from "../../../lib/utils/strings";

export interface FormFieldRadioComponentProps extends FormFieldBaseComponentProps<string> {
  fieldId: string;
  errorMessage?: React.ReactNode;
  explanation?: React.ReactNode;
  required?: boolean;
  showPlaceholderAndHideLabel?: boolean;
  options: FormSelectChoice<string, string>[];
  onKeyDown?: InputSelectOnKeyDown;
  onChange: (value: any) => void;
  value: string;
}

export const FormFieldRadio = ({
  fieldId,
  onChange,
  options,
  label,
  explanation = undefined,
  required,
  errorMessage,
  value,
}: FormFieldRadioComponentProps) => {
  const fieldIdDashed = dashed(fieldId);
  const labelHtmlId = getFormFieldLabelHtmlId(fieldId);

  return (
    <FormFieldLayout
      fieldId={fieldIdDashed}
      label={label}
      explanation={explanation}
      errorMessage={errorMessage}
      showPlaceholderAndHideLabel={null}
      isRequired={required}
    >
      <div
        role="group"
        className={`sid-radio__inputs sid-${fieldIdDashed}__inputs sid-radio-grid${
          errorMessage ? " sid-radio__inputs--error" : ""
        }`}
        aria-labelledby={labelHtmlId}
      >
        {options.map((option, index) => (
          <InputRadio
            key={option.value}
            fieldId={fieldId}
            index={index}
            option={option}
            value={value}
            onChange={onChange}
          />
        ))}
      </div>
    </FormFieldLayout>
  );
};
