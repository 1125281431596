import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { setRef } from "../../../lib/refs/refs";

export type InputTextProps = {
  id: string; // used for the html `id` and `name` attributes
  isErrored: boolean;
  className?: string; // any additional classnames
  hidePlaceholder?: boolean; // placeholder should always be provided, even if hidden (default), so it is easy to reveal using css-only
  onChange?: React.ChangeEventHandler;
  onKeyDown?: React.ChangeEventHandler;
  ref?: never; // use refId instead. Setting the ref on this component, rather than the input inside here is not desired
  refId?: string; // e.g. if id is hyphen-case, and ref field name is camelCase, which is common
  required?: boolean;
  ariaDescribedBy?: string;
  ariaLabel?: string;
  type?: "text" | "number" | "date" | "month" | "email" | "password" | "search" | "url" | "tel";
} & InputHTMLAttributes<HTMLInputElement>;

const InputText = ({
  id,
  isErrored,
  className = undefined,
  hidePlaceholder = true,
  refId,
  required = false,
  type = "text",
  ariaDescribedBy,
  ariaLabel,
  ...props // forward any remaining props to the input
}: InputTextProps) => {
  let internalClassName = "";
  if (className) internalClassName += `${className} `; // want this first
  internalClassName += "sid-text-input sid-l-full-width";
  if (hidePlaceholder) internalClassName += " sid-hidden-placeholder";
  if (required) internalClassName += " sid-text-input--required";
  if (isErrored) internalClassName += " sid-text-input--error";

  const finalRefId = refId || id;

  const inputRef = useRef<HTMLInputElement>(null);

  // NOTE: Dependency array omitted to account for use of resetRef(), as useEffect with dep arr
  // will only trigger on initial render leaving the refs empty, this replicates prior version
  // where ref callback would trigger with each re-render
  useEffect(() => {
    setRef(finalRefId, inputRef.current);
  });

  return (
    <input
      className={internalClassName}
      id={`sid-${id}`}
      name={`sid-${id}`}
      type={type}
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      aria-required={required}
      aria-invalid={isErrored}
      ref={inputRef}
      {...props}
    />
  );
};

// DO NOT INJECT INTL. Localize your strings pass them down as props
export const InputTextComponent = InputText;
