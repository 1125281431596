import { createStore } from "redux";
import { verificationServiceReducer } from "./verificationServiceReducer";
import {
  ReduxState,
  VerificationResponseAction,
  VerificationServiceAction,
  VerificationStore,
} from "../types/types";
import { logger } from "../utils/logger/logger";
import { getOptions } from "../../options/options";

let store: VerificationStore;

export const getStore = (): VerificationStore => {
  if (!store) {
    store = createStore<ReduxState, VerificationServiceAction, void, void>(
      verificationServiceReducer,
      // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
    logger.init(store);
  }

  return store;
};

export const resetStore = () => getStore().dispatch({ type: "RESET_STATE" });

export const refreshStore = (): void => {
  // This prompts the form to re-render which it otherwise wouldn't because a new verification is requested only on mount
  if (getOptions().mockResponse) {
    getStore().dispatch<VerificationResponseAction>({
      verificationResponse: getOptions().mockResponse,
      type: "VERIFICATION_RESPONSE",
    });
  }
  getStore().dispatch({ type: "FORCE_UPDATE" });
};
