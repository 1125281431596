import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { DateComponent } from "../../DateComponent/DateComponent";

export const DischargeDateComponent = ({
  onChange,
  isErrored,
  value = "2000-01-01",
}: FormFieldComponentProps) => (
  <DateComponent
    mode="month"
    value={value}
    label={<FormattedHTMLMessage id="dischargeDate" defaultMessage="Discharge date" />}
    explanation={
      <FormattedHTMLMessage
        id="verificationPurposesOnly"
        defaultMessage="Used for verification purposes only"
      />
    }
    onChange={onChange}
    fieldName="dischargeDate"
    fieldId="discharge-date"
    isErrored={isErrored}
    isRequired
    errorMsg={
      isErrored && (
        <FormattedHTMLMessage
          id="errorId.invalidDischargeDate"
          defaultMessage="Invalid discharge date"
        />
      )
    }
  />
);
