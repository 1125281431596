import { FieldRef, FieldId, ExtendedFieldId } from "../types/types";
import { logger } from "../utils/logger/logger";

let refs: FieldRef[] = [];
let tabRef: Window = null;

/**
 * @private
 */
export const setRef = (fieldId: FieldId | ExtendedFieldId, ref: HTMLElement) => {
  const newRefObject = {
    fieldId,
    ref,
  };
  const currentRefIndex = refs.findIndex((o) => o.fieldId === fieldId);

  if (currentRefIndex !== -1) {
    refs[currentRefIndex] = newRefObject;
  } else {
    refs.push(newRefObject);
  }
};

/**
 * @private
 */
export const getRefs = (): FieldRef[] => refs;

/**
 * @private
 */
export const getRefByFieldId = (fieldId: FieldId | ExtendedFieldId): HTMLElement => {
  for (const ref of refs) {
    if (ref.fieldId === fieldId) {
      return ref.ref;
    }
  }
  logger.error(
    `A ref for fieldId ${fieldId} could not be found. Has one been registered using setRef()?`,
    "ref error",
  );
  return null;
};

/**
 * @private
 */
export const resetRefs = () => {
  refs = [];
  logger.info("Refs have been reset");
};

/**
 * @private
 */
export const setTabRef = (tab: Window) => {
  tabRef = tab;
};

/**
 * @private
 */
export const getTabRef = () => tabRef;

/**
 * @private
 */
export const resetTabRef = () => {
  tabRef = null;
};

/**
 * @private
 */
export const closeTabRef = () => {
  if (tabRef !== null) {
    tabRef.close();
    resetTabRef();
  }
};
