/* eslint-disable */
if (!(<any>Object).values) {
  (<any>Object).values = function (obj) {
    const res = [];
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        res.push(obj[i]);
      }
    }
    return res;
  };
}
export {};
