import React from "react";
import { VerificationService } from "../../lib/types/types";
import { StepActiveMilitaryPersonalInfoComponent } from "./StepActiveMilitaryPersonalInfoComponent";

interface StepCollectMilitaryStatusComponentProps {
  verificationService: VerificationService;
}

// Collect status is identical to Active at this time.
const StepCollectMilitaryStatusComponent = ({
  verificationService,
}: StepCollectMilitaryStatusComponentProps) => (
  <StepActiveMilitaryPersonalInfoComponent verificationService={verificationService} />
);

export { StepCollectMilitaryStatusComponent };
