import produce from "immer";
import {
  ProgramTheme,
  WithOrganization,
  VerificationResponse,
  ViewModel,
  InactiveMilitaryPersonalInfoViewModel,
  PersonalInfoResponse,
  VerificationStep,
  PersonalInfoViewModel,
  DocUploadViewModel,
  Locale,
  Country,
} from "../types/types";
import { getHook } from "../hooks/hooks";
import { VerificationStepsEnum } from "../types/runtimeTypes";
import { getEmptyViewModel } from "../ServerApi/VerificationRequestGetters";
import {
  MAX_DOC_UPLOAD_DOCS_ALLOWED,
  QUERY_STRING_EXTERNAL_USER_ID,
  UPLOAD_FILE_PREFIX,
} from "../../constants";
import { deepClone } from "../utils/objects";
import { getQueryOrOption } from "../ServerApi/TestingRouteOverrides";

/**
 * @todo needs tests
 * @private
 */
export const handleSubmitResponse = (
  verificationResponse: VerificationResponse,
  viewModel: ViewModel,
): ViewModel => {
  const { currentStep } = verificationResponse;

  if (currentStep === VerificationStepsEnum.success) {
    getHook("ON_VERIFICATION_SUCCESS")(verificationResponse);
  }

  // TODO: Find a better way to persist data between military steps.
  // Ensure the new viewModel for military steps persists data from the previous military step.
  let newViewModel: ViewModel;

  if (currentStep === VerificationStepsEnum.collectActiveMilitaryPersonalInfo) {
    newViewModel = { ...viewModel, dischargeDate: "" } as InactiveMilitaryPersonalInfoViewModel;
    delete (newViewModel as InactiveMilitaryPersonalInfoViewModel).dischargeDate;
  } else if (currentStep === VerificationStepsEnum.collectInactiveMilitaryPersonalInfo) {
    if (!(viewModel as InactiveMilitaryPersonalInfoViewModel).dischargeDate) {
      newViewModel = { ...viewModel, dischargeDate: "" } as InactiveMilitaryPersonalInfoViewModel;
    } else {
      newViewModel = viewModel;
    }
  } else if (hasFailedInstantMatch(verificationResponse as PersonalInfoResponse)) {
    newViewModel = viewModel;
  } else {
    // If not military, and not on the confirm info 'step', get an empty viewModel
    const { locale } = verificationResponse;
    newViewModel = initViewModel({
      locale,
      previousViewModel: viewModel,
      currentStep,
      externalUserId: getQueryOrOption(QUERY_STRING_EXTERNAL_USER_ID),
    });
  }
  return newViewModel;
};

/**
 * @private
 */
export const hasFailedInstantMatch = (verificationResponse: PersonalInfoResponse): boolean => {
  if (verificationResponse.instantMatchAttempts && verificationResponse.instantMatchAttempts > 0) {
    return true;
  }
  return false;
};

/**
 * @private
 */
export const initViewModel = ({
  previousViewModel,
  currentStep,
  locale,
  fingerprint,
  country,
  externalUserId,
}: {
  previousViewModel: ViewModel;
  currentStep: VerificationStep;
  locale: Locale;
  fingerprint?: string;
  country?: Country;
  externalUserId?: string;
}): ViewModel => {
  const viewModel = getEmptyViewModel(currentStep);

  // Attempt to bring over any previousViewModel values that apply to the new viewModel
  if (previousViewModel) {
    Object.getOwnPropertyNames(viewModel).forEach((key) => {
      if (previousViewModel.hasOwnProperty(key)) {
        viewModel[key] = previousViewModel[key];
      }
    });
  }

  if (viewModel) {
    if (fingerprint) {
      (viewModel as PersonalInfoViewModel).deviceFingerprintHash = fingerprint;
    }
    if (locale) {
      (viewModel as PersonalInfoViewModel).localeChoice = {
        value: locale || "en-US",
        label: "",
      };
    }
    if (country && "country" in viewModel && viewModel.country === undefined) {
      viewModel.country = country;
    }
    if (country && "countryChoice" in viewModel && viewModel.countryChoice === undefined) {
      viewModel.countryChoice = {
        value: country,
        label: "",
      };
    }
    if (externalUserId) {
      (viewModel as PersonalInfoViewModel).externalUserId = externalUserId;
    }
  }

  return viewModel;
};

export const clearUploadedFiles = (
  viewModel: DocUploadViewModel,
): DocUploadViewModel | undefined => {
  if (viewModel) {
    return produce(viewModel, (draft) => {
      for (let i = 1; i <= MAX_DOC_UPLOAD_DOCS_ALLOWED; i += 1) {
        draft[`${UPLOAD_FILE_PREFIX}${i}`] = undefined;
      }
    });
  }
  return undefined;
};

export const collectThreatMetrixProfile = async (verificationId: string) => {
  import(/* webpackChunkName: "Tmetrix" */ "../ThreatMetrix/index")
    .then(({ ThreatMetrix }) => {
      if (
        ThreatMetrix !== undefined &&
        ThreatMetrix.hasOwnProperty("profile") &&
        typeof ThreatMetrix.profile === "function"
      ) {
        const orgId = "cnl2my47";
        const profilingDomain = "content.sheerid.com";
        ThreatMetrix.profile(profilingDomain, orgId, verificationId);
      }
    })
    .catch(() => {
      // Do nothing - API call will handle this
    });
};

export const enhanceOrganizationFromTheme = (viewModel: ViewModel, programTheme: ProgramTheme) => {
  if (
    viewModel &&
    viewModel.hasOwnProperty("organization") &&
    programTheme.config.orgRemoteSource
  ) {
    const cloned: ViewModel = deepClone(viewModel);
    const { organization } = cloned as WithOrganization;
    organization.source = programTheme.config.orgRemoteSource;
    return cloned;
  }
  return viewModel;
};

export const determineCountry = (
  verificationResponse: VerificationResponse,
  countries: Country[],
): Country => {
  if (verificationResponse?.country) return verificationResponse.country;

  const defaultCountry = "US";
  if (!Array.isArray(countries) || countries.length === 0) return defaultCountry;
  if (countries.length === 1) return countries[0];

  return undefined;
};
