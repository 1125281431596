import React from "react";

import { FormSelectChoice, InputSelectOnKeyDown } from "../../../lib/types/types";
import { InputSelectComponent } from "../../FormInputs/InputSelect/InputSelectComponent";
import { FormFieldBaseComponentProps } from "../FormFieldCustom/FormFieldCustom";
import { FormFieldLayout } from "../FormFieldLayout/FormFieldLayout";
import { dashed } from "../../../lib/utils/strings";

export interface FormFieldSelectProps extends FormFieldBaseComponentProps<string> {
  fieldId: string;
  placeholder: string;
  errorMessage?: React.ReactNode;
  required?: boolean;
  showPlaceholderAndHideLabel?: boolean;
  options: FormSelectChoice<string, string>[];
  onKeyDown?: InputSelectOnKeyDown;
}

export const FormFieldSelect = ({
  value,
  options,
  onChange,
  fieldId,
  label,
  placeholder = "",
  errorMessage,
  required,
  showPlaceholderAndHideLabel = false,
  onKeyDown = undefined,
}: FormFieldSelectProps) => {
  const labelIntlId = `${fieldId}Label`;
  const labelHtmlId = `sid-${dashed(labelIntlId)}`;
  const errorHtmlId = `sid-${dashed(fieldId)}-error`;

  return (
    <FormFieldLayout
      fieldId={fieldId}
      label={label}
      errorMessage={errorMessage}
      showPlaceholderAndHideLabel={showPlaceholderAndHideLabel}
      isRequired={required}
    >
      <InputSelectComponent
        fieldId={fieldId}
        inputId={`sid-${dashed(fieldId)}`}
        fieldLabelId={labelHtmlId}
        isErrored={!!errorMessage}
        options={options}
        onChange={(option: FormSelectChoice) => onChange(option?.value.toString() || "")}
        onKeyDown={onKeyDown}
        value={options.find((option) => option.value === value)}
        suppressPlaceholder={!showPlaceholderAndHideLabel}
        placeholder={placeholder}
        ariaDescribedBy={errorMessage ? errorHtmlId : ""}
        isRequired={required}
      />
    </FormFieldLayout>
  );
};
