import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { FormFieldInputSelect } from "../FormFieldInputSelect/FormFieldInputSelect";

export const StateSelect = ({
  value,
  isErrored,
  options,
  onChange,
  intl,
  placeholder = "",
  label = "",
  isRequired = false,
}: InputSelectComponentProps) => {
  const stateFieldId = "state";

  return (
    <FormFieldInputSelect
      fieldId={stateFieldId}
      value={options.find((option) => option.value === value)}
      refId="state"
      onChange={(state: FormSelectChoice) => onChange(state)}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({
          id: "statePlaceholder",
          defaultMessage: "State",
        })
      }
      label={label || <FormattedHTMLMessage id="state" defaultMessage="State" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage id="errorId.invalidState" defaultMessage="Invalid state" />
        )
      }
      showPlaceholderAndHideLabel={!!placeholder}
      options={options}
      isRequired={isRequired}
      buttonRef="inputSelectButtonState"
      containerId={`sid-${stateFieldId}__select`}
    />
  );
};

export const StateSelectComponent = injectIntl(StateSelect);
