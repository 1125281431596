import deterministicStringify from "json-stable-stringify";
import { DEBOUNCE_TIMEOUT } from "../../constants";
import { logger } from "../utils/logger/logger";

let lastDigest: string = "";
let lastTime = Date.now();

/** return true if this same call occurred within DEBOUNCE_TIMEOUT ago  */
export function isSuccessiveCall(
  url: string,
  headersOrBody: any,
  errorAttributes: { [a: string]: string },
): boolean {
  const currentDigest = `${deterministicStringify(headersOrBody)}${url}`;
  const currentTime = Date.now();
  if (lastDigest === currentDigest) {
    if (currentTime - lastTime < DEBOUNCE_TIMEOUT) {
      logger.error("Rapid fetch to same resource", "successive calls", errorAttributes);
      return true;
    }
  }
  lastDigest = currentDigest;
  lastTime = currentTime;

  return false;
}
