import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

interface PassedProps {
  explanation?: JSX.Element;
  label?: JSX.Element;
}

export const MemberId = ({
  value,
  isErrored,
  onChange,
  explanation,
  intl,
  placeholder = "",
  isRequired = false,
  label,
}: FormFieldComponentProps & PassedProps) => {
  const getDefaultExplanation = () =>
    !isRequired ? <FormattedHTMLMessage id="optional" defaultMessage="Optional" /> : "";
  return (
    <FormFieldText
      fieldId="member-id"
      refId="memberId"
      value={value}
      onChange={onChange}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({ id: "memberIdPlaceholder", defaultMessage: "ID Number" })
      }
      label={label || <FormattedHTMLMessage id="memberId" defaultMessage="ID Number" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage id="errorId.invalidMemberId" defaultMessage="Invalid ID Number" />
        )
      }
      showPlaceholderAndHideLabel={!!placeholder}
      explanation={explanation || getDefaultExplanation()}
      required={isRequired}
    />
  );
};

export const MemberIdComponent = injectIntl(MemberId);
