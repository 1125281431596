import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

interface PassedProps {
  explanation?: string | JSX.Element;
  required?: boolean;
}

const PostalCode = ({
  value,
  isErrored,
  onChange,
  explanation,
  intl,
  required = true, // backwards compatibility. TODOv2 make this the same as other fields
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <FormFieldText
    fieldId="postal-code"
    refId="postalCode"
    pattern="[a-zA-Z0-9 ]*"
    value={value}
    onChange={onChange}
    placeholder={
      placeholder ||
      intl.formatHTMLMessage({ id: "postalCodePlaceholder", defaultMessage: "Postal Code" })
    }
    label={<FormattedHTMLMessage id="postalCode" defaultMessage="Postal Code" />}
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage id="errorId.invalidPostalCode" defaultMessage="Invalid postal code" />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
    required={required}
    explanation={explanation && explanation}
  />
);

export const PostalCodeComponent = injectIntl(PostalCode);
