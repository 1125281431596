import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormFieldComponentProps,
  VerificationServiceProps,
  Organization,
} from "../../../lib/types/types";
import { getOptions } from "../../../options/options";
import { getOrgSearchUrl } from "../../../lib/ProgramTheme/programThemeGetters";
import { FormFieldTypeahead } from "../FormFieldTypeahead/FormFieldTypeahead";

export const TeacherSchool = ({
  value,
  isErrored,
  onChange,
  verificationService,
  intl,
  placeholder = "",
  isRequired = true,
}: FormFieldComponentProps & VerificationServiceProps) => {
  const urlAddSchoolForm = (getOptions().urlAddSchoolForm || "").trim();

  return (
    <FormFieldTypeahead
      fieldId="teacher-school"
      value={value}
      verificationService={verificationService}
      onChange={(choice: Organization) => onChange(choice)}
      placeholder={
        placeholder ||
        intl.formatHTMLMessage({
          id: "schoolNamePlaceholder",
          defaultMessage: "School name",
        })
      }
      label={<FormattedHTMLMessage id="schoolName" defaultMessage="School name" />}
      errorMessage={
        isErrored && (
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        )
      }
      showPlaceholderAndHideLabel={!!placeholder}
      isRequired={isRequired}
      orgSearchUrl={getOrgSearchUrl(
        verificationService.viewModel,
        verificationService.programTheme,
      )}
      urlAddSchoolForm={urlAddSchoolForm}
    />
  );
};

export const TeacherSchoolComponent = injectIntl(TeacherSchool);
