/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from "react-intl";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { getSafe } from "../../lib/utils/objects";
import {
  VerificationService,
  AgePersonalInfoViewModel,
  AgePersonalInfoResponse,
  FormSelectChoice,
  Country,
  CollectFieldsResponse,
  FieldDescription,
  FormFieldConfig,
} from "../../lib/types/types";

import {
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
  getAvailableCountryChoices,
  getDefaultCountryChoice,
  produceDraftViewModel,
  produceDraftViewModelWithRequiredFields,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { PostalCodeComponent as PostalCode } from "../FormFields/PostalCode/PostalCodeComponent";

import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { getFieldsToCollect } from "../../lib/ServerApi/VerificationApiClient";
import { logger } from "../../lib/utils/logger/logger";
import { CityComponent } from "../FormFields/City/City";
import { AddressComponent } from "../FormFields/Address/AddressComponent";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepAgePersonalInfoComponentProps {
  intl: InjectedIntl;
  verificationService: VerificationService;
}

const StepAgePersonalInfo = ({ verificationService, intl }: StepAgePersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as AgePersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse = verificationService.verificationResponse as AgePersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);
  const countryChoices: FormSelectChoice<Country, string>[] = getAvailableCountryChoices(
    verificationService.programTheme,
    intl,
  );
  const defaultCountryChoice: FormSelectChoice<Country, string> =
    getDefaultCountryChoice(countryChoices);
  const safeCountryValue = getSafe(() => viewModel.countryChoice.value, defaultCountryChoice.value);
  const defaultRequiredFields: FieldDescription[] =
    safeCountryValue === "US" ? [{ key: "postalCode" }] : [];

  const locale = viewModel.localeChoice.value;

  const [requiredFields, setRequiredFields] =
    React.useState<FieldDescription[]>(defaultRequiredFields);

  if (
    !fieldValidationErrors.phoneNumber &&
    verificationResponse.errorIds &&
    verificationResponse.errorIds.includes("invalidPhoneNumber")
  ) {
    verificationService.updateFieldValidationErrors({
      ...fieldValidationErrors,
      phoneNumber: "invalidPhoneNumber",
    });
  }

  const updateAgeViewModel = (key: keyof AgePersonalInfoViewModel, value: any) => {
    const nextState: AgePersonalInfoViewModel = produceDraftViewModel(viewModel, key, value);
    verificationService.updateViewModel(nextState);
  };

  const updateViewModelWithRequiredFields = (
    newRequiredFields: FieldDescription[],
    conditionalRequiredFieldKeys: (keyof AgePersonalInfoViewModel)[],
  ) => {
    const nextState: AgePersonalInfoViewModel = produceDraftViewModelWithRequiredFields(
      viewModel,
      newRequiredFields,
      conditionalRequiredFieldKeys,
    );
    verificationService.updateViewModel(nextState);
  };

  const isFieldRequired = (key: keyof AgePersonalInfoViewModel) =>
    requiredFields.some((field) => field.key === key);

  React.useEffect(() => {
    (async () => {
      try {
        const collectFieldsResponse: CollectFieldsResponse = await getFieldsToCollect(
          verificationResponse.verificationId,
          verificationResponse.currentStep,
          viewModel,
        );
        const newRequiredFields = getSafe(() => collectFieldsResponse.fieldsToCollect.required, []);
        updateViewModelWithRequiredFields(newRequiredFields, ["postalCode", "city", "address1"]);
        setRequiredFields(newRequiredFields);
      } catch (error) {
        logger.error(
          `Failed to determine fields to collect: ${error}`,
          verificationResponse.currentStep,
        );
      }
    })();
  }, [viewModel.country]);

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
    >
      <PersonalInfoHeader verificationService={verificationService} classNameIdentifier="age" />

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateAgeViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateAgeViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>
      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateAgeViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />
      {isFieldRequired("city") && (
        <CityComponent
          value={viewModel.city}
          isRequired
          isErrored={!!fieldValidationErrors.city}
          errorId={fieldValidationErrors.city}
          onChange={(value) => {
            updateAgeViewModel("city", value);
            updateFieldValidationErrorsByFieldId("city", value, verificationService);
          }}
        />
      )}
      {isFieldRequired("address1") && (
        <AddressComponent
          value={viewModel.address1}
          isRequired
          isErrored={!!fieldValidationErrors.address1}
          errorId={fieldValidationErrors.address1}
          onChange={(value) => {
            updateAgeViewModel("address1", value);
            updateFieldValidationErrorsByFieldId("address1", value, verificationService);
          }}
        />
      )}
      {isFieldRequired("postalCode") && (
        <PostalCode
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updateAgeViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      )}
      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        onChange={(newValue) => {
          updateAgeViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />

      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateAgeViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateAgeViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <PersonalInfoStepSubmitButton
        step={VerificationStepsEnum.collectAgePersonalInfo}
        verificationService={verificationService}
        viewModel={viewModel}
        failedInstantMatch={failedInstantMatch}
        buttonText={
          <FormattedHTMLMessage id="verifyAndContinue" defaultMessage="Verify and continue" />
        }
      />
    </PersonalInfoWrapper>
  );
};

export const StepAgePersonalInfoComponent = injectIntl(StepAgePersonalInfo);
