import { InjectedIntl } from "react-intl";
import {
  Country,
  EstimatedReviewTime,
  FaqUrl,
  Locale,
  LowIncomePersonalInfoViewModel,
  MarketConsentOptions,
  MaxReviewTime,
  MetadataConfig,
  Options,
  Organization,
  ProgramTheme,
  ProgramThemeMessages,
  Segment,
  State,
  VerificationServiceValues,
  ViewModel,
  WithOrganization,
} from "../types/types";
import { logger } from "../utils/logger/logger";
import { getOptions } from "../../options/options";
import { alphabetically, arrayUnique } from "../utils/arrays";
import { deepClone, getSafe } from "../utils/objects";
import { DEFAULT_PRIVACY_POLICY_HOST, SHEERID } from "../../constants";
import { StateEnum, isCollectStep } from "../types/runtimeTypes";

export const getCompanyName = (programTheme: ProgramTheme): string => {
  let companyName = "{Company}"; // Show literal string "{Company}" so implementor is prompted to fix by adding in my.sheerid.com
  try {
    companyName = programTheme.intl.messages.companyName;
    if (!(typeof companyName === "string" && companyName.length > 0)) {
      throw new Error("Invalid companyName");
    }
  } catch (e) {
    // Property access or invalid company name error
    logger.warn("companyName not found. Please sign in to my.sheerid.com to set.");
  }

  return companyName;
};

const defaultMarketConsent: MarketConsentOptions = {
  enabled: false,
  required: false,
  message: "",
};

const defaultMetadata: MetadataConfig = Object.freeze({
  enabled: false,
  keys: [],
  requiredKeys: [],
});

export const getMetadataConfig = (programTheme: ProgramTheme): MetadataConfig => {
  let customMetadata: MetadataConfig = deepClone(defaultMetadata);

  try {
    if (getSafe<boolean>(() => programTheme.config.customMetadata.enabled)) {
      customMetadata = { ...customMetadata, ...programTheme.config.customMetadata };
    }
  } catch (e) {
    logger.warn("No custom metadata provided by the server.");
  }
  if (getOptions().customMetadata) {
    customMetadata = { ...customMetadata, ...getOptions().customMetadata };
  }

  return customMetadata;
};

export const getMarketConsent = (programTheme: ProgramTheme): MarketConsentOptions => {
  let marketConsent: MarketConsentOptions = { ...defaultMarketConsent };
  try {
    if (programTheme.config.marketConsent) {
      marketConsent = programTheme.config.marketConsent;
    }
  } catch (e) {
    // market consent is not coming for this program from server. Default will be used
    logger.warn("Server did not provided a market consent config. Default will be used");
  }
  if (getOptions().marketConsent) {
    marketConsent = getOptions().marketConsent;
  }
  return marketConsent;
};

export const getThemeMessages = (programTheme: ProgramTheme): ProgramThemeMessages => {
  let themeMessages: ProgramThemeMessages;
  try {
    themeMessages = programTheme.intl.messages;
  } catch (e) {
    logger.error("Unable to get theme messages");
  }
  return themeMessages;
};

export const getCustomCss = (
  programTheme: ProgramTheme,
): ProgramTheme["themeChoices"]["customCss"] =>
  getOptions().customCss || programTheme?.themeChoices?.customCss;

export const getLogoUrl = (programTheme: ProgramTheme): ProgramTheme["themeChoices"]["logoUrl"] => {
  const options = getOptions();

  return options.hasOwnProperty("logoUrl") ? options.logoUrl : programTheme?.themeChoices?.logoUrl;
};

export const getPrivacyPolicyUrl = (
  programTheme: ProgramTheme,
): ProgramTheme["privacyPolicyUrl"] => {
  const options = getOptions();

  return options.privacyPolicyUrl || programTheme.privacyPolicyUrl;
};

export const getPrivacyPolicyCompanyName = (
  programTheme: ProgramTheme,
  intl: InjectedIntl,
): string => {
  let privacyPolicyHostName = "";
  try {
    privacyPolicyHostName = new URL(getPrivacyPolicyUrl(programTheme)).hostname;
  } catch (_) {
    logger.warn("Invalid Privacy Policy URL ", privacyPolicyHostName);
  }
  if (privacyPolicyHostName === DEFAULT_PRIVACY_POLICY_HOST) {
    return SHEERID;
  }
  return intl.formatHTMLMessage({
    id: "companyName",
    defaultMessage: "{companyName}",
  });
};

/**
 * @description Return locales configured in MySheerID for this program, or specified in Javascript options.
 */
export const getAvailableLocales = (programTheme: ProgramTheme): Locale[] => {
  let configuredLocales: Locale[] = ["en-US"];
  let themeLocales: Locale[] = [];
  try {
    themeLocales = programTheme.config.locales;
  } catch (e) {
    logger.warn("Unable to get configured locales from programTheme");
  }
  const optionsLocales: Locale[] = getLocalesFromOptions(getOptions());
  configuredLocales = [...themeLocales, ...optionsLocales];

  return arrayUnique(configuredLocales);
};

/**
 * @private
 */
export const getLocalesFromOptions = (options: Options): Locale[] => {
  if (options && options.messagesWithLocale) {
    return Object.keys(options.messagesWithLocale) as Locale[];
  }
  return [];
};

/**
 * @description Safely return the countries that were chosen/configured in MySheerID for this program.
 * Otherwise, return US as default.
 */
export const getConfiguredCountries = (programTheme: ProgramTheme): Country[] => {
  try {
    if (programTheme && programTheme.config && programTheme.config.countries) {
      return programTheme.config.countries;
    }

    // Old location for this property, may be returned from the server
    // @ts-ignore
    if (programTheme.intl.messages.step.personalInfo.countries) {
      const availableCountries: Country[] = [];

      // @ts-ignore
      // eslint-disable-next-line guard-for-in
      for (const countryCode in programTheme.intl.messages.step.personalInfo.countries) {
        availableCountries.push(countryCode as Country);
      }
      return availableCountries;
    }
  } catch (e) {
    logger.warn("Unable to get configured countries");
  }
  return ["US"];
};

/**
 * @description Safely return the states that were chosen/configured in MySheerID for this program.
 */
export const getConfiguredStates = (programTheme: ProgramTheme): State[] => {
  try {
    if (
      programTheme &&
      programTheme.config &&
      programTheme.config.states &&
      programTheme.config.states.length > 0
    ) {
      return programTheme.config.states;
    }
  } catch (e) {
    logger.warn("Unable to get configured states");
  }
  return Object.keys(StateEnum) as State[];
};

export const getEstimatedReviewTime = (programTheme: ProgramTheme): EstimatedReviewTime => {
  let ewt: EstimatedReviewTime;
  try {
    // Added in API ver 2.5.x, may not be present for all
    ewt = programTheme.config.estimatedReviewTime;
  } catch (e) {
    ewt = "A_FEW_HOURS";
  }
  return ewt;
};

export const getMaxReviewTime = (programTheme: ProgramTheme): MaxReviewTime => {
  let mrt: MaxReviewTime;
  try {
    // Added in API ver 2.5.x, may not be present for all
    mrt = programTheme.config.maxReviewTime;
  } catch (e) {
    mrt = "2_HRS";
  }
  return mrt;
};

const options = getOptions();
const getFaqUrl = (): string => {
  const keyName: keyof Options = "urlFaq";
  if (!options.hasOwnProperty(keyName)) {
    logger.error(`Can't find an option for '${keyName}'`, "Option not found");
  }

  return options[keyName];
};

export const getFaqLink = (
  programTheme: ProgramTheme,
  segment: Segment,
  programId: VerificationServiceValues["programId"],
  locale: Locale,
  verificationResponse?: VerificationServiceValues["verificationResponse"],
): FaqUrl => {
  let faqInTheme: FaqUrl;
  try {
    faqInTheme = programTheme.config.customFaqLink;
  } catch (e) {
    faqInTheme = "";
  }

  // HC-361: Not including verification ids on collect info steps as those are likely to still be placeholder ids.
  const searchParams =
    verificationResponse && !isCollectStep(verificationResponse.currentStep)
      ? new URLSearchParams({ verificationId: verificationResponse.verificationId })
      : undefined;
  const baseFaqUrl = programId
    ? `${getFaqUrl()}/${locale}/${programId}/help-center`
    : `${getFaqUrl()}/${locale}/help-center`;
  const faqUrl = searchParams ? `${baseFaqUrl}?${searchParams}` : baseFaqUrl;

  return !faqInTheme || faqInTheme === "" ? faqUrl : faqInTheme;
};

export const getOrgSearchCountryTags = (programTheme, country: Country): string => {
  let searchTags = "";
  try {
    searchTags = programTheme.config?.orgSearchCountryTags[country]?.join(",") || "";
  } catch (e) {
    logger.error(e);
  }
  return searchTags;
};

// TODO STOP PROP DRILLING use this instead of itemToString being passed so deep
export const orgToString = (item: Organization): string => (item ? item.name : "");

function viewModelWithOrg(candidate): candidate is WithOrganization {
  return Object.prototype.hasOwnProperty.call(candidate, "organization");
}
function isLowIncomeVM(candidate): candidate is LowIncomePersonalInfoViewModel {
  return Object.prototype.hasOwnProperty.call(candidate, "statuses");
}

export const getOrgSearchAffiliationOverrides = (
  searchParams: URLSearchParams,
  viewModel: ViewModel,
  programTheme: ProgramTheme,
): URLSearchParams | undefined => {
  try {
    if (isLowIncomeVM(viewModel)) {
      const { statuses } = viewModel;
      const orgSearchAffiliationOverrides =
        programTheme?.config?.orgSearchAffiliationOverrides[statuses[0]];
      Object.keys(orgSearchAffiliationOverrides)
        .sort(alphabetically) // deterministic for unit tests
        .forEach((key) => {
          searchParams.set(key, orgSearchAffiliationOverrides[key]?.join(","));
        });
      return searchParams;
    }
  } catch (e) {
    logger.error(e);
  }
};

// Get an updated org search URL that considers viewModel values and adjusts QSP accordingly
export const getOrgSearchUrl = (
  viewModel: ViewModel,
  programTheme: ProgramTheme,
): string | undefined => {
  const COUNTRY = "country";
  const NAME = "name";
  const TAGS = "tags";

  try {
    const startingOrgSearchUrl = programTheme?.config?.orgSearchUrl;
    const country = getSafe<Country>(() => viewModel?.countryChoice?.value);

    if (!startingOrgSearchUrl) {
      logger.error("Expected an org search URL");
      return "";
    }

    const orgSearchTags = getOrgSearchCountryTags(programTheme, country);

    const url = new URL(startingOrgSearchUrl);
    const { origin, pathname, search } = url;
    const searchParams = new URLSearchParams(search);

    if (viewModelWithOrg(viewModel)) {
      const name = orgToString(viewModel.organization);
      if (name) {
        searchParams.set(NAME, name);
      }
    }
    if (country) {
      searchParams.set(COUNTRY, country);
    }
    if (orgSearchTags) {
      searchParams.set(TAGS, orgSearchTags);
    }

    const paramsWithOverrides =
      getOrgSearchAffiliationOverrides(searchParams, viewModel, programTheme) || searchParams;

    paramsWithOverrides.sort(); // deterministic for unit tests

    return `${origin}${pathname}?${paramsWithOverrides.toString()}`;
  } catch (e) {
    logger.error(e);
  }
};
