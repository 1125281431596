import React from "react";

import {
  DocumentTypes,
  PublicStepConfig,
  PublicStepTypes,
  SdkHandle,
  SdkResponse,
  SupportedLanguages,
} from "onfido-sdk-ui";
import { whereAlpha2, whereAlpha3 } from "iso-3166-1";
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from "react-intl";
import { logger } from "../../lib/utils/logger/logger";
import {
  ApplicableDocTypesResponse,
  Country,
  FormSelectChoice,
  IDCheckLoopResponse,
  VerificationService,
} from "../../lib/types/types";
import {
  OnfidoDocumentTypes,
  OnfidoReportNames,
  VerificationStepsEnum,
} from "../../lib/types/runtimeTypes";
import { getSafe } from "../../lib/utils/objects";
import { VerificationApiClient } from "../../lib/ServerApi/VerificationApiClient";
import { getSortedIdCheckCountryChoices } from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { DocTypeCardComponent } from "./DocTypeCard/DocTypeCard";
import { FormFieldInputSelect } from "../FormFields/FormFieldInputSelect/FormFieldInputSelect";

export interface IDCheckLoopComponentProps {
  verificationService: VerificationService;
  intl: InjectedIntl;
}

const CONTAINER_ID = "onfido";

const docTypeMap = {
  [OnfidoDocumentTypes.PASSPORT]: {
    title: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.passport.title"
        defaultMessage="Passport"
      />
    ),
    subtitle: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.passport.subtitle"
        defaultMessage="Photo page"
      />
    ),
    imgSrc: "https://assets-resources.sheerid.com/common/images/2018/icons/passport.svg",
  },
  [OnfidoDocumentTypes.DRIVING_LICENCE]: {
    title: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.driverLicense.title"
        defaultMessage="Driver's License"
      />
    ),
    subtitle: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.driverLicense.subtitle"
        defaultMessage="Front and back"
      />
    ),
    imgSrc: "https://assets-resources.sheerid.com/common/images/2018/icons/driver-license.svg",
  },
  [OnfidoDocumentTypes.RESIDENCE_PERMIT]: {
    title: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.residencePermit.title"
        defaultMessage="Residence Permit"
      />
    ),
    subtitle: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.residencePermit.subtitle"
        defaultMessage="Front and back"
      />
    ),
    imgSrc: "https://assets-resources.sheerid.com/common/images/2018/icons/residence-permit.svg",
  },
  [OnfidoDocumentTypes.NATIONAL_IDENTITY_CARD]: {
    title: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.identityCard.title"
        defaultMessage="Identity Card"
      />
    ),
    subtitle: (
      <FormattedHTMLMessage
        id="step.idCheckLoop.docTypes.identityCard.subtitle"
        defaultMessage="Front and back"
      />
    ),
    imgSrc: "https://assets-resources.sheerid.com/common/images/2018/icons/identity-card.svg",
  },
};

const IDCheckLoop = ({ verificationService, intl }: IDCheckLoopComponentProps) => {
  const verificationResponse = verificationService.verificationResponse as IDCheckLoopResponse;
  const initialCountrySelection: FormSelectChoice<Country, string> =
    verificationResponse.country === undefined
      ? undefined
      : {
          value: verificationResponse.country,
          label: intl.formatHTMLMessage({
            id: `countries.${verificationResponse.country}`,
            defaultMessage: verificationResponse.country,
          }),
        };
  const [countrySelection, setCountrySelection] = React.useState(initialCountrySelection);
  const [docTypeSelection, setDocTypeSelection] = React.useState("" as DocumentTypes | "");
  const [docTypeOptions, setDocTypeOptions] = React.useState(
    [] as { documentType: DocumentTypes }[],
  );

  const countryList: Country[] = getSafe(
    () =>
      verificationService.programTheme.config.onfidoIncludedCountries.map(
        (country) => whereAlpha3(country).alpha2 as Country,
      ),
    [],
  );
  const countryChoices: FormSelectChoice<Country, string>[] = getSortedIdCheckCountryChoices(
    countryList,
    intl,
    verificationResponse.locale,
  );

  let onfidoContainer: SdkHandle;

  const handleOnfidoPopstate = async (event) => {
    // Reset the state when the Onfido back arrow is pressed, so we seemlessly transition back to our flow
    if (event.state === null && onfidoContainer !== undefined) {
      await onfidoContainer.safeTearDown();
      setDocTypeSelection("");
    }
  };

  const submitIdCheckLoop = (data: SdkResponse) => {
    logger.info("StepIDCheckLoop submitting ID Check Loop", data);
    verificationService.submitStep(
      VerificationStepsEnum.idCheckLoop,
      verificationService.viewModel,
      verificationService.verificationResponse,
    );
  };

  const initializeOnfido = async (country: string, documentType: DocumentTypes) => {
    const onfidoStepConfig: Array<PublicStepTypes | PublicStepConfig> = getSafe(
      () => verificationService.programTheme.config.onfidoReportNames,
      [OnfidoReportNames.DOCUMENT, OnfidoReportNames.FACIAL_SIMILARITY_MOTION],
    ).map((reportName) => {
      if (reportName === OnfidoReportNames.DOCUMENT) {
        return {
          type: "document",
          options: { forceCrossDevice: true, documentTypes: { [documentType]: { country } } },
        } as PublicStepConfig;
      }
      if (reportName === OnfidoReportNames.FACIAL_SIMILARITY_MOTION) {
        return {
          type: "face",
          options: { requestedVariant: "motion", motionFallbackVariant: "video" },
        } as PublicStepConfig;
      }
      return reportName as PublicStepTypes;
    });

    // Onfido needs to be loaded asynchronously to avoid test failures
    import("onfido-sdk-ui").then((Onfido) => {
      onfidoContainer = Onfido.init({
        containerId: CONTAINER_ID,
        token: verificationResponse.sdkToken,
        onComplete: (data) => {
          if (Object.keys(data).length !== 0) {
            submitIdCheckLoop(data);
          }
        },
        steps: [...onfidoStepConfig, "complete"],
        // Documenation -- https://documentation.onfido.com/sdk/web/#language-selection
        language: verificationService.viewModel.localeChoice.value.replace(
          "-",
          "_",
        ) as SupportedLanguages,
        // TODO: Use LocaleConfig once version supporting 2 character locale codes is confirmed
        // language: {
        //   locale,
        //   phrases: {
        //     "custom.label": "Custom String",
        //   },
        // },
        customUI: {
          fontFamilyTitle: "inherit",
          fontFamilySubtitle: "inherit",
          fontFamilyBody: "inherit",
        },
      });

      window.addEventListener("popstate", handleOnfidoPopstate, { once: true });
    });
  };

  React.useEffect(() => {
    const countryCodeAlpha3 = getSafe(() => whereAlpha2(countrySelection.value).alpha3);
    if (countryCodeAlpha3 !== undefined) {
      VerificationApiClient.getIdCheckDocTypeForCountry(verificationResponse.verificationId, [
        countryCodeAlpha3,
      ]).then((value: ApplicableDocTypesResponse) =>
        setDocTypeOptions(Object.values(value.results)[0]),
      );
    } else {
      setDocTypeOptions([]);
    }
  }, [countrySelection]);

  React.useEffect(() => {
    if (countrySelection !== undefined && docTypeSelection !== "") {
      initializeOnfido(
        getSafe(() => whereAlpha2(countrySelection.value).alpha3),
        docTypeSelection,
      );

      return () => {
        // Clean up Onfido event handlers
        if (onfidoContainer !== undefined) {
          onfidoContainer.safeTearDown();
        }

        window.removeEventListener("popstate", handleOnfidoPopstate);
      };
    }
  }, [countrySelection, docTypeSelection]);

  return (
    <div>
      {countrySelection !== undefined && docTypeSelection !== "" && <div id={CONTAINER_ID} />}
      {docTypeSelection === "" && (
        <div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.idCheckLoop.docTypeSelector.title"
              defaultMessage="Choose your document"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center sid-l-space-btm-md">
            <FormattedHTMLMessage
              id="step.idCheckLoop.docTypeSelector.subtitle"
              defaultMessage="You'll need access to a mobile phone with a camera or computer with a webcam."
            />
          </div>

          <div className="sid-identity-check-doc-types">
            <FormFieldInputSelect
              fieldId="issuingCountry"
              label={
                <FormattedHTMLMessage
                  id="step.idCheckLoop.countrySelector.title"
                  defaultMessage="Issuing Country"
                />
              }
              options={countryChoices}
              value={countrySelection}
              onChange={async (countryChoice: FormSelectChoice<Country, string>) => {
                setCountrySelection(countryChoice);
              }}
              isRequired={false}
            />

            {docTypeOptions.map((docTypeOption) => {
              if (Object.prototype.hasOwnProperty.call(docTypeMap, docTypeOption.documentType)) {
                return (
                  <DocTypeCardComponent
                    icon={
                      <img
                        className="sid-identity-check-doc-type__icon"
                        alt=""
                        src={docTypeMap[docTypeOption.documentType].imgSrc}
                      />
                    }
                    title={docTypeMap[docTypeOption.documentType].title}
                    subtitle={docTypeMap[docTypeOption.documentType].subtitle}
                    setDocType={() => setDocTypeSelection(docTypeOption.documentType)}
                    key={docTypeOption.documentType}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export const IDCheckLoopComponent = injectIntl(IDCheckLoop);
