/**
 * Returns true if the given locale formats dates with day-month-year.
 *
 * this function works by abusing Intl date formatting and using jan 3, 2222
 * which will use unique digits for each date part.
 */
export function isDayFirst(locale: string): boolean {
  const day = 3;
  const date = new Date(2222, 0, day);
  try {
    const formatted = new Intl.DateTimeFormat(locale).format(date);
    // strip zeros in case a date part is zero padded
    return formatted.replace("0", "").startsWith(day.toString());
  } catch (error) {
    return false;
  }
}
