import { postMessageFromIframe } from "../installScript/iframe";

/*
  Trap focus in the lightbox by sending and receiving postMessages, here and from the modal script, while tabbing through the program.
*/
const trapFocus = () => {
  let lastFocusableElement: HTMLElement | null = null;

  document.addEventListener("keydown", (e) => {
    const isTabPressed: boolean = e.key === "Tab";

    if (!isTabPressed) {
      return;
    }
    const focusableElements =
      'button, .sid-link, input, select, textarea, [tabindex]:not([tabindex="-1"]';
    const focusableContent: NodeListOf<HTMLElement> = document.querySelectorAll(focusableElements);
    lastFocusableElement = focusableContent[focusableContent.length - 1];

    if (lastFocusableElement) {
      if (!e.shiftKey && document.activeElement === lastFocusableElement) {
        postMessageFromIframe({ type: "focus", focusOn: "firstElement" });
        e.preventDefault();
      }
    }
  });

  window.addEventListener("message", (event) => {
    const message = event.data;

    if (message.focusOn === "lastElement" && lastFocusableElement) {
      lastFocusableElement.focus();
    }
  });

  // set inital focus to lightbox close button
  postMessageFromIframe({ type: "focus", focusOn: "firstElement" });
};

export const maintainFocusInLightbox = () => {
  window.addEventListener("message", (event) => {
    const message = event.data;

    if (message.isInLightBox === "true") {
      trapFocus();
    }
  });
};
