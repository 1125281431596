import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { VerificationService, Segment } from "../lib/types/types";
import { getSafe } from "../lib/utils/objects";

export interface TryAgainButtonComponentProps {
  verificationService: VerificationService;
}

const TryAgainButtonComponent = ({ verificationService }: TryAgainButtonComponentProps) => {
  const currentSegment = getSafe<Segment>(() => verificationService.verificationResponse.segment);

  const fetchNewVerificationRequest = React.useCallback(() => {
    (async () => {
      const trackingId = undefined;
      const forceNewVerificationRequest = true;
      const { programId } = verificationService;
      await verificationService.resetState();
      verificationService.fetchNewVerificationRequest(
        programId,
        currentSegment,
        undefined,
        trackingId,
        forceNewVerificationRequest,
      );
    })();
  }, [verificationService.programId, currentSegment]);

  return (
    <button
      type="button"
      className="sid-btn sid-btn-light sid-btn__try-again"
      aria-label="submit"
      onClick={fetchNewVerificationRequest}
      onKeyPress={fetchNewVerificationRequest}
      tabIndex={0}
    >
      <FormattedHTMLMessage id="tryAgain" defaultMessage="Try again" />
    </button>
  );
};

export { TryAgainButtonComponent };
