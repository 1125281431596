import React, { lazy } from "react";

import { VerificationService } from "../../lib/types/types";

import { ExperimentComponent } from "../FeatureFlags/ExperimentComponent/ExperimentComponent";

const Default = lazy(() => import("./DefaultStepDocUploadComponent"));
const Redesign = lazy(() => import("./Redesign/RedesignStepDocUploadComponent"));

interface StepDocUploadProps {
  verificationService: VerificationService;
}

export const StepDocUploadComponent = ({ verificationService }: StepDocUploadProps) => (
  <ExperimentComponent
    flag="doc-upload-redesign"
    segments={["student", "teacher", "military", "firstResponder", "medical"]}
    Default={Default}
    Experiment={Redesign}
    props={{ verificationService }}
  />
);
