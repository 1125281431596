import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";

import { IDCheckLoopResponse, ProgramTheme, VerificationService } from "../../lib/types/types";
import { logger } from "../../lib/utils/logger/logger";
import { FormFooterComponent } from "../FormFooter/FormFooterComponent";
import { IDCheckLoopComponent } from "./IDCheckLoop";
import { StepCardComponent } from "./StepCard/StepCard";
import { getSafe } from "../../lib/utils/objects";

export interface StepIDCheckLoopComponentProps {
  verificationService: VerificationService;
}

const getAndFormatOrderedStepCards = (programTheme: ProgramTheme): React.ReactElement[] => {
  const stepComponents: (() => {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    icon: React.ReactNode;
  })[] = [];

  if (getSafe(() => programTheme.config.onfidoReportNames.includes("document"), false)) {
    stepComponents.push(() => ({
      title: (
        <FormattedHTMLMessage
          id="step.idCheckLoop.steps.idCheck.title"
          defaultMessage="Take a photo of your ID"
        />
      ),
      subtitle: (
        <FormattedHTMLMessage
          id="step.idCheckLoop.steps.idCheck.subtitle"
          defaultMessage="You'll need a valid Driver license, State ID, passport, or residence permit."
        />
      ),
      icon: (
        <img
          className="sid-identity-check-steps__icon"
          alt=""
          src="https://assets-resources.sheerid.com/common/images/2018/icons/take-photo.svg"
        />
      ),
    }));
  }

  if (getSafe(() => programTheme.config.onfidoReportNames.includes("facial_similarity_motion"))) {
    stepComponents.push(() => ({
      title: (
        <FormattedHTMLMessage
          id="step.idCheckLoop.steps.selfie.title"
          defaultMessage="Take a selfie"
        />
      ),
      subtitle: (
        <FormattedHTMLMessage
          id="step.idCheckLoop.steps.selfie.subtitle"
          defaultMessage="We'll compare your selfie to your ID photo."
        />
      ),
      icon: (
        <img
          className="sid-identity-check-steps__icon"
          alt=""
          src="https://assets-resources.sheerid.com/common/images/2018/icons/take-selfie.svg"
        />
      ),
    }));
  }

  if (programTheme.docUploadEnabled) {
    stepComponents.push(() => ({
      title: (
        <FormattedHTMLMessage
          id="step.idCheckLoop.steps.docReview.title"
          defaultMessage="Upload supporting documents"
        />
      ),
      subtitle: (
        <FormattedHTMLMessage
          id="step.idCheckLoop.steps.docReview.subtitle"
          defaultMessage="We'll compare your documents with your ID photo."
        />
      ),
      icon: (
        <img
          className="sid-identity-check-steps__icon"
          alt=""
          src="https://assets-resources.sheerid.com/common/images/2018/icons/upload-document.svg"
        />
      ),
    }));
  }

  return stepComponents.map((stepComponentFunction, i, array) => {
    const stepNumber = i + 1;
    const stepComponent = array.length > 1 && (
      <FormattedHTMLMessage
        id={`step.idCheckLoop.steps.step${stepNumber}`}
        defaultMessage={`Step ${stepNumber}`}
      />
    );

    const { title, subtitle, icon } = stepComponentFunction();
    return (
      <StepCardComponent
        title={title}
        subtitle={subtitle}
        icon={icon}
        step={stepComponent}
        isHighlighted={stepNumber === 1}
        key={stepNumber}
      />
    );
  });
};

const StepIDCheckLoop = ({ verificationService }: StepIDCheckLoopComponentProps) => {
  const verificationResponse = verificationService.verificationResponse as IDCheckLoopResponse;
  const [shouldShowIdCheckLoop, setShouldShowIdCheckLoop] = React.useState(false);

  const initiateIdCheckLoop = () => {
    logger.info("StepIDCheckLoop initiating ID Check Loop");
    verificationService.submitStep(
      VerificationStepsEnum.idCheckLoop,
      verificationService.viewModel,
      verificationService.verificationResponse,
    );
  };

  React.useEffect(() => {
    if (verificationResponse && verificationResponse.sdkToken) {
      setShouldShowIdCheckLoop(true);
    }
  }, [verificationResponse]);

  return (
    <div id="sid-step-id-check-loop" className="sid-l-container">
      {shouldShowIdCheckLoop && (
        <div className="sid-l-space-btm-lg">
          <IDCheckLoopComponent verificationService={verificationService} />
        </div>
      )}

      {!shouldShowIdCheckLoop && (
        <div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.idCheckLoop.title"
              defaultMessage="Let's verify your identity"
              tagName="h1"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center sid-l-space-btm-md">
            <FormattedHTMLMessage
              id="step.idCheckLoop.subtitle"
              defaultMessage="You'll need access to a mobile phone with a camera or computer with a webcam."
            />
          </div>

          <div className="sid-identity-check-steps">
            {getAndFormatOrderedStepCards(verificationService.programTheme)}
          </div>

          <div className="sid-submit sid-l-horz-center sid-l-space-top-md sid-l-space-btm-md">
            <button
              onClick={initiateIdCheckLoop}
              type="button"
              className="sid-btn sid-btn--dark"
              aria-label="submit"
            >
              <FormattedHTMLMessage id="step.idCheckLoop.continue" defaultMessage="Continue" />
            </button>
          </div>

          <FormFooterComponent verificationService={verificationService}>
            <div>
              <p>
                <FormattedMessage
                  id="step.idCheckLoop.terms"
                  defaultMessage='By clicking "Continue", you agree you have read, understand, and accept {scanPolicy}, {privacyPolicyLink}, and {termsOfService}.'
                  values={{
                    scanPolicy: (
                      <a
                        href="https://onfido.com/facial-scan-policy-and-release/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="sid-link"
                      >
                        <FormattedMessage
                          id="step.idCheckLoop.scanPolicy"
                          defaultMessage="Onfido Facial Scan Policy and Release"
                        />
                      </a>
                    ),
                    privacyPolicyLink: (
                      <a
                        href="https://www.onfido.com/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="sid-link"
                      >
                        <FormattedMessage
                          id="step.idCheckLoop.privacyPolicy"
                          defaultMessage="Privacy Policy"
                        />
                      </a>
                    ),
                    termsOfService: (
                      <a
                        href="https://onfido.com/terms-of-service/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="sid-link"
                      >
                        <FormattedMessage
                          id="step.idCheckLoop.termsOfService"
                          defaultMessage="Terms of Service"
                        />
                      </a>
                    ),
                  }}
                />
              </p>

              <p>
                <FormattedMessage
                  id="step.idCheckLoop.disclaimer"
                  defaultMessage="SheerID uses a third party, Onfido, to process our identity check."
                />
              </p>
            </div>
          </FormFooterComponent>
        </div>
      )}
    </div>
  );
};

export const StepIDCheckLoopComponent = StepIDCheckLoop;
