/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from "../../types/types";

export const studentMessages: SegmentSpecificMessages = {
  emailExplanation: "A student email address is preferred",
  schoolName: "School",
  step: {
    personalInfo: {
      title: "Unlock this Student-Only Offer",
      subtitle: "Verify you're a student at a degree-granting school.",
      howDoesVerifyingWorkDetails:
        "To be eligible for this offer, you must currently be enrolled in a degree or certificate-granting, accredited school of education." +
        "<br><br>" +
        "{companyName} uses SheerID, a trusted partner, to verify that you are a current student. SheerID only collects information that assists in verifying your eligibility. Additional information or documentation may be requested.",
      tryAgain: {
        title: "Check Your Name & Birthdate",
        subtitle:
          "Your full name and birthdate must match what is on your official school records.",
      },
      verifyingTitle: "Verifying your student status",
    },
    docUpload: {
      title: "We need your ID & class schedule",
      subtitle:
        "Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.",
      howDoesVerifyingWorkDetails:
        "Document review is handled by SheerID, a trusted partner who specializes in verifying students." +
        "<br><br>" +
        "Documents are used only for verifying your student status is current and will be permanently deleted after review is finalized.",
      uploadInstructions: "Upload your school-issued document that shows",
    },
    success: {
      title: "You've been verified",
      subtitle: "Here is your personal coupon code. It is unique to you and can only be used once.",
      redirectButtonText: "Use code now",
    },
    emailLoop: {
      errors: {
        invalidEmail:
          "This email address is not valid for instant verification. Please use an email address provided by your academic institution or upload documentation below.",
      },
      collectOrganizationEmail: {
        title: "Verify using your academic email address",
        subtitle:
          "Enter the email address provided by your academic institution and we'll send you a verification link",
        email: { label: "Academic email address" },
        alternative: "Unable to access an academic email address?",
      },
    },
    sso: {
      title: "Verify using academic institution credentials",
      subtitle:
        "Your academic institution's portal will open in a new window for you to verify using your login information",
      alternative: "You can also verify by uploading proof of your enrollment",
      login: "Sign into my academic institution",
      cancel: "Upload Proof of Enrollment",
    },
  },
};
