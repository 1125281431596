import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { VerificationService, SuccessResponse, ConsumerInfoState } from "../../lib/types/types";

import { LogoComponent } from "../LogoComponent/LogoComponent";
import { CopyToClipboard } from "../CopyToClipboard/CopyToClipboardComponent";
import { getBarcodeUrl } from "../../lib/ServerApi/ApiUrls";
import { RewardDisplayEnum } from "../../lib/types/runtimeTypes";
import { getLocaleSafely } from "../../lib/intl/intl";
import { getRememberMeRedirectDestination } from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { getQueryOrOption } from "../../lib/ServerApi/TestingRouteOverrides";
import { QUERY_STRING_REMEMBER_ME_OVERRIDE } from "../../constants";

interface StepSuccessProps {
  verificationService: VerificationService;
}

const redirect = (redirectDestination: string, target: string = "_top") => {
  const win = window.open(redirectDestination, target);
  win.focus();
};

const StepSuccess = ({ verificationService }: StepSuccessProps) => {
  const { verificationResponse, programId, viewModel } = verificationService;
  const { verificationId, redirectUrl } = verificationResponse as SuccessResponse;
  const { config } = verificationService.programTheme;

  const showRedirect = Boolean(redirectUrl);
  const rewardCode = (verificationResponse as SuccessResponse).rewardCode || "${rewardCode}"; // eslint-disable-line no-template-curly-in-string
  const noCodeType = config.offerType === "noCode";

  const { rewardDisplay } = config;
  const showReward = !noCodeType && !!rewardDisplay.length;

  const mockConsumerInfoState = getQueryOrOption(
    QUERY_STRING_REMEMBER_ME_OVERRIDE,
  ) as ConsumerInfoState;
  const consumerInfoState: ConsumerInfoState =
    mockConsumerInfoState || (verificationResponse as SuccessResponse).consumerInfoState;
  const showRememberMeFooter =
    consumerInfoState === "consumerNotFound" ||
    consumerInfoState === "consumerFoundNewFieldsAvailable";

  const locale = getLocaleSafely(viewModel, verificationResponse);

  return (
    <div id="sid-step-success" className="sid-success-container sid-l-container">
      <div className="sid-header sid-l-horz-center sid-l-space-top-md">
        <div className="sid-success-logo-container sid-l-horz-center">
          <LogoComponent
            verificationService={verificationService}
            fallbackLogo={
              <img
                className="sid-success-logo-default sid-l-lead-image"
                alt=""
                src="https://assets-resources.sheerid.com/common/images/2018/icons/circle-check.svg"
              />
            }
          />
        </div>
        <div
          className="sid-header__title sid-l-horz-center sid-l-space-top-md"
          role="alert"
          aria-live="assertive"
        >
          <FormattedHTMLMessage
            id="step.success.title"
            defaultMessage="Success! You've been verified."
            tagName="h1"
          />
        </div>
        <div className="sid-header__subtitle sid-l-horz-center">
          <FormattedHTMLMessage
            id="step.success.subtitle"
            defaultMessage={`Here is your personal coupon code.
                        It is unique to you and can only be used once.`}
          />
        </div>
      </div>

      {showReward && (
        <div className="sid-l-horz-center sid-reward-container sid-l-space-top-md">
          {rewardDisplay.map((displayType) => {
            if (displayType !== RewardDisplayEnum.TEXT)
              return (
                <img
                  key={displayType}
                  className="sid-l-space-top-md sid-barcode"
                  alt={rewardCode}
                  src={getBarcodeUrl(verificationId, "rewardCode", displayType)}
                />
              );
            return (
              <div key={displayType}>
                <div className="sid-reward-code sid-l-space-top-md sid-h-jumbo-text sid-l-horz-center">
                  {rewardCode}
                </div>
                <div className="sid-l-space-top-md sid-h-bold-text sid-l-horz-center">
                  <CopyToClipboard
                    textToCopy={rewardCode}
                    notificationText={<FormattedHTMLMessage id="copied" defaultMessage="Copied" />}
                  >
                    <FormattedHTMLMessage
                      id="step.success.copyCouponCode"
                      defaultMessage="Copy Coupon code"
                    />
                  </CopyToClipboard>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div
        className={`sid-footer sid-success-footer sid-l-horz-center ${
          noCodeType ? "sid-l-space-top-md" : "sid-l-space-top-lg"
        }`}
      >
        <FormattedHTMLMessage
          id="step.success.emailNotification"
          defaultMessage="We have emailed you a copy so you can use your code now or later. Look for the email from verify@sheerid.com"
        />
      </div>

      {showRedirect ? (
        <div className="sid-submit sid-success-submit sid-l-space-top-md">
          <button
            type="button"
            onClick={() => redirect(redirectUrl)}
            className="sid-btn sid-success-redirect-btn sid-btn--dark"
          >
            <FormattedHTMLMessage
              id="step.success.redirectButtonText"
              defaultMessage="Use code now"
            />
          </button>
        </div>
      ) : (
        ""
      )}

      <div className="sid-footer sid-success-verification-only-footer sid-l-horz-center sid-l-space-top-lg sid-h-small-text">
        <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
          {(companyName) => (
            <FormattedHTMLMessage
              id="step.success.verificationOnly"
              defaultMessage={`
                                SheerID handles verification only.
                                Now that you've been approved, please direct all questions about the
                                promotion terms to {companyName} customer service.
                            `}
              values={{ companyName }}
            />
          )}
        </FormattedHTMLMessage>
      </div>

      {showRememberMeFooter && (
        <div className="sid-remember-me-footer-container">
          <div className="sid-remember-me-title">
            {consumerInfoState === "consumerNotFound" ? (
              <FormattedHTMLMessage
                id="step.success.rememberMe.consumerNotFound.title"
                defaultMessage="Save your info for 1-click verification"
              />
            ) : (
              <FormattedHTMLMessage
                id="step.success.rememberMe.consumerFound.title"
                defaultMessage="Update Your Profile"
              />
            )}
          </div>
          <div className="sid-remember-me-description">
            {consumerInfoState === "consumerNotFound" ? (
              <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
                {(companyName) => (
                  <FormattedHTMLMessage
                    id="step.success.rememberMe.consumerNotFound.description"
                    defaultMessage="Get faster access to future offers with {companyName} and other brands using SheerID's Remember Me service."
                    values={{ companyName }}
                  />
                )}
              </FormattedHTMLMessage>
            ) : (
              <FormattedHTMLMessage id="companyName" defaultMessage="{Company}">
                {(companyName) => (
                  <FormattedHTMLMessage
                    id="step.success.rememberMe.consumerFound.description"
                    defaultMessage="Save new info for future offers with {companyName} and other brands using SheerID's Remember Me service."
                    values={{ companyName }}
                  />
                )}
              </FormattedHTMLMessage>
            )}
          </div>
          <button
            type="button"
            onClick={() =>
              redirect(
                getRememberMeRedirectDestination(locale, programId, verificationId),
                "_blank",
              )
            }
            className="sid-btn sid-remember-me-redirect-btn"
          >
            {consumerInfoState === "consumerNotFound" ? (
              <FormattedHTMLMessage
                id="step.success.rememberMe.consumerNotFound.button"
                defaultMessage="Enroll Now"
              />
            ) : (
              <FormattedHTMLMessage
                id="step.success.rememberMe.consumerFound.button"
                defaultMessage="Save to Remember Me"
              />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export const StepSuccessComponent = StepSuccess;
