import { assertValidFunction } from "../types/assertions";
import { Options, ViewModel } from "../types/types";

// Create IE + others compatible event handler
const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

/* =========== From an install page =========== */

export const listenToSheerIdFrame = (callback: Function) => {
  assertValidFunction(callback);
  const receiveMessage = (event) => {
    callback(event);
  };
  window[eventMethod](messageEvent, receiveMessage, false);
};

/* =========== From within a MySheerID iFrame =========== */

export const speakToOuterFrame = (message: object) => {
  if (
    window.parent &&
    window.parent.postMessage &&
    typeof window.parent.postMessage === "function"
  ) {
    window.parent.postMessage(message, "*");
  }
};

export type InstallSetViewModelPageEvent = {
  action: "setViewModel";
  viewModel: Partial<ViewModel> | ViewModel;
};

export type InstallSetOptionsPageEvent = {
  action: "setOptions";
  options: Options;
};

export type InstallPageEvent = InstallSetViewModelPageEvent | InstallSetOptionsPageEvent;

export const listenToInstallPage = (callback: (event: MessageEvent) => void) => {
  assertValidFunction(callback);
  const receiveMessage = (event) => {
    callback(event);
  };

  window[eventMethod](messageEvent, receiveMessage, false);
};
