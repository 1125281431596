import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { DEFAULT_LOCALE } from "../../../constants";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { DateComponent } from "../../DateComponent/DateComponent";

type Props = FormFieldComponentProps & { locale?: string };

export const ActiveDutyStartDateComponent = ({
  onChange,
  isErrored,
  errorId = "invalidActiveDutyStartDate",
  value = "2000-01-01",
  locale = DEFAULT_LOCALE,
}: Props) => (
  <DateComponent
    value={value}
    label={
      <FormattedHTMLMessage id="activeDutyStartDate" defaultMessage="Active Duty Start Date" />
    }
    explanation={
      <FormattedHTMLMessage
        id="verificationPurposesOnly"
        defaultMessage="Used for verification purposes only"
      />
    }
    onChange={onChange}
    fieldName="activeDutyStartDate"
    fieldId="sid-active-duty-start-date"
    locale={locale}
    isErrored={isErrored}
    errorMsg={
      isErrored && (
        <FormattedHTMLMessage id={`errorId.${errorId}`} defaultMessage="Invalid start date" />
      )
    }
  />
);
