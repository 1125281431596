import { Dispatch, Store } from "redux";
import { InjectedIntl } from "react-intl";
import { DocumentTypes } from "onfido-sdk-ui";
import { Locales, RewardDisplayEnum } from "./runtimeTypes";
import { FormFieldConfigType } from "../../components/FormFields/FormFieldCustom/FormFieldCustom";

declare global {
  interface String {
    insertAt: any;
  }
}

export interface NavigatorBeta extends Navigator {
  globalPrivacyControl: boolean;
}

/**
 * SheerID Javascript Library types and interfaces
 */

/**
 *  The interface exposed on the window object
 */
export interface SheerIdJsApi {
  getMetadata(): Metadata;
  setMetadata(metadata: Metadata): void;
  resetMetadata(): void;
  setOptions(options: Options): void;
  resetOptions(): void;
  // The following means the property BirthdateField must be the class definition
  // of BirthdateField (rather than an instance of that class):
  BirthdateField: new (element: HTMLElement) => DiscreteField;
  StudentTypeaheadField: new (element: HTMLElement) => DiscreteField;
  VerificationForm: new (element: HTMLElement, programId: DatabaseId) => void;
  addHook(hook: Hook): RegisteredHooks;
  allMockedResponses: VerificationStepMap<AllResponseTypes>;
  refreshStore(): void;
  hooks: Hook[]; // Library users can declare an array of hook objects before the library (script) is loaded & initialized
  conversion: Conversion;
  setViewModel(viewModel: ViewModel | {}): void;
  resetViewModel(): void;
  overrideComponent: (
    componentName: OverrideableComponentName,
    newComponent: StepComponent,
  ) => void;
  resetOverriddenComponents: () => void;
  loadInModal(url: VerificationUrl, userConfig: IframeUserConfiguration): void;
  loadInlineIframe(
    containerElement: HTMLElement,
    url: VerificationUrl,
    userConfig: IframeUserConfiguration,
  ): void;
  postVerificationSizeUpdates(options: PostMessagesOptions): void;
  getMessages(
    locale: Locale,
    programThemeMessages?: ProgramThemeMessages,
    segment?: Segment,
  ): Promise<StringMap>;
  collectDeviceProfile(verificationId: string, programId: string): void;
  resetStore: () => void;
  setProgramTheme(theme: Partial<ProgramTheme>): void;
}

export interface FormFieldConfig {
  fieldId: string; // should be camelCase. Will be used as the key in metadata where the value is stored. Also used to build localization message keys
  fieldType: typeof FormFieldConfigType[keyof typeof FormFieldConfigType]; // Currently "text"|"checkbox"|"select"|"radio"|"date"
  validate: (value: string | boolean) => ErrorId | ExtendedErrorId; // If value is invalid, return an error id or string so the error msg can be localized and shown
  showPlaceholderAndHideLabel?: boolean;
  required?: boolean;
  options?: string[]; // the options will be converted to a FormSelectChoice[] when passed into the formSelectComponent
  // each FormSelectChoice value will be each option and the labels will be prefixed with the fieldId. Example -> {config.fieldId}.{option}
}

export interface RequestOrganizationApi {
  RequestOrganizationForm: new (element: HTMLElement, programId: DatabaseId) => void;
  setOptions(options: Options): void;
}

export interface UtilsApi {
  determineDevice: (verificationId: DatabaseId, programId: DatabaseId) => void;
  setOptions(options: Options): void;
}

// #region Private ---------------------------------------------------------------------
/**
 * @description Require only properties of type T
 * @private
 * Example:
    type A = { foo: string }

    function bar(newObject: PropertiesOf<A>) {...}

    bar({ foo: 'baz' }) // valid
    bar({ otherProp: 'stuff' }) // invalid
 */
export type PropertiesOf<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>>;
  }[Keys];

/**
 * @description Recursively applies Partial to a type
 * @private
 */
export type DeepPartial<Thing> = Thing extends Function
  ? Thing
  : Thing extends Array<infer InferredArrayMember>
  ? DeepPartialArray<InferredArrayMember>
  : Thing extends object
  ? DeepPartialObject<Thing>
  : Thing | undefined;

interface DeepPartialArray<Thing> extends Array<DeepPartial<Thing>> {}

/**
 *  @description creates a keypath union type of all nested keys under an object.
 *  @private
 */
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

type DeepPartialObject<Thing> = {
  [Key in keyof Thing]?: DeepPartial<Thing[Key]>;
};

/**
 * @private
 */
export type StringMap = {
  [a: string]: string;
};
// #endregion Private ------------------------------------------------------------------

// #region GeneralPurpose --------------------------------------------------------------
/**
 * @example 5bbd127d9781852f68e14ddc
 * @template 24 digit hexadecimal
 */
export type DatabaseId = string;

/**
 * @example { 'someKey': 'someValue' }
 */
export type Metadata = { [key: string]: string | boolean };

// #endregion GeneralPurpose -----------------------------------------------------------

// #region Options ---------------------------------------------------------------------
/**
 * @description Configurable options that can be passed when initializing this library.
 */
export type Options = {
  restApi?: RestApiOptions;
  logLevel?: LogLevel;
  mockStep?: MockStep;
  mockSegment?: Segment;
  mockSubSegment?: SubSegment;
  mockErrorId?: ErrorId;
  mockRewardCode?: string;
  mockRedirectUrl?: string;
  mockConsumerInfoState?: ConsumerInfoState;
  mockPreviousStep?: VerificationStep;
  mockDocSelected?: string;
  installPageUrl?: string;
  mockResponse?: VerificationResponse;
  // Whether to fetch the program theme or not. False to avoid fetching the theme, for less network overhead.
  // Supply your own language, or use built-in defaults.
  doFetchTheme?: boolean;
  locale?: Locale;
  messages?: object;
  messagesWithLocale?: object;
  urlFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlStudentFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlSeniorFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlAgeFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlMilitaryFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlTeacherFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlMemberFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlFirstResponderFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlMedicalFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlEmploymentFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlIdentityFaq?: string;
  /**
   * @deprecated since version 1.63
   */
  urlLicensedProfessionalFaq?: string;
  urlLowIncomeFaq?: string;
  urlAddSchoolFaq?: string;
  urlAddSchoolForm?: string;
  customCss?: ProgramTheme["customCss"];
  logoUrl?: ProgramTheme["logoUrl"];
  privacyPolicyUrl?: ProgramTheme["privacyPolicyUrl"];

  cookies?: CookieOptions;
  useFingerprinting?: boolean;
  marketConsent?: MarketConsentOptions;
  customMetadata?: MetadataConfig;
  verificationId?: string;
  minimumOrganizationSearchLength?: number;

  httpRequestTimeout?: number;

  hideTestModeFlag?: boolean;

  hideMilitaryFamilyAcceptableUploads?: boolean;
  customFormFields?: FormFieldConfig[];

  // adding as part of HC-202 Launch Darkly experimentation trial
  _launchDarklyUserTargeting?: boolean;

  /**
   * Supports backwards compatibility with consumers who render the a verification form directly on their page, preventing the landing page from rendering within their container.
   * This option may be removed in v2.
   */
  renderAs?: "default" | "landingPage";
};

export type RestApiOptions = {
  serviceUrl: string;
  resources: RestResources;
};

/**
 * @description Control how cookies behave
 * @field `expires` Number of days for cookie to expire in. Use 0 to expire in same browser session.
 * @field `secure` Whether the cookie is set securely.
 */
export type CookieOptions = {
  expires: number;
  secure: boolean;
  enabled: boolean;
  path?: string;
  domain?: string;
  sameSite?: "lax" | "strict" | "none";
};

export type MarketConsentOptions = {
  enabled: boolean;
  required: boolean;
  message: string;
};

export type MetadataConfig = {
  enabled: boolean;
  keys: string[];
  requiredKeys?: string[];
};

export type RestResources = {
  verification: string;
  program: ProgramResources;
  conversion: ConversionResources;
};

export type ProgramResources = {
  base: string;
  theme: string;
  organization: string;
};

/**
 * @description Configuration for a verification being displayed on an iframe.
 */
export type IframeUserConfiguration = {
  closeButtonText?: string;
  mobileRedirect?: boolean;
  mobileThreshold?: string | number;
  stopPropagation?: boolean;
};

export type VerificationFormLayout = "fullRight" | "center" | "splitRight";

/**
 * @description URL of a verification to be displayed.
 */
export type VerificationUrl = string;

/**
 * @description Options for the message events send by a verification loaded on an iframe.
 * These event are used to resize a modal containing a verification.
 */
export type PostMessagesOptions = {
  origin: VerificationUrl;
  interval: number;
};

export type PostMessageAction =
  | {
      type: "updateHeight";
      action?: "updateHeight"; // @deprecated - need to support older versions of the jslib
      height: number;
    }
  | {
      type: "focus";
      action?: "focus"; // @deprecated - need to support older versions of the jslib
      focusOn: "firstElement" | "lastElement";
    };

export type PostMessageData = {
  verificationIframeUid: string;
  action: PostMessageAction;
};
/**
 * @description URLs related to conversion endpoints.
 */
export type ConversionResources = {
  base: string;
};

export type LogLevel = "info" | "log" | "warn" | "error";
// #endregion Options ------------------------------------------------------------------

// #region FormFields ------------------------------------------------------------------
/**
 * @description Interface for individual field instances, such as BirthdateField
 * @example new sheerid.BirthdateField().setValue('2000-01-01');
 */
export interface DiscreteField {
  getValue(): any;
  setValue(value: any): void;
  onChange(callback: Function): void; // TODO callback should be of a type that receives value, isErrored, etc
  setIsErrored(value: boolean): void;
  render(): void;
}

/**
 * @todo document constraints here
 */
export type Email = string;

/**
 * @example (111) 222-3333 | 1112223333 | 111-222-3333
 * TODO - Update this when PhoneNumberValidator regex changes.
 */
export type PhoneNumber = string;

/**
 * @example 2013-03-06
 * @template ISO-8601 'YYYY-MM-DD'
 */
export type BirthDate = string;

/**
 * @example 1234567 | A01205257
 */
export type MemberId = string;

/**
 * @example 2013-03-06
 * @template ISO-8601 'YYYY-MM-DD'
 * The `day` portion of this date is not editable or displayed to the user
 */
export type DischargeDate = string;

/**
 * @example 2013-03-06
 * @template ISO-8601 'YYYY-MM-DD'
 */
export type ActiveDutyStartDate = string;

/**
 * @description TODO
 */
export const MILITARY_STATUS = [
  "ACTIVE_DUTY",
  "VETERAN",
  "RESERVIST",
  "MILITARY_RETIREE",
  "MILITARY_FAMILY",
  "GOLD_STAR_FAMILY",
] as const;
export type MilitaryStatus = typeof MILITARY_STATUS[number];

/**
 * @description TODO
 */
export const FIRST_RESPONDER_STATUS = [
  "FIREFIGHTER",
  "EMT",
  "POLICE",
  "SEARCH_AND_RESCUE",
] as const;
export type FirstResponderStatus = typeof FIRST_RESPONDER_STATUS[number];

export const MEDICAL_PROFESSIONAL_STATUS = [
  "NURSE",
  "DOCTOR",
  "DENTIST",
  "PHARMACIST",
  "OTHER_HEALTH_WORKER",
] as const;
export type MedicalProfessionalStatus = typeof MEDICAL_PROFESSIONAL_STATUS[number];

export const LICENSED_PROFESSIONAL_STATUS = [
  "LICENSED_COSMETOLOGIST",
  "LICENSED_REAL_ESTATE_AGENT",
  "VETERINARIAN",
  "CHILD_CARE_WORKER",
  "LIBRARIAN",
  "INTERIOR_DESIGNER",
  "ARCHITECT",
  "GENERAL_CONTRACTOR",
] as const;
export type LicensedProfessionalStatus = typeof LICENSED_PROFESSIONAL_STATUS[number];

export const RECENT_MOVER_STATUS = ["HOME_BUYER", "OTHER_MOVER"] as const;
export type RecentMoverStatus = typeof RECENT_MOVER_STATUS[number];

export const LOW_INCOME_STATUS = [
  "SNAP_BENEFITS",
  "OTHER_GOVERNMENT_ASSISTANCE",
  "COMMUNITY_ELIGIBILITY_PROVISION",
] as const;
export type LowIncomeStatus = typeof LOW_INCOME_STATUS[number];

/**
 * @description TODO
 */
export type OrganizationId = number;

/**
 * @description TODO
 */
export type OrganizationName = string;

/**
 * @description All possible fields
 */
export type FieldId =
  | "firstName"
  | "lastName"
  | "memberId"
  | "organization"
  | "birthDate"
  | "email"
  | "phoneNumber"
  | "postalCode"
  | "address1"
  | "city"
  | "state"
  | "dischargeDate"
  | "activeDutyStartDate"
  | "status"
  | "statuses"
  | "docUpload"
  | "country"
  | "smsCode"
  | "socialSecurityNumber"
  | "marketConsentValue"
  | "carrierConsentValue"
  | "driverLicenseNumber"
  | "overrideCode"
  | "ebtCardNumber"
  | "organizationEmail"
  | "authenticationCode";

export type FormValidationOptions = {
  minAge?: number;
  maxAge?: number;
  smsLoopEnabled?: boolean;
  strictMilitaryValidationEnabled?: boolean;
  currentStep?: VerificationStep;
  viewModel?: ViewModel;
};

/**
 * @description TODO
 */
export type ExtendedFieldId = FieldId | string;

/**
 * @description The object that contains all possible field ids for setting & displaying field errors, if any
 */
export type FieldValidationErrors = {
  [P in FieldId]: ErrorId;
};

/**
 * @description TODO
 */
export type ExtendedFieldValidationErrors = StringMap;

/**
 * @description TODO
 */
export type FieldContent =
  | string
  | number
  | File
  | FormSelectChoice<Country, string>
  | Organization
  | boolean;

/**
 * @private
 */
export type Organization = {
  id: OrganizationId;
  name: OrganizationName;
  country?: Country;
  type?: OrganizationType;
  idExtended?: string;
  source?: OrganizationRemoteSource;
};

/**
 * @private
 */
export type OrganizationRemoteSource = "EMPLOYER";

/**
 * @private
 */
export type OrganizationSearchResp = Organization[];

/**
 * @description A model to back many of this library's `<select>` elements.
 * For compatibility, a default for value of `string` or `number` is allowed.
 * Made generic to allow arbitrary types (e.g. `FormSelectChoice<Locale, string>`)
 */
export type FormSelectChoice<v = string | number, l = string> = {
  value: v;
  label: l;
};

export type InputSelectOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => void;

export interface FormFieldComponentProps<T = any> {
  onChange: (value: T) => void;
  value: T;
  intl?: InjectedIntl;
  autoFocus?: boolean;
  isErrored?: boolean;
  errorId?: ErrorId;
  isRequired?: boolean;
  onKeyDown?: Function;
  placeholder?: string;
  label?: JSX.Element;
  verificationService?: VerificationService;
}

// #region Refs ---------------------------------------------------------------------
/**
 * @description Configurable refs that can be defined when rendering FormField components.
 */
export type FieldRef = {
  fieldId: FieldId | ExtendedFieldId;
  ref: HTMLElement;
};

export interface VerificationServiceProps {
  verificationService: VerificationService;
}

export interface InputSelectComponentProps {
  onChange: Function;
  options: FormSelectChoice[];
  value: any;
  isErrored?: boolean;
  isRequired?: boolean;
  onKeyDown?: InputSelectOnKeyDown;
  placeholder?: string;
  label?: string | JSX.Element;
  intl?: InjectedIntl;
  suppressPlaceholder?: boolean;
}
// #endregion FormFields ---------------------------------------------------------------

// #region Errors ----------------------------------------------------------------------
export type ErrorId =
  | "activeDutyStartDateBeforeBirthDate"
  | "apiRateLimitExceeded"
  | "dischargeDateBeforeBirthDate"
  | "docReviewLimitExceeded"
  | "inactiveProgram"
  | "expiredProgram"
  | "expiredEmailLoopToken"
  | "expiredSMSCode"
  | "expiredVerification"
  | "fraudRulesReject"
  | "futureBirthDate"
  | "futureDischargeDate"
  | "futureActiveDutyStartDate"
  | "incorrectVerificationOverrideCodeAttemptLimitExceeded"
  | "internalServerError"
  | "invalidActiveDutyStartDate"
  | "invalidAddress"
  | "invalidApiToken"
  | "invalidBirthDate"
  | "invalidCity"
  | "invalidCountry"
  | "invalidDischargeDate"
  | "invalidDocUploadToken"
  | "invalidEbtCardNumber"
  | "invalidEmail"
  | "invalidFileSizeEmpty"
  | "invalidFileSizeMax"
  | "invalidFirstName"
  | "invalidFirstResponderStatus"
  | "invalidLastName"
  | "invalidMemberId"
  | "invalidMilitaryStatus"
  | "invalidNumberOfFiles"
  | "invalidOptIn"
  | "invalidOrganization"
  | "invalidOverrideCode"
  | "invalidPhoneNumber"
  | "invalidPostalCode"
  | "invalidProgram"
  | "invalidRequest"
  | "invalidSMSCode"
  | "invalidSocialSecurityNumber"
  | "invalidState"
  | "invalidStatus"
  | "invalidStatuses"
  | "invalidDriverLicenseNumber"
  | "invalidStep"
  | "invalidAuthenticationLoopToken"
  | "marketConsentRequired"
  | "maxMetadataLengthExceeded"
  | "maxMetadataValuesExceeded"
  | "maxSMSCodeLimitExceeded"
  | "incorrectSMSCodeAttemptLimitExceeded"
  | "noProgram"
  | "noRemainingRewardCodes"
  | "notApproved"
  | "notFound"
  | "noValidFiles"
  | "noVerification"
  | "outsideAgePerson"
  | "simulatedError"
  | "unauthorizedAccountStatus"
  | "unauthorizedDomain"
  | "underagePerson"
  | "unknownError"
  | "unsupportedDocMimeType"
  | "verificationLimitExceeded"
  | "invalidEmailLoopToken"
  | "missingRequiredMetadata"
  | "invalidAuthenticationLoopToken"
  | "duplicateFile"
  | NetworkErrorId;

export type NetworkErrorId = "failedToFetch" | "requestTimeout";

export type ExtendedErrorId = string | undefined;

export type RejectionReasons =
  | "DOCUMENT_UNREADABLE"
  | "DOCUMENT_PASSWORD_PROTECTED"
  | "DOCUMENT_LIKELY_FRAUD"
  | "DOCUMENT_UNSUPPORTED"
  | "DOCUMENT_HANDWRITTEN"
  | "MISSING_INFORMATION"
  | "MISSING_INFORMATION_PERSON_NAME"
  | "MISSING_INFORMATION_ORGANIZATION_NAME"
  | "DOCUMENT_EXPIRED"
  | "DOCUMENT_OUTDATED"
  | "MISMATCH_PERSON_NAME"
  | "MISMATCH_ORGANIZATION"
  | "MISMATCH_STATUS"
  | "INELIGIBLE_ORGANIZATION"
  | "MISSING_INFORMATION_UNIVERSITY_ENROLLMENT"
  | "INELIGIBLE_PERSON_HIGH_SCHOOL_STUDENT"
  | "MISSING_INFORMATION_AFFILIATION_US_ARMED_FORCES"
  | "MISMATCH_COMPANY_NAME_OR_ADDRESS"
  | "PAYSTUB_OUTDATED_LAST_30_DAYS"
  | "MISSING_INFORMATION_BIRTHDATE"
  | "MISMATCH_BIRTHDATE"
  | "DOCUMENT_OUTDATED_FACULTY"
  | "MISSING_OR_MISMATCH_JOB_TITLE"
  | "ILLEGAL_GOVERNMENT_ID"
  | "OTHER_CONTACT_US";
// #endregion Errors -----------------------------------------------------------------------

// #region Steps -----------------------------------------------------------------------
export type VerificationStep =
  | "collectStudentPersonalInfo"
  | "collectTeacherPersonalInfo"
  | "collectMemberPersonalInfo"
  | "collectMilitaryStatus"
  | "collectActiveMilitaryPersonalInfo"
  | "collectInactiveMilitaryPersonalInfo"
  | "collectSeniorPersonalInfo"
  | "collectAgePersonalInfo"
  | "collectFirstResponderPersonalInfo"
  | "collectMedicalProfessionalPersonalInfo"
  | "collectEmployeePersonalInfo"
  | "collectDriverLicensePersonalInfo"
  | "collectGeneralIdentityPersonalInfo"
  | "collectHybridIdentityPersonalInfo"
  | "collectLicensedProfessionalPersonalInfo"
  | "collectLowIncomePersonalInfo"
  | "collectMoverPersonalInfo"
  | "collectSocialSecurityNumber"
  | "collectIdentifier"
  | "collectPersonalInfo"
  | "cancelSocialSecurityNumber"
  | "docUpload"
  | "pending"
  | "docReviewLimitExceeded"
  | "success"
  | "error"
  | "sso"
  | "smsLoop"
  | "emailLoop"
  | "emailLoopCollectOrganizationEmail"
  | "completeAuthentication"
  | "cancelEmailLoop"
  | "idCheckLoop"
  | "consolation"
  | "override"
  | "cancelDocUpload"
  | "missingRequiredMetadata";

export type VerificationStepMap<T> = {
  [P in VerificationStep]: T;
};

export type MockStep = VerificationStep | "loading" | "collect";

export type OverrideableComponentName =
  | "StepStudentPersonalInfoComponent"
  | "StepSeniorPersonalInfoComponent"
  | "StepAgePersonalInfoComponent"
  | "StepTeacherPersonalInfoComponent"
  | "StepMemberPersonalInfoComponent"
  | "StepCollectMilitaryStatusComponent"
  | "StepActiveMilitaryPersonalInfoComponent"
  | "StepInactiveMilitaryPersonalInfoComponent"
  | "StepFirstResponderPersonalInfoComponent"
  | "StepMedicalProfessionalPersonalInfoComponent"
  | "StepEmploymentPersonalInfoComponent"
  | "StepDriverLicensePersonalInfoComponent"
  | "StepGeneralIdentityPersonalInfoComponent"
  | "StepHybridIdentityPersonalInfoComponent"
  | "StepLicensedProfessionalPersonalInfoComponent"
  | "StepLowIncomePersonalInfoComponent"
  | "StepMoverPersonalInfoComponent"
  | "StepSuccessComponent"
  | "StepConsolationComponent"
  | "StepDocUploadComponent"
  | "StepOverrideComponent"
  | "StepPendingComponent"
  | "StepPendingEmailLoopComponent"
  | "StepErrorComponent"
  | "StepSSOComponent"
  | "StepSMSLoopComponent"
  | "StepEmailLoopComponent"
  | "StepIDCheckLoopComponent"
  | "StepSocialSecurity"
  | "StepCollectIdentifierComponent"
  | "StepCompleteAuthentication"
  | "StepCollectPersonalInfo"
  | "RequestOrganizationSuccessComponent"
  | "RequestOrganizationErrorComponent"
  | "RequestOrganizationSearchComponent";

export type StepComponent = React.FunctionComponent<{ verificationService: VerificationService }>;
// #endregion Steps -----------------------------------------------------------------------

// #region Segments --------------------------------------------------------------------
export type Segment =
  | "student"
  | "military"
  | "teacher"
  | "member"
  | "senior"
  | "age"
  | "firstResponder"
  | "medical"
  | "employment"
  | "identity"
  | "licensedProfessional"
  | "recentMover"
  | "lowIncome";

export type SubSegment =
  | "college"
  | "highSchool"
  | "police"
  | "emt"
  | "fireFighter"
  | "nurse"
  | "doctor"
  | "driverLicense"
  | "generalIdentity"
  | "hybridIdentity"
  | "licensedCosmetologists"
  | "licensedRealState"
  | "homeBuyer"
  | "otherMover"
  | "snapBenefits"
  | SubSegmentMilitary;

export type SubSegmentMilitary =
  | "activeDuty"
  | "reservist"
  | "veteran"
  | "retiree"
  | "militaryFamily"
  | "goldStarFamily";
// #endregion Segments -----------------------------------------------------------------------

// #region Organization Types ----------------------------------------------------------
export type OrganizationType =
  | "UNIVERSITY"
  | "POST_SECONDARY"
  | "MEMBERSHIP"
  | "MILITARY"
  | "FIRST_RESPONDER"
  | "MEDICAL"
  | "NON_PROFIT"
  | "CORPORATE"
  | "K12"
  | "AGE_ID"
  | "HIGH_SCHOOL"
  | "NONE"
  | "LOW_INCOME_PROGRAM";
// #endregion Organization Types -------------------------------------------------------

// #region Requests and View Models ----------------------------------------------------
export type NewVerificationRequest = {
  programId: DatabaseId;
  trackingId?: string;
  installPageUrl?: string;
};

export type AllStatuses =
  | MilitaryStatus
  | MedicalProfessionalStatus
  | FirstResponderStatus
  | RecentMoverStatus
  | LicensedProfessionalStatus
  | LowIncomeStatus
  | RecentMoverStatus;

/**
 * @description The request to submit when verification is on the step "collectStudentPersonalInfo"
 */
export type StudentPersonalInfoRequest = {
  birthDate: BirthDate;
} & WithOrganization &
  WithCoreFields;

export type StudentPersonalInfoViewModel = StudentPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectSeniorPersonalInfo"
 */
export type SeniorPersonalInfoRequest = {
  birthDate: BirthDate;
  postalCode: string;
} & WithCoreFields;

export type SeniorPersonalInfoViewModel = SeniorPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectAgePersonalInfo"
 */
export type AgePersonalInfoRequest = {
  birthDate: BirthDate;
  postalCode: string;
  phoneNumber: PhoneNumber;
  country: Country;
  city?: string;
  address1?: string;
} & WithCoreFields;

export type AgePersonalInfoViewModel = AgePersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectMilitaryStatus"
 */
export type MilitaryStatusRequest = {
  status: MilitaryStatus;
};

/**
 * @description The request to submit when verification is on the step "collectActiveMilitaryPersonalInfo"
 */
export type ActiveMilitaryPersonalInfoRequest = {
  birthDate: BirthDate;
  activeDutyStartDate?: ActiveDutyStartDate;
  country?: Country;
} & WithOrganization &
  WithCoreFields;

export type ActiveMilitaryPersonalInfoViewModel = {
  status: MilitaryStatus;
} & ActiveMilitaryPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectInactiveMilitaryPersonalInfo"
 */
export type InactiveMilitaryPersonalInfoRequest = {
  birthDate: BirthDate;
  dischargeDate: DischargeDate;
  activeDutyStartDate?: ActiveDutyStartDate;
  country?: Country;
} & WithOrganization &
  WithCoreFields;

export type InactiveMilitaryPersonalInfoViewModel = {
  status: MilitaryStatus;
  city?: string;
  state?: State;
  address1?: string;
  postalCode?: string;
} & InactiveMilitaryPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectTeacherPersonalInfo"
 */
export type TeacherPersonalInfoRequest = {
  birthDate?: BirthDate;
  postalCode?: string;
} & WithOrganization &
  WithCoreFields;

export type TeacherPersonalInfoViewModel = TeacherPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectMemberPersonalInfo"
 */
export type MemberPersonalInfoRequest = {
  memberId?: MemberId;
  birthDate?: BirthDate;
  phoneNumber?: PhoneNumber;
  postalCode?: string;
  address1?: string;
  city?: string;
  state?: string;
  country?: Country;
} & WithOrganization &
  WithCoreFields;

export type MemberPersonalInfoViewModel = MemberPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectFirstResponderPersonalInfo"
 * Provide postalCode when organization.id = 0 for better verification results.
 */
export type FirstResponderPersonalInfoRequest = {
  birthDate: BirthDate;
  status: FirstResponderStatus;
  statuses?: FirstResponderStatus[];
  postalCode?: string;
  country?: Country;
} & WithOrganization &
  WithCoreFields;

export type FirstResponderPersonalInfoViewModel = FirstResponderPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type MedicalStatusRequest = {
  status: MedicalProfessionalStatus;
};

/**
 * @description The request to submit when verification is on the step "collectMedicalProfessionalPersonalInfo"
 */
export type MedicalProfessionalPersonalInfoRequest = {
  birthDate: BirthDate;
  postalCode: string;
  status: MedicalProfessionalStatus;
  memberId: string;
  country?: Country;
} & WithOrganization &
  WithCoreFields;

export type MedicalProfessionalPersonalInfoViewModel = MedicalProfessionalPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectEmployeePersonalInfo"
 */
export type EmploymentPersonalInfoRequest = {
  postalCode: string;
  phoneNumber?: PhoneNumber;
  shouldCollectAddress?: Boolean;
  country?: Country;
} & WithAddress &
  WithOrganization &
  WithCoreFields;

export type SMSLoopVerificationRequest = {
  smsCode: string;
  deviceFingerprintHash?: string;
};

export type EmailLoopVerificationRequest = {
  emailToken: string;
  deviceFingerprintHash?: string;
};

export type EmailLoopCollectOrganizationRequest = {
  emailAddress: string;
};

export type IDCheckLoopVerificationRequest = {};

export type EmploymentPersonalInfoViewModel = EmploymentPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectDriverLicensePersonalInfo"
 */
export type DriverLicensePersonalInfoRequest = {
  driverLicenseNumber: string;
  state: string;
} & WithCoreFields;

export type DriverLicensePersonalInfoViewModel = DriverLicensePersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type GeneralIdentityPersonalInfoRequest = {
  birthDate: BirthDate;
  postalCode: string;
} & WithAddress &
  WithCoreFields;

export type GeneralIdentityPersonalInfoViewModel = GeneralIdentityPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type HybridIdentityPersonalInfoRequest = {
  birthDate: BirthDate;
  postalCode: string;
  driverLicenseNumber: string;
} & WithAddress &
  WithCoreFields;

export type HybridIdentityPersonalInfoViewModel = HybridIdentityPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectLicensedProfessionalPersonalInfo"
 */
export type LicensedProfessionalPersonalInfoRequest = {
  birthDate: BirthDate;
  postalCode: string;
  statuses: LicensedProfessionalStatus[];
} & WithCoreFields &
  WithOrganization;

export type LicensedProfessionalPersonalInfoViewModel = LicensedProfessionalPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectMoverPersonalInfo"
 */
export type MoverPersonalInfoRequest = {
  address1: string;
  postalCode: string;
  statuses: RecentMoverStatus[];
} & WithCoreFields;

export type MoverPersonalInfoViewModel = MoverPersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type SocialSecurityRequest = {
  socialSecurityNumber: number;
};

export type SocialSecurityResponse = {
  verificationId: DatabaseId;
  submissionUrl: string;
  cancelUrl: string;
  currentStep: "collectSocialSecurityNumber";
  errorIds?: [ErrorId];
} & WithLocaleAndCountryChoiceAndSegment;

export type SocialSecurityViewModel = {
  socialSecurityNumber: string;
  metadata?: Metadata;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "collectLowIncomePersonalInfo"
 */
export type LowIncomePersonalInfoRequest = {
  birthDate: BirthDate;
  postalCode: string;
  statuses: LowIncomeStatus[];
  ebtCardNumber?: string;
} & WithCoreFields &
  WithAddress &
  Partial<WithOrganization>;

export type LowIncomePersonalInfoViewModel = LowIncomePersonalInfoRequest &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type CollectIdentifierRequest = {
  email: string;
};

export type CollectIdentifierResponse = {
  submissionUrl: string;
} & VerificationResponse;

export type CollectIdentifierViewModel = {
  email: string;
  transactionId: string;
  expirationTimestamp: string;
  remembered: boolean;
  metadata?: Metadata;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type CompleteAuthenticationRequest = {
  authenticationToken: string;
};

export type CompleteAuthenticationResponse = {
  cancelUrl: string;
  submissionUrl: string;
} & VerificationResponse;

export type CompleteAuthenticationViewModel = {
  authenticationCode?: string;
  email?: string;
  didManuallyVerify?: boolean;
  metadata?: Metadata;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type CollectPersonalInfoRequest = {
  driverLicenseNumber?: string;
  memberId?: MemberId;
  postalCode?: string;
  ebtCardNumber?: string;
  statuses?: AllStatuses[];
  birthDate?: BirthDate;
  socialSecurityNumber?: string;
  dischargeDate?: DischargeDate;
  activeDutyStartDate?: ActiveDutyStartDate;
  country?: Country;
} & Partial<WithCoreFields> &
  Partial<WithOrganization> &
  Partial<WithAddress>;

/**
 * Response from REST API indicating that CollectPersonalInfoViewModel is expected to be submitted next.
 */
export type CollectPersonalInfoResponse = {
  submissionUrl: string;
  availableStatuses?: AllStatuses[];
  country?: string;
  currentStep: "collectPersonalInfo";
  segment: Segment;
  subSegment: SubSegment | SubSegmentMilitary;
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type CollectPersonalInfoViewModel = {
  driverLicenseNumber?: string;
  memberId?: MemberId;
  postalCode?: string;
  ebtCardNumber?: string;
  status?: AllStatuses;
  statuses?: AllStatuses[];
  birthDate?: BirthDate;
  socialSecurityNumber?: string;
  dischargeDate?: DischargeDate;
  activeDutyStartDate?: ActiveDutyStartDate;
} & Partial<WithCoreFields> &
  Partial<WithOrganization> &
  Partial<WithAddress> &
  WithLocaleAndCountry &
  WithFieldsToSkipValidation;

/**
 * @description The request to submit when verification is on the step "docUpload"
 */
export type DocUploadRequest = {
  file1: File;
  file2?: File;
  file3?: File;
  metadata?: Metadata;
};

/**
 * @description The request to enter a verification override code when it is on the step "override"
 */
export type OverrideVerificationRequest = {
  overrideCode: string;
  deviceFingerprintHash?: string;
};

// No difference between the ViewModel, and what we send to the REST endpoint at this time.
export type DocUploadViewModel = DocUploadRequest & {
  erroredFileNames?: string[];
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type SSOViewModel = {
  isSSOActive: boolean;
  metadata?: Metadata;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type OverrideViewModel = OverrideVerificationRequest & {
  overrideCode: string;
  deviceFingerprintHash?: string;
  metadata?: Metadata;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type SMSLoopViewModel = {
  smsCode: string;
  phoneNumber: string;
  metadata?: Metadata;
  deviceFingerprintHash?: string;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type EmailLoopViewModel = {
  emailToken: string;
  organizationEmail?: string;
  metadata?: Metadata;
  deviceFingerprintHash?: string;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type EmailLoopCollectOrganizationEmailViewModel = {
  organizationEmail?: string;
  metadata?: Metadata;
  deviceFingerprintHash?: string;
} & WithLocaleAndCountry &
  WithFieldsToSkipValidation;

export type IDCheckLoopViewModel = WithLocaleAndCountry & WithFieldsToSkipValidation;

export type RequestOrganizationViewModel = {
  localeChoice: FormSelectChoice<Locale, string>;
  countryChoice: FormSelectChoice<Country, string>;
  orgDomain: string;
  orgName: string;
  firstName: string;
  lastName: string;
  email: string;
  displayResults: boolean;
  searchByOrgName: boolean;
  completeRequest: boolean;
  eligibleOrgs: Organization[];
  ineligibleOrgs: Organization[];
  orgCountryError: string;
  orgDomainError: string;
  orgNameError: string;
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  isSearching: boolean;
};

export type VerificationRequest =
  | StudentPersonalInfoRequest
  | AgePersonalInfoRequest
  | SeniorPersonalInfoRequest
  | TeacherPersonalInfoRequest
  | MemberPersonalInfoRequest
  | MilitaryStatusRequest
  | ActiveMilitaryPersonalInfoRequest
  | InactiveMilitaryPersonalInfoRequest
  | DocUploadRequest
  | OverrideVerificationRequest
  | FirstResponderPersonalInfoRequest
  | MedicalProfessionalPersonalInfoRequest
  | EmploymentPersonalInfoRequest
  | SocialSecurityRequest
  | SMSLoopVerificationRequest
  | EmailLoopVerificationRequest
  | DriverLicensePersonalInfoRequest
  | GeneralIdentityPersonalInfoRequest
  | HybridIdentityPersonalInfoRequest
  | LicensedProfessionalPersonalInfoRequest
  | LowIncomePersonalInfoRequest
  | MoverPersonalInfoRequest
  | CollectIdentifierRequest
  | CompleteAuthenticationRequest
  | CollectPersonalInfoRequest;

export type ViewModel =
  | StudentPersonalInfoViewModel
  | SeniorPersonalInfoViewModel
  | AgePersonalInfoViewModel
  | TeacherPersonalInfoViewModel
  | MemberPersonalInfoViewModel
  | ActiveMilitaryPersonalInfoViewModel
  | InactiveMilitaryPersonalInfoViewModel
  | DocUploadViewModel
  | SSOViewModel
  | OverrideViewModel
  | FirstResponderPersonalInfoViewModel
  | MedicalProfessionalPersonalInfoViewModel
  | EmploymentPersonalInfoViewModel
  | SocialSecurityViewModel
  | SMSLoopViewModel
  | EmailLoopViewModel
  | EmailLoopCollectOrganizationEmailViewModel
  | DriverLicensePersonalInfoViewModel
  | GeneralIdentityPersonalInfoViewModel
  | HybridIdentityPersonalInfoViewModel
  | LicensedProfessionalPersonalInfoViewModel
  | LowIncomePersonalInfoViewModel
  | MoverPersonalInfoViewModel
  | CollectIdentifierViewModel
  | CompleteAuthenticationViewModel
  | CollectPersonalInfoViewModel;

export type PersonalInfoViewModel =
  | StudentPersonalInfoViewModel
  | SeniorPersonalInfoViewModel
  | AgePersonalInfoViewModel
  | TeacherPersonalInfoViewModel
  | MemberPersonalInfoViewModel
  | ActiveMilitaryPersonalInfoViewModel
  | InactiveMilitaryPersonalInfoViewModel
  | FirstResponderPersonalInfoViewModel
  | MedicalProfessionalPersonalInfoViewModel
  | EmploymentPersonalInfoViewModel
  | DriverLicensePersonalInfoViewModel
  | GeneralIdentityPersonalInfoViewModel
  | HybridIdentityPersonalInfoViewModel
  | LicensedProfessionalPersonalInfoViewModel
  | LowIncomePersonalInfoViewModel
  | MoverPersonalInfoViewModel;

export type WithOrganization = {
  organization: Organization;
};

export type WithAddress = {
  address1: string;
  city: string;
  state: string;
};

export type WithCoreFields = {
  firstName: string;
  lastName: string;
  email: Email;
  phoneNumber?: PhoneNumber;
  metadata?: Metadata;
  deviceFingerprintHash?: string;
  locale?: Locale;
  externalUserId?: string;
};

export type WithFieldsToSkipValidation = {
  fieldsToSkipValidation?: FieldId[];
};

export type WithLocaleAndCountry = {
  // countryChoice.label is no longer used when displaying options
  countryChoice: FormSelectChoice<Country, string>;
  // localeChoice.label is no longer used when displaying options
  localeChoice: FormSelectChoice<Locale, string>;
};

export type AddSchoolRequestViewModel = {
  programId: string;
  firstName: string;
  lastName: string;
  email: string;
  schoolCountry: Country;
  schoolName: string;
  schoolDomain: string;
  trackingId?: string;
};

// #endregion Requests and View Models -----------------------------------------------------------------------

// #region Responses -------------------------------------------------------------------
export type WithLocaleAndCountryChoiceAndSegment = {
  segment: Segment;
  subSegment: SubSegment;
  locale: Locale;
  country?: Country;
};

/**
 * @description Base type for all responses
 */
export type VerificationResponse = {
  verificationId: DatabaseId;
  currentStep: VerificationStep;
  errorIds?: ErrorId[];
  maxAge?: number;
  minAge?: number;
  lastResponse?: VerificationResponse;
} & WithLocaleAndCountryChoiceAndSegment;

/**
 * @description Intersection type for all *PersonalInfo responses
 */
export type PersonalInfoResponse =
  | StudentPersonalInfoResponse
  | SeniorPersonalInfoResponse
  | AgePersonalInfoResponse
  | TeacherPersonalInfoResponse
  | MemberPersonalInfoResponse
  | ActiveMilitaryPersonalInfoResponse
  | InactiveMilitaryPersonalInfoResponse
  | FirstResponderPersonalInfoResponse
  | MedicalProfessionalPersonalInfoResponse
  | EmploymentPersonalInfoResponse
  | DriverLicensePersonalInfoResponse
  | GeneralIdentityPersonalInfoResponse
  | HybridIdentityPersonalInfoResponse
  | LicensedProfessionalPersonalInfoResponse
  | LowIncomePersonalInfoResponse
  | MoverPersonalInfoResponse;

export type AllResponseTypes =
  | PersonalInfoResponse
  | SSOResponse
  | DocUploadResponse
  | SMSLoopResponse
  | EmailLoopResponse
  | IDCheckLoopResponse
  | SuccessResponse
  | ConsolationResponse
  | OverrideResponse
  | ErrorResponse
  | PendingResponse
  | SocialSecurityResponse
  | MilitaryStatusResponse
  | CollectIdentifierResponse
  | CompleteAuthenticationResponse
  | CollectPersonalInfoResponse;

/**
 * @description Response from REST API indicating that StudentPersonalInfoViewModel is expected to be submitted next.
 */
export type StudentPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectStudentPersonalInfo";
  segment: "student";
  subSegment: null;
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

/**
 * @description Response from REST API indicating that SeniorPersonalInfoViewModel is expected to be submitted next.
 */
export type SeniorPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectSeniorPersonalInfo";
  segment: "senior";
  subSegment: null;
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

/**
 * @description Response from REST API indicating that AgePersonalInfoViewModel is expected to be submitted next.
 */
export type AgePersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectAgePersonalInfo";
  segment: "age";
  subSegment: null;
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
  minAge?: number;
  maxAge?: number;
} & VerificationResponse;

/**
 * @description Response from REST API indicating that TeacherPersonalInfoViewModel is expected to be submitted next.
 */
export type TeacherPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectTeacherPersonalInfo";
  segment: "teacher";
  subSegment: null;
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type MemberPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectMemberPersonalInfo";
  segment: "member";
  subSegment: null;
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

/**
 * @description Response from REST API indicating that StudentPersonalInfoViewModel is expected to be submitted next.
 */
export type MilitaryStatusResponse = {
  submissionUrl: string;
  currentStep: "collectMilitaryStatus";
  segment: "military";
  errorIds?: [ErrorId];
  availableStatuses?: [MilitaryStatus];
} & VerificationResponse;

/**
 * @description Response from REST API indicating that StudentPersonalInfoViewModel is expected to be submitted next.
 */
export type ActiveMilitaryPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectActiveMilitaryPersonalInfo";
  segment: "military";
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

/**
 * @description Response from REST API indicating that StudentPersonalInfoViewModel is expected to be submitted next.
 */
export type InactiveMilitaryPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectInactiveMilitaryPersonalInfo";
  segment: "military";
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type FirstResponderPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectFirstResponderPersonalInfo";
  segment: "firstResponder";
  errorIds?: [ErrorId];
  availableStatuses?: [FirstResponderStatus];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type MedicalProfessionalPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectMedicalProfessionalPersonalInfo";
  segment: "medical";
  errorIds?: [ErrorId];
  availableStatuses?: [MedicalProfessionalStatus];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type EmploymentPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectEmployeePersonalInfo";
  segment: "employment";
  subSegment: null;
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

/**
 * @description Response from REST API indicating that DriverLicensePersonalInfoViewModel is expected to be submitted next.
 */
export type DriverLicensePersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectDriverLicensePersonalInfo";
  segment: "identity";
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type GeneralIdentityPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectGeneralIdentityPersonalInfo";
  segment: "identity";
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type HybridIdentityPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectHybridIdentityPersonalInfo";
  segment: "identity";
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type LicensedProfessionalPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectLicensedProfessionalPersonalInfo";
  segment: "licensedProfessional";
  availableStatuses?: LicensedProfessionalStatus[];
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type MoverPersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectMoverPersonalInfo";
  segment: "recentMover";
  availableStatuses?: RecentMoverStatus[];
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

export type LowIncomePersonalInfoResponse = {
  submissionUrl: string;
  currentStep: "collectLowIncomePersonalInfo";
  segment: "lowIncome";
  availableStatuses?: LowIncomeStatus[];
  errorIds?: [ErrorId];
  instantMatchAttempts?: number;
} & VerificationResponse;

/**
 * @description Response from REST API indicating that DocUploadRequest is expected to be submitted next.
 */
export type DocUploadResponse = {
  verificationId: DatabaseId;
  submissionUrl: string;
  currentStep: "docUpload";
  errorIds?: [ErrorId];
  rejectionReasons?: RejectionReasons[];
  maxReviewTime?: MaxReviewTime;
  estimatedReviewTime?: EstimatedReviewTime;
} & WithLocaleAndCountryChoiceAndSegment;

/**
 * @description Response from REST API indicating that SSO is expected to be submitted next.
 */
export type SSOResponse = {
  verificationId: DatabaseId;
  loginUrl: string;
  cancelUrl: string;
  currentStep: "sso";
  errorIds?: [ErrorId];
} & WithLocaleAndCountryChoiceAndSegment;

export type SMSLoopResponse = {
  verificationId: DatabaseId;
  submissionUrl: string;
  errorIds?: [ErrorId];
  currentStep: "smsLoop";
} & WithLocaleAndCountryChoiceAndSegment;

export type EmailLoopResponse = {
  verificationId: DatabaseId;
  submissionUrl: string;
  errorIds?: [ErrorId];
  currentStep: "emailLoop";
  currentState: "collectOrganizationEmail" | "pending";
  canResendEmailLoop: boolean;
  cancelUrl: string;
} & WithLocaleAndCountryChoiceAndSegment;

export type IDCheckLoopResponse = {
  verificationId: DatabaseId;
  submissionUrl: string;
  errorIds?: [ErrorId];
  currentStep: "idCheckLoop";
  sdkToken?: string;
} & WithLocaleAndCountryChoiceAndSegment;

/**
 * @description Response from REST API. No further requests are expected.
 */
export type SuccessResponse = {
  verificationId: DatabaseId;
  currentStep: "success";
  rewardCode: string;
  redirectUrl?: string;
  rewardData?: string[];
  consumerInfoState?: ConsumerInfoState;
} & WithLocaleAndCountryChoiceAndSegment;

/**
 * @description Response from REST API. No further requests are expected.
 */
export type ConsolationResponse = {
  verificationId: DatabaseId;
  currentStep: "consolation";
  consolationRewardCode: string;
  segment: Segment;
  subSegment: SubSegment;
  redirectUrl?: string;
} & WithLocaleAndCountryChoiceAndSegment;

/**
 * @description Response from REST API. No further requests are expected.
 */
export type OverrideResponse = {
  verificationId: DatabaseId;
  currentStep: "override";
  segment: Segment;
  subSegment: SubSegment;
  submissionUrl: string;
  errorIds?: [ErrorId];
} & WithLocaleAndCountryChoiceAndSegment;

/**
 * @description Response from REST API. Unrecoverable error. No further requests are expected. Recommend starting over.
 */
export type ErrorResponse = {
  verificationId: DatabaseId;
  currentStep: "error";
  subSegment: SubSegment | null;
  errorIds: [ErrorId]; // Don't supply a message, only an id like "notEnoughInfo" or "unknown", so we can look up the message based on locale
  redirectUrl: string | null;
} & WithLocaleAndCountryChoiceAndSegment;

/**
 * @description Response from REST API. Poll statusUrl for updates while document is reviewed.
 */
export type PendingResponse = {
  verificationId: DatabaseId;
  currentStep: "pending";
  statusUrl: string;
  maxReviewTime?: MaxReviewTime;
  estimatedReviewTime?: EstimatedReviewTime;
} & WithLocaleAndCountryChoiceAndSegment;

export type FieldDescription = {
  key: FieldId;
};

export type FieldsToCollect = {
  required: FieldDescription[];
  optional: FieldDescription[];
};

export type CollectFieldsResponse = {
  verificationId: DatabaseId;
  currentStep: VerificationStep;
  fieldsToCollect: FieldsToCollect;
} & WithLocaleAndCountryChoiceAndSegment;

export type DocTypeResult = {
  [country: string]: { documentType: DocumentTypes }[];
};

export type ApplicableDocTypesResponse = {
  results: DocTypeResult;
};

export type ConsumerInfoState =
  | "consumerNotFound"
  | "consumerFound"
  | "consumerFoundNewFieldsAvailable"
  | "consumerFoundNotActionable"
  | "UNKNOWN";

// #endregion Responses -----------------------------------------------------------------------

// #region Theme -----------------------------------------------------------------------
/**
 * @description Theme information about the Program that was created at my.sheerid.com
 */
export type ProgramTheme = {
  intl: {
    locale: Locale;
    messages: ProgramThemeMessages;
  };
  /** @deprecated - Use themeChoices.customCss instead */
  customCss: string;
  /** @deprecated - Use themeChoices.logoUrl instead */
  logoUrl?: string;
  privacyPolicyUrl?: string;
  isTestMode?: boolean;
  openOrgSearchEnabled?: boolean;
  isSmsNotifierConfigured?: boolean;
  remainingRewardCodes?: number | null;
  smsLoopEnabled?: boolean;
  overrideEnabled?: boolean;
  threatMetrixEnabled?: boolean;
  idCheckAddOnEnabled?: boolean;
  ipqsDeviceFingerprintEnabled?: boolean;
  strictMilitaryValidationEnabled?: boolean;
  docUploadEnabled?: boolean;
  config: {
    brandInfo?: {
      faqUrl: string | null;
      emailAddress: string | null;
      phoneNumber: string | null;
    };
    countries: Country[];
    states?: State[];
    locales: Locale[];
    maxReviewTime: MaxReviewTime;
    estimatedReviewTime: EstimatedReviewTime;
    marketConsent: MarketConsentOptions;
    customFaqLink: FaqUrl;
    customMetadata: MetadataConfig;
    orgSearchUrl: string;
    orgRemoteSource?: OrganizationRemoteSource;
    orgTypes: OrganizationType[];
    excludedOrganizationIds: number[];
    orgSearchCountryTags?: {
      [country in Country]?: string[];
    };
    orgSearchAffiliationOverrides?: {
      [a: string]: {
        [a: string]: string[];
      };
    };
    offerType: "noCode" | "staticCode" | "rewardPool" | undefined;
    minAge: number | null;
    maxAge: number | null;
    rewardDisplay: RewardDisplayEnum[];
    onfidoReportNames?: string[];
    onfidoIncludedCountries?: string[];
    segment?: Segment;
    // future: redirect URL
  };
  accountId?: string; // keep optional until HC-131 is completed
  themeChoices: {
    logoUrl?: string;
    font?: string;
    backgroundColor?: string;
    primaryFontColor?: string;
    buttonColor?: string;
    buttonFontColor?: string;
    linkColor?: string;
    h1FontColor?: string;
    helperFontColor?: string;
    customCss?: string;
    landingPage: {
      logoUrl?: string;
      backgroundImageUrl?: string;
      backgroundColor?: string;
      primaryFontColor?: string;
      layout?: VerificationFormLayout;
    };
  };
};

export type EstimatedReviewTime = "A_FEW_MINUTES" | "A_HALF_HOUR" | "A_FEW_HOURS" | "A_FEW_DAYS";
export type MaxReviewTime =
  | "2_MIN"
  | "20_MIN"
  | "A_HALF_HOUR"
  | "2_HRS"
  | "24_HRS"
  | "2_DAY"
  | "3_DAY";
export type FaqUrl = string;

/**
 * @description List of countries supported by SheerID
 */
export type Country = keyof Countries;

export type State = keyof States;

export type Messages = DefaultMessages & ProgramThemeMessages & SegmentSpecificMessages;
export type NestedMessagePaths = NestedKeyOf<Messages>;

export interface MessagesModule {
  defaultMessages: DefaultMessages;
  pluralizationsAndDateLocaleData: any; // from react-intl
  segmentMessages: {
    [S in Segment]: SegmentSpecificMessages;
  };
  requestOrganizationMessages: RequestOrganizationMessages;
}

/**
 * @description These strings are set by a user editing your program in my.sheerid.com
 */
export type ProgramThemeMessages = {
  companyName?: string;
  // These are the strings we show for the actual error message. Not the error step title, nor the error step button text.
  errorId?: {
    verificationLimitExceeded?: string;
    noRemainingRewardCodes?: string;
  };
  lowRewardPool?: string | null; // null if it should not be shown
  step?: {
    personalInfo?: {
      title?: string;
    };
    success?: {
      redirectUrl?: string;
      redirectButtonText?: string;
      subtitle?: string;
      title?: string;
    };
    docUpload?: {
      title?: string;
      subtitle?: string;
    };
    error?: {
      errorId?: {
        verificationLimitExceeded?: {
          title?: string;
        };
        unauthorizedAccountStatus?: {
          title?: string;
          buttonText?: string;
        };
        noRemainingRewardCodes?: {
          title?: string;
          buttonText?: string;
        };
        expiredProgram?: {
          title?: string;
          buttonText?: string;
        };
        inactiveProgram?: {
          title?: string;
          buttonText?: string;
        };
        missingRequiredMetadata?: {
          title?: string;
          buttonText?: string;
        };
      };
    };
  };
};

/**
 * @description These strings vary by program segment
 */
export type SegmentSpecificMessages = {
  emailExplanation: string;
  addressExplanation?: string;
  schoolName?: string;
  schoolNamePlaceholder?: string;
  step: {
    personalInfo: {
      title: string;
      subtitle: string;
      howDoesVerifyingWorkDetails: string;
      tryAgain: {
        title: string;
        subtitle: string;
      };
      verifyingTitle: string;
    };
    docUpload: {
      howDoesVerifyingWorkDetails: string;
      uploadInstructions: string;
      title: string;
      subtitle: string;
    };
    success: {
      title: string;
      subtitle: string;
      redirectButtonText: string;
    };
    sso?: {
      title?: string;
      subtitle?: string;
      subtitle2?: string;
      login?: string;
      cancel?: string;
      alternative?: string;
    };
    emailLoop?: DeepPartial<DefaultMessages["step"]["emailLoop"]>;
  };
};

/**
 * @description These strings are provided by default
 */
export type DefaultMessages = {
  activeDutyStartDate: string;
  address: string;
  addressPlaceholder: string;
  birthDate: string;
  branchOfService: string;
  branchOfServicePlaceholder: string;
  changeLanguage: string;
  city: string;
  cityPlaceholder: string;
  company: string;
  companyPlaceholder: string;
  copied: string;
  country: string;
  countryPlaceholder: string;
  countries: Countries;
  dateTime: DateTimeMessages;
  dischargeDate: string;
  driverLicenseNumber: string;
  driverLicenseNumberPlaceholder: string;
  driverLicenseState: string;
  emailAddress: string;
  emailAddressPlaceholder: string;
  ebtCardNumber: string;
  error: string;
  errorId: {
    [P in ErrorId]: string;
  };
  firstName: string;
  firstNamePlaceholder: string;
  howDoesReviewWork: string;
  howDoesVerifyingWork: string;
  informationTransferredToUS: string;
  instant: string;
  lastName: string;
  lastNamePlaceholder: string;
  limitExceededError: string;
  memberId: string;
  memberIdPlaceholder: string;
  optional: string;
  loading: string;
  locales: {
    [P in Locale]: string;
  };
  militaryStatus: string;
  noOptions: string;
  lowRewardPool: string;
  organization: string;
  organizationPlaceholder: string;
  lowIncomeOrganization: string;
  lowIncomeOrganizationPlaceholder: string;
  optIn: string;
  personalInformation: any;
  personalOrSchoolIsFine: string;
  phoneNumber: string;
  phoneNumberExplanation: string;
  phoneNumberWarnMessage1: string;
  phoneNumberWarnMessage2: string;
  phoneUsPlaceholder: string;
  postalCode: string;
  postalCodeExplanation: string;
  postalCodePlaceholder: string;
  poweredBy: string;
  proceed: string;
  requestSchool: string;
  requiredFields: string;
  rewardCodeEmailNotification: string;
  school: string;
  schoolName: string;
  schoolNamePlaceholder: string;
  sheeridFaqs: string;
  privacyPolicy: string;
  smsCodePlaceholder: string;
  state: string;
  statePlaceholder: string;
  states: States;
  status: string;
  statusPlaceholder: string;
  step: {
    success: {
      copyCouponCode: string;
      emailNotification: string;
      verificationOnly: string;
      rememberMe: {
        consumerFound: {
          title: string;
          description: string;
          button: string;
        };
        consumerNotFound: {
          title: string;
          description: string;
          button: string;
        };
      };
    };
    consolation: {
      title: string;
      subtitle: string;
      verificationOnly: string;
    };
    override: {
      title: string;
      subtitle: string;
      subtitle2: string;
      verificationOverrideCodeLabel: string;
      submitOverrideCodeButtonLabel: string;
      errors: {
        invalidCode: string;
        codeOverrideLimit: string;
      };
    };
    pending: {
      titleCountdown: string;
      titleReview: string;
      subtitle: string;
      subtitleAlt: string;
      docDiffLang: string;
      turnaroundTime: string;
      subtitle2: string;
      subtitle3: string;
      subtitleCountdown: string;
      idCheckLoop: {
        title: string;
        subtitle: string;
        subtitle2: string;
      };
    };
    personalInfo: {
      verifyingTitle: string;
      verifyingSubtitle: string;
    };
    collectMedicalPersonalInfo: {
      registrationNumberLabel: string;
      registrationNumberExplanation: string;
      numeroColegiadoLabel: string;
      numeroColegiadoExplanation: string;
      autorisationsIdLabel: string;
      autorisationsIdExplanation: string;
      gbrNummerLabel: string;
      gbrNummerExplanation: string;
      globalLocationNumberLabel: string;
      globalLocationNumberExplanation: string;
    };
    docUpload: {
      acceptableUploadExamples: string;
      acceptedTypes: string;
      addFile: string;
      cancelButtonLabel: string;
      fileInstructions: string;
      rejectionReasons: {};
      submitButtonLabel: string;
      rejectedSubtitle: string;
      nameChanged: string;
      allowedDocuments: string;
      cancelDocUploadLabel: string;
      // These vary in structure as well as by-segment
      experiment: {
        student: {
          title: string;
        };
        teacher: {
          title: string;
        };
        medical: {
          title: string;
        };
        military: {
          title: string;
        };
        firstResponder: {
          title: string;
        };
      };
      uploadInfo: {
        affiliation: string;
        fullName: string;
        organization: string;
        student: {
          school: string;
          enrollmentDate: string;
        };
        teacher: {
          school: string;
          currentSchoolYear: string;
        };
        military: {
          serviceBranch: string;
          dischargeDate: string;
          currentAffiliation: string;
          uploadInstructionsDependent: string;
          uploadInstructionsMilitaryIdTitle: string;
          uploadInstructionsMilitaryIdContent: string;
        };
        senior: {
          birthDate: string;
        };
        age: {
          birthDate: string;
        };
        firstResponder: {
          organization: string;
          currentAffiliation: string;
          license: string;
        };
        medical: {
          status: string;
          validDate: string;
        };
        employment: {
          company: string;
          currentAffiliation: string;
        };
        identity: {
          validDate: string;
        };
        licensedProfessional: {
          licenseStatus: string;
          validDate: string;
        };
        recentMover: {
          address: string;
          validDate: string;
        };
        lowIncome: {
          validDate: string;
          fullName: string;
          ebtNumber: string;
        };
        experiment: {
          fullname: string;
          institutionName: string;
          dateCurrentYear: string;
          military: {
            branchOfService: string;
            militaryFamily: {
              currentMilitaryFamilyStatus: string;
            };
            reservist: {
              currentReservistStatus: string;
            };
            veteran: {
              currentVeteranStatus: string;
            };
            militaryRetiree: {
              currentRetireeStatus: string;
            };
            activeDuty: {
              currentActiveDutyStatus: string;
            };
          };
          firstResponder: {
            organizationName: string;
            emt: {
              currentEMTStatus: string;
            };
            police: {
              currentPoliceStatus: string;
            };
            firefighter: {
              currentFirefighterStatus: string;
            };
          };
          medical: {
            medicalRole: string;
            validDate: string;
          };
        };
      };
      // These vary in structure as well as by-segment
      acceptableUploads: {
        student: {
          idCard: string;
          classSchedule: string;
          tuitionReceipt: string;
        };
        teacher: {
          idCard: string;
          payStub: string;
        };
        member: {
          idCard: string;
          payStub: string;
        };
        senior: {
          birthCertificate: string;
          driversLicense: string;
          passport: string;
          stateId: string;
        };
        age: {
          birthCertificate: string;
          driversLicense: string;
          passport: string;
          stateId: string;
        };
        military: {
          activeDuty: string;
          veteran: string;
          reservistA: string;
          retiree: string;
          dependent: string;
          goldStarFamily: string;
        };
        medical: {
          licenseCertificate: string;
          idCard: string;
          photoPayStub: string;
        };
        employment: {
          employeeIdCard: string;
          payStub: string;
          officialLetter: string;
        };
        firstResponder: {
          idCard: string;
          payStub: string;
          letter: string;
        };
        licensedProfessional: {
          license: string;
        };
        recentMover: {
          changeOfAddress: string;
          mortgageDeed: string;
          rentalAgreement: string;
        };
        lowIncome: {
          programCard: string;
          programApprovalLetter: string;
          programApprovalScreenshot: string;
          programBankStatement: string;
        };
        experiment: {
          student: {
            schoolId: string;
            classSchedule: string;
            tuitionReceipt: string;
          };
          teacher: {
            officialLetter: string;
            teacherId: string;
            payStub: string;
          };
          medical: {
            idBadge: string;
            medicalLicense: string;
            payStub: string;
            letterEmployment: string;
          };
          firstResponder: {
            idCard: string;
            officialLetter: string;
            payStub: string;
          };
          military: {
            activeDuty: {
              leaveEarningStatement: string;
              uniformedServicesId: string;
            };
            militaryRetiree: {
              blueUniformedId: string;
              vaHealthBenefitsCard: string;
              retireeId: string;
              dd214Form: string;
              vaDigitalId: string;
            };
            veteran: {
              dd214Form: string;
              dischargeCertificate: string;
              vaHealthBenefitsCard: string;
              vaDigitalId: string;
              driverLicenseVeteran: string;
              departmentVeteranId: string;
            };
            reservist: {
              leaveEarningStatement: string;
              uniformedServicesId: string;
            };
          };
          doNotUploadMilitaryIdTitle: string;
          doNotUploadMilitaryIdContent: string;
          allowedDocumentTypes: string;
          addDocuments: string;
        };
      };
    };
    sso: {
      title: string;
      subtitle: string;
      subtitle2: string;
      cancel: string;
      login: string;
      preheader: string;
      alternative: string;
    };
    collectSocialSecurityNumber: {
      selectOption: string;
      title: string;
      uploadDoc: string;
      uploadInstead: string;
      useSsn: string;
    };
    smsLoop: {
      titleWithNumber: string;
      titleWithoutNumber: string;
      verificationCode: string;
      errors: {
        codeVerification: string;
        codeExpired: string;
        codeResendLimit: string;
        resend: string;
      };
      successResend: string;
      incorrectNumber: {
        incorrectNumber1: string;
        incorrectNumber2: string;
        incorrectNumber3: string;
      };
      resendButton: string;
      submitButton: string;
    };
    emailLoop: {
      title: string;
      subtitleWithEmail: string;
      subtitleWithoutEmail: string;
      subtitle2: string;
      successResend: string;
      resendButton: string;
      errors: {
        resend: string;
        invalidEmail: string;
      };
      skipEmail: string;
      skipEmail2: string;
      skipEmailCTA: string;
      collectOrganizationEmail: {
        title: string;
        subtitle: string;
        email: {
          label: string;
          send: string;
        };
        alternative: string;
      };
    };
    idCheckLoop: {
      title: string;
      subtitle: string;
      continue: string;
      terms: string;
      disclaimer: string;
      steps: {
        step1: string;
        step2: string;
        step3: string;
        idCheck: {
          title: string;
          subtitle: string;
        };
        selfie: {
          title: string;
          subtitle: string;
        };
        docReview: {
          title: string;
          subtitle: string;
        };
      };
      docTypes: {
        passport: {
          title: string;
          subtitle: string;
        };
        driverLicense: {
          title: string;
          subtitle: string;
        };
        residencePermit: {
          title: string;
          subtitle: string;
        };
        identityCard: {
          title: string;
          subtitle: string;
        };
      };
      countrySelector: {
        title: string;
      };
      docTypeSelector: {
        title: string;
        subtitle: string;
      };
    };
    error: ProgramThemeMessages["step"]["error"]; // Let the program theme dictate this section of this structure.
    collectIdentifier: {
      submitButton: string;
      inputExplanation: string;
    };
    completeAuthentication: {
      title: string;
      subtitle: string;
      manuallyVerifyButton: string;
    };
    collectPersonalInfo: {
      submitButton: string;
    };
  };
  streetAddress: string;
  ssn: string;
  ssnPlaceholder: string;
  universityName: string;
  verificationPurposesOnly: string;
  verifyAndContinue: string;
  verifyMyStudentStatus: string;
  verifyMyTeacherStatus: string;
  verifyMyMembershipStatus: string;
  verifyMyMilitaryStatus: string;
  verifyMyIdentityStatus: string;
  verifyMyMedicalProfessionalStatus: string;
  verifyMyEmploymentStatus: string;
  verifyMyLicensedProfessionalStatus: string;
  verifyMyMoverStatus: string;
  verifyMyLowIncomeStatus: string;
  FIREFIGHTER: string;
  POLICE: string;
  EMT: string;
  SEARCH_AND_RESCUE: string;
  verifyMyFirstResponderStatus: string;
  VETERAN: string;
  MILITARY_RETIREE: string;
  RESERVIST: string;
  ACTIVE_DUTY: string;
  MILITARY_FAMILY: string;
  GOLD_STAR_FAMILY: string;
  DOCTOR: string;
  NURSE: string;
  DENTIST: string;
  PHARMACIST: string;
  OTHER_HEALTH_WORKER: string;
  HOME_BUYER: string;
  OTHER_MOVER: string;
  SNAP_BENEFITS: string;
  OTHER_GOVERNMENT_ASSISTANCE: string;
  COMMUNITY_ELIGIBILITY_PROVISION: string;
  LICENSED_COSMETOLOGIST: string;
  LICENSED_REAL_ESTATE_AGENT: string;
  VETERINARIAN: string;
  CHILD_CARE_WORKER: string;
  LIBRARIAN: string;
  INTERIOR_DESIGNER: string;
  ARCHITECT: string;
  GENERAL_CONTRACTOR: string;
  tryAgain: string;
  footerText: string;
  infoShared1: string;
  infoShared2: string;
  studentInfoShared: string;
  militaryInfoShared: string;
  firstResponderInfoShared: string;
  getHelp: string;
  militaryFamilyCardNote: string;
};

type DateTimeMessages = {
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  month: string;
  day: string;
  year: string;
  "2_MIN": string;
  "20_MIN": string;
  "2_HRS": string;
  "24_HRS": string;
  "2_DAY": string;
  "3_DAY": string;
  A_FEW_MINUTES: string;
  A_HALF_HOUR: string;
  A_FEW_HOURS: string;
  A_FEW_DAYS: string;
};

/**
 * @description These strings are provided for the Request Organization Form
 */
export type RequestOrganizationMessages = {
  title: string;
  description: string;
  noCountry: string;
  searchByOrgName: string;
  completeRequest: string;
  submit: string;
  eligibleOrgs: string;
  ineligibleOrgs: string;
  none: string;
  changeLanguage?: DefaultMessages["changeLanguage"];
  poweredBy?: DefaultMessages["poweredBy"];
  country?: DefaultMessages["country"];
  countries?: DefaultMessages["countries"];
  locales?: DefaultMessages["locales"];
  copied: string;
  fields: {
    countryPlaceholder: string;
    domainLabel: string;
    domainPlaceholder: string;
    orgNameLabel: string;
    orgNamePlaceholder: string;
  };
  step: {
    success: {
      title: string;
      description: string;
    };
    error: {
      title: string;
      description: string;
      seeingProblem: string;
      contactUs: string;
    };
  };
  errorId: {
    invalidCountry: DefaultMessages["errorId"]["invalidCountry"];
    invalidEmail: DefaultMessages["errorId"]["invalidEmail"];
    invalidFirstName: DefaultMessages["errorId"]["invalidFirstName"];
    invalidLastName: DefaultMessages["errorId"]["invalidLastName"];
    requiredField: string;
    invalidField: string;
    invalidUrl: string;
    tooManyResults: string;
  };
  orgType?: {
    university: string;
    k12: string;
    postSecondary: string;
    highSchool: string;
    eligible: string;
    ineligible: string;
  };
  faq: string;
};

// #endregion Theme -----------------------------------------------------------------------

// #region VerificationService ---------------------------------------------------------
/**
 * @description View-Model Verification Service
 * @private
 */
export interface VerificationService
  extends VerificationServiceValues,
    VerificationServiceFunctions {}

export interface VerificationServiceValues {
  readonly isLoading: boolean;
  readonly loadingStep?: VerificationStep;
  readonly isErrored: boolean;
  readonly fieldValidationErrors: FieldValidationErrors;
  readonly messages: Messages;
  readonly verificationResponse: VerificationResponse;
  readonly viewModel: ViewModel;
  readonly programTheme: ProgramTheme;
  readonly previousViewModel: ViewModel;
  readonly previousVerificationResponse: VerificationResponse;
  readonly programId: DatabaseId;
  readonly orgList: Organization[];
  readonly formValidationOptions?: FormValidationOptions;
  readonly overrideStep?: VerificationStep;
}

export interface VerificationServiceFunctions {
  readonly fetchNewVerificationRequest: (
    programId: DatabaseId,
    segment: Segment,
    previousViewModel: ViewModel,
    trackingId?: string,
    forceNewVerificationRequest?: boolean,
  ) => Promise<void>;
  readonly fetchExistingVerificationRequest: (
    programId: DatabaseId,
    verificationId: string,
    previousVerificationResponse?: VerificationResponse,
    previousViewModel?: ViewModel,
    needsLoading?: boolean,
  ) => Promise<void>;
  readonly updateViewModel: (viewModel: ViewModel) => void;
  readonly updateProgramTheme: (programTheme: ProgramTheme) => void;
  readonly updateFieldValidationErrors: (fieldValidationErrors: FieldValidationErrors) => void;
  readonly submitStep: (
    stepName: VerificationStep,
    viewModel: ViewModel,
    previousResponse: VerificationResponse,
  ) => Promise<void>;
  readonly updateLocale: (
    viewModel: ViewModel,
    programTheme: ProgramTheme,
    segment: Segment,
  ) => void;
  readonly resetState: () => void;
}
// #endregion VerificationService -----------------------------------------------------------------------

// #region RequestOrganizationService ---------------------------------------------------------
/**
 * @description View-Model Request Organization Service
 * @private
 */
export type RequestOrganizationService = {
  viewModel: RequestOrganizationViewModel;
  programTheme: ProgramTheme;
  programId: DatabaseId;
  submitted: boolean;
  messages: RequestOrganizationMessages;
  error: string;
  locale: Locale;
  isLoading: number;
  isInitialized: boolean;
};
// #endregion RequestOrganizationService -----------------------------------------------------------------------

// #region Redux -----------------------------------------------------------------------
/**
 * @private
 */
export type ReduxState = {
  programId: DatabaseId;
  programTheme: ProgramTheme;
  isLoading: boolean;
  loadingStep?: VerificationStep;
  isErrored: boolean;
  viewModel: ViewModel;
  previousViewModel: ViewModel;
  previousVerificationResponse: VerificationResponse;
  verificationResponse: VerificationResponse;
  fieldValidationErrors: FieldValidationErrors;
  messages: StringMap;
  orgList: Organization[];
  formValidationOptions: FormValidationOptions;
  overrideStep?: VerificationStep;
};

export type VerificationStore = Store<ReduxState, VerificationServiceAction>;

/**
 * @description Create a bound action (wrapped in Redux's dispatch and ready to call)
 */
export type BoundActionCreator = (dispatch: Dispatch, getState?: Function) => BoundAction;
export type BoundAction = (...args: any[]) => Promise<object>;

export interface GenericAction {
  type: string;
  payload: any;
}

export interface SetMessagesAction {
  type: "SET_MESSAGES";
  messages: StringMap;
}

export interface ProgramIdAction {
  type: "PROGRAM_ID";
  programId: DatabaseId;
}

export interface VerificationResponseAction {
  type: "VERIFICATION_RESPONSE";
  verificationResponse: VerificationResponse;
}

export interface FieldValidationErrorsAction {
  type: "FIELD_VALIDATION_ERRORS";
  fieldValidationErrors: FieldValidationErrors;
}

export interface ViewModelAction {
  type: "VIEW_MODEL";
  viewModel: ViewModel | Partial<ViewModel>;
  partial?: boolean; // if false, replace previous viewModel in its entirety. Must pass a full viewModel object
}

export interface FormValidationOptionsAction {
  type: "FORM_VALIDATION_OPTIONS";
}

export interface RequiredMetadataCheckAction {
  type: "REQUIRED_METADATA_CHECK";
}

export interface PreLoadOrgsAction {
  type: "PRE_LOAD_ORGS";
  orgList: Organization[];
}

export interface PreviousViewModelAction {
  type: "PREVIOUS_VIEW_MODEL";
  previousViewModel: ViewModel;
}

export interface ProgramThemeAction {
  type: "PROGRAM_THEME";
  programTheme: ProgramTheme;
  partial?: boolean; // if false, replace previous programTheme in its entirety. Must pass a full programTheme object
}

export interface IsLoadingAction {
  type: "IS_LOADING";
  isLoading: boolean;
  loadingStep?: VerificationStep;
}

export interface IsErroredAction {
  type: "IS_ERRORED";
  isErrored: boolean;
}

export interface ResetStateAction {
  type: "RESET_STATE";
}

export interface ForceUpdate {
  type: "FORCE_UPDATE";
}

export type VerificationServiceAction =
  | SetMessagesAction
  | ProgramIdAction
  | VerificationResponseAction
  | FieldValidationErrorsAction
  | ViewModelAction
  | PreLoadOrgsAction
  | PreviousViewModelAction
  | ProgramThemeAction
  | IsLoadingAction
  | IsErroredAction
  | ResetStateAction
  | FormValidationOptionsAction
  | ForceUpdate
  | RequiredMetadataCheckAction;

/**
 * @private
 * @deprecated Use a specific action interface (or create one) from below
 */
export type ReduxAction = VerificationServiceAction;

// #endregion Redux -----------------------------------------------------------------------

// #region Hooks
/**
 * @description Defines the structure of the hooks that have been added, and that the system knows about.
 * @private
 */
export type RegisteredHooks = {
  [key: string]: Hook[];
};

/**
 * @description Defines a list of hooks names that are available.
 *      Certain events throughout the software will call an arbitrary callback, by this name.
 *      These names are meant to be descriptive, to indicate when the callback (hook) will be called.
 */
export type Hook =
  | {
      name: "ON_VERIFICATION_READY";
      callback: (verificationResponse: VerificationResponse) => void;
    }
  | {
      name: "ON_VERIFICATION_SUCCESS";
      callback: (verificationResponse: SuccessResponse) => void;
    }
  | {
      name: "ON_VERIFICATION_STEP_CHANGE";
      callback: (verificationResponse: AllResponseTypes) => void;
    }
  | {
      name: "ON_FORM_LOCALE_CHANGE";
      callback: (locale: Locale) => void;
    };

export type HookName =
  | "ON_VERIFICATION_READY"
  | "ON_VERIFICATION_SUCCESS"
  | "ON_VERIFICATION_STEP_CHANGE"
  | "ON_FORM_LOCALE_CHANGE";
// #endregion Hooks

// #region Conversion
/**
 * @description The shape of the request payload to send when calling a conversion endpoint.
 */
export type ConversionRequest = {
  amount?: number;
  currency?: string;
  tags?: string[];
};

/**
 * @description The shape of the response object after calling a conversion endpoint.
 */
export type ConversionResponse = {
  id: string;
};

/**
 * @description Methods related to performing a conversion with SheerID.
 */
export interface Conversion {
  /**
   * @description Record a conversion to the REST API using verificationId
   */
  convertByVerificationId(
    verificationId: DatabaseId,
    conversionRequest: ConversionRequest,
  ): Promise<ConversionResponse>;

  /**
   * @description Record a conversion to the REST API using a previously-supplied trackingId
   */
  convertByTrackingId(
    accountId: DatabaseId,
    trackingId: string,
    conversionRequest: ConversionRequest,
  ): Promise<ConversionResponse>;

  /**
   * @description Listen to a SheerID iFrame form and store verificationId as a cookie.
   * Use as step 1 of 2, in combination with convert()
   */
  listenForVerificationId(): void;

  /**
   * @description Attempt to record a conversion using a verificationId retrieved from cookies
   * Use as step 2 of 2, in combination with listenForVerificationId
   */
  convert(conversionRequest: ConversionRequest): void;
}
// #endregion Conversion

// #region Validators
export type Validator = (
  value: FieldContent,
  formOptions?: FormValidationOptions,
) => ErrorId | ExtendedErrorId;
// #endregion Validators

// #region Deprecated
/**
 * @deprecated
 */
export type Intl = any;
// #endregion Deprecated

type Countries = {
  AD: string;
  AE: string;
  AF: string;
  AG: string;
  AI: string;
  AL: string;
  AM: string;
  AN: string;
  AO: string;
  AR: string;
  AS: string;
  AT: string;
  AU: string;
  AW: string;
  AZ: string;
  BA: string;
  BB: string;
  BD: string;
  BE: string;
  BF: string;
  BG: string;
  BH: string;
  BI: string;
  BJ: string;
  BM: string;
  BN: string;
  BO: string;
  BR: string;
  BS: string;
  BT: string;
  BV: string;
  BW: string;
  BY: string;
  BZ: string;
  CA: string;
  CD: string;
  CF: string;
  CG: string;
  CH: string;
  CI: string;
  CK: string;
  CL: string;
  CM: string;
  CN: string;
  CO: string;
  CR: string;
  CU: string;
  CV: string;
  CY: string;
  CZ: string;
  DE: string;
  DJ: string;
  DK: string;
  DM: string;
  DO: string;
  DZ: string;
  EC: string;
  EE: string;
  EG: string;
  EH: string;
  ER: string;
  ES: string;
  ET: string;
  FI: string;
  FJ: string;
  FK: string;
  FM: string;
  FO: string;
  FR: string;
  GA: string;
  GB: string;
  GD: string;
  GE: string;
  GF: string;
  GH: string;
  GI: string;
  GL: string;
  GM: string;
  GN: string;
  GP: string;
  GQ: string;
  GR: string;
  GS: string;
  GT: string;
  GU: string;
  GW: string;
  GY: string;
  HK: string;
  HM: string;
  HN: string;
  HR: string;
  HT: string;
  HU: string;
  ID: string;
  IE: string;
  IL: string;
  IN: string;
  IO: string;
  IQ: string;
  IR: string;
  IS: string;
  IT: string;
  JM: string;
  JO: string;
  JP: string;
  KE: string;
  KG: string;
  KH: string;
  KI: string;
  KM: string;
  KN: string;
  KP: string;
  KR: string;
  KW: string;
  KY: string;
  KZ: string;
  LA: string;
  LB: string;
  LC: string;
  LI: string;
  LK: string;
  LR: string;
  LS: string;
  LT: string;
  LU: string;
  LV: string;
  LY: string;
  MA: string;
  MC: string;
  MD: string;
  ME: string;
  MG: string;
  MH: string;
  MK: string;
  ML: string;
  MM: string;
  MN: string;
  MO: string;
  MP: string;
  MQ: string;
  MR: string;
  MS: string;
  MT: string;
  MU: string;
  MV: string;
  MW: string;
  MX: string;
  MY: string;
  MZ: string;
  NA: string;
  NC: string;
  NE: string;
  NF: string;
  NG: string;
  NI: string;
  NL: string;
  NO: string;
  NP: string;
  NR: string;
  NU: string;
  NZ: string;
  OM: string;
  PA: string;
  PE: string;
  PF: string;
  PG: string;
  PH: string;
  PK: string;
  PL: string;
  PM: string;
  PN: string;
  PR: string;
  PS: string;
  PT: string;
  PW: string;
  PY: string;
  QA: string;
  RE: string;
  RO: string;
  RS: string;
  RU: string;
  RW: string;
  SA: string;
  SB: string;
  SC: string;
  SD: string;
  SE: string;
  SG: string;
  SH: string;
  SI: string;
  SK: string;
  SL: string;
  SM: string;
  SN: string;
  SO: string;
  SR: string;
  SS: string;
  ST: string;
  SV: string;
  SY: string;
  SZ: string;
  TC: string;
  TD: string;
  TF: string;
  TG: string;
  TH: string;
  TJ: string;
  TK: string;
  TL: string;
  TM: string;
  TN: string;
  TO: string;
  TR: string;
  TT: string;
  TV: string;
  TW: string;
  TZ: string;
  UA: string;
  UG: string;
  UM: string;
  US: string;
  UY: string;
  UZ: string;
  VA: string;
  VC: string;
  VE: string;
  VG: string;
  VI: string;
  VN: string;
  VU: string;
  WF: string;
  WS: string;
  XK: string;
  YE: string;
  YT: string;
  ZA: string;
  ZM: string;
  ZW: string;
};

export type States = {
  AK: string;
  AL: string;
  AR: string;
  AS: string;
  AZ: string;
  CA: string;
  CO: string;
  CT: string;
  DC: string;
  DE: string;
  FL: string;
  GA: string;
  GU: string;
  HI: string;
  IA: string;
  ID: string;
  IL: string;
  IN: string;
  KS: string;
  KY: string;
  LA: string;
  MA: string;
  MD: string;
  ME: string;
  MI: string;
  MN: string;
  MO: string;
  MS: string;
  MT: string;
  NC: string;
  ND: string;
  NE: string;
  NH: string;
  NJ: string;
  NM: string;
  NV: string;
  NY: string;
  OH: string;
  OK: string;
  OR: string;
  PA: string;
  PR: string;
  RI: string;
  SC: string;
  SD: string;
  TN: string;
  TX: string;
  UT: string;
  VA: string;
  VI: string;
  VT: string;
  WA: string;
  WI: string;
  WV: string;
  WY: string;
};

/**
 * @description List of locales supported by SheerID
 */
export type Locale = typeof Locales[number];

export type DocUploadData = {
  fileName: string;
  mimeType: string;
  fileSize: number;
};

export type DocumentData = {
  documentId: string;
  status: string;
  mimeType: string;
  fileSize: number;
  uploadUrl: string;
  errors: Array<string>;
};

export type IdentifierField = "EMAIL";
