/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";

import {
  VerificationStepsEnum,
  MedicalProfessionalStatusDefaultMessagesEnum,
} from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import {
  VerificationService,
  FormSelectChoice,
  Organization,
  MedicalProfessionalPersonalInfoViewModel,
  MedicalProfessionalPersonalInfoResponse,
  CollectFieldsResponse,
  FieldDescription,
  FormFieldConfig,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
  produceDraftViewModel,
  getStatusLabel,
  getAvailableMedicalStatuses,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PostalCodeComponent as PostalCode } from "../FormFields/PostalCode/PostalCodeComponent";
import { StatusComponent as MedicalStatus } from "../FormFields/Status/StatusComponent";
import { MedicalProfessionalOrganizationComponent as MedicalProfessionalOrganization } from "../FormFields/MedicalProfessionalOrganization/MedicalProfessionalOrganizationComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { MemberIdComponent as MemberId } from "../FormFields/MemberId/MemberIdComponent";
import { getFieldsToCollect } from "../../lib/ServerApi/VerificationApiClient";
import { getSafe } from "../../lib/utils/objects";
import { logger } from "../../lib/utils/logger/logger";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepMedicalProfessionalPersonalInfoComponentProps {
  verificationService: VerificationService;
  intl: any;
}

interface MemberIdMessageKeys {
  labelKey: string;
  explanationKey: string;
}

type MemberIdFieldType =
  | "registrationNumber"
  | "numeroColegiado"
  | "autorisationsId"
  | "globalLocationNumber"
  | "gbrNummer";

const memberIdMessageKeysByCountry: Record<string, MemberIdFieldType> = {
  AT: "gbrNummer",
  ES: "numeroColegiado",
  DK: "autorisationsId",
  CH: "globalLocationNumber",
  IE: "registrationNumber",
  GB: "registrationNumber",
};

export const StepMedicalProfessionalPersonalInfo = ({
  intl,
  verificationService,
}: StepMedicalProfessionalPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as MedicalProfessionalPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as MedicalProfessionalPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);
  const [memberIdMessageKeys, setMemberIdMessageKeys] = React.useState<
    MemberIdMessageKeys | undefined
  >(undefined);
  const [showMemberId, setShowMemberId] = React.useState<boolean>(false);

  const locale = viewModel.localeChoice.value;

  const updateMedicalProfessionalViewModel = (
    key: keyof MedicalProfessionalPersonalInfoViewModel,
    value: any,
  ) => {
    const nextState = produceDraftViewModel(viewModel, key, value);
    verificationService.updateViewModel(nextState);
  };

  React.useEffect(() => {
    (async () => {
      if (viewModel.country && viewModel.status) {
        try {
          const collectFieldsResponse: CollectFieldsResponse = await getFieldsToCollect(
            verificationResponse.verificationId,
            verificationResponse.currentStep,
            viewModel,
          );
          const optionalFields = getSafe(() => collectFieldsResponse.fieldsToCollect.optional, []);
          setShowMemberId(
            optionalFields.some((field: FieldDescription) => field.key === "memberId"),
          );
        } catch (error) {
          logger.error(
            `Failed to determine fields to collect: ${error}`,
            VerificationStepsEnum.collectMedicalProfessionalPersonalInfo,
          );
        }
      }
      const memberIdFieldType: MemberIdFieldType = memberIdMessageKeysByCountry[viewModel.country]
        ? memberIdMessageKeysByCountry[viewModel.country]
        : "registrationNumber";
      if (memberIdFieldType) {
        setMemberIdMessageKeys({
          labelKey: `step.collectMedicalPersonalInfo.${memberIdFieldType}Label`,
          explanationKey: `step.collectMedicalPersonalInfo.${memberIdFieldType}Explanation`,
        });
      }
    })();
  }, [viewModel.country, viewModel.status]);

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="medical-personal-info"
      />

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      <MedicalStatus
        segmentId="medical"
        value={{
          value: viewModel.status,
          label: getStatusLabel(
            intl,
            viewModel.status,
            MedicalProfessionalStatusDefaultMessagesEnum,
          ),
        }}
        isErrored={!!fieldValidationErrors.status}
        options={getAvailableMedicalStatuses(intl, verificationResponse.availableStatuses)}
        onChange={(status: FormSelectChoice) => {
          updateMedicalProfessionalViewModel("status", status ? (status.value as string) : "");
          updateFieldValidationErrorsByFieldId(
            "status",
            status ? (status.value as string) : "",
            verificationService,
          );
        }}
      />

      <MedicalProfessionalOrganization
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
        value={
          (verificationService.viewModel as MedicalProfessionalPersonalInfoViewModel).organization
        }
        verificationService={verificationService}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateMedicalProfessionalViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateMedicalProfessionalViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateMedicalProfessionalViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />

      <PostalCode
        isErrored={!!fieldValidationErrors.postalCode}
        onChange={(newValue) => {
          updateMedicalProfessionalViewModel("postalCode", newValue);
          updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
        }}
        value={viewModel.postalCode}
        explanation={
          <FormattedHTMLMessage
            id="postalCodeExplanation"
            defaultMessage="Use the location on file with your licensing body"
          />
        }
      />

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Needed to send you your unique code"
          />
        }
        onChange={(newValue) => {
          updateMedicalProfessionalViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateMedicalProfessionalViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      {showMemberId && (
        <MemberId
          value={viewModel.memberId}
          isErrored={!!fieldValidationErrors.memberId}
          isRequired={false}
          onChange={(newValue) => {
            updateMedicalProfessionalViewModel("memberId", newValue);
            updateFieldValidationErrorsByFieldId("memberId", newValue, verificationService);
          }}
          explanation={
            memberIdMessageKeys && (
              <FormattedHTMLMessage
                id={memberIdMessageKeys.explanationKey}
                defaultMessage="Your Professional Membership Registration Number"
              />
            )
          }
          label={
            memberIdMessageKeys && (
              <FormattedHTMLMessage
                id={memberIdMessageKeys.labelKey}
                defaultMessage="Registration Number"
              />
            )
          }
        />
      )}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateMedicalProfessionalViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <PersonalInfoStepSubmitButton
        step={VerificationStepsEnum.collectMedicalProfessionalPersonalInfo}
        verificationService={verificationService}
        viewModel={viewModel}
        failedInstantMatch={failedInstantMatch}
        buttonText={
          <FormattedHTMLMessage
            id="verifyMyMedicalProfessionalStatus"
            defaultMessage="Verify My Medical Professional Status"
          />
        }
      />
    </PersonalInfoWrapper>
  );
};

export const StepMedicalProfessionalPersonalInfoComponent = injectIntl(
  StepMedicalProfessionalPersonalInfo,
);
