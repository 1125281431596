/**
 * Google Analytics custom event recording. This works if there is a
 * `window.gtag` function available. GA should be provided by the implementation.
 * Should always fail gracefully if gtag is not found.
 */
import {
  VerificationStep,
  ReduxState,
  DocUploadViewModel,
  VerificationResponse,
  WithCoreFields,
} from "../types/types";
import { logger } from "../utils/logger/logger";
import { VerificationStepsEnum } from "../types/runtimeTypes";
import { doNotTrack, getGaAttributes, isNewRelicAvailable, setGaAttributes } from "./gaHelpers";

export enum isTestValues {
  TRUE = "true",
  FALSE = "false",
}

export const testRequestEmailDomains = ["sheerid.com"];

enum Command {
  EVENT = "event",
  SET = "set",
}

export enum EventName {
  ASR_SELECTED = "AsrSelected", // Add School Request clicked by user
  COLLECT_STARTED = "CollectStarted", // Collect step is started
  COLLECT_SUBMITTED = "CollectSubmitted", // Collect step's submit button is clicked
  ORG_SELECTED = "OrgSelected", // an org was clicked from the list
  ORGS_DISPLAYED = "OrgsDisplayed", // orgs are listed/shown to the user
  VIEW_EXPERIMENT = "ViewExperiment", // describe the variation for an experiment. Usually a Launch Darkly experiment
  /** @deprecated use specifically-named events instead, e.g. "LCP" event */
  PERFORMANCE = "Performance",
  USER_PROPERTIES = "user_properties",
  /** @deprecated use specifically-named events instead, e.g. "CollectSubmitted" event */
  VERIFICATION = "Verification",
}

// GA admin config needs to correlate with these dimension index numbers:
export enum CustomDimensionNames {
  // User-scoped custom dimensions:
  age = "age",
  jslibVersionActual = "jslibVersionActual",
  programId = "programId",
  segment = "segment",
  subSegment = "subSegment",
  testMode = "testMode", // whether the program is in test mode, or not
  testRequest = "testRequest", // true if using an email with `@sheerid.com` when filling in the form
  verificationId = "verificationId",
  flags = "flags", // a JSON string of all experiment flags
  // Event-scoped custom dimensions:
  flag = "flag", // deprecated - use "flags" which is a user-scoped dimension
  step = "step", // the verification step on which the event occurred
  stepAction = "step_action", // deprecated - use a descriptive event name instead
}

/**
 * Metrics should be somewhat generic but make sense within the context of a specific event.
 * e.g. "event duration" is generic, could be used for a page load event or a organization
 * search event or a user reading terms and conditions event.
 */
export enum CustomMetricNames {
  /**
   * The duration of an event. See our event documentation for context on what this measures, exactly
   * Unit: milliseconds
   */
  EVENT_DURATION = "eventDuration",
  /**
   * For any search related event, how many characters are in the input at the time of search
   */
  QUERY_LENGTH = "queryLength",
  /**
   * For any search related event, which spot in the result list was chosen?
   * Lower numbers are higher on the list (1 at top). A user selecting rank 1 is ideal.
   */
  SELECTED_RESULT_RANK = "selectedResultRank",
  /**
   * For any event, especially search events, the number of results returned to the user
   */
  NUMBER_OF_RESULTS = "numberOfResults",
  /**
   * For any event, number of times a user had to "re-work" something (a frustration metric)
   * e.g. in a search input box, number of times user pressed backspace or delete
   */
  NUMBER_REWORKS = "numberReworks",
  /**
   * @deprecated
   * @TODO Change this to an event name
   *
   * Specific to step loading
   * Unit: milliseconds
   */
  INITIAL_STEP_LOAD = "initialStepLoad",
  /**
   * @deprecated
   * @TODO Change this to an event name
   *
   * For any event (either entire page load or a portion of a page load) the loading time
   * before most content is painted in-browser
   * Unit: milliseconds
   */
  LCP = "largestContentfulPaint",
}

interface GaEvent {
  eventName: EventName;
  params: {
    [a: string]: string | number;
  };
}

/**
 * GA event for after a search returns results and they display to the user
 */
export interface OrgsDisplayedEvent extends GaEvent {
  eventName: EventName.ORGS_DISPLAYED;
  params: {
    [CustomMetricNames.QUERY_LENGTH]: number;
    /**
     * How long (ms) before showing organization result list to user
     */
    [CustomMetricNames.EVENT_DURATION]: number;
    [CustomMetricNames.NUMBER_OF_RESULTS]: number;
  };
}

/**
 * GA event for when an org is selected by the user
 */
export interface OrgSelectedEvent extends GaEvent {
  eventName: EventName.ORG_SELECTED;
  params: {
    [CustomMetricNames.QUERY_LENGTH]: number;
    [CustomMetricNames.NUMBER_REWORKS]: number;
    [CustomMetricNames.SELECTED_RESULT_RANK]: number;
    /**
     * How long (ms) between first keypress and an org selected
     */
    [CustomMetricNames.EVENT_DURATION]: number;
    [CustomMetricNames.NUMBER_OF_RESULTS]: number;
  };
}

/**
 * GA event for when Add School Request is clicked
 */
export type AsrEvent = {
  eventName: EventName.ASR_SELECTED;
  params: {
    [CustomMetricNames.QUERY_LENGTH]: number;
    [CustomMetricNames.NUMBER_REWORKS]: number;
    /**
     * Time since first letter typed into the search input
     */
    [CustomMetricNames.EVENT_DURATION]: number;
    [CustomMetricNames.NUMBER_OF_RESULTS]: number;
  };
};

/**
 * Collect step first shown to user after page loaded
 */
export type CollectStepStartEvent = {
  eventName: EventName.COLLECT_STARTED;
  params: {};
};

/**
 * Collect step submitted
 */
export type CollectStepSubmitEvent = {
  eventName: EventName.COLLECT_SUBMITTED;
  params: {
    /**
     * Amount of milliseconds it took to fill out the form and click submit
     */
    [CustomMetricNames.EVENT_DURATION]: number;
  };
};

/**
 * Our structured gtag wrapper. That enforces our commands/event names
 */
function gtagWrapper(command: Command, event: EventName, options: unknown) {
  if (doNotTrack()) {
    return logger.info("Not able to track");
  }

  try {
    return window.gtag(command, event, options);
  } catch (error) {
    logger.warn(`Failed ${command}:${event} event`, error);
  }
}

export const recordGaEvent = async (event: GaEvent) => {
  try {
    // Record the event to Google Analytics
    gtagWrapper(Command.EVENT, event.eventName, event.params);

    // ALSO log a page action "event" to New Relic
    const gaAttr = await getGaAttributes();
    const extendedParams = { ...(gaAttr || {}), ...event.params };
    if (isNewRelicAvailable()) {
      window.NREUM.addPageAction(event.eventName, extendedParams);
    }
  } catch (e) {
    logger.error(e);
  }
};

/**
 * Special GA event that happens when the verificationResponse is received from the server
 */
export const recordVerificationResponse = (verificationResponse: VerificationResponse) => {
  try {
    const label =
      verificationResponse.errorIds && Array.isArray(verificationResponse.errorIds)
        ? verificationResponse.errorIds.sort().join(",")
        : undefined;
    setDimension(CustomDimensionNames.verificationId, verificationResponse.verificationId);
    setDimension(CustomDimensionNames.segment, verificationResponse.segment);
    setDimension(CustomDimensionNames.subSegment, verificationResponse.subSegment);
    recordEvent(verificationResponse.currentStep, label);
  } catch (e) {
    logger.warn("Failed recordVerificationResponse event", e);
  }
};

/**
 * Sets or a user dimension
 */
export const setUserDimension = (dimensionName: CustomDimensionNames, value: string) => {
  setGaAttributes({ [dimensionName]: value });
  if (doNotTrack()) {
    return;
  }
  try {
    gtagWrapper(Command.SET, EventName.USER_PROPERTIES, {
      [dimensionName]: value,
    });
  } catch (e) {
    logger.warn(`Failed to set ${dimensionName}`, e);
  }
};

/**
 * Sets a user dimension
 * @deprecated Use setUserDimension instead
 */
export const setDimension = setUserDimension;

export const setGaDimensionTestRequest = (viewModel: WithCoreFields) => {
  try {
    const { email } = viewModel;
    if (
      email &&
      testRequestEmailDomains.filter((domain) => email.indexOf(domain) > -1).length > 0
    ) {
      setDimension(CustomDimensionNames.testRequest, isTestValues.TRUE);
    }
  } catch (e) {
    logger.warn("Error setting testRequest dimension", e);
  }
};

export function observePerformanceMetrics() {
  try {
    if (PerformanceObserver.supportedEntryTypes.includes("largest-contentful-paint")) {
      const LCPObserver = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        const lastEntry = entries[entries.length - 1];
        logger.log("Performance:", CustomMetricNames.LCP, lastEntry.startTime);
        recordPerformanceMetric(CustomMetricNames.LCP, lastEntry.startTime);
        LCPObserver.disconnect();
      });
      LCPObserver.observe({ type: "largest-contentful-paint", buffered: true });
    }

    if (PerformanceObserver.supportedEntryTypes.includes("mark")) {
      const stepRenderObserver = new PerformanceObserver((list) => {
        let entries = list.getEntries();
        entries = entries.filter((e) => e.name === CustomMetricNames.INITIAL_STEP_LOAD);
        const firstEntry = entries[0];
        if (firstEntry) {
          logger.log("Performance:", CustomMetricNames.INITIAL_STEP_LOAD, firstEntry.startTime);
          recordPerformanceMetric(CustomMetricNames.INITIAL_STEP_LOAD, firstEntry.startTime);

          stepRenderObserver.disconnect();
        }
      });
      stepRenderObserver.observe({ type: "mark", buffered: true });
    }
  } catch (e) {
    logger.warn("Error setting up observePerformanceMetrics", e);
  }
}

// #region deprecat
/**
 * Record a Verification Step event
 * @deprecated use recordGaEvent() instead
 */
export const recordEvent = (step: VerificationStep, stepAction: string = "") => {
  gtagWrapper(Command.EVENT, EventName.VERIFICATION, {
    step,
    step_action: stepAction,
  });
};

/** @deprecated Use recordGaEvent() instead */
export const recordPerformanceMetric = (name: CustomMetricNames, value: number) => {
  gtagWrapper(Command.EVENT, EventName.PERFORMANCE, { name, value });
};

/** @deprecated Use recordGaEvent() instead */
export const recordViewModelChange = (oldState: ReduxState, newState: ReduxState) => {
  try {
    if (newState.verificationResponse.currentStep === VerificationStepsEnum.docUpload) {
      if (
        (oldState.viewModel as DocUploadViewModel)?.file1 === undefined &&
        (newState.viewModel as DocUploadViewModel).file1 !== undefined
      ) {
        recordEvent(VerificationStepsEnum.docUpload, "addFiles");
      }
    }
  } catch (e) {
    logger.warn("Failed addFiles event", e);
  }
};

/**
 * An event that is sent once a user sees a variant of an experiment flag
 * Potentially fired multiple times per user/session
 *
 * Records the "flag" dimension value as a string like: "{flagName}:{flagValue}"
 * OR
 * records as just "{flagName}" (old) for backward compatibility w/ ExperimentComponent if you omit the 2nd arg
 */
export const recordViewExperimentEvent = (flagName: string, flagValue?: string) => {
  const flag = flagValue ? `${flagName}:${flagValue}` : flagName;

  gtagWrapper(Command.EVENT, EventName.VIEW_EXPERIMENT, {
    flag,
  });
};
// #endregion deprecat
