import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormFieldComponentProps,
  VerificationServiceProps,
  Organization,
} from "../../../lib/types/types";
import { FormFieldTypeahead } from "../FormFieldTypeahead/FormFieldTypeahead";
import { getOrgSearchUrl } from "../../../lib/ProgramTheme/programThemeGetters";

export const MembershipOrganization = ({
  value,
  isErrored,
  onChange,
  verificationService,
  intl,
  placeholder = "",
}: FormFieldComponentProps & VerificationServiceProps) => (
  <FormFieldTypeahead
    fieldId="membership"
    value={value}
    verificationService={verificationService}
    onChange={(choice: Organization) => onChange(choice)}
    placeholder={
      placeholder ||
      intl.formatHTMLMessage({
        id: "organizationPlaceholder",
        defaultMessage: "Organization",
      })
    }
    label={<FormattedHTMLMessage id="organization" defaultMessage="Organization" />}
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage
          id="errorId.invalidOrganization"
          defaultMessage="You must select an organization from the list"
        />
      )
    }
    orgSearchUrl={getOrgSearchUrl(verificationService.viewModel, verificationService.programTheme)}
    showPlaceholderAndHideLabel={!!placeholder}
  />
);

export const MembershipOrganizationComponent = injectIntl(MembershipOrganization);
