import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

interface PassedProps {
  explanation?: string | JSX.Element; // eslint-disable-line
  onKeyDown?: Function;
}

export const State = ({
  value,
  isErrored,
  onChange,
  intl,
  onKeyDown = undefined,
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <FormFieldText
    fieldId="state"
    value={value}
    onChange={onChange}
    placeholder={
      placeholder || intl.formatHTMLMessage({ id: "statePlaceholder", defaultMessage: "State" })
    }
    onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
    label={<FormattedHTMLMessage id="state" defaultMessage="State" />}
    errorMessage={
      isErrored && <FormattedHTMLMessage id="errorId.invalidState" defaultMessage="Invalid state" />
    }
    showPlaceholderAndHideLabel={!!placeholder}
  />
);

export const StateComponent = injectIntl(State);
