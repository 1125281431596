import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { debounce } from "debounce";
import { fetchOrganization } from "../lib/ServerApi/OrganizationApiClient";
import { OrganizationListComponent } from "./OrganizationList/OrganizationListComponent";
import { OrganizationSearchResp, PropertiesOf, DatabaseId, Locale } from "../lib/types/types";
import { logger } from "../lib/utils/logger/logger";
import { DEBOUNCE_TIMEOUT, DEFAULT_LOCALE } from "../constants";
import { LinkExternal } from "./LinkExternal";
import { EventName, OrgsDisplayedEvent, recordGaEvent } from "../lib/GoogleAnalytics/ga";
import { LoadingSpinnerComponent } from "./LoadingSpinner/LoadingSpinnerComponent";
import { getQueryParam } from "../lib/utils/routing/Url";

interface FetchOrganizationsProps {
  countryCode?: string;
  getItemProps: any;
  highlightedIndex: any;
  itemToString: any;
  minimumSearchValueLength: number;
  onLoaded: any;
  programId: DatabaseId;
  orgSearchUrl?: string;
  locale?: Locale;
  urlAddSchoolForm?: string;
  openOrgSearchEnabled?: boolean;
  onClickAddSchool?: () => void;
  /** @deprecated orgSearchTags has no affect, pass a full orgSearchUrl, instead */
  orgSearchTags?: string;
  /** @deprecated pass a complete orgSearchUrl instead, using the `name` parameter with a value of what you would have given `searchValue` */
  searchValue?: string;
  /** @deprecated. Menu is always mounted now, up in TypeaheadComponent. */
  getMenuProps?: any;
}

interface FetchOrganizationState {
  isErrored: string;
  loading: boolean;
  organizations: OrganizationSearchResp;
}

type AddSchoolRequestRowProps = {
  programId: DatabaseId;
  locale: Locale;
  urlAddSchoolForm: string;
  onClick?: () => void;
};

const AddSchoolRequestRow: React.FC<AddSchoolRequestRowProps> = ({
  urlAddSchoolForm,
  programId,
  locale = DEFAULT_LOCALE,
  onClick = () => undefined,
}: AddSchoolRequestRowProps) => (
  <LinkExternal
    onClick={onClick}
    className="sid-organization-list__item sid-organization-list__request-school sid-link"
    href={`${urlAddSchoolForm}?pid=${programId}&locale=${locale}`}
  >
    <FormattedHTMLMessage
      id="requestSchool"
      defaultMessage="Don't see your school? Request to have it added"
    />
  </LinkExternal>
);

const getSearchValue = (orgSearchUrl: string): string => getQueryParam("name", orgSearchUrl) || "";

/**
 * Support deprecated prop "searchValue"
 * @TODO remove searchValue prop and this function in v2
 * @param orgSearchUrl
 */
export const updateName = (orgSearchUrl: string, searchValue: string): string => {
  if (!searchValue) {
    return orgSearchUrl;
  }
  if (!orgSearchUrl) {
    logger.error("Expected orgSearchUrl");
    return orgSearchUrl;
  }

  try {
    const url = new URL(orgSearchUrl);
    const { origin, pathname, search } = url;
    const params = new URLSearchParams(search);
    params.set("name", searchValue);
    params.sort();
    return `${origin}${pathname}?${params.toString()}`;
  } catch (e) {
    logger.error(e);
  }
};

class FetchOrganizations extends React.Component<FetchOrganizationsProps, FetchOrganizationState> {
  static initialState: FetchOrganizationState = {
    isErrored: null,
    loading: false,
    organizations: null,
  };

  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isErrored: null,
      loading: false,
      organizations: null,
    };
  }

  fetch = async () => {
    const { orgSearchUrl, searchValue } = this.props;
    if (!this.mounted) {
      return;
    }

    const start = Date.now();
    const organizations: OrganizationSearchResp = await fetchOrganization(
      updateName(orgSearchUrl, searchValue),
    );

    if (organizations) {
      const { onLoaded } = this.props;
      onLoaded(organizations);
      this.setState({ organizations, loading: false });
    }

    try {
      const searchTerm = searchValue || getSearchValue(orgSearchUrl) || "";
      const event: OrgsDisplayedEvent = {
        eventName: EventName.ORGS_DISPLAYED,
        params: {
          queryLength: searchTerm.length,
          eventDuration: Date.now() - start,
          numberOfResults: organizations?.length,
        },
      };
      recordGaEvent(event);
    } catch (e) {
      logger.error(e);
    }
  };

  // eslint-disable-next-line react/sort-comp
  reset(overrides: PropertiesOf<FetchOrganizationState>) {
    this.setState({ ...FetchOrganizations.initialState, ...overrides });
  }

  // Prevent searching while user is actively typing
  // eslint-disable-next-line react/sort-comp
  debouncedFetch = debounce(this.fetch, DEBOUNCE_TIMEOUT);

  componentDidMount() {
    const { minimumSearchValueLength, orgSearchUrl, searchValue } = this.props;
    const searchTerm = searchValue || getSearchValue(orgSearchUrl) || "";
    this.mounted = true;

    if (searchTerm.length >= minimumSearchValueLength) {
      this.reset({ loading: true });
      this.debouncedFetch();
    } else {
      logger.info(
        `No organizations fetched. Search searchTerm "${searchTerm}" is less than ${minimumSearchValueLength} characters.`,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { minimumSearchValueLength, orgSearchUrl, searchValue } = this.props;
    const searchTerm = searchValue || getSearchValue(orgSearchUrl);
    const prevSearchValue = prevProps.searchValue || getSearchValue(prevProps.orgSearchUrl);

    if (prevSearchValue !== searchTerm) {
      if (searchTerm.length >= minimumSearchValueLength) {
        this.reset({ loading: true });
        this.debouncedFetch();
      } else {
        this.reset({ organizations: null, loading: false });
        logger.info(
          `No organizations fetched. Search searchTterm "${searchTerm}" is less than ${minimumSearchValueLength} characters.`,
        );
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { isErrored, loading, organizations } = this.state;
    const {
      getItemProps,
      getMenuProps,
      highlightedIndex,
      itemToString,
      orgSearchUrl,
      programId,
      locale,
      urlAddSchoolForm,
      openOrgSearchEnabled,
      searchValue,
      onClickAddSchool,
    } = this.props;

    const searchTerm = searchValue || getSearchValue(orgSearchUrl);

    if (loading) {
      return (
        <div className="sid-organization-list__menu">
          <div className="sid-organization-list__message sid-organization-list__spinner-container">
            <div id="sid-spinner-container">
              <LoadingSpinnerComponent />
            </div>
          </div>
        </div>
      );
    }
    if (isErrored) {
      return (
        <div className="sid-organization-list__menu">
          <div className="sid-organization-list__message">
            {/* TODO: Define correct message ID from backend (backend need to send this message?) */}
            <FormattedHTMLMessage
              id="errorId.unknownError"
              defaultMessage="An unknown error occurred"
            />
          </div>
        </div>
      );
    }

    if (organizations && organizations.length > 0) {
      return (
        <OrganizationListComponent
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          itemToString={itemToString}
          organizations={organizations}
        >
          {searchTerm &&
            !openOrgSearchEnabled &&
            urlAddSchoolForm &&
            (!organizations || organizations.length < 26) && (
              <AddSchoolRequestRow
                onClick={onClickAddSchool}
                urlAddSchoolForm={urlAddSchoolForm}
                programId={programId}
                locale={locale}
              />
            )}
        </OrganizationListComponent>
      );
    }

    if (
      searchTerm &&
      !openOrgSearchEnabled &&
      urlAddSchoolForm &&
      (!organizations || organizations.length === 0)
    ) {
      return (
        <div className="sid-organization-list__menu">
          <AddSchoolRequestRow
            onClick={onClickAddSchool}
            urlAddSchoolForm={urlAddSchoolForm}
            programId={programId}
            locale={locale}
          />
        </div>
      );
    }

    return null;
  }
}

export const FetchOrganizationsComponent = FetchOrganizations;
