import React, { useEffect, useRef } from "react";
import { setRef } from "../../../lib/refs/refs";
import { FormSelectChoice } from "../../../lib/types/types";

interface InputRadioProps {
  fieldId: string;
  index: number;
  option: FormSelectChoice<string, string>;
  value: string;
  onChange: Function;
}

// DO NOT INJECT INTL. Localize your strings pass them down as props
export const InputRadio = ({ fieldId, index, option, value, onChange }: InputRadioProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setRef(fieldId, inputRef.current);
  });

  return (
    <label className="sid-radio__input sid-radio__input-label">
      <input
        id={`sid-radio__${fieldId}-${index}`}
        name={`sid-${fieldId}`}
        type="radio"
        checked={option.value === value}
        value={option.value}
        onChange={(evt) => evt.target.checked && onChange(option.value)}
        ref={inputRef}
      />
      <span>{option.label}</span>
    </label>
  );
};
