import produce from "immer";
import React from "react";
import { FormattedHTMLMessage, InjectedIntl, InjectedIntlProps, injectIntl } from "react-intl";
import {
  FormSelectChoice,
  InputSelectComponentProps,
  Locale,
  VerificationService,
} from "../../../lib/types/types";

import { getAvailableLocaleChoices } from "../../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { SelectComponent } from "../../FormInputs/Select/SelectComponent";

const ChangeLocale = ({
  value,
  options,
  onChange,
  intl,
}: InputSelectComponentProps & InjectedIntlProps) => {
  const newOptions: FormSelectChoice[] = options.map((option) => ({
    value: option.value,
    label: intl.formatHTMLMessage({
      id: `locales.${option.value}`,
      defaultMessage: "Label not found",
    }),
  }));
  const id = "changeLanguageSelector";
  return (
    <div className="sid-change-locale">
      <div className="sid-field__label sid-change-locale__label" id={`${id}-label`}>
        <FormattedHTMLMessage id="changeLanguage" defaultMessage="Change language" />
      </div>
      <SelectComponent
        className="sid-change-locale__select"
        hideDropDownButton
        options={newOptions}
        onChange={(locale: FormSelectChoice) => onChange(locale)}
        overrideInputClassName="sid-change-locale__input"
        value={newOptions.find((option) => option.value === (value && value.value))}
        id={id}
      />
      <div className="sid-l-space-btm-sm" />
    </div>
  );
};

export const ChangeLocaleComponent = injectIntl(ChangeLocale);

/**
 * This V2 component wraps the original one
 * It only takes verificationService as a prop for easier/slimmer invocation and to ensure locale ends up in the viewModel
 */
interface ChangeLocaleV2Props {
  verificationService: VerificationService;
  intl: InjectedIntl;
}

const ChangeLocaleV2 = ({ verificationService, intl }: ChangeLocaleV2Props) => {
  const localeChoices: FormSelectChoice<Locale, string>[] = getAvailableLocaleChoices(
    verificationService.programTheme,
    intl,
  );
  const defaultLocaleChoice: FormSelectChoice<Locale, string> = {
    value: "en-US",
    label: "English",
  };

  const updateLocale = (localeChoice: FormSelectChoice<Locale, string>) => {
    const nextState: typeof verificationService.viewModel = produce(
      verificationService.viewModel,
      (draft: typeof verificationService.viewModel) => {
        draft.localeChoice = localeChoice;
      },
    );
    verificationService.updateLocale(
      nextState,
      verificationService.programTheme,
      verificationService.verificationResponse.segment,
    );
  };

  if (localeChoices.length <= 1) {
    return null;
  }

  return (
    <ChangeLocaleComponent
      options={localeChoices}
      value={verificationService.viewModel.localeChoice || defaultLocaleChoice}
      isErrored={false}
      onChange={(localeChoice: FormSelectChoice<Locale, string>) => updateLocale(localeChoice)}
    />
  );
};

export const ChangeLocaleV2Component = injectIntl(ChangeLocaleV2);
