import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { DEFAULT_LOCALE } from "../../../constants";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { DateComponent } from "../../DateComponent/DateComponent";

type Props = FormFieldComponentProps & { locale?: string };

export const BirthDateComponent = ({
  onChange,
  isErrored,
  isRequired = true,
  errorId,
  value = "2000-01-01",
  locale = DEFAULT_LOCALE,
}: Props) => (
  <DateComponent
    value={value}
    label={<FormattedHTMLMessage id="birthDate" defaultMessage="Date of birth" />}
    explanation={
      <FormattedHTMLMessage
        id="verificationPurposesOnly"
        defaultMessage="Used for verification purposes only"
      />
    }
    onChange={onChange}
    fieldId="birthdate"
    fieldName="birthDate"
    isErrored={isErrored}
    isRequired={isRequired}
    locale={locale}
    errorMsg={
      errorId ? (
        <FormattedHTMLMessage id={`errorId.${errorId}`} defaultMessage="Invalid birth date" />
      ) : (
        <FormattedHTMLMessage id="errorId.invalidBirthDate" defaultMessage="Invalid birth date" />
      )
    }
  />
);
