import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";

import AuthCode from "react-auth-code-input";
import produce from "immer";
import { CompleteAuthenticationViewModel, VerificationService } from "../../lib/types/types";
import {
  submitForm,
  updateFieldValidationErrorsByFieldId,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { AUTH_TOKEN_LENGTH } from "../../constants";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";

interface StepCompleteAuthenticationProps {
  verificationService: VerificationService;
}

const StepCompleteAuthentication = ({ verificationService }: StepCompleteAuthenticationProps) => {
  const viewModel = verificationService.viewModel as CompleteAuthenticationViewModel;
  const { fieldValidationErrors, verificationResponse } = verificationService;
  const { errorIds } = verificationResponse;

  const authInputRef = React.useRef(null);

  const updateCompleteAuthenticationViewmodel = (
    key: keyof CompleteAuthenticationViewModel,
    value: any,
  ) => {
    const nextState: CompleteAuthenticationViewModel = produce(
      viewModel,
      (draft: CompleteAuthenticationViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  const manuallyVerifyViewModel = (): CompleteAuthenticationViewModel => {
    const nextState: CompleteAuthenticationViewModel = produce(
      viewModel,
      (draft: CompleteAuthenticationViewModel) => {
        draft.didManuallyVerify = true;
        delete draft.authenticationCode;
      },
    );
    verificationService.updateViewModel(nextState);

    return nextState;
  };

  React.useEffect(() => {
    if (viewModel.authenticationCode) {
      // reset local viewModel's authenticationCode to prevent form from submitting
      // prematurely when provided with an incorrect auth code.
      viewModel.authenticationCode = "";
    }
  }, []);

  React.useEffect(() => {
    if (viewModel.authenticationCode.length >= AUTH_TOKEN_LENGTH) {
      submitForm(viewModel, verificationService, VerificationStepsEnum.completeAuthentication);
    }
  }, [viewModel.authenticationCode]);

  return (
    <div
      id="sid-step-complete-authentication"
      className="sid-step-complete-authentication-container sid-l-container"
    >
      <div className="sid-header">
        <div className="sid-header__title sid-l-horz-center">
          <FormattedHTMLMessage
            id="step.completeAuthentication.title"
            defaultMessage="Verify with Remember Me"
          />
        </div>
        <div className="sid-header__subtitle sid-l-horz-center">
          <FormattedHTMLMessage
            id="step.completeAuthentication.subtitle"
            defaultMessage="Enter the code sent to your email."
          />
        </div>
      </div>
      <div className="sid-l-space-top-md" />
      <div className="auth-code">
        <AuthCode
          onChange={(token) => {
            updateCompleteAuthenticationViewmodel("authenticationCode", token);
            updateFieldValidationErrorsByFieldId("authenticationCode", token, verificationService);
          }}
          ref={authInputRef}
          allowedCharacters="numeric"
          length={AUTH_TOKEN_LENGTH}
          isPassword
          containerClassName="auth-code__container"
          inputClassName={`auth-code__input${
            fieldValidationErrors.authenticationCode ? " auth-code__input-error" : ""
          }`}
        />
        {fieldValidationErrors.authenticationCode ||
          (errorIds.length > 0 && (
            <div className="auth-code__error-container">
              <div>
                <img
                  alt="red-exclaimation-circle"
                  src="https://assets-resources.sheerid.com/common/images/2018/icons/red-exclaimation-circle.svg"
                />
              </div>
              <div className="auth-code__error-text">
                <FormattedHTMLMessage
                  id="errorId.invalidAuthenticationLoopToken"
                  defaultMessage="The code provided is invalid"
                />
              </div>
            </div>
          ))}
      </div>
      <div className="sid-complete-auth__manually-verify-button-continer">
        <button
          type="button"
          className="sid-btn sid-btn-light sid-complete-auth__manually-verify-button"
          aria-label="submit"
          onClick={() => {
            submitForm(
              manuallyVerifyViewModel(),
              verificationService,
              VerificationStepsEnum.completeAuthentication,
            );
          }}
        >
          <FormattedHTMLMessage
            id="step.completeAuthentication.manuallyVerifyButton"
            defaultMessage="Manually verify"
          />
        </button>
      </div>
    </div>
  );
};
export const StepCompleteAuthenticationComponent = StepCompleteAuthentication;
