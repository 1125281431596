/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import {
  VerificationStepsEnum,
  MilitaryStatusDefaultMessagesEnum,
} from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { resetRefs } from "../../lib/refs/refs";
import { logger } from "../../lib/utils/logger/logger";
import {
  VerificationService,
  FormSelectChoice,
  Organization,
  InactiveMilitaryPersonalInfoViewModel,
  InactiveMilitaryPersonalInfoResponse,
  FormFieldConfig,
  CollectFieldsResponse,
  State,
} from "../../lib/types/types";
import {
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
  getAvailableMilitaryStatuses,
  getStatusLabel,
  getAvailableStateChoices,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { StatusComponent as MilitaryStatus } from "../FormFields/Status/StatusComponent";
import { BranchOfServiceComponent as BranchOfService } from "../FormFields/BranchOfService/BranchOfServiceComponent";
import { DischargeDateComponent as DischargeDate } from "../FormFields/DischargeDate/DischargeDateComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { updateMilitaryViewModel } from "./militaryStepHelpers";

import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { getOptions } from "../../options/options";
import { ExtraMessage } from "../ExtraMessage";
import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { ActiveDutyStartDateComponent } from "../FormFields/ActiveDutyStartDate/ActiveDutyStartDateComponent";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { getFieldsToCollect } from "../../lib/ServerApi/VerificationApiClient";
import { CityComponent } from "../FormFields/City/City";
import { AddressComponent } from "../FormFields/Address/AddressComponent";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { StateSelectComponent } from "../FormFields/State/StateSelectComponent";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";

interface StepInactiveMilitaryPersonalInfoComponentProps {
  intl: any;
  verificationService: VerificationService;
}

export const StepInactiveMilitaryPersonalInfo = ({
  intl,
  verificationService,
}: StepInactiveMilitaryPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as InactiveMilitaryPersonalInfoViewModel;
  const previousViewModel =
    verificationService.previousViewModel as InactiveMilitaryPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as InactiveMilitaryPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);
  const [optionalFields, setOptionalFields] = React.useState([]);
  const availableStatuses = getAvailableMilitaryStatuses(verificationService, intl);
  const locale = viewModel.localeChoice.value;

  const updateInactiveMilitaryViewModel = (
    key: keyof InactiveMilitaryPersonalInfoViewModel,
    value: any,
  ) => {
    verificationService.updateViewModel(
      updateMilitaryViewModel(key, value, verificationService, availableStatuses),
    );
  };

  const submitStep = () => {
    const nextState = produce(viewModel, () => {});
    logger.info("StepMilitaryStatusComponent submitting form");
    verificationService.submitStep(
      VerificationStepsEnum.collectMilitaryStatus,
      nextState,
      verificationService.previousVerificationResponse || verificationService.verificationResponse,
    );
  };

  if (
    (viewModel.status && previousViewModel && previousViewModel.status !== viewModel.status) ||
    (viewModel.status &&
      verificationService.verificationResponse.currentStep ===
        VerificationStepsEnum.collectMilitaryStatus)
  ) {
    submitStep();
  }

  React.useEffect(() => {
    (async () => {
      if (
        verificationService.verificationResponse.currentStep ===
          VerificationStepsEnum.collectInactiveMilitaryPersonalInfo &&
        viewModel.countryChoice?.value === "US"
      ) {
        try {
          const collectFieldsResponse: CollectFieldsResponse = await getFieldsToCollect(
            verificationResponse.verificationId,
            VerificationStepsEnum.collectInactiveMilitaryPersonalInfo,
            viewModel,
          );

          setOptionalFields(collectFieldsResponse?.fieldsToCollect?.optional?.map((f) => f.key));
        } catch (error) {
          logger.error(
            `Failed to determine fields to collect: ${error}`,
            VerificationStepsEnum.collectInactiveMilitaryPersonalInfo,
          );
        }
      }
    })();
  }, [verificationService.verificationResponse.currentStep, viewModel.countryChoice]);

  React.useEffect(() => {
    if (viewModel.countryChoice?.value !== "US") {
      setOptionalFields([]);
    }
  }, [viewModel.countryChoice]);

  // Refs should be reset to ensure the field order isn't affected by the previous step
  resetRefs();

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
      className="sid-military-personal-info-container"
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="inactive-military-personal-info"
      />

      <ChangeLocaleV2Component verificationService={verificationService} />
      <CountryComponentWrapper verificationService={verificationService} />

      {availableStatuses ? (
        <MilitaryStatus
          segmentId="military"
          value={{
            value: viewModel.status,
            label: getStatusLabel(intl, viewModel.status, MilitaryStatusDefaultMessagesEnum),
          }}
          isErrored={!!fieldValidationErrors.status}
          errorId="invalidMilitaryStatus"
          options={availableStatuses}
          onChange={(status: FormSelectChoice) => {
            updateInactiveMilitaryViewModel("status", status ? (status.value as string) : "");
            updateFieldValidationErrorsByFieldId(
              "status",
              status ? (status.value as string) : "",
              verificationService,
            );
          }}
        />
      ) : null}

      <BranchOfService
        isErrored={!!fieldValidationErrors.organization}
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
        value={{
          value: (verificationService.viewModel as InactiveMilitaryPersonalInfoViewModel)
            .organization.id,
          label: (verificationService.viewModel as InactiveMilitaryPersonalInfoViewModel)
            .organization.name,
        }}
        verificationService={verificationService}
        organizations={verificationService.orgList}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        locale={locale}
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />

      {verificationService.programTheme.strictMilitaryValidationEnabled && (
        <ActiveDutyStartDateComponent
          isErrored={!!fieldValidationErrors.activeDutyStartDate}
          locale={locale}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("activeDutyStartDate", newValue);
            updateFieldValidationErrorsByFieldId(
              "activeDutyStartDate",
              newValue,
              verificationService,
            );
          }}
          value={viewModel.activeDutyStartDate}
        />
      )}

      <DischargeDate
        isErrored={!!fieldValidationErrors.dischargeDate}
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("dischargeDate", newValue);
          updateFieldValidationErrorsByFieldId("dischargeDate", newValue, verificationService);
        }}
        value={viewModel.dischargeDate}
      />

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Personal email address is recommended"
          />
        }
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      {optionalFields.includes("address1") && (
        <AddressComponent
          value={viewModel.address1}
          isErrored={!!fieldValidationErrors.address1}
          errorId={fieldValidationErrors.address1}
          onChange={(value) => {
            updateInactiveMilitaryViewModel("address1", value);
            updateFieldValidationErrorsByFieldId("address1", value, verificationService);
          }}
        />
      )}
      {optionalFields.includes("city") && (
        <CityComponent
          value={viewModel.city}
          isErrored={!!fieldValidationErrors.city}
          errorId={fieldValidationErrors.city}
          onChange={(value) => {
            updateInactiveMilitaryViewModel("city", value);
            updateFieldValidationErrorsByFieldId("city", value, verificationService);
          }}
        />
      )}
      {optionalFields.includes("state") && (
        <StateSelectComponent
          options={getAvailableStateChoices(verificationService.programTheme, intl)}
          value={viewModel.state || undefined}
          isErrored={Boolean(fieldValidationErrors.state)}
          onChange={async (stateChoice: FormSelectChoice<State, string>) => {
            const stateValue = stateChoice ? stateChoice.value : undefined;
            updateInactiveMilitaryViewModel("state", stateValue);
            updateFieldValidationErrorsByFieldId("state", stateValue, verificationService);
          }}
          label={<FormattedHTMLMessage id="state" defaultMessage="State" />}
        />
      )}
      {optionalFields.includes("postalCode") && (
        <PostalCodeComponent
          required={false}
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updateInactiveMilitaryViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      )}

      {getOptions().customFormFields.map((config: FormFieldConfig) => (
        <FormFieldCustom config={config} verificationService={verificationService} />
      ))}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateInactiveMilitaryViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />

      <PersonalInfoStepSubmitButton
        step={VerificationStepsEnum.collectInactiveMilitaryPersonalInfo}
        verificationService={verificationService}
        viewModel={viewModel}
        failedInstantMatch={failedInstantMatch}
        buttonText={
          <FormattedHTMLMessage
            id="verifyMyMilitaryStatus"
            defaultMessage="Verify My Military Status"
          />
        }
      />
    </PersonalInfoWrapper>
  );
};

export const StepInactiveMilitaryPersonalInfoComponent = injectIntl(
  StepInactiveMilitaryPersonalInfo,
);
