import React from "react";
import { getMockedLoadingStep } from "../../lib/ServerApi/TestingRouteOverrides";
import { VerificationStep } from "../../lib/types/types";
import { LoadingSpinnerComponent as Spinner } from "../LoadingSpinner/LoadingSpinnerComponent";
import LoadingScreenMessage from "./LoadingScreenMessage";

interface Props {
  step?: VerificationStep;
}

// By default, try and getMockedLoadingStep to allow text to be shown when mocking the loading step
// eslint-disable-next-line react/prop-types
const LoadingScreenComponent: React.FC<Props> = ({ step = getMockedLoadingStep() }) => (
  <div className="sid-loading-screen">
    <Spinner />
    {step && <LoadingScreenMessage step={step} />}
  </div>
);

export { LoadingScreenComponent };
