import React from "react";

import produce from "immer";
import { InjectedIntl, injectIntl } from "react-intl";
import { CountryComponent } from "./CountryComponent";
import {
  VerificationService,
  Country,
  FormSelectChoice,
  ViewModel,
  ErrorId,
  FieldId,
} from "../../../lib/types/types";

import {
  getSortedCountryChoices,
  updateFieldValidationErrorsByFieldId,
  produceDraftViewModel,
  updateFieldValidationErrors,
  resetViewModelOrganization,
} from "../../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { validateFieldById } from "../../../lib/validators/validators";
import { logger } from "../../../lib/utils/logger/logger";
import { getQueryParam } from "../../../lib/utils/routing/Url";
import { getSafe } from "../../../lib/utils/objects";

type CountryComponentWrapperProps = {
  verificationService: VerificationService;
  intl: InjectedIntl;
  viewModelDraftDecorator?: (
    draft: ViewModel,
    countryChoice?: FormSelectChoice<Country, string>,
  ) => void;
};

type DeprecatedProps = {
  viewModel?: ViewModel; // deprecated, uses verificationService.viewModel instead
  nextFocusField?: string; // deprecated
};

const BaseCountryComponentWrapper = ({
  verificationService,
  viewModelDraftDecorator = undefined,
  intl,
  viewModel = undefined,
  nextFocusField = undefined,
}: CountryComponentWrapperProps & DeprecatedProps) => {
  if (viewModel) {
    logger.warn("viewModel prop is deprecated, use verificationService.viewModel instead");
  }
  if (nextFocusField) {
    logger.warn("nextFocusField prop is deprecated");
  }
  const locale = getSafe(() => viewModel.localeChoice.value, "en-US");
  const countryChoices: FormSelectChoice<Country, string>[] = getSortedCountryChoices(
    verificationService.programTheme,
    intl,
    locale,
  );
  const countryQueryParam = getSafe(() => getQueryParam("country").toUpperCase());
  const { fieldValidationErrors } = verificationService;
  const hasMultipleCountries = countryChoices.length > 1;

  const updateCountryChoice = (countryChoice?: FormSelectChoice<Country, string>) => {
    const nextState: ViewModel = produce(verificationService.viewModel, (draft: ViewModel) => {
      draft.countryChoice = countryChoice || undefined;

      // Adds backwards compatibility with Age and Employment programs
      (draft as any).country = countryChoice ? countryChoice.value : undefined;
      if (viewModelDraftDecorator) {
        viewModelDraftDecorator(draft, countryChoice);
      }
    });
    verificationService.updateViewModel(nextState);
  };

  React.useEffect(() => {
    const includesUS = countryChoices.some((choice) => choice.value === "US");
    if (!hasMultipleCountries && !includesUS) {
      const fieldsToSkip: FieldId[] = verificationService.viewModel.fieldsToSkipValidation
        ? [...verificationService.viewModel.fieldsToSkipValidation, "country"]
        : ["country", "address1", "postalCode", "state", "city"];
      verificationService.updateViewModel(
        produceDraftViewModel(
          verificationService.viewModel,
          "fieldsToSkipValidation",
          fieldsToSkip,
        ),
      );
    }
  }, [hasMultipleCountries]);

  React.useEffect(() => {
    const updatedFieldValidationErrors = produce(
      verificationService.fieldValidationErrors,
      (draft) => {
        if (draft.country) {
          draft.country = validateFieldById(
            "country",
            verificationService.viewModel.countryChoice,
            verificationService.formValidationOptions,
          ) as ErrorId;
        }
        if (
          "postalCode" in verificationService.viewModel &&
          verificationService.viewModel.postalCode
        ) {
          draft.postalCode = validateFieldById(
            "postalCode",
            verificationService.viewModel.postalCode,
            verificationService.formValidationOptions,
          ) as ErrorId;
        }
      },
    );
    updateFieldValidationErrors(updatedFieldValidationErrors, verificationService);
    resetViewModelOrganization(verificationService);
  }, [verificationService.viewModel.countryChoice]);

  // sets initial country selection based on optional query string param (e.g. ?country=GB)
  React.useEffect(() => {
    const countryChoice = countryChoices.find((country) => country.value === countryQueryParam);
    if (countryChoice) {
      updateCountryChoice(countryChoice);
    }
  }, [countryQueryParam]);

  if (!hasMultipleCountries) {
    return null;
  }

  return (
    <CountryComponent
      isRequired
      options={countryChoices}
      value={verificationService.viewModel.countryChoice}
      isErrored={Boolean(fieldValidationErrors.country)}
      onChange={async (countryChoice: FormSelectChoice<Country, string>) => {
        const countryValue = countryChoice ? countryChoice.value : undefined;
        updateCountryChoice(countryChoice);
        updateFieldValidationErrorsByFieldId("country", countryValue, verificationService);
      }}
    />
  );
};

export const CountryComponentWrapper = injectIntl(BaseCountryComponentWrapper);
