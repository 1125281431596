import { DatabaseId, ConversionRequest, ConversionResponse, Conversion } from "../types/types";
import { getOptions } from "../../options/options";
import { PostJson } from "./Network";
import {
  assertValidTrackingId,
  assertValidDatabaseId,
  assertValidConversionRequest,
} from "../types/assertions";

/**
 * @description Record a successful conversion, such as an e-commerce purchase or account sign-up, using the verificationId that
 *      a user received while verifying.
 */
export const convertByVerificationId: Conversion["convertByVerificationId"] = async (
  verificationId: DatabaseId,
  conversionRequest: ConversionRequest,
): Promise<ConversionResponse> => {
  assertValidDatabaseId(verificationId);
  assertValidConversionRequest(conversionRequest);
  const conversionUrl = `${getOptions().restApi.serviceUrl}${
    getOptions().restApi.resources.conversion.base
  }`;
  const fullUrl = `${conversionUrl}verification/${verificationId}`;
  const conversionResponse = (await PostJson(fullUrl, conversionRequest)) as ConversionResponse;
  return conversionResponse;
};

/**
 * @description Record a successful conversion, such as an e-commerce purchase or account sign-up, using an arbitrary trackingId.
 *      When beginning the verification process, supply a trackingId, such as a customer's ID from an e-commerce platform.
 *      Later, after that customer has completed a purchase, provide that same trackingId and SheerID can correlate the conversion
 *      to that verification attempt.
 *      This allows for high-level reporting on the effectiveness of your SheerID program and account.
 */
export const convertByTrackingId: Conversion["convertByTrackingId"] = async (
  accountId: DatabaseId,
  trackingId: string,
  conversionRequest: ConversionRequest,
): Promise<ConversionResponse> => {
  assertValidDatabaseId(accountId);
  assertValidTrackingId(trackingId);
  assertValidConversionRequest(conversionRequest);
  const conversionUrl = `${getOptions().restApi.serviceUrl}${
    getOptions().restApi.resources.conversion.base
  }`;
  const fullUrl = `${conversionUrl}account/${accountId}/trackingId/${trackingId}`;
  const conversionResponse: ConversionResponse = (await PostJson(
    fullUrl,
    conversionRequest,
  )) as ConversionResponse;
  return conversionResponse;
};
