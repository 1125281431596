import React from "react";
import { FormattedHTMLMessage } from "react-intl";

interface IDCheckLoopPendingProps {
  logo: JSX.Element;
}

const IDCheckLoopPending = ({ logo }: IDCheckLoopPendingProps) => (
  <div className="sid-header sid-l-horz-center" data-testid="sid-review-pending">
    <div className="sid-l-horz-center">{logo}</div>

    <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
      <FormattedHTMLMessage
        id="step.pending.idCheckLoop.title"
        defaultMessage="We're reviewing your documents"
        tagName="h1"
      />
    </div>

    <div className="sid-header__subtitle-one">
      <FormattedHTMLMessage
        id="step.pending.idCheckLoop.subtitle"
        defaultMessage="Hold tight, turnaround time is typically a few minutes or less."
      />
    </div>

    <div className="sid-header__subtitle-two sid-l-space-top-md sid-h-bold-text">
      <FormattedHTMLMessage
        id="step.pending.idCheckLoop.subtitle2"
        defaultMessage="We'll send you an email when we're finished reviewing."
      />
    </div>

    <div className="sid-l-space-top-xl" />
  </div>
);

export const IDCheckLoopPendingComponent = IDCheckLoopPending;
