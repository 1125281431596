/* eslint-disable react/prop-types */
import React, { ComponentType, LazyExoticComponent, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Locale, ReduxState, Segment } from "../../../lib/types/types";
import { FlagNames } from "../flags";
import { useFlag } from "../hooks";
import { getLocaleSafely } from "../../../lib/intl/intl";
import { recordViewExperimentEvent } from "../../../lib/GoogleAnalytics/ga";

interface OwnProps<T extends {} = {}, C extends ComponentType<T> = ComponentType<T>> {
  flag: FlagNames;
  // If not supplied all segments are considered valid
  segments?: Segment[];
  // Defaults to `[en-US]`
  locales?: Locale[];
  Default: C | LazyExoticComponent<C>;
  Experiment: C | LazyExoticComponent<C>;
  props?: T;
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const segment = state.verificationResponse?.segment;
  const showForSegment = segment && ownProps.segments ? ownProps.segments.includes(segment) : true;
  const locale = getLocaleSafely(state.viewModel, state.verificationResponse);
  return { showForSegment, locale };
};

type GetStateProps = ReturnType<typeof mapStateToProps>;
export type ExperimentProps = OwnProps & GetStateProps;

const ExperimentSwitcher: React.FC<ExperimentProps> = ({
  flag,
  showForSegment,
  locales = ["en-US"],
  locale,
  Default,
  Experiment,
  props,
}) => {
  const isFlagged = useFlag(flag);
  const showForLocale = locales.includes(locale);
  const showExperiment = isFlagged && showForSegment && showForLocale;

  useEffect(() => {
    if (showExperiment) {
      recordViewExperimentEvent(flag);
    }
  }, [flag, showExperiment]);

  const Component = showExperiment ? Experiment : Default;

  return (
    <Suspense fallback="...">
      <Component {...props} />
    </Suspense>
  );
};

export const ExperimentComponent = connect(mapStateToProps)(ExperimentSwitcher);
