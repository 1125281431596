import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FormFieldText } from "../FormFieldText/FormFieldText";

export const EBTCardNumber = ({
  value,
  isErrored,
  onChange,
  intl,
  placeholder = "",
}: FormFieldComponentProps) => (
  <FormFieldText
    fieldId="ebtCardNumber"
    value={value}
    onChange={onChange}
    placeholder={
      placeholder ||
      intl.formatHTMLMessage({ id: "ebtCardNumberPlaceholder", defaultMessage: "EBT card Number" })
    }
    label={<FormattedHTMLMessage id="ebtCardNumber" defaultMessage="EBT card Number" />}
    errorMessage={
      isErrored && (
        <FormattedHTMLMessage
          id="errorId.invalidEBTCardNumber"
          defaultMessage="Invalid EBT Card Number"
        />
      )
    }
    showPlaceholderAndHideLabel={!!placeholder}
  />
);

export const EBTCardNumberComponent = injectIntl(EBTCardNumber);
