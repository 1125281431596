/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { produce } from "immer";

import {
  VerificationService,
  StudentPersonalInfoViewModel,
  Organization,
  StudentPersonalInfoResponse,
  FormFieldConfig,
} from "../../lib/types/types";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import {
  handleEmailOnKeyDown,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { ChangeLocaleV2Component } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { CollegeNameComponent as CollegeName } from "../FormFields/CollegeName/CollegeNameComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { getOptions } from "../../options/options";
import { FormFieldCustom } from "../FormFields/FormFieldCustom/FormFieldCustom";
import { ExtraMessage } from "../ExtraMessage";
import { PersonalInfoWrapper } from "../PersonalInfoStepWrapper/PersonalInfoStepWrapper";
import { PersonalInfoStepSubmitButton } from "../PersonalInfoStepSubmitButton/PersonalInfoStepSubmitButton";
import { PersonalInfoHeader } from "../FormHeader/StepHeaders/PersonalInfoHeaderComponent";
import { useFlag } from "../FeatureFlags/hooks";
import { getEmailValidationError } from "../../lib/validators/validators";
import { Flags } from "../FeatureFlags/flags";

interface StepStudentPersonalInfoComponentProps {
  verificationService: VerificationService;
}

const StepStudentPersonalInfo = ({
  verificationService,
}: StepStudentPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as StudentPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as StudentPersonalInfoResponse;
  const isValidEmail = !getEmailValidationError(viewModel.email);

  // #region email-first-experiment
  const emailFirstVariation: Flags["email-first"] = useFlag("email-first");

  // when emailFirstVariation === "isolated", show email field with a "continue" button
  // then show all other fields with the normal submit button
  const [substep, setSubstep] = React.useState<"emailOnly" | "fieldsExceptEmail" | "default">(
    "default",
  );

  const isEmailUpTop = emailFirstVariation === "all-fields-visible";

  React.useEffect(() => {
    if (emailFirstVariation === "isolated" && substep === "default") {
      setSubstep("emailOnly");
    }
  }, [emailFirstVariation]);

  const handleContinueBtnClick = () => {
    if (!isValidEmail) {
      verificationService.updateFieldValidationErrors({
        ...fieldValidationErrors,
        email: "invalidEmail",
      });
    } else {
      verificationService.updateFieldValidationErrors({
        ...fieldValidationErrors,
        email: undefined,
      });
      setSubstep("fieldsExceptEmail");
    }
  };

  const handleEmailKeydownForExperiment = (event) => {
    if (event.key === "Enter") {
      if (substep === "emailOnly") {
        handleContinueBtnClick();
      } else {
        handleEmailOnKeyDown(event);
      }
    }
  };
  // #endregion email-first-experiment

  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const updateStudentViewModel = (key: keyof StudentPersonalInfoViewModel, value: any) => {
    const nextState: StudentPersonalInfoViewModel = produce(
      viewModel,
      (draft: StudentPersonalInfoViewModel) => {
        draft[key] = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  const locale = viewModel.localeChoice.value;

  return (
    <PersonalInfoWrapper
      verificationService={verificationService}
      step={verificationResponse.currentStep}
    >
      <PersonalInfoHeader
        verificationService={verificationService}
        classNameIdentifier="student-personal-info"
      />

      <ChangeLocaleV2Component verificationService={verificationService} />

      {
        // #region email-first-experiment
        (isEmailUpTop || substep === "emailOnly") && (
          <Email
            value={viewModel.email}
            isErrored={!!fieldValidationErrors.email}
            explanation={
              <FormattedHTMLMessage
                id="emailExplanation"
                defaultMessage="Personal or school email is fine"
              />
            }
            onChange={(newValue) => {
              updateStudentViewModel("email", newValue);
              updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
            }}
            onKeyDown={handleEmailKeydownForExperiment}
          />
        )
        // #endregion email-first-experiment
      }

      {substep !== "emailOnly" && (
        <>
          <CountryComponentWrapper verificationService={verificationService} />
          <CollegeName
            value={(verificationService.viewModel as StudentPersonalInfoViewModel).organization}
            verificationService={verificationService}
            isErrored={!!fieldValidationErrors.organization}
            isRequired
            onChange={(choice: Organization) => {
              updateViewModelOrganization(choice, verificationService);
              updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
            }}
          />
          <div className="sid-names">
            <FirstName
              value={viewModel.firstName}
              isErrored={!!fieldValidationErrors.firstName}
              onChange={(newValue) => {
                updateStudentViewModel("firstName", newValue);
                updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
              }}
            />

            <LastName
              value={viewModel.lastName}
              isErrored={!!fieldValidationErrors.lastName}
              onChange={(newValue) => {
                updateStudentViewModel("lastName", newValue);
                updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
              }}
            />
          </div>
          <BirthDate
            value={viewModel.birthDate}
            isErrored={!!fieldValidationErrors.birthDate}
            errorId={fieldValidationErrors.birthDate}
            locale={locale}
            isRequired
            onChange={(newValue) => {
              updateStudentViewModel("birthDate", newValue);
              updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
            }}
          />
          {emailFirstVariation !== "isolated" && !isEmailUpTop && (
            <Email
              value={viewModel.email}
              isErrored={!!fieldValidationErrors.email}
              explanation={
                <FormattedHTMLMessage
                  id="emailExplanation"
                  defaultMessage="Personal or school email is fine"
                />
              }
              onChange={(newValue) => {
                updateStudentViewModel("email", newValue);
                updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
              }}
              onKeyDown={(event) => handleEmailOnKeyDown(event)}
            />
          )}
          {verificationService.programTheme.isSmsNotifierConfigured ||
          verificationService.programTheme.smsLoopEnabled ? (
            <PhoneNumber
              isRequired={!!verificationService.programTheme.smsLoopEnabled}
              value={viewModel.phoneNumber}
              isErrored={!!fieldValidationErrors.phoneNumber}
              onChange={(newValue) => {
                updateStudentViewModel("phoneNumber", newValue);
                updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
              }}
              selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
            />
          ) : null}
          {getOptions().customFormFields.map((config: FormFieldConfig) => (
            <FormFieldCustom config={config} verificationService={verificationService} />
          ))}{" "}
          <MarketConsentWrapper
            verificationService={verificationService}
            isErrored={!!fieldValidationErrors.marketConsentValue}
            onChange={(newValue) => {
              updateStudentViewModel("metadata", {
                ...verificationService.viewModel.metadata,
                marketConsentValue: newValue,
              });
              updateFieldValidationErrorsByFieldId(
                "marketConsentValue",
                newValue,
                verificationService,
              );
            }}
            viewModel={viewModel}
          />
          <ExtraMessage verificationService={verificationService} suffix="CollectAboveSubmit" />
          <PersonalInfoStepSubmitButton
            step={VerificationStepsEnum.collectStudentPersonalInfo}
            verificationService={verificationService}
            viewModel={viewModel}
            failedInstantMatch={failedInstantMatch}
            buttonText={
              <FormattedHTMLMessage
                id="verifyMyStudentStatus"
                defaultMessage="Verify My Student Status"
              />
            }
          />
        </>
      )}

      {
        // #region email-first-experiment
        substep === "emailOnly" && (
          <>
            <MarketConsentWrapper
              verificationService={verificationService}
              isErrored={!!fieldValidationErrors.marketConsentValue}
              onChange={(newValue) => {
                updateStudentViewModel("metadata", {
                  ...verificationService.viewModel.metadata,
                  marketConsentValue: newValue,
                });
                updateFieldValidationErrorsByFieldId(
                  "marketConsentValue",
                  newValue,
                  verificationService,
                );
              }}
              viewModel={viewModel}
            />
            <div style={{ marginTop: "30px" }}>
              <button
                onClick={handleContinueBtnClick}
                type="submit"
                className={`sid-btn sid-btn--dark sid-l-full-width ${
                  !isValidEmail ? "sid-btn--disabled-like" : ""
                }`}
                aria-labelledby="verify-status-text"
              >
                <span id="verify-status-text">Continue</span>
              </button>
            </div>
          </>
        )
        // #endregion email-first-experiment
      }
    </PersonalInfoWrapper>
  );
};

export const StepStudentPersonalInfoComponent = StepStudentPersonalInfo;
