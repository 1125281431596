import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { VerificationService } from "../../lib/types/types";
import { assertValidSegmentName } from "../../lib/types/assertions";
import { useFlags } from "../FeatureFlags/hooks";
import { getAcceptableUploads } from "./getAcceptableUploads";
import { getOptions } from "../../options/options";

interface Props {
  verificationService: VerificationService;
}

const AcceptableUploadsComponent = ({ verificationService }: Props) => {
  const { segment } = verificationService.verificationResponse;
  const { subSegment } = verificationService.verificationResponse;
  const flags = useFlags();
  const { hideMilitaryFamilyAcceptableUploads } = getOptions();
  assertValidSegmentName(segment);

  const acceptableUploads = getAcceptableUploads(segment, subSegment, {
    flags,
    hideMilitaryFamilyAcceptableUploads,
  });

  if (acceptableUploads.length < 1) {
    return null;
  }

  return (
    <div className="sid-acceptable-uploads-list-block sid-l-space-top-md">
      <span className="sid-acceptable-uploads-examples">
        <strong>
          <FormattedHTMLMessage
            id="step.docUpload.acceptableUploadExamples"
            defaultMessage="Acceptable examples include:"
          />
        </strong>
      </span>
      <ul className="sid-acceptable-uploads-list">
        {acceptableUploads.map((i18nKey) => (
          <li key={i18nKey} className="sid-acceptable-uploads-list__li">
            <FormattedHTMLMessage id={i18nKey} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export { AcceptableUploadsComponent };
