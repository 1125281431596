import React from "react";
import { dashed } from "../../../lib/utils/strings";
import { OptInInputComponent } from "../../FormInputs/OptInInput/OptInInputComponent";

export interface FormFieldCheckboxComponentProps {
  fieldId: string; // should be camelCase
  value: boolean;
  onChange: (value: boolean) => void;
  label: React.ReactNode; // Label is always required for screen readers
  errorMessage?: React.ReactNode;
}

export const FormFieldCheckbox = ({
  fieldId,
  value,
  onChange,
  label,
  errorMessage,
}: FormFieldCheckboxComponentProps) => {
  const labelIntlId = `${fieldId}Label`;
  const labelHtmlId = `sid-${dashed(labelIntlId)}`;
  const errorHtmlId = `sid-${dashed(labelIntlId)}-error`;
  const setOppositeValue = () => onChange(!value);
  return (
    <div className={`sid-field sid-${dashed(fieldId)}`}>
      <div className="sid-l-space-top-md" />
      <div className="sid-checkbox-flex">
        <OptInInputComponent
          id={dashed(fieldId)}
          isErrored={!!errorMessage}
          value={value}
          onChange={setOppositeValue}
          ariaLabelledby={labelHtmlId}
          ariaDescribedBy={errorMessage ? errorHtmlId : ""}
        />
        <div
          className="sid-checkbox__label"
          onClick={setOppositeValue}
          onKeyPress={setOppositeValue}
          role="button"
          tabIndex={0}
          id={labelHtmlId}
        >
          {label}
        </div>
      </div>
      {errorMessage ? (
        <div className="sid-field-error" id={errorHtmlId}>
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};

// DO NOT INJECT INTL. Localize your strings in the calling component and pass them as props to this component.
export const FormFieldCheckboxComponent = FormFieldCheckbox;
